// @flow
import { createReducer } from 'reduxsauce'
import { merge } from 'ramda'

export type LoaderState = { fetching: boolean }
const beginRequest = (state: LoaderState) => merge(state, { fetching: true })

const endRequest = (state: LoaderState) => merge(state, { fetching: false })

const initialState = { fetching: false }

const handlers = { BEGIN_REQUEST: beginRequest, END_REQUEST: endRequest }

export default createReducer(initialState, handlers)
