//@flow
import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAuth } from '~actions/User'
import { track } from '~resources/ahoy'

type props = {
  setAuthError: (any) => void,
  setLoading: (string) => void,
}
const GoogleAuth = ({ setAuthError, setLoading }: props) => {
  const clientID = ENV_VARS.GOOGLE_CLIENT_ID || ''
  const dispatch = useDispatch()

  const handleCallbackResponse = useCallback(
    (response) => {
      track('Click google authentication button')

      dispatch(fetchAuth({ googleidtoken: response.credential })).then(
        ([error]) => {
          if (error) {
            setAuthError(error)
            setLoading('idle')
          } else {
            setLoading('success')
          }
        }
      )
    },
    [dispatch, setAuthError, setLoading]
  )

  const initClient = useCallback(() => {
    window.google?.accounts.id.initialize({
      client_id: clientID,
      callback: handleCallbackResponse,
    })
    window.google?.accounts.id.renderButton(
      document.getElementById('google-sign-in-div'),
      {
        theme: 'outline',
        size: 'large',
      }
    )
  }, [clientID, handleCallbackResponse])

  useEffect(() => {
    const isLoaded = Boolean(document.getElementById('google_client'))
    if (!isLoaded) {
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.id = 'google_client'

      document.body?.appendChild(script)

      script.onload = initClient
    } else {
      initClient()
    }
  }, [initClient])
  return <div id="google-sign-in-div" className="google-sign-in" />
}

export default GoogleAuth
