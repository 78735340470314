import qs from 'qs'

export const getActiveLink = ({
  feature,
  id,
  resource,
  pathname,
  search,
  href,
}) => {
  if (feature === 'retrospective') {
    const { exam_id } = qs.parse(search, {
      ignoreQueryPrefix: true,
    })
    const matchesSelectionRoute = pathname.includes(
      `retrospective/${resource}/${id}/`
    )

    const matchesMapRoute = id === Number(exam_id)

    const matchesTctRoute = pathname.includes(`diagnostica/${id}/`)

    return matchesSelectionRoute || matchesMapRoute || matchesTctRoute
  }

  if (feature === 'enem') {
    const matchesEnemQuestionAnalysis =
      pathname.includes('enem/analise-questoes') &&
      resource === 'question_analysis'

    const matchesEnemMap = pathname.includes('enem/mapa') && resource === 'map'

    return matchesEnemQuestionAnalysis || matchesEnemMap
  }

  return pathname === href
}
