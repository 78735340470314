// @flow
import React from 'react'
import { Stack, Typography, Chip } from '@mui/material'

type Props = {
  amountOfQuestion: number,
}

const Legend = ({ amountOfQuestion }: Props) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Typography variant="body2" component="span" fontWeight={700}>
      Selecione as questões baseadas na habilidade do currículo a ser trabalhada
    </Typography>
    {amountOfQuestion === 0 && (
      <Chip
        label="0 Questões"
        sx={{ height: 30, width: 120, fontSize: 16 }}
        variant="labelInactive"
      />
    )}
    {amountOfQuestion > 0 && (
      <Chip
        label={`${amountOfQuestion} Questões`}
        sx={{ height: 30, width: 120, fontSize: 16 }}
        variant="labelInfo"
      />
    )}
  </Stack>
)

export default Legend
