// @flow
import { config } from '../../config'
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'
import { getFieldsAndIncludes } from '~data/helpers'

const { endpoint, key } = config

type Extension = $Keys<typeof extensionsConfig>

type Params = {
  entityType: string,
  entityId: number,
  extensions?: Extension[],
  ...
}

export type Journey = {
  id: number,
  name: string,
  hour_initial: string,
  hour_end: string,
  active: boolean,
}

type JourneysResponse = { journeys: Journey[] }

export const useJourneys = (params: Params = {}) => {
  const { loading, error, data } = useResource({
    key,
    resource: getJourneys,
    params,
  })

  return { loading, error, data }
}

const extensionsConfig = {
  default: {
    include: ['journeys'],
    fields: [
      { journeys: ['id', 'name', 'hour_initial', 'hour_end', 'active'] },
    ],
  },
  in_use: {
    fields: [{ journeys: ['in_use'] }],
  },
}

const getJourneys = ({
  entityType,
  entityId,
  extensions = [],
}: Params): APIResponse<JourneysResponse> => {
  return fetchWithRoot('get')(endpoint(entityType, entityId), {
    params: { ...getFieldsAndIncludes(extensionsConfig, extensions) },
    bypassLoader: true,
  })
}
