//@flow
import React, { useState, useEffect } from 'react'
import { Stack } from '@mui/material'
import { Exam } from './Exam'
import { Pagination } from '~primitives/molecules/Pagination'
import type { FocoExamEdition } from '~data/manageExams/examList'

type Props = {
  data?: FocoExamEdition[],
  meta?: { last_page: boolean, total_count: number, total_pages: number },
  loading?: boolean,
  handleOpenDeleteModal: ({ name: string, id: number }) => void,
  handleOpenStatusModal: ({ name: string, id: number }) => void,
  page?: number,
}

const ExamsList = ({
  data,
  page,
  meta,
  handleOpenDeleteModal,
  handleOpenStatusModal,
}: Props) => {
  const [expanded, setExpanded] = useState([])

  const isExpanded = (editionId) => expanded.includes(editionId)

  const handleClickExpand = (editionId) => {
    if (isExpanded(editionId)) {
      setExpanded(expanded.filter((id) => id !== editionId))
    } else {
      setExpanded([...expanded, editionId])
    }
  }

  useEffect(() => data?.[0] && setExpanded([data[0].id]), [data])

  return (
    <Stack>
      {data?.map((item) => (
        <Exam
          key={item.id}
          focoExamEdition={item}
          expanded={isExpanded(item.id)}
          onChange={() => handleClickExpand(item.id)}
          handleOpenStatusModal={handleOpenStatusModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
      ))}

      <Pagination totalPages={meta?.total_pages || 0} currentPage={page} />
    </Stack>
  )
}

export default ExamsList
