export const handleFormValidate = (values) => {
  const errors = {
    list: [],
  }

  let errorCount = 0

  values.list.forEach((row, index) => {
    errors.list[index] = {}
    for (const [key] of Object.entries(row)) {
      if (values.list[index][key] === '') {
        errorCount++
        errors.list[index][key] = 'Requerido'
      }
    }
  })

  if (values.period === '') {
    errors.period = 'Requerido'
    errorCount++
  }

  return errorCount > 0 ? errors : {}
}
