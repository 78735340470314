// @flow
import { styled } from '@mui/material/styles'

export const Clearfix = styled('div')`
  &:after {
    content: ' ';
    display: block;
    clear: both;
  }
`
