// @flow
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { config } from '../config'
import { config as eventMaterialsConfig } from '~data/api/v3/curriculumEvents/curriculumMaterials/config'
import { addAuthHeaders, logoutOnError } from '~config/interceptors/auth'

const { endpoint, key } = config

const loaderlessAxios = axios.create()
loaderlessAxios.interceptors.request.use(addAuthHeaders, (error) =>
  Promise.reject(error)
)
loaderlessAxios.interceptors.response.use((response) => response, logoutOnError)

type Material = { name: string, file?: File, url?: string }

type Params = {
  curriculumEventId: number,
  materials: Material[],
}

type Props = {
  onSuccess: (Object) => void,
  onError: (Object) => void,
  onSettled: (Object) => void,
}

export const useCreateCurriculumMaterials = ({
  onError,
  onSuccess,
  onSettled,
}: Props) => {
  const queryClient = useQueryClient()

  return useMutation((params) => createCurriculumMaterials(params), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(key)
      queryClient.invalidateQueries(eventMaterialsConfig.key)
      onSuccess(data)
    },
    onError,
    onSettled,
  })
}

export const createCurriculumMaterials = async ({
  materials,
  curriculumEventId,
}: Params) => {
  const [requests] = await Promise.all([
    materials.map(async (material) => {
      const formData = new FormData()

      if (material.file) {
        formData.append('file', material.file)
      }

      return await axios
        .post(endpoint, formData, {
          params: {
            name: material.name,
            curriculum_event_id: curriculumEventId,
            material_url: material.url,
          },
        })
        .then(async (response) =>
          (await response?.data?.status) === 'success'
            ? response
            : response.data
        )
    }),
  ])

  try {
    const responses = await Promise.all(requests)
    return responses
  } catch (error) {
    throw new Error(error.response?.data.errors.details)
  }
}
