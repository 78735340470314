// @flow
import * as React from 'react'
import { Route } from 'react-router-dom'
import type { ContextRouter, LocationShape } from 'react-router-dom'

type RouteProps = {
  component?: React.ComponentType<*>,
  render?: (router: ContextRouter) => React$Node,
  children?: React.ComponentType<ContextRouter> | React$Node,
  path?: string | Array<string>,
  exact?: boolean,
  strict?: boolean,
  location?: LocationShape,
  sensitive?: boolean,
  component: React.ComponentType<Object>,
  props: Object,
}

const RouteWithProps = ({
  component: Component,
  props,
  children,
  path,
  exact,
  strict,
  location,
  sensitive,
}: RouteProps) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    location={location}
    sensitive={sensitive}
    render={(childProps) => <Component {...props} {...childProps} />}
  >
    {children}
  </Route>
)

export default RouteWithProps
