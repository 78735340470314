// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpoint = '/api/v3/student_profiles'

type Params = {
  schoolId?: string,
  classroomId?: any,
}

type Student = {
  name: string,
  id: string,
}

type Classroom = {
  id: string,
  name: string,
  students: Array<Student>,
}

type Classrooms = {
  classrooms: Array<Classroom>,
}

export const useManageBookletFilterStudents = (params: Params) => {
  const { data, error, loading } = useResource({
    key: 'StudentsList',
    resource: getManageBookletFilterStudents,
    params,
    enabled: !!params?.schoolId && !!params?.classroomId,
  })

  return { loading, error, data }
}

const getManageBookletFilterStudents = (
  params: Params
): APIResponse<Classrooms> =>
  fetchWithRoot('get')(endpoint, {
    params: {
      entity_type: 'school',
      entity_id: params?.schoolId,
      q: {
        classroom_id_eq: params?.classroomId,
      },
      fields: [
        {
          student_profiles: ['id', { user: ['id', 'name'] }],
        },
      ],
      include: [{ student_profiles: ['user'] }],
    },
  })
