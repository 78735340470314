// @flow
import { Chip, Stack, Typography } from '@mui/material'
import React from 'react'

type Props = {
  amountOfQuestions?: number,
}

export const Header = ({ amountOfQuestions = 0 }: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="h5" fontWeight={700}>
        Questões adicionadas
      </Typography>
      {amountOfQuestions === 0 && (
        <Chip
          sx={{ height: 30, width: 120, fontSize: 16 }}
          variant="labelInactive"
          label="0 Questões"
        />
      )}
      {amountOfQuestions > 0 && (
        <Chip
          sx={{ height: 30, width: 120, fontSize: 16 }}
          variant="labelInfo"
          label={`${amountOfQuestions} Questões`}
        />
      )}
    </Stack>
  )
}
