// @flow
import React, { useState } from 'react'
import { Formik } from 'formik'
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import type { RouterHistory } from 'react-router-dom'
import { Stack, Typography, Button, Link } from '@mui/material'
import validate from './validate'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { Form } from '~primitives/Input/Form'
import { PasswordInput } from '~primitives/Input/Password'
import { NotificationBox } from '~primitives/Messages/NotificationBox'
import { track } from '~resources/ahoy'
import Auth from '~resources/auth'
import iconSuccess from '~assets/svg/coloricon_success.svg'
import type { NetworkError } from '~types/Network'
import type { ResetResponse } from '~resources/auth'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import useAhoy from '~hooks/useAhoy'

type Props = { match: { params: { token: string } }, history: RouterHistory }
type State = { error: ?NetworkError, data: ?ResetResponse }
type FormValues = { password: string, confirm: string }
type FormikBag = { setErrors: (FormValues) => void }

const SuccessMessage = styled('div')`
  height: 150px;
  width: 100%;
  border-radius: 2px;
  background-color: #f3f3f3;
  margin-bottom: 20px;
  margin-top: 115px;
  position: relative;

  h2 {
    color: #4e4e4e;
    padding-top: 40px;
    font-size: 14px;
    font-weight: bold;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    color: #4e4e4e;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    margin-top: 10px;
  }

  img {
    margin: 5px;
    max-width: 50px;
  }

  div {
    background-color: #fff;
    border-radius: 37px;
    width: 60px;
    height: 60px;
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -25px;
  }
`

const Password = (props: Props) => {
  useAhoy('Signup / Password')
  const [{ error, data }, setState] = useState<State>({
    error: null,
    data: null,
  })

  const handleSubmit = (
    { password, confirm }: FormValues,
    { setErrors }: FormikBag
  ) => {
    const { token } = props.match.params

    Auth.resetPassword({ password, confirm, token }).then(([error, data]) => {
      setState({ error, data })

      if (error) {
        track('Signup / Password / Error', {
          error_code: error.status,
          error_message: error.message,
        })
        setErrors({ password: 'Senha inválida', confirm: 'Senha inválida' })
      }

      if (data) {
        track('Signup / Password / Success')
      }
    })
  }

  if (data) {
    return (
      <>
        <SuccessMessage>
          <div>
            <img src={iconSuccess} alt="" />
          </div>
          <h2>Senha criada com sucesso!</h2>
          <p>
            Você já pode usar a sua nova senha para acessar a plataforma. Vamos
            começar?
          </p>
        </SuccessMessage>
        <Button
          component={RouterLink}
          variant="primary"
          to="/login"
          width="100%"
        >
          Acesse a plataforma
        </Button>
      </>
    )
  }

  return (
    <Formik
      initialValues={{ password: '', confirm: '' }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, handleChange, errors, values }) => (
        <Form onSubmit={handleSubmit}>
          <header>
            <Typography component="h2" variant="h2">
              Defina sua{' '}
              <UnderlinedHeading as="span">
                <strong>senha</strong>
              </UnderlinedHeading>
            </Typography>
          </header>

          <fieldset>
            <ul>
              <li>
                <PasswordInput
                  value={values.password}
                  error={errors.password}
                  helperText={errors.password}
                  onChange={handleChange}
                  name="password"
                  label="Senha"
                  placeholder="Digite sua nova senha"
                  variant="outlined"
                />
              </li>

              <li>
                <PasswordInput
                  value={values.confirm}
                  error={errors.confirm}
                  helperText={errors.confirm}
                  onChange={handleChange}
                  name="confirm"
                  label="Confirmar senha"
                  placeholder="Digite sua senha novamente"
                  variant="outlined"
                />
              </li>

              {error && (
                <li>
                  <NotificationBox
                    danger
                    title="Não foi possível atualizar a sua senha"
                  >
                    <p>
                      {error.message || 'Erro desconhecido'}{' '}
                      <Link to={'/recuperar-senha'} component={RouterLink}>
                        Clique aqui
                      </Link>{' '}
                      para recuperar sua senha
                    </p>
                  </NotificationBox>
                </li>
              )}
            </ul>
          </fieldset>

          <footer className="navigation-footer">
            <Stack
              direction={{
                sm: 'row-reverse',
                xs: 'column',
              }}
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ flex: 1 }}
            >
              <Button
                type="submit"
                id="submit-form"
                data-testid="submit-form"
                className="link-button"
                disabled={errors.password || errors.confirm}
                variant="primary"
                sx={{ width: { xs: 1, sm: 220 } }}
              >
                Enviar
              </Button>

              <Link component={RouterLink} to="/login">
                <SVGIcon name="previous" color="neutral" size={1} /> Voltar
              </Link>
            </Stack>
          </footer>
        </Form>
      )}
    </Formik>
  )
}

export default Password
