//@flow
import React from 'react'
import { Grid } from '@mui/material'
import { Field, useFormikContext } from 'formik'

import { SelectField } from '~primitives/Input/SelectField'
import { TimePickerField } from '~primitives/Input/TimerPickerField'
import { SwitchField } from '~primitives/Input/SwitchField'

const Fields = () => {
  const journeySelect = [
    { value: '', label: '' },
    { value: 'Matutino', label: 'Matutino' },
    { value: 'Vespertino', label: 'Vespertino' },
    { value: 'Noturno', label: 'Noturno' },
    { value: 'Integral', label: 'Integral' },
  ]

  const { values } = useFormikContext()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Field
            component={SelectField}
            variant="standard"
            name="name"
            label="Período"
            required
            options={journeySelect}
            placeholder="Selecione uma opção"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Field
            component={TimePickerField}
            name="hour_initial"
            label="Hora de início"
            required
            fullWidth
            placeholder="Selecione uma hora"
            ampm={false}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            component={TimePickerField}
            variant="standard"
            name="hour_end"
            label="Hora fim"
            maxTime={values.hout}
            required
            fullWidth
            placeholder="Selecione uma hora"
            ampm={false}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} mt={2}>
          <Field
            component={SwitchField}
            name="active"
            label="Turno ativo"
            required
            placeholder="Turno ativo"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Fields
