// @flow
import React from 'react'
import { Box } from '@mui/material'
import { Header } from './Header'
import { Insights } from './Insights'
import { useFocoExamStudentProfileStatus } from '~data/manageExams/examStatus'
import { formatDecimalNumber } from '~helpers/formatting/numbers'

type Props = {
  editionId: number,
  exam?: { id: number, name: string },
  classroom?: { id: number, name: string },
}

const formatAverageRate = formatDecimalNumber(1)

export const Summary = ({ editionId, exam, classroom }: Props) => {
  const { loading, error, data } = useFocoExamStudentProfileStatus({
    editionId,
    examId: exam?.id,
    classroomId: classroom?.id,
  })

  const averageHitRate = data
    ? formatAverageRate(data?.average_hit_rate * 10)
    : ''

  if (error) return <div>erro</div>

  return (
    <Box component="section" data-testid="exam-result-summary" py={4}>
      <Header
        examName={exam?.name}
        classroomName={classroom?.name}
        loading={loading || !data || !exam || !classroom}
      />

      <Insights
        averageHitRate={averageHitRate}
        answeredRate={data?.answered_rate}
        answeredCount={data?.answered_count}
        studentsCount={data?.students_count}
        notStartedCount={data?.not_started_count}
        loading={loading || !data}
      />
    </Box>
  )
}
