// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export type Grade = {
  id: number,
  name: string,
}

export type GradesResponse = { grades: Grade[] }

export const endpoint = '/api/v3/grades'

const useGrades = () => {
  const { loading, error, data } = useResource({
    resource: getGrades,
    key: 'useQuestionGrades',
  })

  return { loading, error, data }
}

const getGrades = (): APIResponse<GradesResponse> =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      include: ['grades'],
      fields: [{ grades: ['id', 'name'] }],
      q: { foco_questions_id_not_null: true },
    },
  })

export default useGrades
