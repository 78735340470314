//@flow
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'

export const getEndpoint = (questionId: number) =>
  `/api/v3/foco_questions/${questionId}`

type Params = {
  questionId: number,
}

type QuestionOption = {
  id: number,
  alternative: string,
  text: string,
  correct: boolean,
}

type CurriculumSkill = {
  name: string,
  description: string,
}

type Response = {
  id: number,
  code: number,
  text: string,
  statement: string,
  support_image_path: string,
  reference: string,
  command: string,
  foco_question_options: Array<QuestionOption>,
  curriculum_skill: CurriculumSkill,
}

export const useFocoQuestion = (params: Params) => {
  const { data, loading, error } = useResource({
    key: 'useFocoQuestion',
    resource: getQuestionItemDetails,
    params,
  })

  return { data, loading, error }
}

const getQuestionItemDetails = ({
  questionId,
}: Params): APIResponse<Response> =>
  fetchWithRoot('get')(getEndpoint(questionId), {
    params: {
      include: [
        'curriculum_skill',
        'foco_question_options',
        { skill: ['competence'] },
      ],
      fields: [
        [
          'id',
          'code',
          'text',
          'statement',
          'support_image_path',
          'reference',
          'command',
        ],
        {
          curriculum_skill: ['name', 'description'],
          foco_question_options: ['id', 'alternative', 'text', 'correct'],
        },
      ],
    },
  })
