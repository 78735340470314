// @flow
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import {
  Box,
  Stack,
  Typography,
  Button,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
  Select,
} from '@mui/material'
import { Formik, FieldArray } from 'formik'
import { generateCurriculum } from './fetch'
import SelectGroup from './SelectGroup'
import { handleFormValidate } from './validate'
import Feedback from './Feedback'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { Form } from '~primitives/Input/Form'
import { useFeedback } from '~contexts/Feedback'
import { useAcademicYearsBySchool } from '~data/curriculum/school/academicYears'
import { useCurriculumPeriodsBySchool } from '~data/curriculum/school/curriculumPeriods'

type Props = {
  entityId: string,
  setModalIsOpen: (boolean) => void,
}

const CreateCurriculum = ({ entityId, setModalIsOpen }: Props) => {
  const queryClient = useQueryClient()

  const { showMessage } = useFeedback()

  const [curriculumsResponse, setCurriculumsResponse] = useState(null)

  const {
    data: academicYearsData,
    loading: academicYearsLoading,
    error: academicYearsError,
  } = useAcademicYearsBySchool({ schoolId: Number(entityId) })

  const {
    data: curriculumPeriodsData,
    loading: curriculumPeriodsLoading,
    error: curriculumPeriodsError,
  } = useCurriculumPeriodsBySchool({ schoolId: Number(entityId) })

  const { mutate } = useMutation(
    ({ values, setSubmitting }) => {
      const data = values.list.map(({ classroom_id, subject_id }) => {
        return {
          period_id: values.period,
          classroom_id,
          subject_id,
        }
      })
      generateCurriculum({
        data: {
          entity_type: 'school',
          entity_id: entityId,
          list: [...data],
        },
      })
        .then((data) => {
          setCurriculumsResponse(data)
          setSubmitting(false)
        })
        .catch((error) => {
          showMessage({ type: 'error', text: error.message })
        })
    },
    {
      onSuccess: () => {
        setTimeout(() => queryClient.invalidateQueries(), 2000)
      },
      onError: (error) => {
        showMessage({ type: 'error', text: error.message })
      },
    }
  )

  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    mutate({ values, setSubmitting })
  }

  const initialValues = {
    period: '',
    list: [
      {
        grade: '',
        classroom_id: '',
        subject_id: '',
      },
    ],
  }

  return (
    <Modal
      width="670px"
      height="98vh"
      confirmButtonText="Salvar"
      overflowContent={true}
      contentPadding="0"
      showFooter={false}
      footerPadding="20px 35px 30px"
      onClose={() => {
        setModalIsOpen(false)
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validate={handleFormValidate}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          isSubmitting,
          values,
          errors,
          isValid,
        }) => (
          <Box p={2}>
            <Typography variant="h4" margin="15px 0">
              <UnderlinedHeading as="span">
                <strong>Adicionar plano de aula</strong>
              </UnderlinedHeading>
            </Typography>

            {curriculumsResponse ? (
              <>
                <Box
                  sx={{
                    backgroundColor: 'grey.200',
                    padding: 3,
                    borderRadius: 4,
                  }}
                >
                  <Feedback data={curriculumsResponse} />
                </Box>
                <Stack flexDirection="row" justifyContent="flex-end" mt={2.5}>
                  <Button
                    onClick={() => {
                      setModalIsOpen(false)
                    }}
                    variant="primary"
                    sx={{ maxWidth: 160, width: 1 }}
                    disabled={isSubmitting || !isValid}
                    size="small"
                  >
                    Voltar
                  </Button>
                </Stack>
              </>
            ) : (
              <Form onSubmit={handleSubmit} noValidate headingSize="24px">
                {academicYearsError || curriculumPeriodsError ? (
                  <Box my={3}>
                    <Alert variant="filled" severity="error">
                      Ocorreu um erro ao carregar os dados.
                    </Alert>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        backgroundColor: 'grey.200',
                        padding: 3,
                        borderRadius: 4,
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <FormControl variant="inline">
                          <InputLabel id="year-label" required>
                            Ano letivo
                          </InputLabel>
                          <Select
                            labelId="select-year"
                            name="year"
                            value={values.year}
                            onChange={handleChange}
                            disabled={
                              academicYearsLoading ||
                              academicYearsError ||
                              academicYearsData?.academic_years?.length === 0
                            }
                            variant="standard"
                            size="small"
                            placeholder
                            defaultValue=""
                            displayEmpty
                            autoWidth
                            required
                            error={errors.year}
                            MenuProps={{
                              style: { zIndex: 1000001 },
                            }}
                          >
                            <MenuItem value="" disabled>
                              Ano
                            </MenuItem>
                            {academicYearsData?.academic_years?.map(
                              ({ year, id }) => (
                                <MenuItem key={id} value={id}>
                                  {year}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                        <FormControl variant="inline">
                          <InputLabel id="select-period" required>
                            Periodo
                          </InputLabel>
                          <Select
                            labelId="select-period"
                            name="period"
                            value={values.period}
                            onChange={handleChange}
                            disabled={
                              curriculumPeriodsLoading ||
                              curriculumPeriodsError ||
                              curriculumPeriodsData?.curriculum_periods
                                ?.length === 0
                            }
                            error={Boolean(errors.period)}
                            variant="standard"
                            size="small"
                            placeholder
                            defaultValue=""
                            displayEmpty
                            autoWidth
                            MenuProps={{
                              style: { zIndex: 1000001 },
                            }}
                          >
                            <MenuItem value="" disabled>
                              Selecione um período
                            </MenuItem>
                            {curriculumPeriodsData?.curriculum_periods?.map(
                              ({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                  {name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Stack>
                      <Divider sx={{ mt: 1, mb: 2.5 }} />
                      <Stack direction="column" gap={0.5}>
                        <FieldArray
                          name="list"
                          render={(arrayHelpers) =>
                            values.list.map((item, index) => (
                              <SelectGroup
                                errors={errors}
                                values={values}
                                handleChange={handleChange}
                                key={index}
                                index={index}
                                listLength={values.list.length}
                                arrayHelpers={arrayHelpers}
                                year={
                                  academicYearsData?.academic_years.find(
                                    ({ id }) => id === values.year
                                  )?.year
                                }
                                setFieldValue={setFieldValue}
                              />
                            ))
                          }
                        />
                      </Stack>
                    </Box>
                  </>
                )}
                <Stack flexDirection="row" justifyContent="flex-end" mt={2}>
                  <Button
                    variant="neutral"
                    onClick={() => {
                      setModalIsOpen(false)
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    sx={{ maxWidth: 160, width: 1 }}
                    disabled={isSubmitting || !isValid}
                    size="small"
                  >
                    Salvar
                  </Button>
                </Stack>
              </Form>
            )}
          </Box>
        )}
      </Formik>
    </Modal>
  )
}

export default CreateCurriculum
