// @flow
import { useMutation, useQueryClient } from 'react-query'
import { config } from '../../config'
import { config as eventMaterialsConfig } from '~data/api/v3/curriculumEvents/curriculumMaterials/config'
import { fetchWithRoot } from '~helpers/fetch'

const { endpoint } = config

type Props = {
  onSuccess: (Object) => void,
  onError: (Object) => void,
}

type Params = { id: number }

export const useDeleteCurriculumMaterial = ({ onError, onSuccess }: Props) => {
  const queryClient = useQueryClient()

  return useMutation((params) => deleteCurriculumMaterial(params), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(config.key)
      queryClient.invalidateQueries(eventMaterialsConfig.key)
      onSuccess(data)
    },
    onError,
  })
}

export const deleteCurriculumMaterial = async (params: Params) => {
  const [error, data] = await fetchWithRoot('delete')(
    `${endpoint}/${params.id}`,
    { bypassLoader: true }
  )

  if (error) throw new Error(error.message)

  return data
}
