// @flow
import React, { useState, useEffect, useCallback } from 'react'
import { omit } from 'ramda'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Typography,
  Stack,
  TextField,
  useTheme,
  InputAdornment,
} from '@mui/material'
import { getStyles } from './styles'
import useDebounce from '~hooks/useDebounce'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

export const Search = () => {
  const [searchValue, setSearchValue] = useState('')
  const debouncedValue = useDebounce(searchValue, 500)
  const history = useHistory()
  const { search } = useLocation()

  const theme = useTheme()
  const styles = getStyles(theme)

  const handleChange = ({ target }) => {
    setSearchValue(target.value)
  }

  const handleSearch = useCallback(() => {
    const query = {
      ...qs.parse(search, { ignoreQueryPrefix: true }),
      ...(debouncedValue && { q: debouncedValue }),
    }
    history.push({
      search: qs.stringify(debouncedValue ? query : omit('q', query)),
    })
  }, [debouncedValue, history, search])

  useEffect(() => {
    handleSearch()
  }, [handleSearch])

  return (
    <Stack flexDirection="row" alignItems="center" sx={{ ...styles.container }}>
      <Typography variant="body2" fontWeight={700}>
        Buscar
      </Typography>
      <TextField
        sx={{ ml: 1, width: 717, maxWidth: 1, pb: 0 }}
        placeholder="Digite o nome ou email"
        variant="standard"
        onChange={handleChange}
        value={searchValue}
        InputProps={{
          inputProps: {
            'data-testid': 'search-student-input',
          },
          endAdornment: (
            <InputAdornment position="end">
              <SVGIcon name="search" size={6} color="linkColor" />
            </InputAdornment>
          ),
        }}
        size="small"
      />
    </Stack>
  )
}
