// @flow
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'
import { saveBlob } from '~helpers/saveFile'

const endpoint = (id) => `/api/v3/foco_exams/${id}/generate_pdf.pdf`

export const getFocoExamPDF = (
  id: number,
  fileName: string
): APIResponse<string> => {
  return fetchWithRoot('get')(endpoint(id), {
    headers: { Accept: 'text/pdf' },
    responseType: 'blob',
    bypassLoader: false,
  }).then(([error, data]) => {
    if (data) {
      saveBlob({ data, fileName: `${fileName}.pdf` })
    }

    return [error, data]
  })
}
