//@flow
export default function FormControl(theme: Object) {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          position: 'relative',
          paddingBottom: '20px',
        },
      },
      variants: [
        {
          props: { variant: 'inline' },
          style: {
            flexDirection: 'row',
            alignItems: 'baseline',
            gap: theme.spacing(1),
            paddingBottom: 0,
            '& .MuiInput-root': {
              flexGrow: 1,
            },
          },
        },
      ],
    },
  }
}
