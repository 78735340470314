// @flow
import React, { useState } from 'react'
import { Grid, Button } from '@mui/material'
import { getFocoExamPDF } from '~data/api/v3/foco_exams/queries/downloadPDF'

type Props = {
  focoExamId: number,
  examName: string,
  editionName: string,
  gradeName: string,
}

type ButtonStatus = 'waiting' | 'loading' | 'error'

export const DownloadPDF = ({
  focoExamId,
  editionName,
  examName,
  gradeName,
}: Props) => {
  const [status, setStatus] = useState<ButtonStatus>('waiting')

  const fileName = `${editionName} - ${examName} - ${gradeName}`

  const handleClick = () => {
    setStatus('loading')

    getFocoExamPDF(focoExamId, fileName).then(([error]) => {
      if (error) {
        setStatus('error')
        return
      }

      setStatus('waiting')
    })
  }

  return (
    <Grid container direction="row-reverse">
      <Button
        variant="primary"
        onClick={handleClick}
        disabled={status === 'loading'}
        size="small"
      >
        Imprimir
      </Button>
    </Grid>
  )
}
