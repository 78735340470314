import React from 'react'
import { useParams } from 'react-router-dom'
import filters from '~resources/filters'
import { FiltersProvider } from '~contexts/Filters'
import { Content } from '~components/ManageStudents/Content'

export const ManageStudents = () => {
  const { entityId } = useParams()

  return (
    <FiltersProvider
      resource={filters.getManageStudentsFilters}
      config={{
        grade_id: { filter: 'grades', label: 'Série' },
        classroom_id: { filter: 'classrooms', label: 'Turma' },
      }}
      additionalParams={{
        entityId: entityId,
      }}
    >
      <Content />
    </FiltersProvider>
  )
}

export default ManageStudents
