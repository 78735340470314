import { styled } from '@mui/material/styles'

export const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.4, 1),
  background: theme.palette.grey[200],
  borderRadius: 4,
  marginBottom: theme.spacing(2),
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    padding: theme.spacing(1.4, 3),
    justifyContent: 'space-between',
  },

  'button[data-active="true"]': {
    i: {
      transform: 'rotate(-180deg)',
    },
  },
}))
