//@flow
import React from 'react'
import {
  FormControl,
  InputLabel,
  Stack,
  IconButton,
  Input,
  Typography,
} from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { InputFile } from '~primitives/Input/File'

type Props = {
  index: number,
  name: string,
  file: File,
  setFile: ({ file: File, name: string }) => void,
  removeDisabled: boolean,
  handlePush: (index: number) => void,
  handleRemove: (index: number) => void,
}

export const FileSelectGroup = ({
  index,
  name,
  file,
  setFile,
  removeDisabled,
  handlePush,
  handleRemove,
}: Props) => {
  const handleSetFile = (file: File) => {
    setFile({ name, file: file ?? null })
  }

  const handleChangeName = (event) => {
    const { value } = event.target
    setFile({ name: value, file })
  }

  const isPushButtonDisabled = checkPushButtonIsDisabled()

  return (
    <Stack direction="row" alignItems="flex-start" pb={2}>
      <FormControl fullWidth sx={{ pb: 0, mr: 3 }} variant="standard">
        <InputLabel id="file-name-select-label" required>
          Nome do arquivo
        </InputLabel>
        <Input
          variant="standard"
          labelId="file-select-label"
          name="subject_ids"
          value={name}
          label="Nome do arquivo"
          size="small"
          onChange={handleChangeName}
        />
      </FormControl>

      <FormControl fullWidth sx={{ pb: 0, mr: 3 }}>
        <InputLabel id="file-name-select-label" required>
          Importar arquivo {file?.name}
        </InputLabel>
        <Stack sx={{ width: 1, mt: 0.5 }}>
          <InputFile
            size="small"
            selectedFile={file}
            setSelectedFile={handleSetFile}
            acceptedFormats={[
              'application/pdf',
              'application/vnd.ms-powerpoint',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/msword',
              'image/jpeg',
              'image/png',
            ]}
            placeholder="Selecionar arquivo"
          />
          <Typography variant="caption" color="disabled" sx={{ mt: 0.5 }}>
            Tamanho máximo do arquivo: 50 MB
          </Typography>
        </Stack>
      </FormControl>

      <Stack direction="row" gap={1} sx={{ mt: 3 }}>
        <IconButton
          type="button"
          variant="secondary"
          size="small"
          onClick={() => handlePush(index)}
          disabled={isPushButtonDisabled}
        >
          <SVGIcon name="add" color="white" size={6} />
        </IconButton>

        <IconButton
          type="button"
          variant="secondary"
          size="small"
          onClick={() => handleRemove(index)}
          disabled={removeDisabled}
        >
          <SVGIcon name="delete" color="white" size={6} />
        </IconButton>
      </Stack>
    </Stack>
  )
}

const checkPushButtonIsDisabled = () => {
  return false
}
