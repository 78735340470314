// @flow
import React from 'react'
import {
  Checkbox,
  ListItemText,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  Stack,
  useTheme,
  FormHelperText,
} from '@mui/material'
import { useManageBookletFilterStudents } from '~data/manageBooklets/form/filterStudents'

type Props = {
  selectedStudents?: Array<number>,
  selectedClassroom: number,
  schoolId: string,
  handleChangeStudents: (Array<number>) => void,
}

export const SelectStudents = ({
  selectedClassroom,
  selectedStudents,
  handleChangeStudents,
  schoolId,
}: Props) => {
  const { data, loading, error } = useManageBookletFilterStudents({
    schoolId,
    classroomId: selectedClassroom,
  })
  const theme = useTheme()
  const styles = getStyles(theme)

  if (!data) return null

  const studentOptions = normalizeStudentsOptions(data?.student_profiles)

  const handleStudentSelectChange = (event) => {
    handleChangeStudents(event.target.value)
  }

  const handleSelectAllStudents = () => {
    if (studentOptions.length === selectedStudents?.length) {
      handleChangeStudents([])
    } else {
      handleChangeStudents(studentOptions.map(({ id }) => id))
    }
  }

  return (
    <FormControl
      fullWidth
      sx={{
        ...styles.formControl,
      }}
    >
      <InputLabel id="students-label" required>
        Alunos
      </InputLabel>
      <Stack flexGrow={1} sx={{ position: 'relative', minWidth: 120 }}>
        {studentOptions.length > 0 && (
          <FormControlLabel
            sx={{
              ...styles.formControlLabel,
            }}
            control={
              <Checkbox
                onChange={handleSelectAllStudents}
                color="secondary"
                disableRipple
                checked={studentOptions.length === selectedStudents?.length}
                sx={{ p: 0 }}
              />
            }
            label="Todos"
          />
        )}

        <Select
          id="select-students"
          name="students"
          label={`students-label-${schoolId}`}
          multiple
          value={selectedStudents}
          variant="standard"
          size="small"
          sx={{ ...styles.select }}
          disabled={loading || error}
          renderValue={(value) => <span>{value.length} selecionados</span>}
          onChange={handleStudentSelectChange}
          error={!selectedStudents?.length}
        >
          {studentOptions.length === 0 && (
            <MenuItem
              disabled
              sx={{
                height: 36,
                '& .Mui-selected': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              Nenhum aluno disponível
            </MenuItem>
          )}
          {studentOptions.map((student) => (
            <MenuItem
              key={student.id}
              value={student.id}
              disableGutters
              sx={{
                height: 36,
                '& .Mui-selected': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Checkbox
                color="secondary"
                disableRipple
                checked={selectedStudents?.some((id) => id === student.id)}
              />
              <ListItemText
                primary={student.name}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </MenuItem>
          ))}
        </Select>
      </Stack>
      {selectedStudents?.length == 0 && (
        <FormHelperText error={true}>Obrigatório</FormHelperText>
      )}
    </FormControl>
  )
}

const normalizeStudentsOptions = (studentProfiles) =>
  studentProfiles.length > 0
    ? studentProfiles.map(({ id, user }) => ({ id, name: user.name }))
    : []

const getStyles = (theme: Object) => ({
  formControl: {
    mr: 1,
    ml: 2,
  },
  formControlLabel: {
    position: 'absolute',
    zIndex: 100,
    left: 20,
    top: 'calc(50% + 2px)',
    transform: 'translateY(-50%)',
    p: 0,
    gap: 1,
    mb: 0.25,
  },
  select: {
    flexGrow: 1,
    '& fieldset legend': { display: 'none' },
    '& .MuiSelect-select span': { display: 'none' },
    '&.Mui-disabled svg': {
      color: theme.palette.action.disabled,
    },
  },
})
