// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpoint = '/api/v3/foco_exams'

type ParamsExamsBooklets = {
  entityId?: string,
  foco_exam_edition_id?: number,
}

type ResponseExamsBooklets = {
  id: number,
  name: string,
  participation_rate: number,
  questions_count: number,
  status: string,
  classrooms: {
    name: string,
  }[],
}

export const useManageExamsBooklets = (params: ParamsExamsBooklets) => {
  const {
    data: dataBooklets,
    error,
    loading,
  } = useResource({
    key: 'useBookletsList',
    resource: getManageExamsBooklets,
    params,
  })

  return { loading, error, dataBooklets }
}

const getManageExamsBooklets = (
  params: ParamsExamsBooklets
): APIResponse<ResponseExamsBooklets> => {
  const foco_exam_edition_id_eq =
    Number(params.foco_exam_edition_id) !== 0
      ? params.foco_exam_edition_id
      : undefined

  return fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      entity_type: 'school',
      entity_id: params?.entityId,
      include: [{ foco_exams: ['classrooms'] }],
      fields: [
        {
          foco_exams: [
            'id',
            'name',
            'status',
            'amount_of_student',
            'questions_count',
            'participation_rate',
            { classrooms: ['name'] },
          ],
        },
      ],
      q: {
        foco_exam_edition_id_eq,
      },
    },
  })
}
