// @flow
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { equals } from 'ramda'
import {
  FormControl,
  Box,
  MenuItem,
  InputLabel,
  Select,
  Divider,
  Stack,
  Typography,
  Button,
} from '@mui/material'
import { Formik } from 'formik'
import {
  useCurriculumCompetencies,
  useCurriculumUnits,
  createEvent,
  editEvent,
} from './fetch'
import { useFeedback } from '~contexts/Feedback'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { Form } from '~primitives/Input/Form'
import useResponsive from '~hooks/useResponsive'
import SuccessCheck from '~assets/svg/success_check.svg'

export type Props = {
  handleCloseModal: () => void,
  lastEventAmount: number,
  baseEvent: any,
  subjectId: number,
  gradeId: number,
}

const CreateEventModal = ({
  handleCloseModal,
  lastEventAmount,
  baseEvent,
  subjectId,
  gradeId,
}: Props) => {
  const isEditMode = Boolean(baseEvent)

  const queryClient = useQueryClient()
  const { showMessage } = useFeedback()

  const [currentCompetency, setCurrentCompetency] = useState(
    isEditMode
      ? baseEvent.minimum_curriculum_unit.curriculum_skill.curriculum_competency
          .id
      : ''
  )

  const { entityId, baseCurriculumId } = useParams()

  const isDesktop = useResponsive({ query: 'up', key: 'lg' })

  const {
    error: errorCompetencies,
    loading: loadingCompetencies,
    data: competencies,
  } = useCurriculumCompetencies({ subjectId, gradeId })

  const {
    error: errorCurriculumUnits,
    loading: loadingCurriculumUnits,
    data: curriculumUnits,
  } = useCurriculumUnits({ competencyId: currentCompetency, gradeId })

  const [isSuccess, setIsSuccess] = useState(false)

  const { mutate } = useMutation(
    ({ data }) =>
      isEditMode
        ? editEvent({
            data,
            baseCurriculumId: String(baseCurriculumId),
            baseDataId: baseEvent.id,
          })
        : createEvent({ data, baseCurriculumId: String(baseCurriculumId) }),
    {
      onSuccess: () => {
        setIsSuccess(true)
        queryClient.invalidateQueries((query) =>
          ['useBaseCurriculum', 'useManagementBaseCurriculums'].includes(
            query.queryKey[0]
          )
        )
      },
      onError: (error) => {
        showMessage({ type: 'error', text: error.message })
      },
    }
  )

  if (errorCompetencies || errorCurriculumUnits)
    return 'Ops! Tivemos um problema com a requisição.'

  if (
    (loadingCompetencies && loadingCurriculumUnits) ||
    (competencies && !curriculumUnits)
  )
    return null

  const title = isEditMode ? 'Editar Aula' : 'Adicionar Aula'

  const successMessage = isEditMode
    ? 'Aula editada com sucesso!'
    : 'Aula adicionada com sucesso!'

  const initialValues = {
    minimum_curriculum_unit_id: isEditMode
      ? baseEvent.minimum_curriculum_unit.id
      : '',
    activity_description: isEditMode ? baseEvent.activity_description : '',
    curriculum_activity_ids: isEditMode
      ? baseEvent.curriculum_activities.map(({ id }) => String(id))
      : [],
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    mutate({
      data: values,
      entityId: entityId,
    })
  }

  const handleFormReset = (resetForm) => {
    setIsSuccess(false)
    setCurrentCompetency('')
    resetForm()
  }

  const handleFormValidate = (values) => {
    const errors = {}
    const fields = ['minimum_curriculum_unit_id']

    fields.forEach((field) => {
      if (values[field] === '') {
        errors[field] = 'Requerido'
      }
    })

    return errors
  }

  return (
    <Modal
      width="670px"
      height="98vh"
      confirmButtonText="Salvar"
      overflowContent={false}
      contentPadding="5px 0"
      showFooter={false}
      footerPadding="20px 35px 30px"
      onClose={handleCloseModal}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validate={handleFormValidate}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          resetForm,
          isSubmitting,
          values,
          errors,
          setFieldValue,
        }) => (
          <Form
            onSubmit={handleSubmit}
            noValidate
            headingSize="24px"
            style={{ padding: '0 19px' }}
          >
            <Typography variant="h4" margin="15px 0">
              <UnderlinedHeading as="span">
                <strong>{title}</strong>
              </UnderlinedHeading>
            </Typography>

            {isSuccess ? (
              <>
                <Box>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                    sx={{ bgcolor: 'grey.200', px: 4, height: 350 }}
                  >
                    <img src={SuccessCheck} alt="" width="90px" />
                    <Typography variant="h5">{successMessage}</Typography>
                  </Stack>
                </Box>
                <Stack flexDirection="row" justifyContent="flex-end" my={2.5}>
                  {isEditMode ? (
                    <Button
                      variant="primary"
                      onClick={handleCloseModal}
                      sx={{ maxWidth: 160, width: 1 }}
                    >
                      Ver plano de aulas
                    </Button>
                  ) : (
                    <>
                      <Button variant="neutral" onClick={handleCloseModal}>
                        Voltar para Plano de Aulas
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          handleFormReset(resetForm)
                        }}
                        sx={{ maxWidth: 160, width: 1 }}
                      >
                        {title}
                      </Button>
                    </>
                  )}
                </Stack>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    backgroundColor: 'grey.200',
                    padding: 3,
                    borderRadius: 4,
                  }}
                >
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ maxWidth: { lg: '100%' } }}
                  >
                    <Typography
                      sx={{ fontWeight: 700 }}
                      component="span"
                      variant="subtitle1"
                    >
                      Aula{' '}
                      {isEditMode ? baseEvent?.event_order : lastEventAmount}
                    </Typography>
                  </Stack>
                  <Divider sx={{ my: 2 }} />

                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    gap={3}
                    sx={{ maxWidth: { lg: '100%' } }}
                  >
                    <FormControl fullWidth>
                      <InputLabel>Unidade temática</InputLabel>
                      <Select
                        name="competencies"
                        value={currentCompetency}
                        disabled={!competencies}
                        onChange={(e) => {
                          setCurrentCompetency(e.target.value)
                          setFieldValue('minimum_curriculum_unit_id', '')
                        }}
                        onBlur={handleBlur}
                        variant="standard"
                        MenuProps={{
                          style: { zIndex: 9999999 },
                        }}
                        native={!isDesktop}
                        defaultValue=""
                        placeholder
                        displayEmpty
                        required
                      >
                        {getPlaceholder({
                          placeholder: 'Selecione uma Unidade Temática',
                          isDesktop: isDesktop || false,
                        })}
                        {competencies?.curriculum_competencies.map(
                          (competencie) =>
                            isDesktop ? (
                              <MenuItem
                                value={competencie.id}
                                key={competencie.id}
                              >
                                {competencie.description}
                              </MenuItem>
                            ) : (
                              <option
                                value={competencie.id}
                                key={competencie.id}
                              >
                                {competencie.description}
                              </option>
                            )
                        )}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel>Habilidades do curriculo</InputLabel>
                      <Select
                        name="minimum_curriculum_unit_id"
                        disabled={
                          !curriculumUnits ||
                          curriculumUnits.length === 0 ||
                          currentCompetency === ''
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="standard"
                        MenuProps={{
                          style: { zIndex: 9999999 },
                        }}
                        native={!isDesktop}
                        defaultValue={values.minimum_curriculum_unit_id}
                        value={values.minimum_curriculum_unit_id}
                        placeholder
                        displayEmpty
                      >
                        {getPlaceholder({
                          placeholder: 'Selecione uma Habilidade',
                          isDesktop: isDesktop || false,
                        })}
                        {curriculumUnits?.minimum_curriculum_units.map(
                          (unity) =>
                            isDesktop ? (
                              <MenuItem value={unity.id} key={unity.id}>
                                <span>
                                  <strong>
                                    ({unity.curriculum_skill.name}){' '}
                                  </strong>
                                  {unity.curriculum_skill.description}
                                </span>
                              </MenuItem>
                            ) : (
                              <option value={unity.id} key={unity.id}>
                                ({unity.curriculum_skill.name}){' '}
                                {unity.curriculum_skill.description}
                              </option>
                            )
                        )}
                      </Select>
                    </FormControl>
                  </Stack>
                </Box>
                <Stack flexDirection="row" justifyContent="flex-end" my={2.5}>
                  <Button variant="neutral" onClick={handleCloseModal}>
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    sx={{ maxWidth: 160, width: 1 }}
                    disabled={
                      isSubmitting ||
                      equals(values, initialValues) ||
                      Object.keys(errors).length > 0
                    }
                  >
                    Salvar
                  </Button>
                </Stack>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

type PlaceholderProps = {
  placeholder: string,
  isDesktop: boolean,
}

function getPlaceholder({ placeholder, isDesktop }: PlaceholderProps) {
  if (isDesktop) {
    return (
      <MenuItem value="" disabled>
        {placeholder}
      </MenuItem>
    )
  } else {
    return (
      <option value="" disabled>
        {placeholder}
      </option>
    )
  }
}

export default CreateEventModal
