// @flow
import React from 'react'
import { Typography, Stack, Box, LinearProgress, Button } from '@mui/material'
import { useParams, useLocation, Link } from 'react-router-dom'
import { TableLoadingPlaceholder } from './Loading'
import SortTable from '~primitives/molecules/Table/SortTable'
import { formatDecimalPercent } from '~helpers/formatting/numbers'
import { useManagementCurriculumData } from '~data/curriculum/management'

const cellsHeader = [
  {
    label: 'Regionais',
    property: 'curriculum_skill',
    align: 'left',
    width: { lg: '33.333%' },
  },
  {
    label: 'Aplicação do currículo (%)',
    property: 'activity_description',
    align: 'center',
    width: { lg: '33.333%' },
  },
  {
    align: 'center',
    width: { lg: '33.333%' },
  },
]

const List = () => {
  const { entityType } = useParams()

  const { search } = useLocation()

  const backgroundColor = entityType && `resultables.${entityType}`

  const { data, error } = useManagementCurriculumData()

  if (error) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={2.5}
        bgcolor="grey.200"
        p={2.5}
        borderRadius={2}
        sx={{ height: 126, mt: 4 }}
      >
        Erro: {error.status} {error.message && `- ${error.message}`}
      </Stack>
    )
  }

  const childEntityType = entityType === 'district' ? 'sector' : 'school'

  const normalizeData = data ? data[`${childEntityType}s`] : null
  const placeholder = TableLoadingPlaceholder()

  const rows = normalizeTableRow(
    normalizeData,
    childEntityType,
    backgroundColor,
    search
  )

  return (
    <Box mt={4}>
      <SortTable
        variant="simple"
        rows={rows ?? placeholder}
        rowsAlign={['left', 'center', 'center']}
        cellsHeader={cellsHeader}
      />
    </Box>
  )
}

const normalizeTableRow = (data, childEntityType, backgroundColor, search) => {
  if (!data) return null

  return data.map(({ id, name, curriculum_performed_rate }) => {
    const url =
      childEntityType === 'school'
        ? `/curriculo/school/${id || ''}${search}`
        : `/curriculo/sector/${id || ''}/gestao${search}`

    return {
      fields: [
        <Typography key="week" component="span" variant="body2">
          {name}
        </Typography>,
        <Stack
          key="skill"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <LinearProgress
            variant="determinate"
            sx={{
              width: 175,
              '.MuiLinearProgress-barColorPrimary': {
                backgroundColor,
              },
            }}
            value={curriculum_performed_rate * 100}
          />
          <Typography>
            {formatDecimalPercent(0)(curriculum_performed_rate)}
          </Typography>
        </Stack>,
        <Stack
          direction="row"
          justifyContent="flex-end"
          key="minimum_curriculum_unit"
        >
          <Button
            component={Link}
            to={url}
            variant="outlined"
            size="small"
            sx={{
              minWidth: 160,
            }}
          >
            {childEntityType === 'school' ? 'Ver escola' : 'Ver regional'}
          </Button>
        </Stack>,
      ],
      key: id,
    }
  })
}

export default List
