// @flow
import { Stack, Typography } from '@mui/material'
import { isNil } from 'ramda'
import React from 'react'
import { pxToRem } from '~helpers/getFontValue'
import { SVGIcon, type IconName } from '~primitives/Utilities/SVGIcon'

type Icon = {
  name: IconName,
  color?: string,
}

type Props = {
  icon?: Icon,
  label: string,
  labelSize?: number,
  value: string | number,
  total?: number,
  valueSize?: number,
  suffix?: string,
  suffixSize?: number,
  sx?: any,
  name?: string,
}

export const InsightBox = ({
  icon,
  label,
  labelSize,
  value,
  total,
  valueSize = 28,
  suffix = '',
  suffixSize,
  sx,
  name,
}: Props) => (
  <Stack
    data-testid={name || 'insight-box'}
    alignItems="center"
    sx={{
      bgcolor: 'grey.0',
      py: '13px',
      height: 90,
      flexGrow: 1,
      width: '20%',
      color: 'grey.800',
      borderRadius: '4px',
      ...sx,
    }}
  >
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="baseline"
      sx={{ width: 1, px: '11px' }}
    >
      {icon?.name && (
        <SVGIcon name={icon.name} color={icon?.color ?? '#96A6A9'} size={1} />
      )}
      <Stack direction="row" justifyContent="center" sx={{ width: 1 }}>
        <Typography sx={{ fontSize: pxToRem(labelSize || 13) }}>
          {label}
        </Typography>
      </Stack>
    </Stack>
    <Stack direction="row" alignItems="baseline" justifyContent="center">
      <Typography sx={{ fontSize: pxToRem(valueSize), fontWeight: 300 }}>
        {value}
        {!isNil(total) && (
          <Typography
            sx={{ fontSize: pxToRem((2 / 3) * valueSize) }}
            component="span"
          >
            / {total}
          </Typography>
        )}
      </Typography>
      {suffix && (
        <Typography sx={{ fontSize: pxToRem(suffixSize || 16) }}>
          {suffix}
        </Typography>
      )}
    </Stack>
  </Stack>
)
