// @flow
import React from 'react'
import { IconButton, Skeleton, Stack, Typography } from '@mui/material'
import SortTable from '~primitives/molecules/Table/SortTable'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const CELLS_HEADER = [
  {
    label: 'Nº da aula',
  },
  {
    label: 'Unidade temática',
    align: 'center',
  },
  {
    label: 'Habilidade do Currículo',
    align: 'left',
  },
  {
    align: 'center',
  },
]

type CurriculumCompetencyProps = {
  id: number,
  description: string,
  name: string,
}

type CurriculumSkillProps = {
  name: string,
  description: string,
  curriculum_competency: CurriculumCompetencyProps,
}

type MinimumCurriculumUnitProps = {
  id: number,
  curriculum_skill: CurriculumSkillProps,
}

type BaseEventProps = {
  id: number,
  activity_description: string,
  curriculum_activities: any,
  event_order: number,
  performed: boolean,
  minimum_curriculum_unit: MinimumCurriculumUnitProps,
}

type LessonPlanDataProps = {
  id: number,
  activity_description: string,
  curriculum_activities: any,
  event_order: number,
  minimum_curriculum_unit: MinimumCurriculumUnitProps,
}

type Props = {
  isLoading: boolean,
  isSchool: boolean,
  baseEvents?: Array<BaseEventProps>,
  handleOpenModalDelete: (number) => void,
  setLessonPlanData: (LessonPlanDataProps) => void,
}

const BaseEventsTable = ({
  isLoading,
  baseEvents,
  setLessonPlanData,
  isSchool,
  handleOpenModalDelete,
}: Props) => {
  const placeholder = getPlaceholder()
  const rows = normalizeTableRow({
    baseEvents,
    setLessonPlanData,
    handleOpenModalDelete,
    isSchool,
  })

  return (
    <Stack sx={{ px: 3.5, bgcolor: 'grey.200' }}>
      <SortTable
        variant="whiteRow"
        rows={!isLoading && rows ? rows : placeholder}
        rowsAlign={['left', 'left', 'left', 'left', 'center']}
        cellsHeader={CELLS_HEADER}
        pagination={false}
        emptyContentText="Não há aulas cadastradas na disciplina"
      />
    </Stack>
  )
}

export default BaseEventsTable

const normalizeTableRow = ({
  baseEvents,
  setLessonPlanData,
  handleOpenModalDelete,
  isSchool,
}) =>
  baseEvents?.length !== 0
    ? baseEvents?.map(
        (
          {
            curriculum_activities,
            event_order,
            id,
            performed,
            activity_description,
            minimum_curriculum_unit,
          },
          index
        ) => {
          return {
            fields: [
              <React.Fragment key="week">
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ display: 'block', minWidth: 75 }}
                >
                  Aula {event_order}
                </Typography>
              </React.Fragment>,
              <React.Fragment key="skill">
                <Typography
                  component="span"
                  variant="body2"
                  align="center"
                  sx={{ display: 'block', width: '100%' }}
                >
                  {minimum_curriculum_unit.curriculum_skill.curriculum_competency?.description
                    .charAt(0)
                    .toUpperCase() +
                    minimum_curriculum_unit.curriculum_skill.curriculum_competency?.description
                      .slice(1)
                      .toLowerCase()}
                </Typography>
              </React.Fragment>,
              <React.Fragment key="minimum_curriculum_unit">
                <Typography
                  component="span"
                  variant="body2"
                  align="left"
                  sx={{ display: 'block', width: '100%' }}
                >
                  ({minimum_curriculum_unit?.curriculum_skill?.name}){' '}
                  {minimum_curriculum_unit?.curriculum_skill?.description}
                </Typography>
              </React.Fragment>,
              <React.Fragment key="activities">
                {isSchool && (
                  <Stack
                    direction="row"
                    justifyContent="right"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      maxWidth: '140px',
                      float: 'right',
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setLessonPlanData({
                          id,
                          activity_description,
                          curriculum_activities,
                          event_order,
                          minimum_curriculum_unit,
                        })
                      }}
                      disabled={performed}
                      variant="outlinedSecondary"
                      data-testid="button-action-edit"
                    >
                      <SVGIcon name="edit" size={3} />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        handleOpenModalDelete(id)
                      }}
                      disabled={performed}
                      variant="outlinedSecondary"
                      data-testid="button-action-cancel"
                    >
                      <SVGIcon name="delete" size={3} />
                    </IconButton>
                  </Stack>
                )}
              </React.Fragment>,
            ],
            key: index,
          }
        }
      )
    : []

const getPlaceholder = () =>
  [...Array(6).keys()].map((_, index) => ({
    fields: [
      <Skeleton key={`a-${index}`} variant="text" />,
      <Stack key={`b-${index}`}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Stack>,
      <Stack key={`c-${index}`}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Stack>,
      <Stack key={'d'} direction="row" gap={1} justifyContent="flex-end">
        <Skeleton variant="circular" width={34} height={34} />
        <Skeleton variant="circular" width={34} height={34} />
      </Stack>,
    ],
    key: index,
  }))
