// @flow
import React, { useState, useRef, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import { Box, Stack, Chip, Typography, useTheme } from '@mui/material'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import qs from 'qs'
import { RegisterEvent } from '../Modals/RegisterEvent'
import { getStatusIcons } from '../helpers'
import { Presences } from '../Modals/Presences'
import { SuggestedMaterialsModal } from '../Modals/Materials/SuggestedMaterials'
import { AddMaterialsModal } from '../Modals/Materials/AddMaterials'
import { type RegisterFormBuffer, type MaterialData } from '../validate'
import CalendarToolbar from './CalendarToolbar'
import CalendarAside from './CalendarAside'
import { Aside, Overlay, Content } from './styles'
import { FullCalendar, CalendarStyles } from '~components/Common/FullCalendar'
import useWeekCurriculumEvents from '~data/curriculum/weekEvents'
import useResponsive from '~hooks/useResponsive'
import useFilters from '~hooks/useFilters'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { pxToRem } from '~helpers/getFontValue'
import { Scribble } from '~primitives/Typography/Underline'

const Calendar = () => {
  const isDesktop = useResponsive({ query: 'up', key: 'sm' })

  const { filters } = useFilters()

  const calendarRef = useRef(null)

  const { entityId, day, month, year } = useParams()

  const hasParams = day && month && year
  const initialDate = hasParams
    ? new Date(Number(year), Number(month), Number(day))
    : new Date()

  const [date, setDate] = useState(initialDate)

  const [view, setView] = useState(isDesktop ? 'timeGridWeek' : 'timeGridDay')

  const [filtersGrade, setFiltersGrade] = useState([])

  const [filtersIdsGrade, setFiltersIdsGrade] = useState([])

  const [filterIsOpen, setFilterIsOpen] = useState(false)

  const [isPresencesModalOpen, setIsPresencesModalOpen] = useState(false)

  const handleClosePresencesModal = () => {
    setIsPresencesModalOpen(false)
  }

  const handleOpenPresencesModal = () => {
    setIsPresencesModalOpen(true)
  }

  const startOfWeek = dayjs(date)
    .startOf('week')
    .add(1, 'day')
    .format('YYYY-MM-DD')

  const endOfWeek = dayjs(date).endOf('week').format('YYYY-MM-DD')

  useEffect(() => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()
      const newView = isDesktop ? 'timeGridWeek' : 'timeGridDay'
      calendarApi.changeView(newView)
      setView(newView)
    }
  }, [isDesktop])

  const handleChangeView = (newView: string) => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()
      calendarApi.changeView(newView)
      setView(newView)
    }
  }

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()
      calendarApi.prev()
      setDate(calendarApi.getDate())
    }
  }

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()
      calendarApi.next()
      setDate(calendarApi.getDate())
    }
  }

  const handleClickNewDay = (date) => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()
      calendarApi.today()
      calendarApi.gotoDate(dayjs(date).format('YYYY-MM-DD'))
      setDate(calendarApi.getDate())
    }
  }

  const [formBuffer, setFormBuffer] = useState<RegisterFormBuffer | null>(null)
  const [modalProps, setModalProps] = useState(undefined)
  const [isSuggestedMaterialsModalOpen, setIsSuggestedMaterialsModalOpen] =
    useState(false)
  const [isAddMaterialsModalOpen, setIsAddMaterialsModalOpen] = useState(false)
  const [materialsData, setMaterialsData] = useState<MaterialData | null>(null)

  const handleCloseRegisterEventModal = () => {
    setFormBuffer(null)
    setModalProps(undefined)
  }
  const handleOpenSuggestedMaterialsModal = ({
    eventIndex,
    skillCode,
    skillId,
    date,
    initialValues,
    setValuesOnConfirm,
  }: MaterialData) => {
    setMaterialsData({
      eventIndex,
      skillCode,
      skillId,
      date,
      initialValues,
      setValuesOnConfirm,
    })
    setIsSuggestedMaterialsModalOpen(true)
  }

  const handleOpenAddMaterialsModal = ({
    eventIndex,
    skillCode,
    date,
  }: MaterialData) => {
    setMaterialsData({
      eventIndex,
      skillCode,
      date,
    })
    setIsAddMaterialsModalOpen(true)
  }

  const { pathname, search } = useLocation()
  const history = useHistory()

  const grade_id = qs.parse(search).grade_id || qs.parse(search)['?grade_id']

  const openModal = (props: any) => {
    if (!entityId) return
    setModalProps(props)
  }

  const { data, error } = useWeekCurriculumEvents({
    starts: startOfWeek,
    ends: endOfWeek,
    grades: filtersIdsGrade,
  })

  const setInitialFilters = useCallback(() => {
    if (filters) {
      const filtersGrade =
        filters.grade_id?.options.map((item) => {
          let isChecked
          if (grade_id) {
            isChecked = item.id === Number(grade_id) ? true : false
          } else {
            isChecked = true
          }
          return { id: item.id, name: item.name, checked: isChecked }
        }) ?? []

      setFiltersGrade(filtersGrade)
    }
  }, [filters, grade_id])

  useEffect(() => {
    setInitialFilters()
  }, [filters, setInitialFilters])

  const handleChangeFilterGrade = (params) => {
    const idsGradeFilter = params
      .filter((item) => item.checked)
      ?.map((v) => v.id)
    if (idsGradeFilter.length === 0) {
      setFiltersIdsGrade([0])
    } else {
      setFiltersIdsGrade(idsGradeFilter)
    }
    setFiltersGrade(params)
    if (search) {
      history.push({
        pathname,
      })
    }
  }

  const fullCalendarEvents =
    data?.curriculum_events.map(
      ({
        id,
        start_time,
        grade,
        end_time,
        classroom,
        status,
        subject,
        minimum_curriculum_unit,
      }) => ({
        id,
        allDay: false,
        status,
        start: start_time,
        end: end_time,
        title: `<strong>${
          subject.name
        } • ${grade.short_name.toUpperCase()}</strong><br/> ${
          classroom?.name
        }  •  ${minimum_curriculum_unit?.curriculum_skill?.name || ''}`,
      })
    ) ?? []

  if (error) {
    return `Erro: ${error.status} - ${error.message || ''}`
  }

  return (
    <CalendarStyles>
      <Aside data-is-open={filterIsOpen}>
        <CalendarAside
          date={date}
          filtersGrade={filtersGrade || []}
          filtersIdsGrade={
            filtersGrade.filter((item) => item.checked)?.map((v) => v.id) || []
          }
          onChangeCalendar={handleClickNewDay}
          setFiltersGrade={handleChangeFilterGrade}
          setFilterIsOpen={setFilterIsOpen}
          view={view}
          onChangeView={handleChangeView}
        />
      </Aside>
      <Overlay
        data-is-open={filterIsOpen}
        onClick={() => {
          setFilterIsOpen(!filterIsOpen)
        }}
      />
      <Content>
        {!isDesktop && (
          <Typography
            sx={{ fontSize: pxToRem(18), fontWeight: 700, mb: 2, ml: 2 }}
          >
            <Scribble>Calendário</Scribble>
          </Typography>
        )}

        <CalendarToolbar
          date={date}
          view={view}
          onNextDate={handleClickDateNext}
          onPrevDate={handleClickDatePrev}
          onChangeView={handleChangeView}
          onChangeCalendar={handleClickNewDay}
          setFilterIsOpen={setFilterIsOpen}
          filterIsOpen={filterIsOpen}
        />

        <FullCalendar
          ref={calendarRef}
          initialDate={date}
          initialView={view}
          slotMinTime="07:00"
          slotMaxTime="23:00"
          dayHeaderContent={HeaderContent}
          eventContent={EventContent}
          eventClick={(info) => {
            const eventID = info.event._def.publicId
            const event = data?.curriculum_events.find(
              (event) => event.id === Number(eventID)
            )

            openModal({ ...event, entityId })
          }}
          events={fullCalendarEvents}
        />
      </Content>

      {entityId && modalProps && (
        <RegisterEvent
          eventId={modalProps.id}
          isOpen={
            !!modalProps &&
            !isSuggestedMaterialsModalOpen &&
            !isPresencesModalOpen
          }
          entityId={entityId}
          formBuffer={formBuffer}
          setFormBuffer={setFormBuffer}
          onClose={handleCloseRegisterEventModal}
          onOpenSuggestedMaterials={handleOpenSuggestedMaterialsModal}
          onOpenPresences={handleOpenPresencesModal}
          onOpenAddMaterials={handleOpenAddMaterialsModal}
        />
      )}

      {isSuggestedMaterialsModalOpen && materialsData && (
        <SuggestedMaterialsModal
          isOpen={isSuggestedMaterialsModalOpen}
          setIsOpen={setIsSuggestedMaterialsModalOpen}
          closeButtonText="Volta para o Registro de Aula"
          {...materialsData}
        />
      )}

      {isAddMaterialsModalOpen &&
        materialsData &&
        modalProps &&
        materialsData?.eventIndex && (
          <AddMaterialsModal
            isOpen={isAddMaterialsModalOpen}
            setIsOpen={setIsAddMaterialsModalOpen}
            eventIndex={materialsData.eventIndex}
            curriculumEventId={modalProps.id}
            skillCode={materialsData.skillCode}
            date={materialsData.date}
          />
        )}

      {isPresencesModalOpen && modalProps && entityId && (
        <Presences
          eventId={modalProps.id}
          onClose={handleClosePresencesModal}
          isOpen={isPresencesModalOpen}
          entityId={Number(entityId)}
        />
      )}
    </CalendarStyles>
  )
}

export const HeaderContent = (data: any) => {
  const isCurrentDay =
    dayjs(data.date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')

  if (isCurrentDay) {
    return (
      <Stack spacing={0.5}>
        <Typography
          color="primary"
          variant="caption"
          component="span"
          sx={{ textTransform: 'uppercase' }}
        >
          {dayjs(data.date).format('ddd')}
        </Typography>
        <Chip
          color="secondary"
          size="large"
          variant="circle"
          label={dayjs(data.date).format('DD')}
        />
      </Stack>
    )
  } else {
    return (
      <Stack spacing={0.5}>
        <Typography
          color="primary"
          variant="caption"
          component="span"
          sx={{ textTransform: 'uppercase' }}
        >
          {dayjs(data.date).format('ddd')}
        </Typography>
        <Chip
          color="secondary"
          size="large"
          variant="circle"
          label={dayjs(data.date).format('DD')}
          sx={{ backgroundColor: 'transparent', color: 'text.primary' }}
        />
      </Stack>
    )
  }
}

const EventContent = (data) => {
  return <EventContentRender data={data} />
}

const EventContentRender = ({ data }: any) => {
  const { palette } = useTheme()

  const status = data.event._def.extendedProps.status
  const endTime = data.event.end

  const icon = getStatusIcons(status, endTime)

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography
        variant="caption"
        component="span"
        sx={{ fontSize: '12px' }}
        dangerouslySetInnerHTML={{
          __html: data.event._def.title,
        }}
      />

      <Box height={20} minWidth={20}>
        <SVGIcon
          name={icon?.name}
          color={palette?.[icon?.color]?.main}
          size={3}
        />
      </Box>
    </Stack>
  )
}

export default Calendar
