import { validateEmail } from '~helpers/validators'

const validate = (formValues) => {
  const requiredFields = ['email']
  const errors = {}

  requiredFields.forEach((field) => {
    if (!formValues[field]) {
      errors[field] = 'Obrigatório'
    }
  })

  if (!errors.email && !validateEmail(formValues.email)) {
    errors.email = 'E-mail inválido'
  }

  return errors
}

export default validate
