// @flow
import { createReducer } from 'reduxsauce'

import type { Menu, Profile } from '~types/Profile'

type Session = {
  token: ?string,
  type: ?string,
  client: ?string,
  expiry: ?string,
  uid: ?string,
}

export type UserState = {
  name: string,
  session: Session,
  selectedProfile: ?number,
  profiles: Array<Profile>,
  features: Menu,
  showNewHome: boolean,
  cellphone?: ?string,
  news_email?: ?string,
  email: string,
  gender?: string,
  id: number,
  receive_news?: boolean,
  active: boolean,
  accept_terms_and_conditions: boolean,
  student: boolean,
}

type Action = { type: string, payload: Object }

export const initialState: $Shape<UserState> = {
  name: '',
  session: { token: null, type: null, client: null, expiry: null, uid: null },
  selectedProfile: null,
  profiles: [],
  features: {
    enem: { label: null, description: null, resources: [] },
    indicators: { label: null, description: null, resources: [] },
    retrospective: { label: null, description: null, resources: [] },
    prospective: { label: null, description: null, resources: [] },
  },
  showNewHome: true,
  active: false,
  accept_terms_and_conditions: false,
  student: false,
}

const updateLogin = (state: UserState, { payload }: Action) => ({
  ...state,
  session: payload,
})

const logout = () => initialState

const getProfileList = (state: UserState, { payload }: Action) => ({
  ...state,
  profiles: payload,
})

const selectProfile = (state: UserState, { payload }: Action) => ({
  ...state,
  selectedProfile: payload,
  features: initialState.features,
})

const getUserData = (state: UserState, { payload }: Action) => ({
  ...state,
  ...payload,
})

const updateProfile = (state, { payload }: Action) => ({
  ...state,
  profiles: state.profiles.map((p) =>
    p.id !== payload.id ? p : { ...p, ...payload }
  ),
})

const updateUser = (state, { payload }: Action) => ({
  ...state,
  ...payload,
})

const getFeatures = (state, { payload }: Action) => ({
  ...state,
  features: payload,
})

const getStudentFeatures = (state, { payload }: Action) => ({
  ...state,
  studentFeatures: payload,
})

const toggleNewHome = (state) => ({
  ...state,
  showNewHome: !state.showNewHome,
})

const handlers = {
  UPDATE_LOGIN: updateLogin,
  GET_PROFILE_LIST: getProfileList,
  SELECT_PROFILE: selectProfile,
  GET_USER_DATA: getUserData,
  GET_FEATURES: getFeatures,
  GET_STUDENT_FEATURES: getStudentFeatures,
  LOGOUT: logout,
  TOGGLE_NEW_HOME: toggleNewHome,
  UPDATE_PROFILE: updateProfile,
  UPDATE_USER: updateUser,
}

export default createReducer(initialState, handlers)
