//@flow
import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material'
import { BookletList } from '../../BookletList'
import { DateLabel } from './DateLabel'
import { ExamStatus } from './ExamStatus'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { formatDecimalPercent } from '~helpers/formatting/numbers'
import type { FocoExamEdition } from '~data/manageExams/examList'

type Props = {
  focoExamEdition: FocoExamEdition,
  expanded: boolean,
  onChange: () => void,
  handleOpenDeleteModal: ({ name: string, id: number }) => void,
  handleOpenStatusModal: ({ name: string, id: number }) => void,
}

export const Exam = ({
  focoExamEdition,
  expanded,
  onChange,
  handleOpenDeleteModal,
  handleOpenStatusModal,
}: Props) => {
  return (
    <Accordion
      component="section"
      key={focoExamEdition.id}
      variant="simple"
      expanded={expanded}
      onChange={onChange}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<SVGIcon name="arrowDown" color="linkColor" size={3} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack
            direction="row"
            justifyContent="space-between"
            flexGrow={1}
            alignItems="center"
            sx={{ maxWidth: { md: '140px', lg: '200px', xl: '250px' } }}
          >
            <Typography variant="body2">
              <strong>{focoExamEdition.name}</strong>
            </Typography>

            <Box sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
              <Typography variant="body2">
                {focoExamEdition.grades[0]?.name}
              </Typography>
            </Box>
          </Stack>

          <Stack
            flexGrow={1}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
            }}
          >
            <DateLabel label="Início" date={focoExamEdition.exam_start} />
            <DateLabel label="Fim" date={focoExamEdition.exam_end} />
          </Stack>

          <Stack
            flexGrow={1}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            maxWidth="200px"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
            }}
          >
            <ExamStatus status={focoExamEdition.status} />
          </Stack>

          <Stack
            flexGrow={1}
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            sx={{ display: { xs: 'none', sm: 'none', lg: 'flex' } }}
          >
            <Typography variant="body2">
              <strong>Participação</strong>
            </Typography>
            <LinearProgress
              variant="determinate"
              sx={{
                width: { md: 100, lg: 120, xl: 175 },
                '.MuiLinearProgress-barColorPrimary': {
                  backgroundColor: getColorByStatus(focoExamEdition.status),
                },
              }}
              value={focoExamEdition.participation_rate * 100 || 0}
            />
            <Typography variant="body2">
              {format(focoExamEdition.participation_rate) || 0}
            </Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <BookletList
          focoExamEdition={focoExamEdition}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenStatusModal={handleOpenStatusModal}
        />
      </AccordionDetails>
    </Accordion>
  )
}

const getColorByStatus = (status) => {
  const colors = {
    created: '#1890FF',
    published: '#FFCD11',
    in_progress: '#66CB97',
    finished: '#B1B1B1',
    inactive: '#B1B1B1',
  }

  return colors[status]
}

const format = formatDecimalPercent(0)
