// @flow
import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Box } from '@mui/material'

type Props = {
  id: number,
  children: React$Node,
  component?: 'string' | React$Node,
  sx?: Object,
  draggingStyle?: Object,
}

export const SortableItem = ({
  id,
  children,
  component = 'div',
  sx = {},
  draggingStyle = {},
}: Props) => {
  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Box
      data-testid={`sortable-item-${id}`}
      style={style}
      ref={setNodeRef}
      component={component}
      sx={{ ...sx, ...(isDragging && draggingStyle) }}
    >
      {children}
    </Box>
  )
}
