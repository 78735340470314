//@flow
import { useEffect, useRef } from 'react'

type ClickEvent = MouseEvent | TouchEvent

const clickWasOutside = (parent, target) =>
  parent && target instanceof Node && !parent.contains(target)

const useClickOutside = (handler: (ClickEvent) => void) => {
  const ref = useRef<?HTMLElement>()

  useEffect(() => {
    const listener = (event: ClickEvent) => {
      if (clickWasOutside(ref.current, event.target)) {
        handler(event)
        return
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])

  return ref
}

export default useClickOutside
