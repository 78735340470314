// @flow
import { createSelector } from 'reselect'
import { pick, prop } from 'ramda'

import type { UserState } from '~reducers/user'

const userSelector = prop('user')

const findSelectedProfile = (user: UserState) => {
  return user.profiles.find(({ id }) => id === user.selectedProfile)
}

export const getSelectedProfile = createSelector(
  userSelector,
  findSelectedProfile
)

export const getUser = createSelector(userSelector, pick(['name']))
