// @flow
import dayjs from 'dayjs'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'
import { saveBlob } from '~helpers/saveFile'

type Params = {
  entityId: number,
  classroomId?: string,
  order?: string,
  orderBy?: string,
}

const endpoint = '/api/v3/student_profiles'

const getFocoStudentListExport =
  ({ entityId, classroomId, order = 'asc', orderBy = 'user_name' }: Params) =>
  (): APIResponse<string> => {
    return fetchWithRoot('get')(endpoint, {
      params: {
        entity_type: 'school',
        entity_id: entityId,
        q: {
          s: `${orderBy} ${order}`,
          classroom_id_eq: classroomId,
        },
      },
      headers: { Accept: 'text/csv' },
      responseType: 'blob',
      bypassLoader: true,
    }).then(([error, data]) => {
      if (data) {
        const fileName = 'lista_alunos'
        const timestamp = dayjs().format('YYYY-MM-DD_HH-mm-ss')

        saveBlob({ data, fileName: `${fileName}_${timestamp}.csv` })
      }

      return [error, data]
    })
  }

export default getFocoStudentListExport
