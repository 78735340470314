// @flow
import { Helmet } from 'react-helmet'
import React from 'react'
import useCustomization from '~hooks/useCustomization'

const HTMLHead = () => {
  const customization = useCustomization()

  return (
    <Helmet>
      <title>{customization.applicationName}</title>
    </Helmet>
  )
}

export default HTMLHead
