/* global __REDUX_DEVTOOLS_EXTENSION__ */
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

let devTools

try {
  devTools = __REDUX_DEVTOOLS_EXTENSION__
} catch (e) {
  devTools = () => (f) => f
}

const configureStore = (initialState, reducers) =>
  createStore(
    combineReducers({ ...reducers }),
    initialState,
    compose(applyMiddleware(thunk), devTools())
  )

export default configureStore
