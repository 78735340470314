import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import configureStore from './configureStore'

import aap from '~reducers/aap'
import ahoy from '~reducers/ahoy'
import header from '~reducers/header'
import loader from '~reducers/loader'
import loginRedirect from '~reducers/loginRedirect'
import subjects from '~reducers/subjects'
import user from '~reducers/user'

const initialState = {}

export const reducers = {
  user: persistReducer(
    {
      key: '_focobr_user',
      blacklist: ['profiles', 'personables', 'features'],
      storage,
    },
    user
  ),
  ahoy: persistReducer({ key: '_focobr_ahoy', storage }, ahoy),
  aap,
  subjects,
  loader,
  header,
  loginRedirect,
}

const store = configureStore(initialState, reducers)

export const persistor = persistStore(store)

export default store
