// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useFilters from '~hooks/useFilters'
import useResource from '~hooks/useResource'

type Skill = {
  id: number,
  name: string,
  code: string,
  description: string,
  curriculum_competency: Competency,
}

type Competency = { id: number, name: string, description: string }

type BaseEvent = {
  id: number,
  curriculum_skill: Skill,
  curriculum_competency: Competency,
}

type Grade = { id: number, name: string }

type Subject = { id: number, name: string }

type Classroom = { id: number, name: string }

type CurriculumPeriod = { id: number, name: string }

type CurriculumEventsSkill = {
  curriculum_skill: Skill,
  id: number,
  status: string,
}

type CurriculumEventsMaterial = {
  id: number,
  curriculum_material_id: number,
}

type CurriculumEventStudentProfile = {
  student_profile: {
    user: {
      id: number,
      name: string,
      email: string,
      active: boolean,
    },
  },
}

export type CurriculumEvent = {
  id: number,
  week: number,
  date: string,
  start_time: string,
  objective: string,
  status: string,
  end_time: string,
  performed: boolean,
  performed_to_plan: boolean,
  activity_description: ?string,
  content_description: ?string,
  unperformed_justification: ?string,
  unplanned_justification: ?string,
  notes: ?string,
  base_event: BaseEvent,
  grade: Grade,
  classroom: Classroom,
  subject: Subject,
  curriculum_period: CurriculumPeriod,
  curriculum_events_curriculum_skills: CurriculumEventsSkill[],
  curriculum_events_curriculum_materials: CurriculumEventsMaterial[],
  curriculum_events_student_profiles: CurriculumEventStudentProfile[],
  curriculum_activities: { id: number, name: string }[],
}

export const endpoint = (id: number) => `/api/v3/curriculum_events/${id}`

export const useCurriculumEvent = (id: number) => {
  const { params }: any = useFilters()

  const { loading, error, data } = useResource({
    resource: getEvent,
    params: { ...params, id },
    key: 'useCurriculumEvent',
  })

  return { loading, error, data }
}

const getEvent = ({ entityId, id }): APIResponse<CurriculumEvent> => {
  return fetchWithRoot('get')(endpoint(id), {
    params: {
      entity_id: entityId,
      entity_type: 'school',
      fields: [
        'id',
        'date',
        'week',
        'activity_description',
        'start_time',
        'end_time',
        'notes',
        'performed',
        'status',
        'objective',
        'performed_to_plan',
        'unperformed_justification',
        'unplanned_justification',
        'content_description',
        'activity_description',
        'entity_id',
        {
          curriculum_events_student_profiles: [
            'presence',
            'id',
            { student_profile: ['id', { user: ['name', 'id', 'active'] }] },
          ],
        },
        {
          base_event: [
            'id',
            {
              curriculum_skill: ['id', 'name', 'code', 'description'],
            },
            { curriculum_competency: ['id', 'name', 'description'] },
          ],
        },
        { classroom: ['id', 'name'] },
        { grade: ['id', 'name'] },
        { subject: ['id', 'name'] },
        { curriculum_period: ['id', 'name'] },
        { curriculum_activities: ['id', 'name'] },
        {
          curriculum_events_curriculum_skills: [
            'id',
            'status',
            {
              curriculum_skill: [
                'id',
                'name',
                'description',
                'code',
                { curriculum_competency: ['id', 'name'] },
              ],
            },
          ],
        },
      ],
      include: [
        'classroom',
        'curriculum_period',
        'curriculum_events_curriculum_materials',
        'grade',
        'subject',
        'curriculum_activities',
        {
          curriculum_events_student_profiles: [{ student_profile: ['user'] }],
        },

        {
          base_event: [
            'curriculum_skill',
            'curriculum_competency',
            'curriculum_skill',
          ],
        },
        {
          curriculum_events_curriculum_skills: [
            { curriculum_skill: ['curriculum_competency'] },
          ],
        },
      ],
    },
    bypassLoader: true,
  })
}
