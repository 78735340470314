// @flow
import { createReducer } from 'reduxsauce'

export type RedirectState = {| pathname: string, search: string |}

type Action = { type: string, payload: $Shape<RedirectState> }

export const initialState: RedirectState = {
  pathname: '/pagina-inicial',
  search: '',
}

const setRedirect = (state: RedirectState, { payload }: Action) => ({
  ...state,
  ...payload,
})

const clearRedirect = () => initialState

const handlers = {
  SET_LOGIN_REDIRECT: setRedirect,
  CLEAR_LOGIN_REDIRECT: clearRedirect,
}

export default createReducer(initialState, handlers)
