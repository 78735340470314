// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const competencies_endpoint = '/api/v3/curriculum_competencies'
export const curriculum_units_endpoint = '/api/v3/minimum_curriculum_units'
export const create_event_endpoint = (baseCurriculumId: string) =>
  `/api/v3/base_curriculums/${baseCurriculumId}/base_events`
export const base_event_endpoint = (
  baseEventId: number,
  baseCurriculumId: string
) => `/api/v3/base_curriculums/${baseCurriculumId}/base_events/${baseEventId}`
export const edit_event_endpoint = (
  baseEventId: number,
  baseCurriculumId: string
) => `/api/v3/base_curriculums/${baseCurriculumId}/base_events/${baseEventId}`

type CurriculumCompetenciesParams = {
  subjectId: number,
  gradeId: number,
}

export const useCurriculumCompetencies = ({
  subjectId,
  gradeId,
}: CurriculumCompetenciesParams) => {
  const { error, loading, data } = useResource({
    params: { subjectId, gradeId },
    key: 'getCurriculumCompetencies',
    resource: getCurriculumCompetencies,
  })

  return { error, loading, data }
}

export const getCurriculumCompetencies = ({
  subjectId,
  gradeId,
}: CurriculumCompetenciesParams) =>
  fetchWithRoot('get')(competencies_endpoint, {
    bypassLoader: true,
    params: {
      include: ['curriculum_competencies'],
      fields: [{ curriculum_competencies: ['id', 'name', 'description'] }],
      q: {
        active_eq: true,
        subject_id_eq: subjectId,
        curriculum_skills_grades_id: gradeId,
      },
    },
  })

type UnitsParams = { competencyId: string, gradeId: number }

export const useCurriculumUnits = ({ competencyId, gradeId }: UnitsParams) => {
  const { error, loading, data } = useResource({
    key: 'getCurriculumUnits',
    resource: getCurriculumUnits,
    params: { competencyId, gradeId },
  })

  return { error, loading, data }
}

export const getCurriculumUnits = ({ competencyId, gradeId }: UnitsParams) => {
  return fetchWithRoot('get')(curriculum_units_endpoint, {
    bypassLoader: true,
    params: {
      include: [{ minimum_curriculum_units: ['curriculum_skill'] }],
      fields: [
        {
          minimum_curriculum_units: [
            'id',
            'name',
            { curriculum_skills: ['name', 'description'] },
          ],
        },
      ],
      q: {
        active_eq: true,
        curriculum_skill_curriculum_competency_id_eq: competencyId,
        curriculum_skill_grades_id_eq: gradeId,
      },
    },
  })
}

type Data = {
  minimum_curriculum_unit_id: string,
  activity_description: string,
  curriculum_activity_ids: Array<string>,
}

type Params = {
  data: Data,
  baseCurriculumId: string,
}

type EditParams = {
  ...Params,
  baseDataId: number,
}

export const createEvent = ({ data, baseCurriculumId }: Params) => {
  return fetchWithRoot('post')(create_event_endpoint(baseCurriculumId), {
    data: {
      base_event: {
        ...data,
      },
    },
  }).then(([error]) => {
    if (error) {
      throw new Error(error?.message ?? error?.status)
    }
  })
}

export const editEvent = ({
  data,
  baseDataId,
  baseCurriculumId,
}: EditParams) => {
  if (!baseCurriculumId) return false
  return fetchWithRoot('put')(
    edit_event_endpoint(baseDataId, baseCurriculumId),
    {
      data: {
        base_event: {
          ...data,
        },
      },
    }
  ).then(([error]) => {
    if (error) {
      throw new Error(error?.message ?? error?.status)
    }
  })
}
