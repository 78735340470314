// @flow
import React, { createContext, useContext, useState } from 'react'
import { FeedbackSnackbar } from '~primitives/atoms/FeedbackSnackbar'

type MessageParam = {
  type: 'error' | 'warning' | 'info' | 'success',
  text: string,
}

type Message = {
  ...MessageParam,
  id: string,
}

type ContextData = { showMessage: (MessageParam) => void }

export const FeedbackContext = createContext<ContextData>({
  showMessage: () => {},
})

export const FeedbackProvider = ({ children }: { children: React$Node }) => {
  const [messages, setMessages] = useState<Message[]>([])

  const handleCloseMessage = (deleteId) => {
    setMessages((currentMessages) =>
      currentMessages.filter(({ id }) => id !== deleteId)
    )
  }

  const showMessage = (message) => {
    const id = Date.now().toString()
    setMessages((currentMessages) => [...currentMessages, { ...message, id }])
  }

  return (
    <FeedbackContext.Provider value={{ showMessage }}>
      {children}
      {messages.map(({ type, text, id }, i) => (
        <FeedbackSnackbar
          key={id}
          id={id}
          type={type}
          text={text}
          onClose={handleCloseMessage}
          isOpen={messages.length - 1 === i}
        />
      ))}
    </FeedbackContext.Provider>
  )
}

export const useFeedback = () => useContext(FeedbackContext)
