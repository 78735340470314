// @flow
import React from 'react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js'

const reactPlugin = new ReactPlugin()

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: ENV_VARS['APPLICATIONINSIGHTS_INSTRUMENTATION_KEY'],
    ingestionEndpoint: ENV_VARS['APPLICATIONINSIGHTS_INGESTION_ENDPOINT'],
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
})

appInsights.loadAppInsights()

const TrackingComponent = ({ children }) => {
  return children
}

export const ApplicationInsightsTracking = withAITracking(
  reactPlugin,
  TrackingComponent
)
