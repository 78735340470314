// @flow
import React from 'react'
import { Stack } from '@mui/material'
import { InsightBox } from './Box'
import { formatDecimalPercent } from '~helpers/formatting/numbers'
import { useCurriculumEventsInsights } from '~data/curriculum/events'
import useFilters from '~hooks/useFilters'

export const Insights = () => {
  const { params }: any = useFilters()

  const handlingParameters = (params) => {
    for (const property in params) {
      params[property] === 0 ? delete params[property] : ''
    }

    return params
  }

  const { data } = useCurriculumEventsInsights(handlingParameters(params))
  const performedRate = data?.performed_rate

  return (
    <Stack
      direction="row"
      gap={2.5}
      bgcolor="grey.200"
      p={2.5}
      borderRadius={2}
    >
      <InsightBox
        label="Aplicação do currículo"
        value={formatDecimalPercent(0)(performedRate).replace('%', '')}
        suffix={
          performedRate === null || performedRate === undefined ? '' : '%'
        }
        name="perfomed-rate"
      />
      <InsightBox
        label="Aulas realizadas"
        value={data?.performed_count}
        total={data?.events_count}
        name="perfomed-count"
      />
      <InsightBox
        label="Habilidades aplicadas"
        value={data?.performed_curriculum_skills_count}
        total={data?.planned_curriculum_skills_count}
        name="planned-curriculum-skill-count"
      />
    </Stack>
  )
}
