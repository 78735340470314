// @flow
import { Stack, Typography } from '@mui/material'
import React from 'react'
import IconWarning from '~assets/svg/icon_warning.svg'

type Props = {
  text: string,
}
export const Placeholder = ({ text }: Props) => (
  <Stack
    justifyContent="center"
    alignItems="center"
    direction="row"
    gap={2}
    sx={{ bgcolor: 'grey.200', p: 2.5 }}
  >
    <img src={IconWarning} alt="Ícone de alerta" width="18px" />
    <Typography variant="body2">{text}</Typography>
  </Stack>
)
