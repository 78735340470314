// @flow
import { useParams } from 'react-router-dom'
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

type CurriculumSkill = {
  code: number,
  description: string,
  curriculum_competency: { name: string },
}

type CurriculumUnit = {
  id: number,
  curriculum_skill: CurriculumSkill[],
}

type BaseEvents = {
  id: number,
  performed: boolean,
  activity_description: string | null,
  event_order: CurriculumSkill,
  minimum_curriculum_unit: CurriculumUnit,
  curriculum_activities: { name: string },
}

type BaseCurriculumResponse = {
  id: number,
  events_per_week: number,
  curriculum_period: { name: string },
  subject: { name: string, id: number },
  grade: { name: string, id: number },
  base_events: BaseEvents[],
}

export const endpoint = (base_curriculum_id: string) =>
  `api/v3/base_curriculums/${base_curriculum_id}`

export const useBaseCurriculum = () => {
  const { baseCurriculumId, entityId, entityType }: any = useParams()

  const { loading, error, data } = useResource({
    resource: getBaseCurriculum,
    params: { baseCurriculumId, entityId, entityType },
    enabled: !!entityId,
    key: 'useBaseCurriculum',
  })

  return {
    loading,
    error,
    data,
  }
}

const getBaseCurriculum = ({
  baseCurriculumId,
  entityId,
  entityType,
}: {
  baseCurriculumId: string,
  entityId: string,
  entityType: string,
}): APIResponse<BaseCurriculumResponse> => {
  return fetchWithRoot('get')(endpoint(baseCurriculumId), {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      include: [
        'curriculum_period',
        'subject',
        'grade',
        {
          base_events: [
            {
              minimum_curriculum_unit: [
                { curriculum_skill: ['curriculum_competency'] },
              ],
            },
            'curriculum_activities',
          ],
        },
      ],
      fields: [
        'id',
        'events_per_week',
        {
          curriculum_period: ['name', 'id'],
          subject: ['name', 'id'],
          grade: ['name', 'id'],
          base_events: [
            'id',
            'activity_description',
            'event_order',
            'performed',
            {
              minimum_curriculum_unit: [
                'id',
                {
                  curriculum_skill: [
                    'name',
                    'description',
                    { curriculum_competency: ['name', 'id', 'description'] },
                  ],
                },
              ],
              curriculum_activities: ['name', 'id'],
            },
          ],
        },
      ],
    },
    bypassLoader: true,
  })
}
