// @flow
import React from 'react'
import { DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core'

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
}

type Props = {
  children: React$Node,
  styles: Object,
}

export const SortableOverlay = ({ children, styles = {} }: Props) => (
  <DragOverlay
    dropAnimation={dropAnimationConfig}
    style={{
      ...styles,
    }}
  >
    {children}
  </DragOverlay>
)
