// @flow
import React from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { SVGIcon, type IconName } from '~primitives/Utilities/SVGIcon'

type Icon = {
  name: IconName,
  size?: number,
  color?: string,
}

type Props = {
  title: string,
  icon?: Icon,
  value: string | React$Node,
  ...Object,
}

export const InsightBox = ({ title, icon, value, ...props }: Props) => {
  const { palette } = useTheme()
  return (
    <Stack
      direction={{ xs: 'row', md: 'column' }}
      alignItems="center"
      justifyContent="space-between"
      borderRadius={2}
      p={{ xs: 2, md: 1 }}
      height={120}
      bgcolor={palette.grey[0]}
      position="relative"
      {...props}
    >
      {icon && (
        <Box
          sx={{
            position: 'absolute',
            top: 4,
            left: 20,
            display: { xs: 'none', md: 'block' },
          }}
        >
          <SVGIcon
            name={icon.name}
            size={icon?.size ?? 3}
            color={icon?.color ?? palette.primary.main}
          />
        </Box>
      )}
      {typeof title === 'string' ? (
        <Typography fontSize={13}>{title}</Typography>
      ) : (
        title
      )}
      {typeof value === 'string' ? (
        <Typography fontSize={30}>{value}</Typography>
      ) : (
        value
      )}
    </Stack>
  )
}
