// @flow
import React from 'react'
import { styled } from '@mui/material/styles'
import type { Node } from 'react'

type FlashMessageProps = {|
  color: 'danger' | 'warning' | 'success' | 'white',
  center?: boolean,
  children: Node,
  buttonText?: string,
  onClick?: (SyntheticInputEvent<HTMLButtonElement>) => void,
  'data-testid'?: string,
|}

const Wrapper = styled('div')`
  background: ${({ theme, color }) => theme.palette.foco[color]};
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  overflow: hidden;
  border-radius: 2px;
  padding: 12px 8px 0;
  height: 44px;
  max-width: 960px;
  margin: 16px auto;
  position: relative;

  button {
    font-size: 1em;
    color: #fff;
    border: none;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    padding: 0.2em 0.8em;
    position: absolute;
    right: 16px;
    top: 8px;
    font-weight: 600;
    transition: background 0.1s ease-in-out;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
`

export const FlashMessage = ({
  children,
  buttonText,
  onClick,
  ...props
}: FlashMessageProps) => (
  <Wrapper {...props}>
    {children}
    {buttonText && <button onClick={onClick}>{buttonText}</button>}
  </Wrapper>
)
