// @flow
import { config } from '../../config'
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'
import { getFieldsAndIncludes } from '~data/helpers'

type Extension = $Keys<typeof extensionsConfig>

type Params = {
  extensions?: Extension[],
}

export type CurriculumActivity = { id: number, name: string }

type CurriculumActivitiesResponse = { subjects: CurriculumActivity[] }

export const useCurriculumActivities = (params?: Params) => {
  const { loading, error, data } = useResource({
    key: config.key,
    resource: getCurriculumActivities,
    params,
  })

  return { loading, error, data }
}

export const getCurriculumActivities = ({
  extensions = [],
}: Params): APIResponse<CurriculumActivitiesResponse> =>
  fetchWithRoot('get')(config.endpoint, {
    bypassLoader: true,
    params: {
      ...getFieldsAndIncludes(extensionsConfig, extensions),
      q: { active_eq: true },
    },
  })

const extensionsConfig = {
  default: {
    include: ['curriculum_activities'],
    fields: [{ curriculum_activities: ['id', 'name'] }],
  },
}
