// @flow
import { createReducer } from 'reduxsauce'

export type HeaderState = {
  title: string,
  subtitle: string,
  hideTitle: boolean,
}

type Action = { type: string, payload: Object }

const initialState = {
  title: '',
  subtitle: '',
  hideTitle: false,
}

const setHeader = (state: HeaderState, { payload }: Action) => ({
  ...state,
  hideTitle: false,
  subtitle: '',
  ...payload,
})

const handlers = {
  SET_HEADER: setHeader,
}

export default createReducer(initialState, handlers)
