//@flow
import React, { useState } from 'react'
import qs from 'qs'

import { useParams, useLocation } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { Placeholder } from './Placeholder'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import SortTable from '~primitives/molecules/Table/SortTable'

import DownloadCSV from '~components/ManageStudents/DownloadCSV'
import getFocoStudentListExport from '~data/manageStudents/studentsListExport'

import useFilters from '~hooks/useFilters'

import { useStudentProfiles } from '~data/api/v3/studentProfiles/query/findMany'

const options = [
  { key: 'all', name: 'Todos' },
  { key: 'active', name: 'Ativos' },
  { key: 'inactive', name: 'Inativos' },
]

export const StudentsTable = ({
  handleOpenEditModal,
  handleOpenDeleteModal,
}: any) => {
  const { filters } = useFilters()
  const { entityId } = useParams()
  const search = qs.parse(useLocation().search)
  const page = search.page || search['?page']
  const order = search.order || search['?order']
  const orderBy = search.orderBy || search['?orderBy']
  const searchText = search.q || search['?q']

  const { data, loading, error } = useStudentProfiles({
    entityType: 'school',
    entityId: Number(entityId),
    classroomId: filters?.classroom_id?.selected.id,
    page: page ? Number(page) : 1,
    order,
    orderBy,
    searchText,
    enabled: !!filters?.classroom_id?.selected.id,
    extensions: ['manageStudents'],
  })

  const cellsHeader = [
    { label: '', align: 'center' },
    { label: 'Nome', property: 'user_name' },
    { label: 'Matrícula', property: 'registration_number', align: 'center' },
    { label: 'Email', property: 'user_email', align: 'center' },
    { label: 'Turma', property: 'classroom_name', align: 'center' },
    { label: 'Status', property: 'active', align: 'center' },
    { label: 'Editar', align: 'center' },
  ]

  const [selectedRows, setSelectedRows] = useState([])

  const handleSelectRow = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId))
    } else {
      setSelectedRows([...selectedRows, rowId])
    }
  }

  const rows = normalizeTableRow(
    data?.student_profiles,
    handleOpenEditModal,
    entityId,
    filters?.grade_id?.selected.id,
    selectedRows,
    handleSelectRow
  )

  const handleSelectAllRows = () => {
    if (selectedRows?.length === rows?.length) {
      setSelectedRows([])
    } else {
      const allRowIds = rows?.map((row) => row.key)
      setSelectedRows(allRowIds)
    }
  }

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleMenuItemClick = (event, index, option) => {
    setSelectedIndex(index)
    setOpen(false)

    if (option.key === 'all') {
      const allRowIds = rows?.map((row) => row.key)
      setSelectedRows(allRowIds)
    } else if (option.key === 'active') {
      let allRowIds = rows.filter((row) => row.disabled)
      allRowIds = allRowIds.map((row) => row.key)
      setSelectedRows(allRowIds)
    } else if (option.key === 'inactive') {
      let allRowIds = rows.filter((row) => !row.disabled)
      allRowIds = allRowIds.map((row) => row.key)
      setSelectedRows(allRowIds)
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  if (error || !rows) return null

  return (
    <>
      <Stack justifyContent="space-between" direction="row" mb={2}>
        <Box>
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            sx={{ boxShadow: 'none !important', alignItems: 'center' }}
          >
            <Checkbox
              checked={selectedRows.length === rows.length && rows.length > 0}
              onChange={handleSelectAllRows}
              disabled={rows.length === 0}
            />
            <Button
              size="small"
              disableRipple
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              sx={{
                backgroundColor: 'white',
                borderColor: '#ffffff !important',
                minWidth: 24,
                transition: 'transform .2s ease-in',
                transform: `rotateX(${open ? '180deg' : '0'})`,
                '&:hover': {
                  backgroundColor: 'white',
                },
              }}
            >
              <SVGIcon name="openDropdown" color="linkColor" size={0} />
            </Button>
            {selectedRows.length > 0 && (
              <Button
                variant="outlined-noborder"
                size="small"
                onClick={() => {
                  handleOpenDeleteModal({ schoolId: entityId, selectedRows })
                  setSelectedRows([])
                }}
                startIcon={<SVGIcon name="delete" size={2} color="#F05E09" />}
              >
                Excluir
              </Button>
            )}
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 99,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.key}
                          selected={index === selectedIndex}
                          onClick={(event) =>
                            handleMenuItemClick(event, index, option)
                          }
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
        <DownloadCSV
          getCSV={getFocoStudentListExport({
            entityId: Number(entityId),
            classroomId: filters?.classroom_id?.selected.id,
          })}
        />
      </Stack>
      <SortTable
        variant="simple"
        rows={loading ? Placeholder() : rows}
        rowsAlign={[
          'left',
          'left',
          'center',
          'center',
          'center',
          'center',
          'center',
        ]}
        firstCheckbox={true}
        cellsHeader={cellsHeader}
        lengthText="Total de alunos"
        totalPages={data?.meta?.total_pages || 1}
        currentPage={page ? Number(page) : 1}
      />
    </>
  )
}

const normalizeTableRow = (
  data,
  handleOpenEditModal,
  schoolId,
  grade,
  selectedRows,
  onSelect
) => {
  if (!schoolId || !data) {
    return []
  } else {
    return data?.map(({ registration_number, active, user, classroom, id }) => {
      const handleChange = () => {
        onSelect(id)
      }
      const { email, name } = user
      const buttonModalProps = {
        id,
        name,
        registration_number,
        email,
        isActive: active,
        classroom: classroom.id,
        grade,
        schoolId,
      }

      const status = active ? 'Ativo' : 'Inativo'
      return {
        fields: [
          <Checkbox
            key={'check-' + id}
            checked={selectedRows.includes(id)}
            onChange={handleChange}
          />,
          name,
          registration_number,
          email,
          classroom?.name,
          status,
          <ButtonEditModal
            onClick={() => handleOpenEditModal({ ...buttonModalProps })}
            disabled={!schoolId}
            key={user.id}
          />,
        ],
        disabled: !active,
        key: id,
      }
    })
  }
}

const ButtonEditModal = (props) => (
  <Tooltip title="Editar aluno" placement="bottom" arrow>
    <IconButton aria-label="edit" variant="outlinedSecondary" {...props}>
      <SVGIcon name="edit" size={2} />
    </IconButton>
  </Tooltip>
)
