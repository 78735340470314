// @flow
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Stack, Typography, Button } from '@mui/material'
import qs from 'qs'
import FormModal from './Modals/Form'
import DeleteModal from './Modals/Delete'
import CreateModal from './Modals/Create'
import BaseCurriculumsTable from './BaseCurriculumsTable'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { pxToRem } from '~helpers/getFontValue'
import { useBaseCurriculums } from '~data/curriculum/baseCurriculums'
import FilterBar from '~components/Common/FilterBar'

type Curriculum = {
  curriculumId: number,
  academic_year_id: number,
  grade_id: number,
  subject_id: number,
  curriculum_period_id: number,
  events_per_week: number,
}

type Props = {
  entityId: number,
  entityType: string,
}

export const List = ({ entityId, entityType }: Props) => {
  const [curriculumId, setCurriculumId] = useState(0)

  const { order, orderBy, page } = qs.parse(useLocation().search, {
    ignoreQueryPrefix: true,
  })

  const [modalFormIsOpen, setModalFormIsOpen] = useState(false)
  const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)

  const [curriculumData, setCurriculumData]: [Curriculum | null, Function] =
    useState(null)

  const closeModal = () => {
    setModalFormIsOpen(false)
    setModalDeleteIsOpen(false)
    setCurriculumData(null)
  }

  const handleOpenModalForm = () => {
    setModalFormIsOpen(true)
  }

  const handleOpenModalDelete = (id: number) => {
    setCurriculumId(id)
    setModalDeleteIsOpen(true)
  }

  const handleOpenModalCreate = () => {
    setModalCreateIsOpen(true)
  }

  const { loading, error, data } = useBaseCurriculums({
    entityType,
    entityId,
    order,
    orderBy,
    page,
  })

  if (error) {
    return `Erro: ${error.status} - ${error.message || ''}`
  }

  return (
    <>
      <Box my={4}>
        <FilterBar
          margin="0"
          contained
          wrapperProps={{ maxWidth: '160px' }}
          reloadOnChange={false}
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mt: 4,
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: pxToRem(18),
              my: pxToRem(6),
              fontWeight: 700,
            }}
          >
            Meus Currículos
          </Typography>

          {entityType === 'district' && (
            <Button
              onClick={handleOpenModalForm}
              variant="primary"
              size="small"
              startIcon={<SVGIcon name="addCircle" color="white" />}
              sx={{
                minWidth: '200px',
                display: 'flex',
                float: { xs: 'none', sm: 'right' },
              }}
            >
              Criar currículo
            </Button>
          )}
          {entityType === 'school' && (
            <Button
              onClick={handleOpenModalCreate}
              variant="primary"
              size="small"
              startIcon={<SVGIcon name="addCircle" color="white" />}
              sx={{
                minWidth: '200px',
                display: 'flex',
                float: { xs: 'none', sm: 'right' },
              }}
            >
              Adicionar currículo
            </Button>
          )}
        </Stack>

        <BaseCurriculumsTable
          baseCurriculums={data?.base_curriculums}
          isLoading={loading}
          totalPages={data?.meta?.total_pages || 1}
          currentPage={page ? Number(page) : 1}
          setCurriculumData={setCurriculumData}
          handleOpenModalDelete={handleOpenModalDelete}
          entityId={entityId}
          entityType={entityType}
        />
      </Box>

      {entityId && (modalFormIsOpen || curriculumData) && (
        <FormModal
          handleCloseModal={closeModal}
          entityId={String(entityId)}
          curriculumData={curriculumData}
        />
      )}

      {modalDeleteIsOpen && curriculumId && (
        <DeleteModal
          handleCloseModal={closeModal}
          curriculumId={curriculumId}
          entityId={entityId}
          entityType={entityType}
        />
      )}

      {modalCreateIsOpen && (
        <CreateModal
          schoolId={entityId}
          isOpen={modalCreateIsOpen}
          setIsOpen={setModalCreateIsOpen}
        />
      )}
    </>
  )
}
