// @flow
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'

type Params = { id: number }

const useExam = (params: Params) => {
  const { loading, error, data } = useResource({
    key: 'useStudentFocoExam',
    resource: getExam,
    params,
  })

  return { loading, error, data }
}

export const endpoint = (id: number) =>
  `/api/v3/foco_exam_student_profiles/${id}`

export type FocoQuestionOption = {
  id: number,
  alternative: string,
  text: string,
}

export type FocoQuestion = {
  id: number,
  code: string,
  foco_question_options: Array<FocoQuestionOption>,
  statement?: string,
  command?: string,
  reference?: string,
  support_image_path?: string,
}

export type FocoExam = {
  id: string,
  foco_exam_edition_name: string,
  subject_name: string,
  grade_name: string,
  exam_start: string,
  exam_end: string,
  time_limit: number,
  question_number: number,
  instructions: string,
  foco_answers: { id: number, selected_foco_question_option_id: number },
  foco_questions: Array<FocoQuestion>,
}

const getExam = ({ id }: Params): APIResponse<FocoExam> =>
  fetchWithRoot('get')(endpoint(id), {
    params: {
      include: [
        'foco_answers',
        {
          foco_questions: ['foco_question_options'],
        },
      ],
      fields: [
        'id',
        'foco_exam_edition_name',
        'subject_name',
        'grade_name',
        'exam_start',
        'exam_end',
        'time_limit',
        'question_number',
        'instructions',
        {
          foco_questions: [
            'id',
            'code',
            'statement',
            'command',
            'reference',
            'support_image_path',
            { foco_question_options: ['id', 'alternative', 'text'] },
          ],

          foco_answers: [
            'id',
            'selected_foco_question_option_id',
            'foco_question_id',
          ],
        },
      ],
    },
    bypassLoader: true,
  })

export default useExam
