// @flow

export const getStyles = (theme: Object) => ({
  container: {
    bgcolor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    py: 2.5,
    px: 4,
    gap: 1,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: 2,
    },
    [theme.breakpoints.up('md')]: {
      gap: 3.5,
    },
  },
})
