// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpoint = (id?: number) =>
  `/api/v3/foco_exams/${id || 0}/foco_exam_result`

export type QuestionResult = {
  id: number,
  hit_rate: number,
  foco_question: {
    id: number,
    code: string,
    curriculum_skill: { id: number, name: string, description: string },
  },
}

export type QuestionsResponse = {
  foco_exam_results_questions: QuestionResult[],
}

type Params = {
  examId?: number,
  classroomId?: number,
  schoolId: number,
}

export const useFocoExamResultQuestions = (params: Params) => {
  const { data, loading, error } = useResource({
    key: 'useFocoExamResultQuestions',
    resource: getFocoExamResultQuestions,
    params,
    enabled: Boolean(params.examId && params.classroomId),
  })

  return { data, loading, error }
}

const getFocoExamResultQuestions = (
  params: Params
): APIResponse<QuestionsResponse> =>
  fetchWithRoot('get')(endpoint(params.examId), {
    params: {
      entity_type: 'school',
      entity_id: params.schoolId,
      classroom_id: params.classroomId,
      include: [{ questions: [{ foco_question: ['curriculum_skill'] }] }],
      fields: [
        {
          questions: [
            'id',
            'hit_rate',
            {
              foco_question: [
                'id',
                { curriculum_skill: ['id', 'name', 'description'] },
              ],
            },
          ],
        },
      ],
    },
  })
