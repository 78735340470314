//@flow
import type { Profile } from '~types/Profile'

const basePaths = {
  assessments_panel: 'avaliacoes-foco',
  editions_management: 'gerenciar-avaliacoes',
  exams_management: 'gerenciar-cadernos',
}

export const getFocoExamsLink = (profile: Profile, resource: string) => {
  return `/${basePaths[resource]}/${profile.kind}/${profile.personable.id}`
}
