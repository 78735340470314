// @flow
import React from 'react'
import { Stack, Button, Typography } from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

type Props = {
  selectedFile: any,
  acceptedFormats?: Array<string>,
  setSelectedFile: (File) => void,
  onClick?: (Event) => void,
  sx?: Object,
  disabled?: boolean,
  size?: 'small' | 'medium' | 'large',
  placeholder?: string,
}

export const InputFile = ({
  selectedFile,
  setSelectedFile,
  acceptedFormats = [],
  onClick,
  sx = {},
  disabled,
  size = 'medium',
  placeholder,
}: Props) => {
  const handleFileChange = ({ target }) => {
    setSelectedFile(target.files[0])
  }

  return (
    <Button
      component="label"
      variant="white"
      size={size}
      onChange={handleFileChange}
      onClick={onClick}
      sx={{ ...sx }}
    >
      <Typography
        variant="body2"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          pr: 1,
        }}
      >
        {selectedFile?.name ? selectedFile.name : placeholder}
      </Typography>
      <input
        disabled={disabled}
        type="file"
        accept={acceptedFormats.length === 0 ? '*' : acceptedFormats.join(', ')}
        hidden
      />
      <Stack alignItems="end" sx={{ minWidth: 30 }}>
        <SVGIcon
          color={selectedFile ? 'neutral' : 'linkColor'}
          size={5}
          name="cloudUpload"
        />
      </Stack>
    </Button>
  )
}
