// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const getEndpoint = (questionId: number) =>
  `api/v3/foco_questions/${questionId}`

type Params = {
  questionId: number,
}

type FocoQuestion = {
  id: number,
  text: string,
  code: string,
  curriculum_skill: {
    name: 'string',
    description: 'string',
    id: number,
  },
}

export const useQuestion = (params: Params) => {
  const { data, loading, error } = useResource({
    key: 'useQuestion',
    params,
    enabled: !!params?.questionId,
    resource: getQuestion,
  })

  return { data, loading, error }
}

const getQuestion = (params: Params): APIResponse<FocoQuestion> =>
  fetchWithRoot('get')(getEndpoint(params?.questionId), {
    params: {
      include: ['curriculum_skill'],
      fields: [
        'text',
        'id',
        'code',
        { curriculum_skill: ['name', 'description', 'id'] },
      ],
    },
  })
