// @flow
import { fetchWithoutRoot } from '~helpers/fetch'
import type { NetworkError } from '~types/Network'

export const endpoints = {
  validateDocument: (cpf: string) =>
    `/api/v2/authentication/validate_document/${cpf}`,
  validateUserData: '/api/v2/authentication/validate_user_data',
  createUser: '/api/v2/authentication/users',
}

export type RegistrationData = {
  name: string,
  personable: {
    label?: string,
    name?: string,
  },
}

const validateDocument = (
  cpf: string
): Promise<[?NetworkError, ?RegistrationData]> =>
  fetchWithoutRoot('get')(endpoints.validateDocument(cpf))

type UserParams = { cpf: string, birthYear: string }

const validateUserData = (
  params: UserParams
): Promise<[?NetworkError, ?RegistrationData]> =>
  fetchWithoutRoot('get')(endpoints.validateUserData, {
    headers: { Document: params.cpf, 'Birth-year': params.birthYear },
  })

export type CreateParams = {
  cpf: string,
  birthYear: string,
  email: string,
  confirm: string,
  acceptTermsAndConditions: boolean,
}

export type CreateUserResponse = {
  email: string,
  registration_number: number,
}

// send invite
const createUser = (
  params: CreateParams
): Promise<[?NetworkError, ?CreateUserResponse]> =>
  fetchWithoutRoot('post')(endpoints.createUser, {
    headers: {
      Document: params.cpf,
      'Birth-year': params.birthYear,
      Email: params.email,
      'Email-confirmation': params.confirm,
    },
    data: {
      accept_terms_and_conditions: params.acceptTermsAndConditions,
    },
  })

export default {
  endpoints,
  validateDocument,
  validateUserData,
  createUser,
}
