//@flow
import { pxToRem } from '~helpers/getFontValue'

export default function Link(theme: Object) {
  return {
    MuiLink: {
      variants: [
        {
          props: { variant: 'rounded' },
          style: {
            ...theme.typography.body2,
            backgroundColor: theme.palette.secondary['main'],
            padding: '5px 25px',
            borderRadius: '32px',
            color: `${theme.palette.grey['100']} !important`, //isso tá errado
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            fontSize: 14,
            textAlign: 'center',
            lineHeight: 1,
            padding: '10px 15px',
            color: theme.palette.secondary['main'],
            borderRadius: theme.shape.borderRadius * 2,
            border: `1px solid ${theme.palette.secondary['main']}`,
            margin: 0,
            '&:hover': {
              textDecoration: 'none',
              color: theme.palette.grey[0],
              backgroundColor: theme.palette.secondary['main'],
              border: `1px solid ${theme.palette.secondary['main']}`,
            },
          },
        },
        {
          props: { variant: 'secondaryOutlined' },
          style: {
            fontSize: 14,
            lineHeight: pxToRem(18.2),
            backgroundColor: theme.palette.secondary.main,
            color: `${theme.palette.grey[0]} !important`,
            padding: `${pxToRem(9)} ${pxToRem(20)}`,
            borderRadius: theme.shape.borderRadius * 2,
            minWidth: 207,
            minHeight: 36,
            textAlign: 'center',
            '&:hover': {
              backgroundColor: theme.palette.secondary.light,
            },
          },
        },
      ],
      defaultProps: { underline: 'none' },
      styleOverrides: {
        root: { '&:hover': { color: theme.palette.secondary.main } },
      },
    },
  }
}
