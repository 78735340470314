// @flow
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import useGrades from '~data/questions/grades'
import useSkills from '~data/questions/skills'
import usePrevious from '~hooks/usePrevious'

type Props = {
  onChange: ({ gradeId: ?number, skillId: ?number }) => void,
  subjectId?: number,
}

const Filters = ({ onChange, subjectId }: Props) => {
  const gradesData = useGrades()

  const [gradeId, setGradeId] = useState(0)

  const skillsData = useSkills({
    gradeId: gradeId !== 0 ? gradeId : undefined,
    subjectId,
  })

  const [skillId, setSkillId] = useState(undefined)

  const previousSkillId = usePrevious(skillId)

  useEffect(() => {
    if (skillId && !previousSkillId) {
      onChange({ skillId, gradeId })
    }
  }, [skillId, previousSkillId, onChange, gradeId])

  useEffect(() => {
    if (!skillId) {
      setSkillId(skillsData.data?.curriculum_skills[0]?.id)
    }
  }, [skillId, skillsData.data])

  const handleChangeGrade = (event) => {
    setGradeId(event.target.value)
  }

  const handleChangeSkill = (event) => {
    setSkillId(event.target.value)
  }

  const handleClick = () => {
    onChange({ skillId, gradeId })
  }

  return (
    <section>
      <Stack direction="row" alignItems="center" gap={2.5}>
        <FormControl sx={{ minWidth: 160 }} size="small">
          <InputLabel id="grade-label" htmlFor="grade" sx={{ mb: 0 }}>
            Série
          </InputLabel>
          <Select
            value={gradeId}
            label="Série"
            labelId="grade-label"
            id="grade"
            data-testid="grade-select"
            variant="standard"
            disabled={gradesData.loading}
            onChange={handleChangeGrade}
            MenuProps={{
              style: { zIndex: 9999999 },
            }}
          >
            <MenuItem value={0}>Todas</MenuItem>
            {!!gradesData?.data?.grades?.length &&
              gradesData?.data?.grades.map((grade) => (
                <MenuItem key={grade.id} value={grade.id}>
                  {grade.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth size="small">
          <InputLabel id="skill-label" htmlFor="skill" sx={{ mb: 0 }}>
            Habilidade
          </InputLabel>
          <Select
            value={String(skillId)}
            label="Habilidade"
            labelId="skill-label"
            id="skill"
            data-testid="skill-select"
            variant="standard"
            disabled={skillsData.loading}
            onChange={handleChangeSkill}
            MenuProps={{
              style: { zIndex: 9999999 },
            }}
          >
            {!!skillsData?.data?.curriculum_skills?.length &&
              skillsData?.data?.curriculum_skills.map((skill) => (
                <MenuItem key={skill.id} value={skill.id}>
                  <Box
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      pr: 1.5,
                    }}
                  >
                    <strong>({skill.name})</strong> {skill.description}
                  </Box>
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Button variant="primary" onClick={handleClick} size="small">
          Filtrar
        </Button>
      </Stack>
    </section>
  )
}

export default Filters
