import { clone } from 'ramda'
import { createReducer } from 'reduxsauce'

const AAPResponse = (state, { payload }) => ({ ...state, response: payload })

const getAAPQuestions = (state, { payload }) => ({
  ...state,
  exam: payload,
  questionDetails: {},
})

const getAAPQuestion = (state, { payload }) => {
  const newState = clone(state)
  newState.questionDetails[payload.id] = payload
  return newState
}

export const initialState = {
  response: { status: 200, message: '' },
  exam: {
    id: 0,
    name: '',
    difficulties: '',
    students: [],
    questions: [],
  },
  questionDetails: {},
}

const handlers = {
  GET_AAP_QUESTIONS: getAAPQuestions,
  GET_AAP_QUESTION: getAAPQuestion,
  AAP_RESPONSE: AAPResponse,
}

export default createReducer(initialState, handlers)
