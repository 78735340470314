// @flow
import { type Location, type RouterHistory, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { LoggedInContainer, LoggedInInner } from './styles'
import { type Profile } from '~types/Profile'
import {
  fetchFeatures,
  fetchStudentFeatures,
  fetchProfiles,
} from '~actions/User'
import AsideMenu from '~components/AsideMenu'
import Footer from '~components/Footer'
import Header from '~components/Header'
import Loader from '~components/Common/Loader'
import LoggedInRoutes from '~routes/LoggedIn'
import usePrevious from '~hooks/usePrevious'
import type { LoaderState } from '~reducers/loader'
import type { UserState } from '~reducers/user'
import { NotificationsProvider } from '~contexts/NotificationsContext'
import { WalkthroughProvider } from '~contexts/Walkthrough'

type Props = {
  user: UserState,
  loader: LoaderState,
  fetchFeatures: () => void,
  fetchStudentFeatures: () => void,
  fetchProfiles: () => void,
  history: RouterHistory,
  location: Location,
  profile: Profile,
}

const LoggedInTemplate = ({ history, location }: Props) => {
  const user = useSelector((state) => state.user)
  const loader = useSelector((state) => state.loader)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user.profiles.length) {
      dispatch(fetchProfiles())
    }
  }, [dispatch, user.profiles.length])

  const prevSelectedProfile = usePrevious(user.selectedProfile)
  useEffect(() => {
    if (!user.selectedProfile) {
      dispatch({
        type: 'SET_LOGIN_REDIRECT',
        payload: { pathname: location.pathname, search: location.search },
      })

      history.push('/login')
    } else if (user.selectedProfile !== prevSelectedProfile) {
      if (user.student) {
        dispatch(fetchStudentFeatures())
      } else {
        dispatch(fetchFeatures())
      }
    }
  }, [
    dispatch,
    history,
    location.pathname,
    location.search,
    prevSelectedProfile,
    user,
    user.selectedProfile,
    user.student,
  ])

  if (!user.profiles.length || !user.selectedProfile) {
    return (
      <section>
        <Loader />
      </section>
    )
  }

  return (
    <LoggedInContainer>
      {loader.fetching && <Loader />}
      <WalkthroughProvider>
        <NotificationsProvider>
          <AsideMenu />

          <LoggedInInner>
            {/*eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex*/}
            <div tabIndex="0" id="main-content">
              <Header history={history} />
              <LoggedInRoutes />
              <Footer />
            </div>
          </LoggedInInner>
        </NotificationsProvider>
      </WalkthroughProvider>
    </LoggedInContainer>
  )
}

export default withRouter(LoggedInTemplate)
