// @flow
import React from 'react'
import { connect } from 'react-redux'
import FeaturesGroup from '..'
import { FeatureLink } from '../../FeatureLink'
import { getPath } from '~helpers/paths'
import { getSelectedProfile } from '~selectors/profile'
import type { Profile } from '~types/Profile'

type Props = {
  profile: Profile,
  features: Object,
  isShowingNewHome: boolean,
  activeCollapse: string,
  setActiveCollapse: (string) => void,
  activeMenu: string,
  setActiveMenu: (string) => void,
}

const mapStateToProps = (state) => ({ profile: getSelectedProfile(state) })

const TeacherFeaturesGroup = ({
  profile,
  features,
  isShowingNewHome,
  activeCollapse,
  setActiveCollapse,
  activeMenu,
  setActiveMenu,
}: Props) => {
  const defaultProps = {
    activeCollapse,
    setActiveCollapse,
    activeMenu,
    setActiveMenu,
  }

  const generateFeatureData = (featureData, feature) =>
    featureData?.map(({ resource, id, label, exam_type }) => ({
      label,
      href: getPath({
        feature,
        examType: exam_type,
        resource,
      })(id, profile),
      feature: feature,
      name: `${feature}-${resource}`,
      resource,
      id,
    }))

  return (
    <>
      {(features?.retrospective?.resources.length > 0 ||
        features?.enem?.resources.length > 0) && (
        <FeaturesGroup
          title="Resultados da Escola"
          icon="menuSchoolResult"
          name="school_result"
          activeMatches={['retrospective', 'prospective', 'enem']}
          {...defaultProps}
          groups={[
            {
              title: 'Avaliações externas',
              features: [
                ...generateFeatureData(
                  features?.retrospective.resources,
                  'retrospective'
                ),
                ...generateFeatureData(
                  features?.prospective.resources,
                  'prospective'
                ),
              ],
            },
            {
              title: 'Enem',
              features: [
                ...generateFeatureData(features?.enem.resources, 'enem'),
              ],
            },
          ]}
        />
      )}

      {features?.indicators?.resources.length > 0 && (
        <FeaturesGroup
          title="Painel"
          icon="menuDashboard"
          name="indicators"
          {...defaultProps}
          activeMatches={['dashboard', 'painel']}
          groups={[
            {
              features: [
                ...generateFeatureData(
                  features?.indicators.resources.filter(
                    (feature) =>
                      !(isShowingNewHome && feature.resource === 'dashboard')
                  ),
                  'indicators'
                ),
              ],
            },
          ]}
        />
      )}

      {features?.foco_exam?.resources.length > 0 && (
        <FeatureLink
          iconName="focoExamEditions"
          to={`/gerenciar-avaliacoes/${profile.kind}/${profile.personable.id}`}
          text="Avaliações"
          isActive={location.pathname.includes('/gerenciar-avaliacoes')}
          onMouseOver={() => setActiveMenu('')}
        />
      )}

      {features?.curriculum?.resources.length > 0 && (
        <FeaturesGroup
          title="Currículo"
          icon="menuCurriculum"
          name="curriculum"
          {...defaultProps}
          activeMatches={['curriculo']}
          groups={[
            {
              features: [
                ...generateFeatureData(
                  features.curriculum.resources,
                  'curriculum'
                ),
              ],
            },
          ]}
        />
      )}

      {features?.management?.resources.length > 0 && (
        <FeaturesGroup
          title="Cadastrar"
          icon="menuManagement"
          name="management"
          {...defaultProps}
          activeMatches={['gerenciar-turmas', 'gerenciar-calendario']}
          groups={[
            {
              features: [
                ...generateFeatureData(
                  features.management.resources,
                  'management'
                ),
              ],
            },
          ]}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps, {})(TeacherFeaturesGroup)
