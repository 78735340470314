// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

const getEndpoint = (schoolId: string) =>
  `api/v3/schools/${schoolId}/curriculum_periods`

type CurriculumPeriod = {
  id: number,
  name: string,
}

type CurriculumPeriodsResponse = {
  curriculum_periods: Array<CurriculumPeriod>,
}

type Params = {
  schoolId: number,
}

export const useCurriculumPeriodsBySchool = ({ schoolId }: Params) => {
  const { error, data, loading } = useResource({
    resource: getCurriculumPeriodsBySchool,
    params: { schoolId },
    key: 'useCurriculumPeriodsBySchool',
  })

  return { error, data, loading }
}

const getCurriculumPeriodsBySchool = ({
  schoolId,
}: Params): APIResponse<CurriculumPeriodsResponse> =>
  fetchWithRoot('get')(getEndpoint(String(schoolId)), {
    params: {
      include: ['curriculum_periods'],
      fields: ['id', 'name'],
    },
  })
