// @flow
import { Route, Switch, Redirect } from 'react-router-dom'
import React, { lazy, Suspense } from 'react'
import ManageExamsRoutes from './ManageExams'
import ManageClassroomsRoutes from './ManageClassrooms'
import Selection from '~pages/Selection'
import FocoExamTeacher from '~pages/FocoExams/Teacher/Exam'
import TeacherHome from '~pages/Home/Teacher'
import ManageStudents from '~pages/ManageStudents'
import Curriculum from '~pages/Curriculum'
import CurriculumList from '~pages/CurriculumList'
import CurriculumCalendar from '~pages/CurriculumCalendar'
import CurriculumManagement from '~pages/CurriculumManagement'
import LessonsPlan from '~pages/LessonsPlan'
import JourneysListPage from '~pages/Journeys'
import JourneysFormPage from '~pages/Journeys/CreateJourneys'
import AcademicYears from '~pages/AcademicYears'
import DistrictAcademicYear from '~pages/AcademicYears/District'
import CreateSchoolAcademicYear from '~pages/AcademicYears/School/Create'
import UpdateSchoolAcademicYear from '~pages/AcademicYears/School/Update'
const TCT = lazy(() => import('~pages/TCT'))
const User = lazy(() => import('~pages/User'))
const SkillMap = lazy(() => import('~pages/HabilitiesMap'))
const Dashboard = lazy(() => import('~pages/Dashboard'))
const ENEMSkillMap = lazy(() => import('~pages/ENEM/SkillMap'))
const ENEMQuestions = lazy(() => import('~pages/ENEM/QuestionAnalysis'))

const ManagementRoutes = () => {
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/pagina-inicial" />
        </Route>
        <Route
          exact
          path={['/pagina-inicial', '/home']}
          component={TeacherHome}
        />

        <Route
          path="/gerenciar-alunos/:entityType/:entityId"
          component={ManageStudents}
        />

        <Route
          path="/curriculo/:entityType/:entityId"
          component={Curriculum}
          exact
        />
        <Route
          path="/curriculo/:entityType/:entityId/plano-de-aulas/:baseCurriculumId"
          component={LessonsPlan}
          exact
        />

        <Route
          path="/curriculo/:entityType/:entityId/listar"
          component={CurriculumList}
          exact
        />

        <Route
          path="/curriculo/:entityType/:entityId/calendario/:day?/:month?/:year?"
          component={CurriculumCalendar}
        />

        <Route
          path="/curriculo/:entityType/:entityId/gestao"
          component={CurriculumManagement}
        />

        <Route
          exact
          path="/gerenciar-calendario/school/:entityId/turnos"
          component={JourneysListPage}
        />

        <Route
          exact
          path="/gerenciar-calendario/school/:entityId/turnos/novo"
          component={JourneysFormPage}
        />

        <Route
          exact
          path="/gerenciar-calendario/school/:entityId/turnos/editar/:id"
          component={JourneysFormPage}
        />

        <Route
          path={[
            '/resultados/:feature/:resource',
            '/habilities-map/:feature/:resource',
          ]}
          component={SkillMap}
        />

        <Route path={['/painel', '/dashboard']} component={Dashboard} />

        <Route
          path={'/diagnostica/:examId/:feature/:entityType/:entityId'}
          component={TCT}
        />

        <Route
          path={['/:feature/mapa/', '/enem/skill-map']}
          component={ENEMSkillMap}
        />

        <Route
          path={[
            '/enem/analise-questoes/:questionID',
            '/enem/question-analysis/:questionID',
            '/enem/analise-questoes/',
            '/enem/question-analysis',
          ]}
          component={ENEMQuestions}
        />

        <Route
          path={[
            '/selecao/:feature/:resource/:examId',
            '/selecao/:feature/:resource',
            '/selection/:feature/:resource/:examId',
            '/selection/:feature/:resource',
          ]}
          component={Selection}
        />

        <Route path={['/perfil', '/user']} component={User} />

        <Route
          exact
          path="/avaliacoes-foco/:entityType/:entityId"
          component={FocoExamTeacher}
        />

        <Route
          exact
          path="/gerenciar-calendario/:entityType/:entityId/anos-letivos"
          component={AcademicYears}
        />

        <Route
          exact
          path={[
            '/gerenciar-calendario/district/:entityId/anos-letivos/novo',
            '/gerenciar-calendario/district/:entityId/anos-letivos/editar/:academicYearId',
          ]}
          component={DistrictAcademicYear}
        />

        <Route
          exact
          path={'/gerenciar-calendario/school/:entityId/anos-letivos/novo'}
          component={CreateSchoolAcademicYear}
        />

        <Route
          exact
          path={
            '/gerenciar-calendario/school/:entityId/anos-letivos/editar/:academicYearId'
          }
          component={UpdateSchoolAcademicYear}
        />

        <Route path="/gerenciar-avaliacoes">
          <ManageExamsRoutes />
        </Route>

        <Route path="/gerenciar-turmas">
          <ManageClassroomsRoutes />
        </Route>

        <Route exact path="*">
          <Redirect to="/pagina-inicial" />
        </Route>
      </Switch>
    </Suspense>
  )
}
export default ManagementRoutes
