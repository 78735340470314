//@flow
import React from 'react'
import dayjs from 'dayjs'
import { Grid, Stack, Divider, Typography } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { DesktopDatePickerField } from '~primitives/Input/DesktopDatePickerField'

type Props = { index: number }

export const PeriodFields = ({ index }: Props) => {
  const { values } = useFormikContext()

  const curriculumPeriod = values.curriculumPeriods[index]

  const totalDays =
    curriculumPeriod.totalDays ??
    businessDiff(curriculumPeriod.startDate, curriculumPeriod.endDate)

  const minDate = dayjs().year(values.year).startOf('year')
  const maxDate = dayjs().year(values.year).endOf('year')

  return (
    <Grid
      component="fieldset"
      title={values.curriculumPeriods[index].name}
      container
      spacing={1}
      py={2}
      alignItems="center"
    >
      <Grid item xs={12} md={2} lg={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography component="h4" variant="body1">
            <strong>{values.curriculumPeriods[index].name}</strong>
          </Typography>

          <Divider flexItem orientation="vertical" />
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent="space-between"
          spacing={{ xs: 2, lg: 6 }}
          pl={6}
        >
          <Field
            component={DesktopDatePickerField}
            variant="inline"
            name={`curriculumPeriods.${index}.startDate`}
            label="Início"
            minDate={minDate}
            maxDate={values.curriculumPeriods[index].endDate}
            required
            placeholder="Selecione"
          />

          <Field
            component={DesktopDatePickerField}
            variant="inline"
            name={`curriculumPeriods.${index}.endDate`}
            label="Fim"
            minDate={values.curriculumPeriods[index].startDate}
            maxDate={maxDate}
            required
            placeholder="Selecione"
          />

          <Divider flexItem orientation="vertical" />
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          pl={4}
        >
          <Typography variant="body2">
            <strong>Dias letivos: {totalDays}</strong>
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

const isBusinessDay = function (date) {
  const workingWeekdays = [1, 2, 3, 4, 5]

  if (workingWeekdays.includes(date.day())) return true

  return false
}

const businessDiff = function (startDate, endDate) {
  const day1 = endDate.add(1, 'days')
  const day2 = startDate.clone()

  const isPositiveDiff = day1 >= day2
  let start = isPositiveDiff ? day2 : day1
  const end = isPositiveDiff ? day1 : day2

  let daysBetween = 0

  if (start.isSame(end)) return daysBetween

  while (start < end) {
    if (isBusinessDay(start)) daysBetween += 1
    start = start.add(1, 'd')
  }

  return isPositiveDiff ? daysBetween : -daysBetween
}
