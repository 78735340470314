// @flow
import { Button, Stack } from '@mui/material'
import React from 'react'

type Props = {
  amountOfQuestions: number,
  currentQuestionIndex: number,
  handleCloseForm: () => void,
  handleToNextQuestion: () => void,
  handleToPreviousQuestion: () => void,
  handleOpenSubmitModal: () => void,
}

export const ControlButtons = ({
  amountOfQuestions,
  currentQuestionIndex,
  handleCloseForm,
  handleToNextQuestion,
  handleToPreviousQuestion,
  handleOpenSubmitModal,
}: Props) => {
  const isTheFirstQuestion = currentQuestionIndex == 0
  const isTheLastQuestion = currentQuestionIndex + 1 == amountOfQuestions

  return (
    <Stack justifyContent="space-between" direction="row" width={1} mt={6}>
      {isTheFirstQuestion && (
        <Button variant="primary" onClick={handleCloseForm}>
          {'<'} Anterior
        </Button>
      )}
      {!isTheFirstQuestion && (
        <Button variant="primary" onClick={handleToPreviousQuestion}>
          {'<'} Anterior
        </Button>
      )}
      {!isTheLastQuestion && (
        <Button variant="primary" onClick={handleToNextQuestion}>
          Próxima {'>'}
        </Button>
      )}
      {isTheLastQuestion && (
        <Button variant="primary" onClick={handleOpenSubmitModal}>
          Finalizar avaliação
        </Button>
      )}
    </Stack>
  )
}
