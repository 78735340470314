// @flow
import React from 'react'
import ShareButtonContent from './ShareButtonContent'
import {
  useInsightContent,
  useNotificationContent,
  useInsightSubjectContent,
  useInsightProficiencyContent,
} from '~hooks/useShareContent'
import { track } from '~resources/ahoy'
import type { LowestSkillProbability } from '~data/skillmap/mapInsights'

type Props = {
  entityName?: string,
  subject?: string,
  yearProficiency?: number,
  proficiency?: string,
  statistic?: string,
  currentYearProficiency?: number,
  points?: string,
  rate?: string,
  skill?: LowestSkillProbability,
  content: string,
  notification?: any,
  internalLink?: string,
  UTM?: { source: string, medium: string, campaign: string },
}

const ShareButton = ({
  entityName,
  skill,
  subject,
  content,
  statistic,
  points,
  rate,
  notification,
  internalLink,
  proficiency,
  yearProficiency,
  currentYearProficiency,
  UTM,
}: Props) => {
  const contents = {
    insightProficiency: useInsightProficiencyContent({
      UTM,
      yearProficiency,
      entityName,
      statistic,
      points,
      proficiency,
      currentYearProficiency,
    }),
    insightSubject: useInsightSubjectContent({
      entityName,
      UTM,
      subject,
      rate,
    }),
    insight: useInsightContent({ entityName, skill, UTM }),
    notification: useNotificationContent({ notification, internalLink, UTM }),
  }

  const handleClickAhoy = () =>
    track(`Share Button / ${content} Share Button Click`)

  if (!contents) return null

  return (
    <ShareButtonContent
      onClick={handleClickAhoy}
      content={contents[content]}
      customization={content}
    />
  )
}

export default ShareButton
