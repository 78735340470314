// @flow
import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

const Analytics = () => {
  const googleAnalyticsCode = useGoogleAnalyticsCode()

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsCode}`}
      />
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${googleAnalyticsCode}');`}
      </script>
    </Helmet>
  )
}

const landingRoutes = ['', 'a-foco-escola', 'quem-somos']

export const useGoogleAnalyticsCode = () => {
  const basePath = useLocation().pathname.split('/')[1]

  if (landingRoutes.includes(basePath)) return ENV_VARS.LANDING_GA_CODE

  return ENV_VARS.GA_CODE
}

export default Analytics
