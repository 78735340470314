// @flow
import { fetchWithoutRoot } from '~helpers/fetch'

const endpoints = {
  put: (id) => `/api/v3/student_profiles/${id || ''}`,
  post: '/api/v3/student_profiles/',
  delete: (ids) => `/api/v3/student_profiles/${String(ids.map((id) => id))}`,
}

export type StudentModalProps = {
  schoolId?: string,
  classroomId?: number,
  email?: string,
  grade?: number,
  id?: number,
  isActive?: boolean,
  name?: string,
  selectedRows?: string,
  registration_number?: string,
}

export const patchStudentProfile = async (data: StudentModalProps) => {
  const response = await fetchWithoutRoot('put')(endpoints.put(data?.id), {
    params: {
      entity_type: 'school',
      entity_id: data?.schoolId,
      student_profile: {
        registration_number: data?.registration_number,
        classroom_id: data?.classroomId,
        active: data?.isActive,
        user: {
          name: data?.name,
          email: data?.email,
        },
      },
    },
  })

  if (response[0]?.message) {
    throw new Error(response[0]?.message)
  }
}

export const postStudentProfile = async (data: StudentModalProps) => {
  const response = await fetchWithoutRoot('post')(endpoints.post, {
    params: {
      entity_type: 'school',
      entity_id: data?.schoolId,
      student_profile: {
        registration_number: data?.registration_number,
        classroom_id: data?.classroomId,
        active: data?.isActive,
        user: {
          name: data?.name,
          email: data?.email,
        },
      },
    },
  })

  if (response[0]?.message) {
    throw new Error(response[0]?.message)
  }
}
