//@flow
const breakpoints = {
  values: {
    xs: 320,
    sm: 800,
    md: 980,
    lg: 1260,
    xl: 1600,
  },
}

export default breakpoints
