// @flow
import { requestParamsConfig } from './params'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpoint = (id?: string) =>
  `/api/v3/foco_exam_editions/${id ?? ''}`

export type Params = {
  id?: string,
  entityType?: string,
  entityId?: string,
  requestTemplate: 'exam-details' | 'exam-results',
}

type Grade = {
  id: string,
  name: string,
}

type Subject = {
  id: string,
  name: string,
}

type Fields = {
  exam_start: string,
  exam_end: string,
  publish_date: string,
  time_limit: number,
  name: string,
  grades: Grade[],
  subjects: Subject[],
  grade_subjects: { grade: Grade, subject: Subject }[],
  instruction: string,
  active: boolean,
  description: string,
}

export const useFocoExamEdition = (params: Params) => {
  const { data, loading, error } = useResource({
    key: 'useFocoExamEdition',
    resource: getFocoExamEdition,
    params,
    enabled: !!params.id && !!params.entityId && !!params.entityType,
  })
  return { data, loading, error }
}

const getFocoExamEdition = ({
  requestTemplate,
  ...params
}: Params): APIResponse<Fields> =>
  fetchWithRoot('get')(endpoint(params.id), {
    params: {
      ...requestParamsConfig[requestTemplate](params),
    },
  })
