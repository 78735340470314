// @flow
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, Stack, Typography, useTheme } from '@mui/material'
import { options, convertTimeToHour } from '../../helpers'
import { getStyles } from './styles'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

type Props = {
  id: string,
  name: string,
  subject: string,
  answered: boolean,
  available: boolean,
  started: boolean,
  expiresSoon: boolean,
  unperformed: boolean,
  answeredCount: number,
  questionsCount: number,
  estimatedTime: number,
}

export const ExamCard = ({
  id,
  name,
  subject,
  answered,
  available,
  started,
  expiresSoon,
  unperformed,
  answeredCount,
  questionsCount,
  estimatedTime = 0,
}: Props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  return (
    <Stack
      component="article"
      data-testid={`exam-card-${id}`}
      justifyContent="space-between"
      sx={{
        height: 1,
        p: 1,
        border: 1,
        borderColor: 'grey.300',
        borderRadius: 2,
      }}
    >
      <Box>
        <Stack direction="row" flexWrap="wrap" gap={1.5}>
          {available && !answered && (
            <Typography
              sx={{ ...styles.label, bgcolor: options.available.color }}
            >
              {options.available.title}
            </Typography>
          )}
          {started && (
            <Typography
              sx={{ ...styles.label, bgcolor: options.started.color }}
            >
              {options.started.title}
            </Typography>
          )}
          {answered && (
            <Typography
              sx={{ ...styles.label, bgcolor: options.answered.color }}
            >
              {options.answered.title}
            </Typography>
          )}
          {expiresSoon && (
            <Typography
              sx={{ ...styles.label, bgcolor: options.expiresSoon.color }}
            >
              {options.expiresSoon.title}
            </Typography>
          )}
          {unperformed && (
            <Typography
              sx={{ ...styles.label, bgcolor: options.unperformed.color }}
            >
              {options.unperformed.title}
            </Typography>
          )}
        </Stack>
        <Typography sx={{ fontSize: 16, fontWeight: 700, mt: 2, mb: 1.5 }}>
          {name} - {subject}
        </Typography>
      </Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          {available && !answered && (
            <Stack direction="row" gap={1} alignItems="center">
              <SVGIcon
                name="clock"
                color={
                  started
                    ? theme.palette.grey[500]
                    : theme.palette.primary.light
                }
              />
              <Typography
                variant="body2"
                color={started ? 'grey.500' : 'grey.800'}
              >
                {convertTimeToHour(estimatedTime)}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" gap={1} alignItems="center">
            <SVGIcon
              name="gridDots"
              color={
                available && !answered
                  ? theme.palette.secondary.lighter
                  : theme.palette.grey[500]
              }
            />
            <Typography
              variant="body2"
              color={available && !answered ? 'grey.800' : 'grey.500'}
            >
              {answeredCount}/{questionsCount}
            </Typography>
          </Stack>
        </Box>
        {!answered && available && (
          <Link
            component={RouterLink}
            to={`/responder-caderno/${id}`}
            variant="outlined"
            sx={{ ...styles.link }}
          >
            {started ? 'Continuar' : 'Iniciar'}
          </Link>
        )}
      </Stack>
    </Stack>
  )
}
