import { styled } from '@mui/material/styles'
import { Dialog } from '@reach/dialog'
import ModalClose from '~assets/svg/modal_close_hover.svg'

export const Wrapper = styled(Dialog)`
  position: absolute;
  bottom: 0;
  margin: 0 auto !important;
  display: grid;
  grid-template-rows: ${({ withHeader }) =>
    withHeader ? 'auto 1fr auto' : '1fr'};
  padding: 0 !important;
  border-radius: 20px 20px 0 0;
  max-height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  width: 100%;
  max-width: ${({ width }) => width} !important;
  overflow-y: auto;

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
    margin: 1vh auto !important;
    border-radius: 10px;
    overflow-x: hidden;
  }
`

export const Header = styled('header')`
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 15px 0px;
  margin: 0px 22px;
  min-height: 60px;

  strong {
    font-weight: bold;
    font-size: 16px;
    color: #334449;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    border-radius: 50px;

    > i {
      font-weight: 800;
    }
  }
`

export const Content = styled('section')`
  padding: ${({ padding }) => padding};
  overflow: ${({ overflow }) => (overflow ? 'visible' : 'auto')};
`

export const Footer = styled('footer')`
  padding: ${({ footerPadding }) => footerPadding};

  hr {
    margin-bottom: 20px;
    margin-top: 0;
  }

  div {
    display: flex;
    justify-content: flex-end;

    button:not(:first-child) {
      margin-left: 10px;
    }
  }
`

export const CloseButton = styled('button')`
  border: 0;
  background-color: transparent;
  height: 30px;
  width: 30px;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 18px;
  cursor: pointer;
  z-index: 9999;

  &:hover {
    background-image: url(${ModalClose});
    background-size: cover;

    i {
      background-color: ${({ theme }) => theme.palette.foco.textNegative};
    }
  }
`
