import { styled } from '@mui/material/styles'

export const ToggleWrapper = styled('div')`
  background-color: #2b3c40;
  color: #ffffff;
  padding: 25px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -24px;
`

export const Container = styled('div')`
  max-width: 960px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;

  a {
    background: transparent;
    padding: 0.6rem 2.5rem;
    border: solid 1px #ffffff;
  }
  a:hover {
    color: #212b36;
    background: #ffffff;
  }
`

export const LinkStyled = styled('button')`
  border: none;
  background: none;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.palette.secondary.darker};
  text-decoration: underline;
  cursor: pointer;
  margin: 10px auto;
`
