// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const academic_years_endpoint = (districtId: string) =>
  `api/v3/districts/${districtId}/academic_years`
export const grades_endpoint = 'api/v3/grades'
export const subjects_endpoint = 'api/v3/subjects'
export const curriculum_periods_endpoint = (districtId: string) =>
  `api/v3/districts/${districtId}/curriculum_periods`
export const create_curriculum_endpoint = (districtId: string) =>
  `api/v3/districts/${districtId}/base_curriculums`
export const edit_curriculum_endpoint = ({
  entityId,
  curriculumId,
}: {
  entityId: string,
  curriculumId: number,
}) => `/api/v3/districts/${entityId}/base_curriculums/${curriculumId}`

export const useAcademicYearsOptions = (districtId: string) => {
  const { error, loading, data } = useResource({
    key: 'getAcademicYear',
    params: districtId,
    resource: getAcademicYearsOptions,
  })

  return { error, loading, data: data?.academic_years }
}

export const getAcademicYearsOptions = (districtId: string) =>
  fetchWithRoot('get')(academic_years_endpoint(districtId), {
    bypassLoader: true,
    params: {
      include: ['academic_years'],
      fields: [{ academic_years: ['id', 'year'] }],
      q: { active: true },
    },
  })

export const useGrades = () => {
  const { error, loading, data } = useResource({
    key: 'getGrades',
    resource: getGrades,
  })

  return { error, loading, data: data?.grades }
}

export const getGrades = () =>
  fetchWithRoot('get')(grades_endpoint, {
    bypassLoader: true,
    params: {
      include: ['grades'],
      fields: [{ grades: ['id', 'name'] }],
    },
  })

export const useSubjects = () => {
  const { error, loading, data } = useResource({
    key: 'getSubjects',
    resource: getSubjects,
  })

  return { error, loading, data: data?.subjects }
}

export const getSubjects = () =>
  fetchWithRoot('get')(subjects_endpoint, {
    bypassLoader: true,
    params: {
      include: ['subjects'],
      fields: [{ subjects: ['id', 'name'] }],
    },
  })

export const getCurriculumPeriods = ({
  districtId,
  academicYearId,
}: {
  districtId: string,
  academicYearId: string | null,
}) =>
  fetchWithRoot('get')(curriculum_periods_endpoint(districtId), {
    bypassLoader: true,
    params: {
      include: ['curriculum_periods'],
      fields: [{ curriculum_periods: ['id', 'name'] }],
      q: { academic_year_id_eq: academicYearId },
    },
  })

export const useCurriculumPeriods = (
  districtId: string,
  academicYearId: string | null
) => {
  const { error, loading, data } = useResource({
    key: 'getCurriculumPeriods',
    params: { districtId, academicYearId },
    resource: getCurriculumPeriods,
    enabled: !!academicYearId,
  })

  return { error, loading, data: data?.curriculum_periods }
}

type Params = {
  data: {
    grade_id: string | number,
    subject_id: string | number,
    curriculum_period_id: string | number,
    events_per_week: string | number,
  },
  entityId: string,
}

type EditParams = {
  ...Params,
  curriculumId: number,
}

export const createCurriculum = ({ data, entityId }: Params) => {
  return fetchWithRoot('post')(create_curriculum_endpoint(entityId), {
    data: {
      base_curriculum: data,
    },
  })
}

export const editCurriculum = ({
  data,
  entityId,
  curriculumId,
}: EditParams) => {
  if (!curriculumId) return false
  return fetchWithRoot('put')(
    edit_curriculum_endpoint({ entityId, curriculumId }),
    {
      data: {
        base_curriculum: data,
      },
    }
  )
}

export const useCurriculumOptions = (
  entityId: string,
  academicYearId: string | null
) => {
  const grades = useGrades()
  const subjects = useSubjects()
  const curriculumPeriods = useCurriculumPeriods(entityId, academicYearId)

  const loading =
    grades.loading || subjects.loading || curriculumPeriods.loading

  const error = grades.error || subjects.error || curriculumPeriods.error

  const data = {
    grades: grades.data || [],
    subjects: subjects.data || [],
    curriculumPeriods: curriculumPeriods.data || [],
  }

  return { loading, error, data }
}
