export default {
  applicationName: 'Módulo de Devolutivas Pedagógicas',
  homeBanner:
    'https://s3-sa-east-1.amazonaws.com/focobr-assets/espiritosanto/banner-home-2.0.jpg',
  logoSymbol: '',
  logoText: '',
  fullLogo: '',
  inline_logo: '',
  faviconFolder:
    'https://s3-sa-east-1.amazonaws.com/focobr-assets/development/favicon',
  loginVideo: 'eKJQnRhhvX4',

  clientName: 'Tuneduc',
  clientLogo: '',
  departmentName: 'Secretaria do Estado',
  supportUrl: 'https://focobrasil.zendesk.com/hc/pt-br',
  supportEmail: 'ajuda@focobrasil.com.br',

  map_style: 'mapbox://styles/tuneduc/ckf2m3k956o6o18p76oiv5fvn',
  coordinates: { latitude: -19.6786152, longitude: -40.6212832 },
  map_zoom: 6,

  district: 'Rede',
  sector: 'Regional',
  school: 'Escola',

  indicatorName: 'IDEB',

  classGroupNames: [
    'Geral',
    'Muito crítico',
    'Crítico',
    'Intermediário',
    'Adequado',
  ],

  main_exam_result: { name: 'PAEBES', id: 32 },

  featured_tri_exam: {
    id: 32,
    label: 'PAEBES',
    resource: 'paebes',
    exam_type: 'tri',
  },

  featured_tct_exam: {
    id: 33,
    label: 'diagnostica',
    resource: 'diagnostica',
    exam_type: 'tct',
  },

  estadual: {
    featureName: 'Mapa de Habilidades',
    skill: 'habilidade',
    skills: 'habilidades',
    xAxis: {
      name: 'Grau de Domínio',
      description:
        'Consiste na probabilidade de um aluno, do conjunto de alunos selecionados, acertar um item/questão aleatório associado a este descritor no PAEBES',
      supportLink:
        'support/solutions/articles/23000014034-o-que-%C3%A9-o-eixo-grau-de-dom%C3%ADnio-',
      low: 'Baixo',
      medium: 'Médio',
      high: 'Alto',
      of: 'do',
    },
    yAxis: {
      name: 'Complexidade Pedagógica',
      description:
        'Representa a complexidade de uma determinada habilidade, conforme categorização pedagógica.',
      supportLink:
        'support/solutions/articles/23000014035-o-que-%C3%A9-o-eixo-complexidade-pedag%C3%B3gica-',
      low: 'Baixa',
      medium: 'Média',
      high: 'Alta',
      of: 'da',
    },
    referenceLink:
      'https://s3-sa-east-1.amazonaws.com/focobr-assets/espiritosanto/matriz-de-referencia.pdf',
  },

  map: {
    featureName: 'Mapa de Habilidades',
    skill: 'habilidade',
    skills: 'habilidades',
    xAxis: {
      name: 'Grau de Domínio',
      description:
        'Consiste na probabilidade de um aluno, do conjunto de alunos selecionados, acertar um item/questão aleatório associado a este descritor no PAEBES',
      supportLink:
        'support/solutions/articles/23000014034-o-que-%C3%A9-o-eixo-grau-de-dom%C3%ADnio-',
      low: 'Baixo',
      medium: 'Médio',
      high: 'Alto',
      of: 'do',
    },
    yAxis: {
      name: 'Peso Histórico',
      description:
        'Representa o impacto na nota do aluno de acertar ou errar questões da habilidade, com base no histórico das provas.',
      supportLink:
        'support/solutions/articles/23000014035-o-que-%C3%A9-o-eixo-complexidade-pedag%C3%B3gica-',
      low: 'Alto',
      medium: 'Médio',
      high: 'Baixo',
      of: 'do',
    },
    referenceLink:
      'support/solutions/articles/23000016866-o-que-é-o-eixo-peso-histórico-',
  },
}
