export const getStyles = (theme) => ({
  container: {
    px: 3.5,
    pt: 4,
    pb: 2.5,
    bgcolor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius * 5,
  },
  label: {
    fontWeight: 700,
  },
  input: {
    my: 1,
    '& i': {
      fontSize: 29,
    },
  },
  link: {
    cursor: 'pointer',
    fontSize: 12,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  fileContainer: {
    minHeight: 36,
    pt: 1.25,
  },
})
