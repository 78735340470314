// @flow
import React from 'react'
import { styled } from '@mui/material/styles'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import usePlotlines, { type PlotLine } from '../hooks/usePlotlines'
import { formatDecimalNumber } from '~helpers/formatting/numbers'
import { type ThemeColor } from '~styles/themes/focoEscola'
import useTheme from '~hooks/useTheme'

type ColumnLabelStyle = {|
  fontSize?: string,
  whiteSpace?: string,
|}

type Props = {|
  name?: string,
  title?: string,
  height?: number,
  width?: number,
  maximum?: number,
  percentage?: boolean,
  insideLabels?: boolean,
  alignLegend?: string,
  xLegend?: number,
  yLegend?: number,
  legendItemMarginTop?: number,
  legendItemMarginBottom?: number,
  labelColumns?: boolean | ColumnLabelStyle,
  gridLine?: boolean,
  enableTooltip?: boolean,
  noLegend?: boolean,
  noDataLabel?: boolean,
  print?: boolean,
  bgColor?: ThemeColor,
  categories?: Array<string>,
  series: Array<Object>,
  groupPadding?: number,
  pointPadding?: number,
  pointWidth?: number,
  pointSize?: string,
  noGrouping?: boolean,
  plotLines?: {
    lines: PlotLine[],
    getPosition?: (number) => number,
    format?: (number) => string,
  },
  tickInterval?: number,
  responsive?: Object,
  dataLabelDecimalPlaces?: number,
  tooltipDecimalPlaces?: number,
  inactiveSeriesOnHover?: boolean,
  seriesAnimation?: boolean,
|}

const Container = styled('div')`
  position: relative;
  width: ${({ width }) => width + 'px' || 'auto'};
  margin: 0 auto;
`

export const ColumnChart = ({
  name = 'column-chart',
  title = '',
  series,
  height,
  width,
  maximum,
  alignLegend = 'center',
  xLegend = 0,
  yLegend = 0,
  legendItemMarginTop = 0,
  legendItemMarginBottom = 0,
  insideLabels = true,
  labelColumns,
  gridLine,
  enableTooltip,
  percentage = false,
  noLegend,
  noDataLabel = false,
  bgColor = 'backgoundLight',
  categories,
  groupPadding = 0.2,
  pointPadding = 0.1,
  pointWidth,
  pointSize = '12px',
  noGrouping,
  plotLines = { lines: [] },
  tickInterval = 200,
  responsive = {},
  dataLabelDecimalPlaces = 0,
  tooltipDecimalPlaces = 2,
  inactiveSeriesOnHover = false,
  seriesAnimation = false,
}: Props) => {
  const pLines = usePlotlines(plotLines)
  const theme = useTheme()

  const formatter = (decimalPlaces) =>
    function () {
      if (percentage) {
        return formatDecimalNumber(decimalPlaces)(this.y) + '%'
      }

      return formatDecimalNumber(decimalPlaces)(this.y)
    }

  const options = {
    title: { text: title },
    chart: {
      type: 'column',
      height,
      width,
      backgroundColor: theme[bgColor],
      spacing: 20,
      style: {
        fontFamily: 'Aribau Grotesk',
      },
    },
    xAxis: {
      type: 'category',
      uniqueNames: true,
      labels: {
        autoRotation: false,
        enabled: !!labelColumns,
        align: 'center',
        style: {
          fontSize: '12px',
          color: theme.text,
          textOverflow: 'none',
          ...labelColumns,
        },
      },
      tickLength: 0,
      categories,
      plotLines: pLines.lines,
    },
    yAxis: {
      min: 0,
      max: maximum,
      tickInterval: gridLine && tickInterval,
      gridLineWidth: gridLine ? 1 : 0,
      title: { text: null },
      labels: {
        enabled: gridLine,
        format: percentage ? '{value}%' : '{value}',
      },
    },
    credits: { enabled: false },
    tooltip: {
      enabled: enableTooltip,
      borderWidth: 1,
      backgroundColor: theme.white,
      borderColor: '#D3D7DC',
      shadow: false,
      formatter: formatter(tooltipDecimalPlaces),
    },
    legend: {
      enabled: !noLegend,
      itemStyle: { fontSize: '12px', fontWeight: 400 },
      symbolRadius: 1,
      align: alignLegend,
      x: xLegend,
      y: yLegend,
      itemarginTop: legendItemMarginTop,
      itemMarginBottom: legendItemMarginBottom,
      margin: 23,
      padding: -5,
    },
    plotOptions: {
      series: {
        animation: seriesAnimation,
        pointWidth: pointWidth ? pointWidth : undefined,
        borderRadius: 2,
        dataLabels: {
          enabled: !noDataLabel,
          inside: insideLabels,
          color: insideLabels ? theme.white : theme.text,
          align: 'center',
          verticalAlign: insideLabels ? 'top' : undefined,
          formatter: formatter(dataLabelDecimalPlaces),
          style: {
            fontSize: insideLabels ? '14px' : pointSize,
            fontWeight: '400',
            textOutline: '0',
          },
        },
        pointPadding,
        groupPadding,
        grouping: !noGrouping,
        states: {
          hover: {
            enabled: !inactiveSeriesOnHover,
          },
          inactive: {
            enabled: !inactiveSeriesOnHover,
          },
        },
      },
      grouping: false,
      pointRange: 1.4,
    },
    responsive: responsive,
    series: [...series, ...pLines.series],
  }

  return (
    <Container data-testid={name} width={width}>
      <pLines.Tooltip />
      <HighchartsReact
        data-testid={name}
        highcharts={Highcharts}
        options={options}
      />
    </Container>
  )
}
