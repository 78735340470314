import { styled } from '@mui/material/styles'

export const StartButtonContainer = styled('div')`
  display: block;
  margin: 0 auto;
  padding: 0;
  max-width: 960px;
  margin-top: 30px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: right;
  }
`
export const StartButton = styled('button')`
  position: relative;
  bottom: -24px;
  width: 225px;
  height: 45px;
  border: 0;
  border-radius: 2px 2px 0 0;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  color: ${({ theme }) => theme.palette.secondary.darker};
  font-weight: 700;
  cursor: pointer;
  padding-left: 40px;
  z-index: 1;
  font-size: 12px;

  i {
    position: absolute;
    left: 20px;
    top: 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.secondary.darker};
    color: #fff;

    i {
      background-color: #fff;
    }
  }

  @media print {
    display: none;
  }
`
