// @flow
export default function Checkbox(theme: Object) {
  return {
    MuiCheckbox: {
      variants: [
        {
          props: { variant: 'form' },
          style: {
            marginRight: '8px',
            height: 16,
            width: 16,
            borderRadius: '2px',
            '&.Mui-checked': {
              backgroundColor: 'transparent',
              svg: {
                fill: theme.palette.primary.main,
              },
            },
            '&.Mui-checked svg': {
              fill: theme.palette.secondary.main,
            },
            '&:not(.Mui-checked)': {
              backgroundColor: theme.palette.grey[0],
            },
            '&:not(.Mui-checked) svg': {
              fill: 'transparent',
            },
            '& + .MuiTypography-root': {
              ...theme.typography.body2,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '& + .MuiTypography-root': {
            ...theme.typography.body2,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  }
}
