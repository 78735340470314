// @flow
import { fetchWithRoot } from '~helpers/fetch'

export const delete_lesson_plan_endpoint = ({
  baseCurriculumId,
  planId,
}: {
  baseCurriculumId: string,
  planId: number,
}) => `/api/v3/base_curriculums/${baseCurriculumId}/base_events/${planId}`

type DeleteParams = {
  baseCurriculumId: string,
  planId: number,
}

export const deleteCurriculum = ({
  baseCurriculumId,
  planId,
}: DeleteParams) => {
  if (!planId) return false
  return fetchWithRoot('delete')(
    delete_lesson_plan_endpoint({ baseCurriculumId, planId })
  )
}
