// @flow
import React, { type Node } from 'react'
import { styled } from '@mui/material/styles'
import { SVGIcon } from '../../Utilities/SVGIcon'
import SupportButton from '~components/Common/SupportButton'

type Props = {
  children?: Node,
  danger?: boolean,
  success?: boolean,
  title?: string,
  text?: string,
  style?: Object,
  showSupportButton?: boolean,
}

const getColor = ({ danger, success, theme }) => {
  if (danger) return theme.palette.foco.danger
  if (success) return theme.palette.foco.success
  return theme.palette.foco.textMedium
}

const Wrapper = styled('div')`
  position: relative;
  background-color: ${({ theme, danger }) =>
    danger
      ? theme.palette.foco.backgroundDanger
      : theme.palette.foco.backgroundLight};
  border-radius: 2px;
  padding: 12px 12px 12px 48px;
  text-align: left;
  margin-top: 8px;

  i {
    font-size: 22px;
    position: absolute;
    top: 25px;
    left: 15px;
    transform: translate(0, -50%);
  }

  h3 {
    position: relative;
    text-transform: uppercase;
    color: ${getColor};
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
  }

  p,
  > div {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
    margin-top: 5px;
    color: ${({ theme }) => theme.palette.foco.linkColor};
    &:hover {
      color: ${({ theme }) => theme.palette.foco.linkColor};
    }
  }
`

export const NotificationBox = ({
  children,
  title,
  text,
  danger,
  style,
  success,
  showSupportButton,
}: Props) => (
  <Wrapper style={style} danger={danger} success={success}>
    {danger && <SVGIcon color="danger" name="warning" />}
    {success && <SVGIcon color="success" name="warning" />}
    {title && <h3>{title}</h3>}
    {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
    {children}
    {showSupportButton && (
      <div>
        <SupportButton buttonText="Clique aqui" type="link" /> para falar com
        nossa central de suporte.
      </div>
    )}
  </Wrapper>
)
