//@flow
export default function Paper(theme: Object) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },

      variants: [
        {
          props: { variant: 'outlined' },
          style: { borderColor: theme.palette.grey[500_12] },
        },
        {
          props: { variant: 'nowrap' },
          style: {
            '& ul': {
              li: {
                whiteSpace: 'break-spaces',
              },
            },
          },
        },
      ],

      styleOverrides: {
        root: {
          backgroundImage: 'none',
          borderColor: theme.palette.grey[500_12],
          '&.MuiPopover-paper': {
            margin: '15px 0',
          },
        },
      },
    },

    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: '100000 !important',
        },
      },
    },
  }
}
