// @flow
import React from 'react'
import { Box, Stack, Typography, Paper } from '@mui/material'
import dayjs from 'dayjs'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

type Props = {
  label: string,
  date: string,
}

export const DateLabel = ({ label, date }: Props) => {
  if (!label || !date) {
    return (
      <Box>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2">
            <strong>{label}</strong>
          </Typography>{' '}
          <Paper
            sx={{
              padding: 1,
              display: 'flex',
              flexDirection: 'row',
              borderRadius: 2,
              justifyContent: 'space-around',
              minWidth: '143px',
            }}
            elevation={0}
          >
            <Stack mr={1} direction="row" alignItems="center" gap={1}>
              <SVGIcon name="date" size={2} color="textLight" />
              <Typography variant="body2">-</Typography>
            </Stack>
            <Stack gap={1} direction="row" alignItems="center">
              <SVGIcon name="clock" size={2} color="textLight" />
              <Typography variant="body2">-</Typography>
            </Stack>
          </Paper>
        </Stack>
      </Box>
    )
  }

  const dateObj = dayjs(date)

  return (
    <Box>
      {label && date && (
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2">
            <strong>{label}</strong>
          </Typography>{' '}
          <Paper
            sx={{
              padding: 1,
              display: 'flex',
              flexDirection: 'row',
              borderRadius: 2,
            }}
            elevation={0}
          >
            <Stack mr={1} direction="row" alignItems="center" gap={1}>
              <SVGIcon name="date" size={2} color="primary" />
              <Typography variant="body2">
                {dateObj.format('DD/MM')}{' '}
              </Typography>
            </Stack>
            <Stack gap={1} direction="row" alignItems="center">
              <SVGIcon name="clock" size={2} color="primary" />
              <Typography variant="body2">{dateObj.format('HH:mm')}</Typography>
            </Stack>
          </Paper>
        </Stack>
      )}
    </Box>
  )
}
