// @flow
import { styled } from '@mui/material/styles'

export const FooterWrapper = styled('footer')`
  display: block;
  min-height: 99px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  bottom: 0;
  padding: 15px 0;
  margin-top: 30px;

  @media print {
    display: none;
  }

  img {
    margin-left: 0;
    max-width: 100%;
    max-height: 50px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute;
    margin: 0;
  }
`

export const FooterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  max-width: 960px;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: flex-start;
    flex-direction: row;
    padding: 0;
    gap: 10px;
  }
`

export const FooterColumn = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
`

export const Item = styled('div')`
  display: inline-block;
`
