// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpointProfileClassrom = '/api/v3/profiles/grades'

type Subjects = {
  id: number,
  name: string,
}

type Classrooms = {
  id: number,
  name: string,
  subjects: Subjects[],
}
export type Grades = {
  id: number,
  name: string,
  classrooms_with_subjects: Classrooms[],
}

export type GradesResponse = {
  grades: Grades[],
}

type Params = { year?: number }

export const useGrades = (params: Params) => {
  const { data, loading, error } = useResource({
    key: 'useGrades',
    resource: getGrades,
    params: params,
    enabled: !!params.year,
  })
  return { data, loading, error }
}

const getGrades = ({ year }: Params): APIResponse<GradesResponse> =>
  fetchWithRoot('get')(endpointProfileClassrom, {
    params: {
      year,
      fields: [{ grades: ['id', 'name', 'classrooms_with_subjects'] }],
      include: ['grades'],
    },
  })
