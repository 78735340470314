//@flow
import React, { useState } from 'react'
import { Grid, Stack } from '@mui/material'
import { QuestionDetailsModal } from '../../../Modals/QuestionDetails'
import { TotalQuestions } from './TotalQuestions'
import { AssessmentLevel } from './AssessmentLevel'
import { QuestionsTable } from './QuestionsTable'
import { DifficultyLevel } from './DifficultyLevel'
import { InsightContainer } from '~primitives/InsightsContainer'
import {
  useFocoExamResultQuestions,
  type QuestionResult,
} from '~data/manageExams/results/questions'

type Props = { examId?: number, classroomId?: number, schoolId: number }

export type Difficulty = 'hard' | 'medium' | 'easy'
export type DifficultyFilter = Difficulty | null

export const QuestionAnalysis = ({ examId, classroomId, schoolId }: Props) => {
  const { loading, data } = useFocoExamResultQuestions({
    examId,
    classroomId,
    schoolId,
  })

  const [difficultyFilter, setDifficultyFilter] =
    useState<DifficultyFilter>(null)
  const [isQuestionDetailsModalOpen, setIsQuestionDetailsModalOpen] =
    useState(false)
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(
    null
  )

  const handleOpenQuestionDetailsModal = (questionId: number) => {
    setSelectedQuestionId(questionId)
    setIsQuestionDetailsModalOpen(true)
  }

  const questions = normalizeQuestionsData(data?.questions)

  const difficultyQuestionsCount = getDifficultyQuestionsCount(questions)
  const assessmentLevel = getAssessmentLevel(difficultyQuestionsCount)

  return (
    <React.Fragment>
      <InsightContainer title="Análise de questões">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={5}>
            <Stack gap={{ xs: 1, md: 2 }} height="100%">
              <TotalQuestions
                loading={loading}
                totalOfQuestions={questions?.length ?? '-'}
              />
              <AssessmentLevel loading={loading} level={assessmentLevel} />
              <DifficultyLevel
                difficultyFilter={difficultyFilter}
                setDifficultyFilter={setDifficultyFilter}
                hard={difficultyQuestionsCount.hard}
                medium={difficultyQuestionsCount.medium}
                easy={difficultyQuestionsCount.easy}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <QuestionsTable
              difficultyFilter={difficultyFilter}
              loading={loading}
              questions={questions}
              handleOpenQuestionDetailsModal={handleOpenQuestionDetailsModal}
            />
          </Grid>
        </Grid>
      </InsightContainer>

      {selectedQuestionId && isQuestionDetailsModalOpen && (
        <QuestionDetailsModal
          isOpen={isQuestionDetailsModalOpen}
          setIsOpen={setIsQuestionDetailsModalOpen}
          questionId={selectedQuestionId}
        />
      )}
    </React.Fragment>
  )
}

const normalizeQuestionsData = (data?: QuestionResult[]) => {
  if (!data) return null

  const questions = data.map(({ foco_question, hit_rate }) => ({
    id: foco_question.id,
    skill: foco_question.curriculum_skill,
    difficulty: hit_rate < 0.33 ? 'hard' : hit_rate < 0.66 ? 'medium' : 'easy',
    hitRate: hit_rate,
  }))

  const sortedQuestions = questions.sort((current, prev) =>
    current.hitRate > prev.hitRate ? 1 : -1
  )

  return sortedQuestions
}

type DifficultyQuestionsCount = {
  hard: number,
  medium: number,
  easy: number,
}

const getDifficultyQuestionsCount = (questions): DifficultyQuestionsCount => {
  const initialCount = { hard: 0, medium: 0, easy: 0 }

  if (!questions) return initialCount

  return questions.reduce((acc, { difficulty }) => {
    if (difficulty === 'hard') return { ...acc, hard: acc.hard + 1 }
    if (difficulty === 'medium') return { ...acc, medium: acc.medium + 1 }
    if (difficulty === 'easy') return { ...acc, easy: acc.easy + 1 }
    return { ...acc }
  }, initialCount)
}

const getAssessmentLevel = ({
  hard,
  medium,
  easy,
}: DifficultyQuestionsCount): Difficulty | null => {
  if (hard >= medium && hard >= easy) return 'hard'
  if (medium >= hard && medium >= easy) return 'medium'
  if (easy >= hard && easy >= medium) return 'easy'
  return null
}
