// @flow
import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { pxToRem } from '~helpers/getFontValue'
import { Breadcrumbs } from '~primitives/Breadcrumbs'
import { Scribble } from '~primitives/Typography/Underline'
import { getSelectedProfile } from '~selectors/profile'
import LessonsPlanContent from '~components/Curriculum/LessonsPlan'

export default function LessonPlan() {
  const profile = useSelector(getSelectedProfile)
  const { entityId, entityType } = useParams()

  const breadcrumbList = {
    district: [
      { id: 'curriculum', text: 'Currículo', pathname: '#' },
      {
        id: 'list-curriculums',
        text: 'Lista de Currículos',
        pathname: `/curriculo/district/${entityId ?? ''}/listar`,
      },
      {
        id: 'lessons-plan',
        text: 'Plano de aulas',
        pathname: '#',
      },
    ],
    school: [
      { id: 'curriculum', text: 'Currículo', pathname: '#' },
      {
        id: 'list-curriculums',
        text: 'Lista de Currículos',
        pathname: `/curriculo/school/${entityId ?? ''}/listar`,
      },
      {
        id: 'lessons-plan',
        text: 'Plano de aulas',
        pathname: '#',
      },
    ],
  }

  return (
    <Box sx={{ px: 8 }}>
      <Breadcrumbs margin="0" items={breadcrumbList[profile.kind]} />
      <Typography
        sx={{ fontSize: pxToRem(24), fontWeight: 700, my: pxToRem(8) }}
      >
        <Scribble>Plano de aulas</Scribble>
      </Typography>
      {entityType && <LessonsPlanContent entityType={entityType} />}
    </Box>
  )
}
