// @flow
import { connect } from 'react-redux'
import React from 'react'

import { getSelectedProfile, getUser } from '~selectors/profile'
import CustomizationContext from '~contexts/Customization'
import actionCreators from '~actions/Common'

const mapStateToProps = (state) => {
  const profile = getSelectedProfile(state)

  return {
    roleDescription: profile?.personable?.name,
    roleName: profile?.kind,
    selection: state.user.personables,
    user: getUser(state),
  }
}
const mapDispatchToProps = actionCreators

type HeaderParams = { hideTitle?: boolean, title?: string, subtitle?: string }

type Options = Array<{ id: number, name: string }>
type Props = {
  htmlTitle: string,
  templateTitle?: string,
  subtitle?: string,
  fetchHeader: (HeaderParams) => void,
  selection: { schools: Options, sectors: Options },
  roleName: string,
  roleDescription: string,
}

class TitleUpdater extends React.Component<Props> {
  static contextType = CustomizationContext

  componentDidMount() {
    this.updateTitle()
  }

  componentDidUpdate() {
    this.updateTitle()
  }

  shouldComponentUpdate(prevProps) {
    const { templateTitle, subtitle, htmlTitle } = this.props

    if (prevProps.templateTitle !== templateTitle) return true
    if (prevProps.subtitle !== subtitle) return true
    if (prevProps.htmlTitle !== htmlTitle) return true

    return false
  }

  updateTitle = () => {
    const { templateTitle, htmlTitle, subtitle } = this.props

    if (this.props.templateTitle === null) {
      this.props.fetchHeader({ hideTitle: true })
    } else {
      this.props.fetchHeader({ title: templateTitle, subtitle })
    }

    document.title = htmlTitle
      ? `${this.context.applicationName} | ${htmlTitle}`
      : this.context.applicationName
  }

  render() {
    return null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TitleUpdater)
