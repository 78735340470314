// @flow
export const requestTemplate = {
  'modal-suggested-material': (params: any) => ({
    include: [{ curriculum_skills: ['curriculum_materials'] }],
    fields: [
      {
        curriculum_skills: [
          'id',
          'name',
          'description',
          {
            curriculum_materials: [
              'id',
              'name',
              'filename',
              'file_path',
              'file_type',
            ],
          },
        ],
      },
    ],
    q: {
      id_eq: params?.curriculumSkillId,
    },
  }),
}

export type RequestTemplate = 'modal-suggested-material'
