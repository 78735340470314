// @flow
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { Container } from '@mui/material'
import { type AnswersByQuestions, type SelectedAnswer } from '../types'
import { StatusBar } from './StatusBar'
import { ControlButtons } from './ControlButtons'
import { QuestionBox } from './QuestionBox'
import ModalSubmit from './ModalSubmit'
import { updateFocoExamStart } from './ModalSubmit/fetch'
import { useFeedback } from '~contexts/Feedback'

type Props = {
  handleCloseForm: () => void,
  examEditionName: string,
  subjectName: string,
  gradeName: string,
  selectedAnswers: Array<SelectedAnswer>,
  answersByQuestions: AnswersByQuestions,
  amountOfQuestions: number,
  handleNewSelectOptionId: (answerId: number, optionId: number) => void,
}

export const Form = ({
  handleCloseForm,
  examEditionName,
  subjectName,
  gradeName,
  selectedAnswers,
  answersByQuestions,
  amountOfQuestions,
  handleNewSelectOptionId,
}: Props) => {
  const { id } = useParams()

  const queryClient = useQueryClient()
  const { showMessage } = useFeedback()
  const history = useHistory()

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    getFirstUnasweredQuestion(answersByQuestions)
  )

  const [isOpenModal, setIsOpenModal] = React.useState(false)

  const [isSuccess, setIsSuccess] = useState(false)

  const handleCloseModal = () => {
    setIsOpenModal(false)

    if (isSuccess) {
      history.push('/pagina-inicial')
    }
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const { mutate } = useMutation(
    () =>
      updateFocoExamStart({
        id: Number(id),
        data: { answered: true, started: true },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('useManageExamsList')
        setIsSuccess(true)
      },
      onError: (error) => {
        setIsSuccess(false)
        showMessage({ type: 'error', text: error.message })
      },
    }
  )

  const hasSomeNullOption = (): boolean => {
    return (
      selectedAnswers
        .map((item) => item.selectedOptionId !== null)
        .filter((item) => !item).length === 0
    )
  }

  const handleConfirm = () => {
    if (isSuccess) {
      handleCloseModal()
    } else {
      mutate()
    }
  }

  const handleToNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1)
  }

  const handleToPreviousQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1)
  }

  return (
    <>
      <StatusBar
        examEditionName={examEditionName}
        subjectName={subjectName}
        gradeName={gradeName}
        currentQuestionIndex={currentQuestionIndex}
        amountOfQuestions={amountOfQuestions}
        handleOpenSubmitModal={handleOpenModal}
      />

      <Container maxWidth="sm">
        <QuestionBox
          currentQuestionIndex={currentQuestionIndex}
          selectedAnswers={selectedAnswers}
          answersByQuestions={answersByQuestions}
          handleNewSelectOptionId={handleNewSelectOptionId}
        />

        <ControlButtons
          currentQuestionIndex={currentQuestionIndex}
          amountOfQuestions={amountOfQuestions}
          handleCloseForm={handleCloseForm}
          handleToNextQuestion={handleToNextQuestion}
          handleToPreviousQuestion={handleToPreviousQuestion}
          handleOpenSubmitModal={handleOpenModal}
        />
      </Container>

      <ModalSubmit
        handleConfirm={handleConfirm}
        allQuestionsAnswered={hasSomeNullOption}
        isOpen={isOpenModal}
        handleClose={handleCloseModal}
        isSuccess={isSuccess}
      />
    </>
  )
}

const getFirstUnasweredQuestion = (answersByQuestions) => {
  const indexOfNullOption = answersByQuestions.findIndex(
    ({ selectedOptionId }) => selectedOptionId === null
  )

  if (indexOfNullOption === -1) {
    return answersByQuestions.length - 1
  } else {
    return indexOfNullOption
  }
}
