// @flow
import { useMutation, useQueryClient } from 'react-query'
import { config } from '../../config'
import { fetchWithRoot } from '~helpers/fetch'

const { endpoint } = config

type Props = {
  onSuccess: (Object) => void,
  onError: (Object) => void,
}

type Params = {
  id: number,
  entityType: string,
  entityId: number,
}

export const useDeleteAcademicYear = ({ onError, onSuccess }: Props) => {
  const queryClient = useQueryClient()

  return useMutation((params) => deleteAcademicYear(params), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(config.key)
      onSuccess(data)
    },
    onError,
  })
}

export const deleteAcademicYear = async (params: Params) => {
  const [error, data] = await fetchWithRoot('delete')(
    `${endpoint(params.entityType, params.entityId)}/${params.id}`,
    {
      params: { entity_type: params.entityType, entity_id: params.entityId },
      bypassLoader: true,
    }
  )

  if (error) throw new Error(error.message)

  return data
}
