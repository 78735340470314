//@flow
export default function Badge(theme: Object) {
  return {
    MuiBadge: {
      variants: [
        {
          props: { variant: 'dot' },
          style: {
            '.MuiBadge-badge': {
              width: 15,
              height: 15,
              border: `solid 2px ${theme.palette.grey[0]}`,
              borderRadius: theme.shape.borderRadius * 4,
            },
          },
        },
        {
          props: { variant: 'index' },
          style: {
            '.MuiBadge-badge': {
              position: 'relative',
              transform: 'none',
              height: '18px',
              width: '18px',
              fontSize: 12,
              fontWeight: 700,
              color: theme.palette.grey[800],
              backgroundColor: theme.palette.secondary.lighter,
            },
          },
        },
        {
          props: { variant: 'status' },
          style: {
            '.MuiBadge-badge': {
              position: 'relative',
              transform: 'none',
              height: '18px',
              width: 'auto',
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.grey[0],
              padding: '0px 20px',
              borderRadius: '4px',
            },
          },
        },
      ],
    },
  }
}
