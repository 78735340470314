// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

const getEndpoint = (schoolId: string) =>
  `api/v3/schools/${schoolId}/academic_years`

type AcademicYear = {
  id: number,
  name: string,
  year: number,
  initial_date: string,
  end_date: string,
  status: string,
}

type AcademicYearsResponse = {
  academic_years: Array<AcademicYear>,
}

type Params = {
  schoolId?: number,
  entityType?: string,
  entityId?: string,
}

export const useAcademicYearsBySchool = ({ schoolId }: Params) => {
  const { error, data, loading } = useResource({
    resource: getAcademicYearsBySchool,
    params: { schoolId },
    key: 'useAcademicYearsBySchool',
  })

  return { error, data, loading }
}

const getAcademicYearsBySchool = ({
  schoolId,
}: Params): APIResponse<AcademicYearsResponse> =>
  fetchWithRoot('get')(getEndpoint(String(schoolId)), {
    params: {
      include: ['academic_years'],
      fields: ['id', 'name'],
    },
  })
