// @flow
import React from 'react'
import { useHistory } from 'react-router-dom'
import { StudentFormModal } from '~components/Students/FormModal'
import { postStudentProfile } from '~data/manageStudents/studentProfile'

type Props = {
  schoolId: string,
}

export const AddModal = ({ schoolId }: Props) => {
  const history = useHistory()

  const handleClose = () => {
    history.push({
      pathname: `/gerenciar-alunos/school/${schoolId}`,
    })
  }

  return (
    <StudentFormModal
      title="Adicionar aluno"
      requestOnSubmit={postStudentProfile}
      handleClose={handleClose}
      schoolId={schoolId}
      grade={{ id: 1, name: 'Série' }}
      classroom={{ id: 1, name: 'Turma' }}
    />
  )
}
