// @flow
import React, { useState } from 'react'

import { equals } from 'ramda'
import {
  FormControl,
  Box,
  InputLabel,
  Switch,
  Stack,
  Typography,
  Button,
  TextField,
} from '@mui/material'
import { Field, Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { FormikText } from '~primitives/Input/Text'
import { Form } from '~primitives/Input/Form'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { keyboardClick } from '~helpers/events'
import SuccessCheck from '~assets/svg/success_check.svg'
import { config } from '~data/api/v3/studentProfiles/config'

type Grade = { id: number, name: string }

type Classroom = { id: number, name: string }

type Props = {
  title: string,
  requestOnSubmit: any,
  closeOnSuccessSubmit?: boolean,
  id?: number,
  name?: string,
  registration_number?: string,
  email?: string,
  isActive?: boolean,
  classroom?: Classroom,
  grade?: Grade,
  schoolId: string,
  handleClose?: () => void,
}

export const StudentFormModal = ({
  title,
  requestOnSubmit,
  closeOnSuccessSubmit = false,
  id,
  name,
  registration_number,
  email,
  isActive = true,
  classroom,
  grade,
  schoolId,
  handleClose = () => {},
}: Props) => {
  const queryClient = useQueryClient()
  const { key } = config

  const initialValues = {
    name: name ?? '',
    registration_number: registration_number ?? '',
    email: email ?? '',
    isActive: isActive,
  }

  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { mutate } = useMutation(
    (requestProps) => requestOnSubmit(requestProps),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(key)
        setIsSuccess(true)
        if (closeOnSuccessSubmit) {
          handleClose()
        }
      },
      onError: (error: Error) => {
        setError(error.message)
      },
    }
  )

  const handleFormSubmit = (values, { setSubmitting }) => {
    mutate({ ...values, id, schoolId, classroomId: classroom?.id })
    setSubmitting(false)
  }

  const handleFormValidate = (values) => {
    const errors = {}
    const fields = ['name', 'registration_number', 'email']

    fields.forEach((field) => {
      if (values[field] === '' || values[field] === false) {
        if (field !== 'zip_code') {
          errors[field] = 'Requerido'
        }
      }
    })

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = ' Endereço de e-mail inválido '
    }

    return errors
  }

  return (
    <Modal
      width="520px"
      height="98vh"
      confirmButtonText="Salvar"
      overflowContent={true}
      contentPadding="5px 0"
      showFooter={false}
      footerPadding="20px 35px 30px"
      onClose={handleClose}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validate={handleFormValidate}
      >
        {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
          <Form
            onSubmit={handleSubmit}
            noValidate
            onKeyPress={keyboardClick}
            headingSize="24px"
            style={{ padding: '0 19px' }}
          >
            <header>
              <UnderlinedHeading as="h2" size={7}>
                <Typography component="strong" variant="h4">
                  {title}
                </Typography>
              </UnderlinedHeading>
            </header>

            {isSuccess ? (
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={{ bgcolor: 'grey.200', px: 4, height: 85 }}
                >
                  <img src={SuccessCheck} alt="" width="24px" />
                  <Typography variant="body2">
                    Aluno adicionado com sucesso
                  </Typography>
                </Stack>
                <Stack flexDirection="row" justifyContent="flex-end" my={2.5}>
                  <Button
                    variant="primary"
                    size="small"
                    onClick={handleClose}
                    sx={{ maxWidth: 170, width: 1 }}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Box>
            ) : (
              <>
                <Stack variant="modalBox">
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    gap={0.5}
                    mt={2}
                    sx={{ width: '100%' }}
                  >
                    <Field
                      component={FormikText}
                      id="name"
                      name="name"
                      label="Nome"
                      value={values.name}
                      size="small"
                    />

                    <Field
                      component={FormikText}
                      id="registration_number"
                      name="registration_number"
                      label="Matrícula"
                      value={values.registration_number}
                      size="small"
                    />

                    <Field
                      component={FormikText}
                      type="email"
                      id="email"
                      name="email"
                      label="E-mail"
                      value={values.email}
                      size="small"
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    gap={3}
                    sx={{ width: '100%' }}
                  >
                    <FormControl size="small" fullWidth>
                      <InputLabel>Série</InputLabel>
                      <TextField
                        variant="standard"
                        size="small"
                        value={grade?.name}
                        disabled
                      />
                    </FormControl>
                    <FormControl size="small" fullWidth>
                      <InputLabel>Turma</InputLabel>
                      <TextField
                        variant="standard"
                        size="small"
                        value={classroom?.name}
                        disabled
                      />
                    </FormControl>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    mt={1.5}
                    width={'100%'}
                  >
                    <Switch
                      onChange={handleChange}
                      variant="default"
                      name="isActive"
                      checked={values.isActive}
                      color="secondary"
                      inputProps={{ 'aria-label': 'Switch active' }}
                    />
                    <Typography variant="body2">
                      Matrícula {values.isActive ? 'ativa' : 'inativa'}
                    </Typography>
                  </Stack>
                  {error && (
                    <Typography
                      color="warning.dark"
                      variant="body2"
                      sx={{
                        mt: 1,
                      }}
                    >
                      {error}
                    </Typography>
                  )}
                </Stack>
                <Stack flexDirection="row" justifyContent="flex-end" my={2.5}>
                  <Button variant="text" size="small" onClick={handleClose}>
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    sx={{ maxWidth: 160, width: 1 }}
                    disabled={
                      isSubmitting ||
                      Object.keys(errors).length > 0 ||
                      equals(values, initialValues)
                    }
                  >
                    Enviar
                  </Button>
                </Stack>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
