//@flow
import React, { useMemo, type Node } from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from 'styled-components'
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import breakpoints from './breakpoints'
import ComponentsOverrides from './overrides'
import shadows, { customShadows } from './shadows'
import useSettings from '~hooks/useSettings'
import useIsLoggedin from '~hooks/useIsLoggedin'
import GlobalStyle from '~styles/global'
import { focoEscolaTheme } from '~styles/themes/focoEscola'

type Props = {
  children: Node,
}

export default function FocoThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettings()
  const isLight = themeMode === 'light'
  const logged = useIsLoggedin()

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography: logged ? typography('primary') : typography('secondary'),
      breakpoints,
      shape: { borderRadius: 2 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, logged, themeDirection]
  )

  const theme = createTheme(themeOptions)
  theme.components = ComponentsOverrides(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <ThemeProvider theme={focoEscolaTheme}>
          <CssBaseline />
          {children}
          <GlobalStyle />
        </ThemeProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
