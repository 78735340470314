//@flow
import React from 'react'
import { Skeleton, Stack, Typography } from '@mui/material'

type Props = {
  totalOfQuestions?: number | string,
  loading: boolean,
}

export const TotalQuestions = ({ totalOfQuestions, loading }: Props) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      height: { xs: 50, md: 80 },
      borderRadius: 2,
      bgcolor: 'grey.0',
      px: 2.5,
    }}
  >
    {loading ? (
      <React.Fragment>
        <Skeleton variant="text" width={120} height={14} />
        <Skeleton variant="text" width={38} height={30} />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Typography variant="body2" fontWeight={700}>
          Total de questões
        </Typography>
        <Typography fontWeight={700} fontSize={30}>
          {totalOfQuestions}
        </Typography>
      </React.Fragment>
    )}
  </Stack>
)
