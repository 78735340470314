// @flow
import React from 'react'
import { Stack, Divider, Box, Alert, Typography } from '@mui/material'
import { BelowBasicText } from './BelowBasicText'
import { Chart } from './Chart'
import { InsightContainer } from '~primitives/InsightsContainer'
import { usePerformanceProficiency } from '~data/manageExams/results/performanceProficiency'

type Props = { examId: ?number, classroomId: ?number }

export const PerformanceByProficiency = ({ examId, classroomId }: Props) => {
  const { loading, error, data } = usePerformanceProficiency({
    examId,
    classroomId,
  })

  const ContentPopover = () => {
    const CELLS = [
      { id: 'c-0', category: 'Abaixo do básico', value: '<= 25%' },
      { id: 'c-1', category: 'Básico', value: '> 25% e <= 50%' },
      { id: 'c-2', category: 'Proficiente', value: '> 50% e <= 75%' },
      { id: 'c-3', category: 'Avançado', value: '> 75%' },
    ]

    return (
      <Box p={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography>Categoria</Typography>
          <Typography>Nota</Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
        {CELLS.map((item) => (
          <Stack
            key={item.id}
            direction="row"
            justifyContent="space-between"
            my={0.5}
          >
            <Typography>
              <strong>{item.category}</strong>{' '}
            </Typography>
            <Typography>{item.value}</Typography>
          </Stack>
        ))}
      </Box>
    )
  }

  if (error)
    return (
      <InsightContainer
        title="Desempenho por proficiência"
        component="section"
        data-testid="exam-result-proficiencies"
      >
        <Box>
          <Alert variant="filled" severity="error">
            Ocorreu um erro ao carregar o gráfico
          </Alert>
        </Box>
      </InsightContainer>
    )

  if (loading || !data) return <div>loading</div>

  return (
    <InsightContainer
      title="Desempenho por proficiência"
      component="section"
      data-testid="exam-result-proficiencies"
      isPopover={true}
      contentPopover={<ContentPopover />}
    >
      <Box
        sx={{
          position: 'relative',
          minHeight: '220px',
          borderRadius: 2,
          py: 1,
          px: 2,
          bgcolor: 'grey.0',
        }}
      >
        <BelowBasicText proficiency={data} />

        <Divider />

        <Chart proficiency={data} />
      </Box>
    </InsightContainer>
  )
}
