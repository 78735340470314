//@flow
export default function LinearProgress(theme: Object) {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {},
        determinate: {
          height: 20,
          borderRadius: 30,
          '&.MuiLinearProgress-colorPrimary': {
            backgroundColor: theme.palette.grey[250],
          },
        },
      },
    },
  }
}
