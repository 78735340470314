// @flow
import { fetchWithRoot } from '~helpers/fetch'

export const curriculum_events_endpoint = '/api/v3/curriculum_events/generate'

type Grade = {
  period_id: string,
  classroom_id: string,
  subject_id: number,
}

type Params = {
  data: {
    entity_type: string,
    entity_id: string,
    list: Array<Grade>,
  },
}

export const generateCurriculum = ({ data }: Params) => {
  return fetchWithRoot('post')(curriculum_events_endpoint, {
    data,
  }).then(([error, response]) => {
    if (error) {
      throw new Error(error?.message ?? `Erro: ${error?.status}`)
    }
    if (response) return response
  })
}
