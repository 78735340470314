// @flow
import { config } from '../../config'
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'
import { getFieldsAndIncludes } from '~data/helpers'
import { useUser } from '~hooks/useUser'

const { endpoint, key } = config

type Extension = $Keys<typeof extensionsConfig>

type Params = {
  curriculumEventId: number,
  entityId: number,
  entityType: string,
  extensions?: Extension[],
  ...
}

export type CurriculumMaterial = {
  id: number,
  name: string,
  filename: string,
  file_path: string,
  file_type: string,
}

type CurriculumMaterials = { curriculumMaterials: CurriculumMaterial[] }

export const useCurriculumMaterials = (params: Params = {}) => {
  const user = useUser()

  const { loading, error, data } = useResource({
    key,
    resource: getCurriculumMaterials,
    params: { ...params, userId: user.id },
  })

  return { loading, error, data }
}

const extensionsConfig = {
  default: {
    include: ['curriculum_materials'],
    fields: [{ curriculum_materials: ['id', 'name'] }],
  },
}

type GetParams = { ...Params, userId: number, ... }

const getCurriculumMaterials = ({
  curriculumEventId,
  entityId,
  entityType,
  userId,
  extensions = [],
}: GetParams): APIResponse<CurriculumMaterials> => {
  return fetchWithRoot('get')(endpoint(curriculumEventId), {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      user_id: userId,
      ...getFieldsAndIncludes(extensionsConfig, extensions),
    },
    bypassLoader: true,
  })
}
