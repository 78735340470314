//@flow
import React, { useEffect } from 'react'
import { Box, Divider, Skeleton, Stack } from '@mui/material'
import dayjs from 'dayjs'
import isEqual from 'react-fast-compare'
import { type Status, type Id, type MaterialData } from '../../../validate'
import { Form } from './Form'
import usePrevious from '~hooks/usePrevious'
import type { NetworkError } from '~types/Network'

type Props = {
  id: number,
  data: any,
  error: NetworkError | null,
  status: Status,
  loading: boolean,
  plannedCompetencyId?: number,
  plannedSkillId?: number,
  objective?: string,
  activityDescription?: string,
  materialsId?: Array<number> | null,
  activityIds?: number[],
  eventIndex: number,
  handleSetValues: (id: Id, values: Object) => void,
  handleOpenSuggestedMaterialsModal: (MaterialData) => void,
  handleOpenAddMaterialsModal: (MaterialData) => void,
  isFormBuffed: boolean,
}

export const Content = ({
  id,
  loading,
  data,
  error,
  status,
  plannedCompetencyId,
  plannedSkillId,
  objective,
  activityDescription,
  materialsId,
  activityIds,
  eventIndex,
  handleSetValues,
  handleOpenSuggestedMaterialsModal,
  handleOpenAddMaterialsModal,
  isFormBuffed,
}: Props) => {
  const date = getFormattedDate(data?.date)
  const startTime = getFormattedHour(data?.start_time)
  const endTime = getFormattedHour(data?.end_time)

  const prevData = usePrevious(data)

  const plannedSkill = data?.curriculum_events_curriculum_skills.find(
    ({ status }) => status === 'planned'
  )

  useEffect(() => {
    if (!isEqual(data, prevData) && !isFormBuffed) {
      handleSetValues(id, {
        status: data?.status,
        materialsId: getMaterialsId(
          data?.curriculum_events_curriculum_materials
        ),
        initialMaterialsId: getInitialMaterialsId(
          data?.curriculum_events_curriculum_materials
        ),
        activityIds: data?.curriculum_activities.map(({ id }) => id),
        isFormBuffed: true,
      })
    }
  }, [data, handleSetValues, id, isFormBuffed, materialsId, prevData])

  return (
    <React.Fragment>
      {error && <span>Error</span>}
      {loading && <Placeholder />}
      {data && !loading && (
        <Form
          key={data.id}
          id={data.id}
          status={status}
          date={date}
          formattedStartTime={startTime}
          formattedEndTime={endTime}
          endTime={data?.end_time}
          baseCompetency={data?.base_event.curriculum_competency}
          baseSkill={data?.base_event.curriculum_skill}
          plannedCompetencyId={
            plannedCompetencyId ??
            plannedSkill?.curriculum_skill.curriculum_competency.id
          }
          plannedSkillId={plannedSkillId ?? plannedSkill?.curriculum_skill.id}
          objective={objective ?? data?.objective}
          activityDescription={
            activityDescription ?? data?.activity_description
          }
          eventIndex={eventIndex}
          materialsId={materialsId}
          handleSetValues={handleSetValues}
          handleOpenSuggestedMaterialsModal={handleOpenSuggestedMaterialsModal}
          activityIds={activityIds}
          handleOpenAddMaterialsModal={handleOpenAddMaterialsModal}
        />
      )}
    </React.Fragment>
  )
}

const Placeholder = () => (
  <React.Fragment>
    <Stack direction="row" justifyContent="space-between" gap={2} my={1.5}>
      <Skeleton variant="text" height={14} width="100%" />
      <Skeleton variant="text" height={14} width="100%" />
      <Skeleton variant="text" height={14} width="100%" />
    </Stack>

    <Divider sx={{ my: 1 }} />

    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      my={3.5}
    >
      <Skeleton variant="text" height={14} width="100%" />
      <Skeleton variant="text" height={14} width="100%" />
    </Stack>

    <Divider sx={{ my: 1 }} />

    <Box width={1}>
      <Stack sx={{ my: 1.5 }} gap={1}>
        <Skeleton variant="text" height={14} width={150} />
        <Skeleton variant="text" height={14} width={110} />
      </Stack>
      <Stack sx={{ my: 1.5 }} gap={1}>
        <Skeleton variant="text" height={14} width={150} />
        <Skeleton variant="text" height={14} width="100%" />
        <Skeleton variant="text" height={14} width="100%" />
        <Skeleton variant="text" height={14} width="50%" />
      </Stack>

      <Divider sx={{ my: 1 }} />

      <Stack
        sx={{ my: 1.5 }}
        gap={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Skeleton variant="text" height={14} width={130} />
        <Skeleton variant="circular" height={34} width={34} />
      </Stack>

      <Divider sx={{ my: 1 }} />

      <Stack sx={{ my: 2 }} gap={1}>
        <Skeleton variant="text" height={14} width={150} />
        <Skeleton variant="text" height={14} width="90%" />
        <Skeleton variant="text" height={14} width="100%" />
        <Skeleton variant="text" height={14} width="50%" />
        <Skeleton variant="text" height={14} width="80%" />
      </Stack>

      <Stack sx={{ my: 2 }} gap={1}>
        <Skeleton variant="text" height={14} width={150} />
        <Skeleton variant="text" height={14} width="90%" />
        <Skeleton variant="text" height={14} width="100%" />
      </Stack>
    </Box>
  </React.Fragment>
)

const getFormattedHour = (time?: string) => {
  const date = dayjs(time)
  if (date.minute() === 0) {
    return date.format('H[h]')
  }
  return date.format('H:mm[h]')
}

const getFormattedDate = (date?: string) => dayjs(date).format('DD/MM/YYYY')

const getMaterialsId = (materials) =>
  materials?.map(({ curriculum_material_id }) => curriculum_material_id)

const getInitialMaterialsId = (materials) =>
  materials?.map(({ id, curriculum_material_id }) => ({
    id,
    curriculum_material_id,
  }))
