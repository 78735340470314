//@flow
import React from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Stack,
} from '@mui/material'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

interface Props {
  grades?: {
    id: number,
    name: string,
    subjects: [{ id: number, name: string }],
  }[];
  gradeSubjectPairs: {
    id?: string,
    grade_id: string,
    subject_id: string,
  }[];
  setGradeSubjectPairs: any;
}

export const GroupGradeSubjectInput = ({
  grades,
  gradeSubjectPairs,
  setGradeSubjectPairs,
}: Props) => {
  const handleGradeChange = (index, event) => {
    const newPairs = [...gradeSubjectPairs]
    newPairs[index].grade_id = event.target.value
    newPairs[index].subject_id = ''
    setGradeSubjectPairs(newPairs)
  }

  const handleSubjectChange = (index, event) => {
    const newPairs = [...gradeSubjectPairs]
    newPairs[index].subject_id = event.target.value
    setGradeSubjectPairs(newPairs)
  }

  const addGradeSubjectPair = () => {
    setGradeSubjectPairs([
      ...gradeSubjectPairs,
      { grade_id: '', subject_id: '' },
    ])
  }

  const removeGradeSubjectPair = (index) => {
    const newPairs = gradeSubjectPairs.filter((_, i) => i !== index)
    setGradeSubjectPairs(newPairs)
  }

  return (
    <Stack width="100%">
      {gradeSubjectPairs?.map((pair, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <FormControl variant="standard" fullWidth>
            <InputLabel required htmlFor="select-grade" variant="standard">
              Turma
            </InputLabel>
            <Select
              id="select-grade"
              value={pair.grade_id}
              onChange={(e) => handleGradeChange(index, e)}
              label="Grade"
            >
              {grades?.map((grade) => (
                <MenuItem key={grade.id} value={grade.id}>
                  {grade.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel required htmlFor="select-subject" variant="standard">
              Disciplina
            </InputLabel>
            <Select
              id="select-subject"
              value={pair.subject_id}
              onChange={(e) => handleSubjectChange(index, e)}
              label="Subject"
              disabled={!pair.grade_id}
            >
              {pair.grade_id &&
                grades
                  ?.find((grade) => grade.id === parseInt(pair.grade_id))
                  ?.subjects?.map((subject) => (
                    <MenuItem key={subject.id} value={subject.id}>
                      {subject.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>

          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton
              type="button"
              variant="secondary"
              color="secondary"
              disabled={gradeSubjectPairs.length <= 1}
              onClick={() => removeGradeSubjectPair(index)}
              size="small"
            >
              <SVGIcon name="delete" color="white" size={6} />
            </IconButton>

            <IconButton
              variant="secondary"
              onClick={addGradeSubjectPair}
              size="small"
            >
              <SVGIcon name="add" color="white" size={6} />
            </IconButton>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}
