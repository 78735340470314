// @flow
import React, { useState } from 'react'
import { DndContext, closestCenter } from '@dnd-kit/core'
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable'
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import { SortableItem } from './Item'
import { SortableHandler } from './Handler'
import { SortableOverlay } from './Overlay'

type Props = {
  items: Array<any>,
  setItems: (Array<any>) => void,
  renderItem: (any) => React$Node,
  overlayStyle?: Object,
}

export const SortableList = ({
  items,
  setItems,
  renderItem,
  overlayStyle = {},
}: Props) => {
  const [activeId, setActiveId] = useState(null)

  const handleDragEnd = (event) => {
    const { active, over } = event

    const prevPosition = items.findIndex((item) => item === active.id)
    const newPosition = items.findIndex((item) => item === over.id)

    setItems(arrayMove(items, prevPosition, newPosition))

    setActiveId(null)
  }

  const handleDragStart = (event) => {
    setActiveId(event.active.id)
  }

  const handleDragCancel = () => {
    setActiveId(null)
  }

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item) => (
          <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
        ))}
      </SortableContext>
      <SortableOverlay styles={overlayStyle}>
        {activeId ? renderItem(activeId) : null}
      </SortableOverlay>
    </DndContext>
  )
}

SortableList.Item = SortableItem
SortableList.Handler = SortableHandler
SortableList.Overlay = SortableOverlay
