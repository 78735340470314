// @flow
import { Formik } from 'formik'
import { Link as RouterLink } from 'react-router-dom'
import React, { useState } from 'react'
import { Typography, Link, Stack, Button } from '@mui/material'
import validate from './validate'
import { Form } from '~primitives/Input/Form'
import { TextInput } from '~primitives/Input/Text'
import { NotificationBox } from '~primitives/Messages/NotificationBox'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { track } from '~resources/ahoy'
import Auth from '~resources/auth'
import useAhoy from '~hooks/useAhoy'

const initialValues = { email: '' }

const RememberPass = () => {
  const [{ data, error }, setState] = useState({ error: null, data: null })

  useAhoy('Forgot password')

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validateOnChange
      onSubmit={(formValues, { setErrors }) => {
        Auth.requestPasswordReset(formValues).then(([error, response]) => {
          if (response) {
            track('Forgot password / Email sent', formValues)
          }

          if (error) {
            track('Forgot password / Error', {
              email: formValues.email,
              error_code: error.status,
              error_message: error.message,
            })

            setErrors({ email: 'E-mail inválido' })
          }

          setState({ error, data: response })
        })
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isValid,
      }) => (
        <Form onSubmit={handleSubmit}>
          <header>
            <Typography component="h2" variant="h2">
              Bem-vindo
            </Typography>
            <Typography component="span" variant="body1">
              Digite seu e-mail para continuar
            </Typography>
          </header>

          <fieldset>
            <ul>
              <li>
                <TextInput
                  label="E-mail"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Digite seu e-mail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email}
                  helperText={errors.email}
                  variant="outlined"
                />
              </li>

              {error && (
                <li>
                  <NotificationBox
                    danger
                    title="Erro ao buscar seu usuário"
                    text={error.message}
                  />
                </li>
              )}

              {data && (
                <li>
                  <NotificationBox
                    success
                    title="Um link foi enviado para o seu email."
                    text="Acesse seu email e clique no link indicado para cadastrar uma nova senha."
                  />
                </li>
              )}
            </ul>

            <footer className="navigation-footer">
              <Stack
                direction={{
                  sm: 'row-reverse',
                  xs: 'column',
                }}
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ flex: 1 }}
              >
                <Button
                  type="submit"
                  id="submit-form"
                  variant="primary"
                  disabled={!isValid}
                  bold
                  color="secondary"
                  borderRadius="4px"
                  width="200px"
                  height="45px"
                  padding="0.8em 0"
                >
                  Enviar
                </Button>

                <Link to="/login" component={RouterLink}>
                  <SVGIcon name="previous" color="neutral" size={1} /> Voltar
                </Link>
              </Stack>
            </footer>
          </fieldset>
        </Form>
      )}
    </Formik>
  )
}

export default RememberPass
