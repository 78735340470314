//@flow
import React, { type Node, createContext, useState } from 'react'

type Props = {
  children?: Node,
}

export const WalkthroughContext = createContext<any>({
  state: { step: 1, run: false },
  setState: () => {},
})

export const WalkthroughProvider = ({ children }: Props) => {
  const [state, setState] = useState({ step: 1, run: false })

  return (
    <WalkthroughContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </WalkthroughContext.Provider>
  )
}

export const WalkthroughConsumer = WalkthroughContext.Consumer

export default WalkthroughContext
