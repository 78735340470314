import { styled } from '@mui/material/styles'

export const ErrorMessage = styled('section')`
  text-align: center;
  line-height: 1.5;

  h2 {
    font-weight: bold;
  }

  p {
    font-style: italic;
  }
`

export const Info = styled('section')`
  margin-bottom: 45px;
  h3 {
    text-align: center;
    margin-bottom: 16px;
    line-height: 24px;
  }

  p {
    font-size: 14px;
    margin: 0 auto;
    text-align: center;
    line-height: 24px;
  }

  button {
    font-size: 14px;
    padding: 0;
    background: transparent;
    border: none;
    appearance: none;
    display: inline;
    color: ${({ theme }) => theme.palette.secondary.darker};
  }

  div {
    height: 150px;
    width: 100%;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.palette.grey[200]};
    margin-bottom: 40px;

    p {
      padding-top: 35px;
    }
  }
`
