// @flow
import { pxToRem } from '~helpers/getFontValue'

export const getStyles = (
  theme: Object,
  collapseExpanded: boolean,
  isHoverExpanded: boolean,
  isGroupActive: boolean
) => ({
  container: {
    bgcolor: theme.palette.grey[collapseExpanded ? 200 : 800],
    borderRadius: theme.shape.borderRadius * 2,
    my: 1,
    transition: 'background ease-in-out .2s',
    '&:hover > .MuiButton-root i': {
      bgcolor: theme.palette.grey[550],
    },
    '&:hover > .MuiButton-root span': {
      color: theme.palette.grey[800],
      fontWeight: 700,
    },
    '&:hover > .MuiButton-root': {
      bgcolor: theme.palette.grey[200],
      borderRadius: theme.shape.borderRadius * 2,
    },
    [theme.breakpoints.up('sm')]: {
      height: 1,
      bgcolor: theme.palette.grey[800],
      '.MuiButton-root': {
        transition: 'none',
      },
      '&:hover > .MuiButton-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&:hover > .MuiButton-root:before': {
        bgcolor: theme.palette.grey[200],
      },
    },
  },
  button: {
    position: 'relative',
    justifyContent: 'flex-start',
    textAlign: 'left',
    gap: 1.5,
    p: 1.125,
    borderRadius: theme.shape.borderRadius * 2,
    bgcolor: isGroupActive
      ? theme.palette.grey[
          collapseExpanded ? 200 : theme.palette.secondary.darker
        ]
      : 'transparent',
    '& > i': {
      fontSize: 24,
    },
    [theme.breakpoints.up('sm')]: {
      bgcolor: isGroupActive ? theme.palette.secondary.darker : 'transparent',
      position: 'relative',
      height: 48,
      '&:before': {
        content: '""',
        position: 'absolute',
        right: -8,
        top: 0,
        height: 48,
        width: 20,
        bgcolor: 'transparent',
        zIndex: 1,
      },
      '& > span': {
        fontWeight: isGroupActive ? 700 : 400,
        maxWidth: 84,
      },
    },
  },
  title: {
    color: theme.palette.grey[collapseExpanded ? 800 : 200],
    fontWeight: collapseExpanded ? 700 : 400,
    fontSize: 13,
    textTransform: 'none',
    '&:hover': {
      color: collapseExpanded
        ? theme.palette.grey[800]
        : theme.palette.secondary.light,
    },
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.grey[200],
    },
  },
  divider: {
    borderColor: theme.palette.secondary.lighter,
    borderWidth: 1,
    ml: 5.5,
    maxWidth: 200,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
      ml: 0,
      mt: 0.625,
    },
  },
  collapse: {
    container: {
      [theme.breakpoints.up('sm')]: {
        display: isHoverExpanded ? '' : 'none',
        position: 'fixed',
        top: 0,
        left: 190,
        width: 190,
        minHeight: '100% !important',
        bgcolor: theme.palette.grey[200],
        pr: 2.9,
        pl: 2,
      },
    },
    list: {
      ml: 5.5,
      mr: 1.75,
      opacity: collapseExpanded ? 1 : 0,
      transition: 'opacity ease-in-out .2s',
      [theme.breakpoints.up('sm')]: {
        opacity: 1,
        ml: 0,
        mr: 0,
      },
    },
    subheader: {
      ml: 0,
      px: 0,
      fontSize: 12,
      height: 30,
      mb: 2,
      bgcolor: 'transparent',
      color: theme.palette.grey[550],
      [theme.breakpoints.up('sm')]: {
        ml: 1,
        bgcolor: theme.palette.grey[200],
      },
    },
    item: {
      fontSize: 13,
      px: 0,
      pl: 1.5,
      minHeight: 37,
      borderRadius: theme.shape.borderRadius * 1,
      '&:hover': {
        bgcolor: theme.palette.grey[250],
      },
      '.MuiListItem-root.active': {
        bgcolor: theme.palette.grey[500],
      },
      '&.Mui-selected a': {
        fontWeight: 700,
      },
      '& a, &:hover a': {
        color: theme.palette.grey[800],
      },
      '& a': {
        width: 1,
        lineHeight: pxToRem(17),
      },
      [theme.breakpoints.up('sm')]: {
        pl: 0,
        mt: 0.125,
        '& a': {
          pl: 1.2,
        },
      },
    },
  },
})
