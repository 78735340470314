// @flow
import { config } from '../../config'
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'
import { getFieldsAndIncludes } from '~data/helpers'

const { endpoint, key } = config

type Extension = $Keys<typeof extensionsConfig>

type Params = {
  entityType: string,
  entityId: number,
  extensions?: Extension[],
  ...
}

export type AcademicYear = {
  id: number,
  year: string,
  end_date: string,
  start_date: string,
  active: boolean,
  school_number: number,
  expected_total_days: number,
}

type AcademicYearsResponse = { academic_years: AcademicYear[] }

export const useAcademicYears = (params: Params = {}) => {
  const { loading, error, data } = useResource({
    key,
    resource: getAcademicYears,
    params,
  })

  return { loading, error, data }
}

const extensionsConfig = {
  default: {
    include: ['academic_years'],
    fields: [
      {
        academic_years: ['id', 'year', 'initial_date', 'end_date', 'active'],
      },
    ],
  },
  school: {
    fields: [
      {
        academic_years: ['expected_total_days', 'total_days'],
      },
    ],
  },
  district: {
    fields: [{ academic_years: ['expected_total_days', 'school_number'] }],
  },
  in_use: {
    fields: [{ academic_years: ['in_use'] }],
  },
}

const getAcademicYears = ({
  entityType,
  entityId,
  extensions = [],
}: Params): APIResponse<AcademicYearsResponse> => {
  return fetchWithRoot('get')(endpoint(entityType, entityId), {
    params: {
      ...getFieldsAndIncludes(extensionsConfig, extensions),
      q: { s: 'year desc' },
    },
    bypassLoader: true,
  })
}
