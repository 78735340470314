// @flow
import React from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  Alert,
  Button,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { ActionButtons } from './ActionButtons'
import { CardExam } from './CardExam'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { useManageExamsBooklets } from '~data/manageBooklets/ListBooklets/listBooklets'
import type { FocoExamEdition } from '~data/manageExams/examList'

type Props = {
  focoExamEdition: FocoExamEdition,
  handleOpenDeleteModal: ({ name: string, id: number }) => void,
  handleOpenStatusModal: ({ name: string, id: number }) => void,
}

export const BookletList = ({
  focoExamEdition,
  handleOpenDeleteModal,
  handleOpenStatusModal,
}: Props) => {
  const { entityType, entityId } = useParams()

  const { dataBooklets, loading, error } = useManageExamsBooklets({
    entityId: String(entityId),
    foco_exam_edition_id: focoExamEdition.id,
  })

  const basePath = `/gerenciar-avaliacoes/${entityType ?? ''}/${entityId ?? ''}`

  return (
    <>
      <Divider />

      {dataBooklets?.foco_exams.length === 0 ? (
        <Stack
          py={9}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <SVGIcon name="iconAlert" color="primary" size={3} />
          <Typography variant="body2" ml={1}>
            Não há cadernos cadastrados. Clique no botão “Criar Caderno” para
            adicionar.
          </Typography>
        </Stack>
      ) : (
        <Stack py={2}>
          <Grid container spacing={2}>
            {loading && (
              <>
                {[0, 1, 2].map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={`skele-${item}`}>
                    <Skeleton variant="rectangular" height="200px" />
                  </Grid>
                ))}
              </>
            )}

            {error && (
              <Alert variant="filled" severity="error">
                Ocorreu um erro ao carregar os cadernos da avaliação.
              </Alert>
            )}
            {!loading &&
              dataBooklets &&
              dataBooklets?.foco_exams.map((exam) => (
                <Grid item xs={12} sm={6} md={4} key={`card-${exam.id}`}>
                  <CardExam
                    data={exam}
                    focoExamEditionId={focoExamEdition.id}
                  />
                </Grid>
              ))}
          </Grid>
        </Stack>
      )}

      <Divider />
      <Stack direction="row" justifyContent="space-between" pt={2}>
        <Stack direction="row" gap={1}>
          <Button
            variant="primary"
            component={Link}
            to={`${basePath}/avaliacao/${focoExamEdition.id}/caderno/criar`}
            startIcon={<SVGIcon name="addCircle" color="white" />}
            sx={{ display: 'flex', pr: 5, gap: 3 }}
            size="small"
          >
            Criar caderno
          </Button>

          <Button
            variant="primary"
            component={Link}
            startIcon={<SVGIcon name="progressLineChart" color="white" />}
            sx={{ display: 'flex', pr: 5, gap: 3 }}
            size="small"
            disabled={!focoExamEdition.completed}
            to={`${basePath}/avaliacao/${focoExamEdition.id}/resultados`}
          >
            Resultados
          </Button>
        </Stack>
        <Stack>
          <ActionButtons
            key={focoExamEdition.id}
            id={focoExamEdition.id}
            name={focoExamEdition.name}
            basePath={basePath}
            examsCount={focoExamEdition.foco_exams_count}
            status={focoExamEdition.status}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenStatusModal={handleOpenStatusModal}
          />
        </Stack>
      </Stack>
    </>
  )
}
