// @flow
import { any, filter, map, omit, pipe } from 'ramda'
import store from '~store'

type requiredFeature = {
  feature: string,
  requiredResource: string,
}
export type Step = {
  title?: string,
  target: string,
  content: string,
  requiredResources?: Array<requiredFeature>,
  placement?: string,
}

const profileHasFeature = (feature: string, resource_name: string) => {
  const features = store.getState().user.features
  if (!features[feature]) return false
  return !!features[feature].resources.find(
    ({ resource }) => resource === resource_name
  )
}

const profileHasAnyFeature = any(({ feature, requiredResource }): boolean =>
  profileHasFeature(feature, requiredResource)
)

export const filterStepsByFeatures = (steps: Array<Step>) =>
  pipe(
    filter(({ requiredResources }) => {
      if (!requiredResources) return true
      return profileHasAnyFeature(requiredResources)
    }),
    map(omit(['requiredResources']))
  )(steps)
