// @flow
import React from 'react'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import Header from '~components/Common/Header'
import filters from '~resources/filters'
import { FiltersProvider } from '~contexts/Filters'
import { Content } from '~components/ManageExams/Content'

export const ManageExams = () => {
  const { entityType, entityId } = useParams()

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ px: { xs: 2, sm: 4, md: 8 } }}
    >
      <Header
        title="Gerenciar avaliações"
        breadcrumbs={[
          {
            text: 'Avaliações',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }`,
          },
          {
            text: 'Gerenciar avaliações',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }`,
          },
        ]}
      />

      <FiltersProvider
        resource={filters.getManageExamsFilters}
        config={{
          foco_exam_edition_id: {
            filter: 'foco_exam_editions',
            label: 'Avaliação',
          },
          subject_id: {
            filter: 'subjects',
            label: 'Disciplina',
          },
          grade_id: {
            filter: 'grades',
            label: 'Série',
          },
          status_id: {
            filter: 'status',
            label: 'Status',
          },
        }}
        additionalParams={{
          entityId,
          entityType,
        }}
      >
        <Content />
      </FiltersProvider>
    </Container>
  )
}

export default ManageExams
