import React from 'react'
import { Skeleton, Stack } from '@mui/material'

export const Placeholder = () =>
  Array(6)
    .fill(null)
    .map((_, index) => ({
      fields: [
        <Stack key={`action-toggle-${index}`} px={1}>
          <Skeleton
            key={`action-toggle-${index}`}
            width={24}
            height={24}
            variant="rectangular"
          />
        </Stack>,
        <Skeleton key={`name-${index}`} variant="text" minWidth={100} />,
        <Skeleton
          key={`registration-number-${index}`}
          variant="text"
          minWidth={100}
        />,
        <Skeleton key={`email-${index}`} variant="text" minWidth={100} />,
        <Skeleton key={`classroom-${index}`} variant="text" minWidth={100} />,
        <Skeleton key={`status-${index}`} variant="text" minWidth={100} />,
        <Stack key={`name-${index}`} direction="row" justifyContent="center">
          <Skeleton variant="circular" width={40} height={40} />
        </Stack>,
      ],
      key: index,
    }))
