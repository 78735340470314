//@flow
import React, { useState } from 'react'
import { Button } from '@mui/material'
import type { APIResponse } from '~types/Network'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

type Props = { getCSV: () => APIResponse<string> }
type ButtonStatus = 'waiting' | 'loading' | 'error'

const DownloadCSV = ({ getCSV }: Props) => {
  const [status, setStatus] = useState<ButtonStatus>('waiting')

  const handleClick = () => {
    setStatus('loading')

    getCSV().then(([error]) => {
      if (error) {
        setStatus('error')
        return
      }

      setStatus('waiting')
    })
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<SVGIcon name="exportButton" color="linkColor" size={3} />}
      onClick={handleClick}
      disabled={status === 'loading'}
      size="small"
    >
      Exportar lista
    </Button>
  )
}

export default DownloadCSV
