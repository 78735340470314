// @flow

export type Values = {
  parentAcademicYear: ?number,
  year: ?number,
  expectedTotalDays: ?number,
  initialDate: Object,
  endDate: Object,
  entityType: 'school',
  entityId: number,
  curriculumPeriods: Array<{
    entity_id: number,
    entity_type: 'school',
    name: string,
    end_date: Object,
    start_date: Object,
    parent_curriculum_period_id: number,
  }>,
}

export const validate = (values: Values) =>
  Object.fromEntries<string, string>(
    ['parentAcademicYear', 'initialDate', 'endDate']
      .filter((field) => !values[field])
      .map((field) => [field, 'Obrigatório'])
  )
