// @flow
import { Divider, Stack, Typography } from '@mui/material'
import React, { useLayoutEffect, useRef } from 'react'
import { type AnswerByQuestion, type SelectedAnswer } from '../../types'
import { Question } from './Question'
import ExamIcon from '~assets/svg/icon_exam_name.svg'

type SelectedAnswers = Array<SelectedAnswer>

type Props = {
  currentQuestionIndex: number,
  answersByQuestions: Array<AnswerByQuestion>,
  selectedAnswers: SelectedAnswers,
  handleNewSelectOptionId: (answerId: number, optionId: number) => void,
}

export const QuestionBox = ({
  currentQuestionIndex,
  answersByQuestions,
  selectedAnswers,
  handleNewSelectOptionId,
}: Props) => {
  useLayoutEffect(() => {
    const offsetTop = boxRef?.current?.offsetTop ?? 0
    window.scroll(0, offsetTop)
  }, [currentQuestionIndex])
  const boxRef = useRef(null)

  const currentQuestion = answersByQuestions[currentQuestionIndex]

  const selectedAnswer = selectedAnswers.find(
    (answer) => currentQuestion.answerId === answer.id
  )

  if (!selectedAnswer?.id) return null

  return (
    <Stack
      component="article"
      ref={boxRef}
      variant="modalBox"
      alignItems="flex-start"
      width={1}
      mt={10}
    >
      <Stack direction="row" gap={1}>
        <img src={ExamIcon} alt="Ícone de exames" />
        <Typography variant="h4">Questão {currentQuestionIndex + 1}</Typography>
      </Stack>

      <Divider sx={{ width: '100%', my: 2 }} />

      <Question
        key={currentQuestion.id}
        id={currentQuestion.id}
        answerId={selectedAnswer.id}
        statement={currentQuestion.statement}
        command={currentQuestion.command}
        reference={currentQuestion.reference}
        supportImagePath={currentQuestion.support_image_path}
        initialOptionId={selectedAnswer.selectedOptionId}
        options={currentQuestion.foco_question_options}
        handleNewSelectOptionId={handleNewSelectOptionId}
      />
    </Stack>
  )
}
