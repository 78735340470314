// @flow
import { omit } from 'ramda'
import type { PersonableKind } from '~types/Profile'
import type { APIResponse } from '~types/Network'
import type { Option } from '~types/Filters'
import { fetchWithRoot, fetchWithoutRoot } from '~helpers/fetch'

export const endpoints = {
  getSkillMapFilters: (exam_id?: string) =>
    `/api/v2/exam_results/${exam_id || ''}/ability_filters`,
  getEnemFilters: '/api/v2/enem/exam_results/0/ability_filters',
  getEnemQuestionFilters: '/api/v2/enem/questions/filters',
  getSelectionFilters: '/api/v2/selection',
  getTCTFilters: (entityType: string) => `/api/v3/filters/tct/${entityType}`,
  getFocoExamsFilters: (entityType: string) =>
    `api/v3/filters/foco_exams/${entityType}`,
  getManageStudentsFilters: '/api/v3/filters/student_profiles/',
  getCurriculumFilters: '/api/v3/curriculum_events/filters',
  getBaseCurriculumsFilters: (entityType: string, entityId: string) =>
    `api/v3/${entityType}s/${entityId}/base_curriculums/filters`,
  getJourneyResultsFilters: '/api/v3/filters/foco_exam_student_profiles',
  getManageExamsFilters: '/api/v3/filters/foco_exam_editions',
  getManageExamsResultsFilters: (examId: string) =>
    `/api/v3/foco_exam_editions/${examId}/results_filters`,
  getManageBookletsFilters: '/api/v3/foco_exams/filters',
}

type FiltersResponse = {
  grades: Array<Option<string>>,
  subjects: Array<Option<string>>,
  years: Array<Option<string>>,
  class_groups: Array<Option<string>>,
  classrooms: Array<Option<string>>,
}

type EnemParams = {
  exam_id: string,
  school_id: string,
  year?: string,
  subject_id?: string,
  ...
}

type JourneyParamns = {
  foco_exam_edition_id?: string,
  subject_id?: string,
  ...
}

type JourneyResponse = {
  foco_exam_editions: Array<Option<string>>,
  subjects: Array<Option<string>>,
}

const getEnemFilters = (params: EnemParams): APIResponse<FiltersResponse> =>
  fetchWithoutRoot('get')(endpoints.getEnemFilters, {
    params,
    bypassLoader: true,
  })

type SkillMapParams = {
  resultable_type?: string,
  resultable_id?: string,
  exam_id?: string,
  year?: string,
  grade_id?: string,
  classroom_id?: string,
  ...
}

export const getSkillMapFilters = (
  params: SkillMapParams
): APIResponse<FiltersResponse> => {
  const isClassroom = !!params.classroom_id && params.classroom_id !== '0'
  const resultable_type = isClassroom ? 'classroom' : params.resultable_type
  const resultable_id = isClassroom ? params.classroom_id : params.resultable_id

  return fetchWithoutRoot('get')(endpoints.getSkillMapFilters(params.exam_id), {
    params: { ...omit(['exam_id'], params), resultable_type, resultable_id },
    bypassLoader: true,
  })
}

type EnemQuestionParams = {
  school_id: string,
  year?: string,
  subject_id?: string,
  skill_id?: string,
  ...
}

type QuestionFiltersResponse = {
  years: Array<Option<string>>,
  subjects: Array<Option<string>>,
  skills: Array<Option<string>>,
}

const getEnemQuestionFilters = (
  params: EnemQuestionParams
): APIResponse<QuestionFiltersResponse> =>
  fetchWithoutRoot('get')(endpoints.getEnemQuestionFilters, {
    params,
    bypassLoader: true,
  })

type TCTFiltersParams = {
  edition_id?: string,
  grade_id?: string,
  subject_id?: string,
  classroom_id?: string,
  entityType: string,
  entityId: string,
  ...
}

type TCTFiltersResponse = {
  editions: Array<Option<string>>,
  grades: Array<Option<string>>,
  subjects: Array<Option<string>>,
  classrooms: Array<Option<string>>,
}

export const getTCTFilters = ({
  entityType,
  entityId,
  ...params
}: TCTFiltersParams): APIResponse<TCTFiltersResponse> =>
  fetchWithRoot('get')(endpoints.getTCTFilters(entityType), {
    params: { ...params, entity_id: entityId },
    bypassLoader: true,
  })

type FocoExamsFiltersParams = {
  foco_exam_edition_id?: string,
  subject_id?: string,
  grade_id?: string,
  classroom_id?: string,
  entityType: string,
  entityId: string,
  ...
}

type FocoExamsFiltersResponse = {
  foco_exam_editions: Array<Option<string>>,
  subjects: Array<Option<string>>,
  grades: Array<Option<string>>,
  classrooms: Array<Option<string>>,
}

export const getFocoExamsFilters = ({
  entityType,
  entityId,
  ...params
}: FocoExamsFiltersParams): APIResponse<FocoExamsFiltersResponse> =>
  fetchWithRoot('get')(endpoints.getFocoExamsFilters(entityType), {
    params: { ...params, entity_id: entityId },
    bypassLoader: true,
  })

type SelectionParams = {|
  feature: string,
  resource: string,
  examId?: number,
  kind?: PersonableKind,
  groupType?: number,
  districtId?: number,
  sectorId?: number,
  schoolId?: number,
|}

export type SelectionFilter<T> = Array<{
  name: string,
  id: T,
  disabled?: boolean,
}>

export type SelectionFilters = {
  kinds: SelectionFilter<PersonableKind>,
  group_types: SelectionFilter<number>,
  district: { name: string, id: number },
  sectors: SelectionFilter<number>,
  schools: SelectionFilter<number>,
}

export type CurriculumParams = {
  curriculum_period_id?: string,
  grade_id?: string,
  subject_id?: string,
  classroom_id?: string,
  entityId: string,
  entityType: string,
  ...
}
export type CurriculumFilters = {
  school_id: Array<Option<string>>,
  curriculum_periods: Array<Option<string>>,
  grades: Array<Option<string>>,
  subjects: Array<Option<string>>,
  classrooms: Array<Option<string>>,
}

export type ManageStudentsFiltersParams = {
  grade_id: string,
  entityId: string,
  ...
}

export type ManageStudentsFilters = {
  grades: Array<Option<string>>,
  classrooms: Array<Option<string>>,
}

const getManageStudentsFilters = ({
  grade_id,
  entityId,
}: ManageStudentsFiltersParams): APIResponse<ManageStudentsFilters> =>
  fetchWithRoot('get')(endpoints.getManageStudentsFilters, {
    params: {
      grade_id,
      school_id: entityId,
    },
    bypassLoader: true,
  })

export type ManageExamsFiltersParams = {
  entityType: string,
  entityId: string,
  foco_exam_edition_id?: string,
  subject_id?: string,
  ...
}

export type ManageExamsFilters = {
  foco_exam_editions: Array<Option<string>>,
  subjects: Array<Option<string>>,
  grades: Array<Option<string>>,
  status: Array<Option<string>>,
}

const getManageExamsFilters = ({
  entityType,
  entityId,
  foco_exam_edition_id,
  subject_id,
}: ManageExamsFiltersParams): APIResponse<ManageExamsFilters> =>
  fetchWithRoot('get')(endpoints.getManageExamsFilters, {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      foco_exam_edition_id,
      subject_id,
    },
    bypassLoader: true,
  })

export type ManageExamsResultsFiltersParams = {
  entityType: string,
  entityId: string,
  examId: string,
  foco_exam_id?: string,
  classroom_id?: string,
  ...
}

export type ManageExamsResultsFilters = {
  foco_exams: Array<Option<string>>,
  classrooms: Array<Option<string>>,
}

const getManageExamsResultsFilters = ({
  entityType,
  entityId,
  examId,
  foco_exam_id,
  classroom_id,
}: ManageExamsResultsFiltersParams): APIResponse<ManageExamsResultsFilters> =>
  fetchWithRoot('get')(endpoints.getManageExamsResultsFilters(examId), {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      foco_exam_id,
      classroom_id,
    },
    bypassLoader: true,
  })

export type ManageBookletsFiltersParams = {
  entityType: string,
  entityId: string,
  ...
}

export type ManageBookletsFilters = {
  foco_exam_editions: Array<Option<string>>,
  subjects: Array<Option<string>>,
  grades: Array<Option<string>>,
  status: Array<Option<string>>,
}

export const getManageBookletsFilters = ({
  entityType,
  entityId,
}: ManageBookletsFiltersParams): APIResponse<ManageBookletsFilters> =>
  fetchWithRoot('get')(endpoints.getManageBookletsFilters, {
    params: { entity_type: entityType, entity_id: entityId },
    bypassLoader: true,
  })

const getSelectionFilters = ({
  feature,
  resource,
  examId,
  kind,
  groupType,
  sectorId,
  schoolId,
}: SelectionParams): APIResponse<SelectionFilters> =>
  fetchWithRoot('get')(endpoints.getSelectionFilters, {
    params: {
      feature,
      resource,
      kind,
      group_type: groupType,
      sector_id: sectorId,
      school_id: schoolId,
      exam_id: examId,
    },
    bypassLoader: true,
  })

const getCurriculumFilters = ({
  entityType,
  entityId,
  curriculum_period_id,
  grade_id,
  subject_id,
  classroom_id,
}: CurriculumParams): APIResponse<CurriculumFilters> =>
  fetchWithRoot('get')(endpoints.getCurriculumFilters, {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      curriculum_period_id,
      grade_id,
      subject_id,
      classroom_id,
    },
    bypassLoader: true,
  })

const getCurriculumFiltersCalendar = ({
  entityType,
  entityId,
}: CurriculumParams): APIResponse<CurriculumFilters> =>
  fetchWithRoot('get')(endpoints.getCurriculumFilters, {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      filter: 'calendar',
    },
    bypassLoader: true,
  })

const getJourneyFiltersResults = ({
  foco_exam_edition_id,
  subject_id,
}: JourneyParamns): APIResponse<JourneyResponse> =>
  fetchWithRoot('get')(endpoints.getJourneyResultsFilters, {
    params: {
      foco_exam_edition_id: foco_exam_edition_id,
      subject_id: subject_id,
    },
    bypassLoader: true,
  })

type CurriculumsFiltersParams = {
  entityId: string,
  entityType: string,
  ...
}

type CurriculumsFiltersResponse = {
  academic_years: Array<Option<string>>,
  grades: Array<Option<string>>,
  subjects: Array<Option<string>>,
  classrooms: Array<Option<string>>,
}

export const getBaseCurriculumsFilters = ({
  entityId,
  entityType,
}: CurriculumsFiltersParams): APIResponse<CurriculumsFiltersResponse> =>
  fetchWithRoot('get')(
    endpoints.getBaseCurriculumsFilters(entityType, entityId),
    {
      params: { entity_id: entityId, entity_type: entityType },
      bypassLoader: true,
    }
  )

export default {
  endpoints,
  getEnemFilters,
  getSkillMapFilters,
  getEnemQuestionFilters,
  getSelectionFilters,
  getTCTFilters,
  getFocoExamsFilters,
  getCurriculumFilters,
  getManageStudentsFilters,
  getCurriculumFiltersCalendar,
  getJourneyFiltersResults,
  getManageExamsFilters,
  getManageBookletsFilters,
  getManageExamsResultsFilters,
  getBaseCurriculumsFilters,
}
