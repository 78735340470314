// @flow
/* eslint-disable no-unused-vars */
import { getAAPLink, getEnemQuestionsLink } from './links/aap'
import { getDashboardLink } from './links/indicators'
import { getFocoExamsLink } from './links/focoExams'
import { getCurriculumLink } from './links/curriculum'
import { getManagementLink } from './links/management'
import { getEnemMapLink, getSkillMapLink } from './links/skillMap'
import type { FeatureName, Profile, ExamType } from '~types/Profile'

export const paths = {
  retrospective: (examType: ExamType, resource: string) => {
    if (examType === 'tct') {
      return (id: number, profile: Profile) =>
        getAAPLink('retrospective', id, profile)
    }

    return (id: number, profile: Profile) =>
      getSkillMapLink('retrospective', resource, id, profile)
  },
  prospective: (examType: ExamType, resource: string) => {
    if (examType === 'tct') {
      return (id: number, profile: Profile) =>
        getAAPLink('prospective', id, profile)
    }

    return (id: number, profile: Profile) =>
      getSkillMapLink('prospective', resource, id, profile)
  },
  enem: (examType: ExamType, resource: string) => {
    if (examType === 'tri') {
      return (id: number, profile: Profile) => getEnemMapLink(profile)
    }

    return (id: number, profile: Profile) => getEnemQuestionsLink(profile)
  },
  indicators: (examType: ExamType, resource: string) => {
    return (id: number, profile: Profile) => getDashboardLink(profile, resource)
  },
  foco_exam: (examType: ExamType, resource: string) => {
    return (id: number, profile: Profile) => getFocoExamsLink(profile, resource)
  },
  curriculum: (examType: ExamType, resource: string) => {
    return (id: number, profile: Profile) =>
      getCurriculumLink(profile, resource)
  },
  management: (examType: ExamType, resource: string) => {
    return (id: number, profile: Profile) =>
      getManagementLink(profile, resource)
  },
  foco_bi:
    (examType: ExamType, resource: string) => (id: number, profile: Profile) =>
      '/foco-bi',
}

type PathParams = { feature: FeatureName, examType: ExamType, resource: string }

export const getPath = ({ feature, examType, resource }: PathParams) =>
  paths[feature](examType, resource) || ((id: number, profile: Profile) => '')
