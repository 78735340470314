// @flow
import React, { useState, useEffect } from 'react'
import { Snackbar, Alert } from '@mui/material'

type Props = {
  type: 'error' | 'warning' | 'info' | 'success',
  text: string,
  id: string,
  onClose?: (string) => void,
  isOpen?: boolean,
}

export const FeedbackSnackbar = ({
  type,
  text,
  id,
  onClose = () => {},
  isOpen: isOpenProp = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState(isOpenProp)

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return
    setIsOpen(false)
  }

  useEffect(() => {
    if (!isOpenProp) handleClose(null, 'stateUpdate')
    setIsOpen(isOpenProp)
  }, [isOpenProp])

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionProps={{ onExited: () => onClose(id) }}
      sx={{ zIndex: '1000000' }}
    >
      <Alert
        onClose={handleClose}
        variant="filled"
        severity={type}
        sx={{ width: '100%' }}
      >
        {text}
      </Alert>
    </Snackbar>
  )
}
