import {
  groupBy,
  prop,
  uniq,
  sortWith,
  ascend,
  descend,
  path,
  pipe,
  defaultTo,
} from 'ramda'
import dayjs from 'dayjs'

export const getWeeks = (data) => {
  if (!data) return null

  const weeks = groupBy(prop('week'), data)

  return Object.keys(weeks).map((item) => {
    const event = weeks[item]

    const curriculumEvents = event.map(({ id, status, end_time }) => ({
      id,
      status,
      endTime: end_time,
    }))

    const activities = uniq(
      event
        .map((item) => item.curriculum_activities)
        .reduce(function (pre, cur) {
          return pre.concat(cur)
        })
    )

    const skills = uniq(
      event.map((item) => {
        const skill =
          item.curriculum_events_curriculum_skills.find(
            ({ status }) => status === 'planned'
          )?.curriculum_skill ?? {}

        return {
          name: skill.name,
          description: skill.description,
        }
      })
    )

    const competencies = uniq(
      event.map((item) => {
        const competency =
          item.curriculum_events_curriculum_skills.find(
            ({ status }) => status === 'planned'
          )?.curriculum_skill.curriculum_competency ?? {}

        return {
          name: competency.name,
          description: competency.description,
        }
      })
    )

    const date = dayjs(event[0].date, ['YYYY', 'YYYY-MM-DD'], true)

    const startOfWeek = date.startOf('week').add(1, 'day').format('DD/MM')

    const endOfWeek = date.endOf('week').format('DD/MM')

    const classes = weeks[item].length

    const performedClasses = weeks[item].filter(
      ({ performed }) => performed === true
    ).length

    return {
      week: item,
      weekDays: `${startOfWeek} - ${endOfWeek}`,
      curriculum_skill: skills,
      curriculum_competencies: competencies,
      curriculum_activities: activities,
      classes,
      performed_classes: performedClasses,
      curriculumEvents,
      day: date.date(),
      month: date.month(),
      year: date.year(),
    }
  })
}

export const sortWeeks = ({ weeks, order }) => {
  if (order.direction === 'asc')
    return sortWith([ascend(pipe(path(order.property), defaultTo(2)))])(weeks)
  return sortWith([descend(pipe(path(order.property), defaultTo(-1)))])(weeks)
}
