//@flow
import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const ElevationTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    TransitionProps={{ style: { transformOrigin: '0 0 0' } }}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[250],
    boxShadow: theme.shadows[15],
    fontSize: 13,
    lineHeight: '19px',
    color: theme.palette.primary.main,
    padding: 15,
    margin: '0 !important',
    borderRadius: '0 4px 4px 4px',
  },
}))
