import styled from '@emotion/styled'
import AuthCircle from '~assets/svg/pages/Auth/circle.svg'

export const AuthSection = styled('section')(({ theme }) => ({
  position: 'relative',
  zIndex: 9,
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4, 0),

  '&:after': {
    content: '""',
    position: 'fixed',
    background: `url(${AuthCircle}) no-repeat`,
    backgroundSize: 'cover',
    bottom: 0,
    right: 0,
    width: '300px',
    height: '300px',

    zIndex: -1,
    [theme.breakpoints.up('sm')]: {
      width: '680px',
      height: '500px',
    },
  },
}))

export const ResponsivePicture = styled.picture`
  display: block;
  img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`
