// @flow
import React, { type Node } from 'react'
import { styled } from '@mui/material/styles'
import useCustomization from '~hooks/useCustomization'

type Props = { id?: string, children: Node, image?: boolean }

const Container = styled('header')`
  width: 100%;
  min-height: 100px;
  padding: 40px 0 25px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;

  > div {
    max-width: 700px;
    margin: 0 auto;

    h2 {
      position: relative;
      padding-bottom: 10px;
      color: #334449;
      text-align: center;
      font-size: 20px;
      line-height: 27px;
      text-transform: uppercase;
      strong {
        font-weight: bold;
      }
    }

    p {
      position: relative;
      color: #334449;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }

    strong {
      font-weight: 700;
    }
  }
`

export const Banner = ({ id, children, image }: Props) => {
  const customization = useCustomization()

  return (
    <Container
      id={id}
      data-testid={id}
      bgUrl={customization.homeBanner}
      image={image}
    >
      <div>{children}</div>
    </Container>
  )
}
