import { styled } from '@mui/material/styles'

export const Form = styled('form')`
  width: 100%;
  font-size: 14px;
  height: 100%;
  margin: 0 auto;

  header {
    text-align: center;
    margin: 5px auto 0;
  }

  h3 {
    margin: 0 0 16px 0;
  }

  fieldset {
    h3 {
      margin: 0 0 1em;
    }

    ul {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;

      li {
        margin-bottom: 4px;
        text-align: center;

        &.align-left {
          text-align: left;
        }

        label {
          text-align: left;
        }
      }
    }
    &.no-gap {
      h3 {
        margin: -10px 0 6px;
      }

      ul {
        grid-gap: 0;
      }
    }
  }

  footer {
    text-align: center;
    margin-top: 16px;

    .link-button {
      color: ${({ theme }) => theme.palette.foco.textNegative};
    }

    &.navigation-footer {
      display: block;
    }
  }

  aside {
    text-align: center;
    padding-top: 22px;
    padding-bottom: 6px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;

    header {
      text-align: left;
      max-width: none;
      margin: 0;
      padding: 16px 0;
    }

    fieldset ul {
      display: flex;
      align-items: center;

      li {
        width: 100%;
        margin: 0;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: 0;

    header {
      text-align: left;
      max-width: none;
    }
  }
`
