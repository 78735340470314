//@flow
export default function IconButton(theme: Object) {
  return {
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'secondary' },
          style: {
            '&.Mui-disabled': {
              background: theme.palette.grey[550],
              color: theme.palette.common.white,
            },
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: theme.palette.secondary.light,
            },
          },
        },
        {
          props: { variant: 'outlinedSecondary' },
          style: {
            backgroundColor: theme.palette.grey[0],
            border: `solid 1px ${theme.palette.secondary.main}`,
            width: 34,
            height: 34,
            padding: 0,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.grey[0],
            },
            '& i': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:hover > i': {
              backgroundColor: theme.palette.grey[0],
            },
            '&.Mui-disabled': {
              border: `solid 1px ${theme.palette.action.disabled}`,
              '& > i': {
                backgroundColor: theme.palette.action.disabled,
              },
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            backgroundColor: 'transparent',
            border: `solid 1px ${theme.palette.secondary.main}`,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '& > i': {
              backgroundColor: theme.palette.secondary.main,
              transition: 'background-color 150ms',
            },
            '&:hover > i': {
              backgroundColor: theme.palette.common.white,
            },
            '&.Mui-disabled': {
              border: `solid 1px ${theme.palette.action.disabled}`,
              '& > i': {
                backgroundColor: theme.palette.action.disabled,
              },
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            height: 36,
            width: 36,
          },
        },
      ],
    },
  }
}
