// @flow
import React from 'react'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

type Props = {
  entityId: number,
}

export const CreateExamButton = ({ entityId }: Props) => (
  <Button
    variant="primary"
    component={Link}
    to={`/gerenciar-avaliacoes/school/${entityId}/avaliacao/criar`}
    startIcon={<SVGIcon name="addCircle" color="white" />}
    sx={{ display: 'flex', pr: 5, gap: 3 }}
    size="small"
  >
    Criar avaliação
  </Button>
)
