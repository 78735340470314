import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { FiltersProvider } from '~contexts/Filters'
import useEntity from '~data/entity'
import filters from '~resources/filters'
import { pxToRem } from '~helpers/getFontValue'
import { Breadcrumbs } from '~primitives/Breadcrumbs'
import { Scribble } from '~primitives/Typography/Underline'
import { ManagementContent } from '~components/Curriculum/ManagementContent'
import FilterBar from '~components/Common/FilterBar'
import { getSelectedProfile } from '~selectors/profile'

export default function CurriculumManagement() {
  const { entityType, entityId } = useParams()

  const profile = useSelector(getSelectedProfile)

  const { data } = useEntity(entityType, String(entityId))

  const breadcrumbList = {
    district: {
      district: [
        { id: 'curriculum', text: 'Currículo', pathname: '#' },
        {
          id: 'curriculum-district',
          text: 'Rede',
          pathname: '#',
        },
      ],
      sector: [
        { id: 'curriculum', text: 'Currículo', pathname: '#' },
        {
          id: 'curriculum-district',
          text: 'Rede',
          pathname: `/curriculo/district/${data?.district?.id}/gestao`,
        },
        {
          id: 'curriculum-sector',
          text: 'Regional',
          pathname: '#',
        },
      ],
    },
    sector: {
      sector: [
        { id: 'curriculum', text: 'Currículo', pathname: '#' },
        {
          id: 'curriculum-sector',
          text: 'Regional',
          pathname: '#',
        },
      ],
    },
  }

  return (
    <FiltersProvider
      config={{
        curriculum_period_id: {
          filter: 'curriculum_periods',
          label: 'Período',
        },
        subject_id: { filter: 'subjects', label: 'Disciplina' },
        grade_id: { filter: 'grades', label: 'Série' },
        classroom_id: { filter: 'classrooms', label: 'Turma' },
      }}
      resource={filters.getCurriculumFilters}
      additionalParams={{
        entityType,
        entityId,
      }}
    >
      {' '}
      <Box
        sx={{
          px: {
            xs: 2,
            sm: 2,
            md: 2,
            lg: 8,
          },
        }}
      >
        <Breadcrumbs
          margin="0"
          items={breadcrumbList[profile.kind][entityType]}
        />

        <Typography
          sx={{ fontSize: pxToRem(24), fontWeight: 700, my: pxToRem(6) }}
        >
          <Scribble>Aplicação do currículo</Scribble>
        </Typography>

        <FilterBar contained margin="20px 0" reloadOnChange={false} />

        <ManagementContent />
      </Box>
    </FiltersProvider>
  )
}
