// @flow
import { styled } from '@mui/material/styles'
import { hideVisually } from 'polished'

export const ErrorText = styled('span')`
  ${({ hideWarning }) => hideWarning && hideVisually()}
  display: flex;
  margin-top: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.foco.danger};
  text-align: left;
  line-height: 18px;
`
