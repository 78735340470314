// @flow
import { Button } from '@mui/material'
import React from 'react'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

type Props = {
  onClick: any,
}

export const AbortButton = ({ onClick }: Props) => {
  return (
    <Button variant="transparent" onClick={onClick}>
      <SVGIcon name="close" color="neutralLight" size={5} />
    </Button>
  )
}
