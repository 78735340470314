// @flow
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'

type Params = {
  id: number,
  entityId: number,
  page: number,
  order?: string,
  orderBy?: string,
}

type ExamParams = {
  entityId: number,
  gradeId?: number,
  subjectId?: number,
  districtId?: number,
  page: number,
  order?: string,
  orderBy?: string,
}

const useSchoolList = (params: ExamParams) => {
  const { data: examData } = useResource({
    key: 'useFocoExamData',
    resource: getFocoExamData,
    params,
  })

  const selectedExamId = examData?.foco_exams[0]?.id

  const { loading, error, data } = useResource({
    key: 'useSchoolList',
    resource: getSchoolList,
    params: {
      id: Number(selectedExamId),
      entityId: Number(params.entityId),
      page: Number(params.page),
      order: params?.order,
      orderBy: params?.orderBy,
    },
    enabled: !!selectedExamId,
  })

  return { loading, error, data, examData }
}

export const focoExamEndpoint = '/api/v3/foco_exams/'

export type Exam = {
  id: number,
  estimated_time: number,
  amount_of_displayed: number,
  active: boolean,
  amount_of_answered: number,
  amount_of_started: number,
}

const getFocoExamData = (params: ExamParams): APIResponse<Exam> =>
  fetchWithRoot('get')(focoExamEndpoint, {
    params: {
      entity_type: 'sector',
      entity_id: params?.entityId,
      q: {
        district_id_eq: params?.districtId,
        grade_id_eq: params?.gradeId,
        subject_id_eq: params?.subjectId,
      },
      fields: [{ foco_exams: ['id'] }],
      include: ['foco_exams'],
    },
    bypassLoader: true,
  })

export type School = {
  id: number,
  name: string,
  foco_exam_completion_rate: ?number,
}

export type SchoolsResponse = { schools: School[] }

export const endpoint = '/api/v3/schools'

const getSchoolList = ({
  id,
  entityId,
  page,
  order = 'asc',
  orderBy = 'name',
}: Params): APIResponse<SchoolsResponse> =>
  fetchWithRoot('get')(endpoint, {
    params: {
      entity_type: 'sector',
      entity_id: entityId,
      foco_exam_id: id,
      page: page || 1,
      per_page: 10,
      q: {
        classrooms_student_profiles_foco_exam_student_profiles_foco_exam_id_eq:
          id,
        s: `${orderBy} ${order}`,
      },
      fields: [
        'meta',
        { schools: ['id', 'name', 'foco_exam_completion_rate'] },
      ],
      include: ['schools'],
    },
    bypassLoader: true,
  })

export default useSchoolList
