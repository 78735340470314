// @flow
import React from 'react'
import TitleUpdater from '~connected/TitleUpdater'
import BasicSelection from '~components/Selection'
import type { FeatureName } from '~types/Profile.js'
import useAhoy from '~hooks/useAhoy'

type Params = {
  feature: FeatureName,
  resource: string,
  examId?: string,
}

type Props = { match: { params: Params } }

const Selection = ({ match }: Props) => {
  useAhoy('Selection', match.params)

  return (
    <>
      <TitleUpdater htmlTitle="Defina sua pesquisa" templateTitle={null} />

      <BasicSelection
        feature={match.params.feature}
        resource={match.params.resource}
        examId={Number(match.params.examId)}
      />
    </>
  )
}

export default Selection
