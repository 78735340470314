// @flow
import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import Login from '~pages/Login'
import Password from '~pages/Register/Password'
import ProfileSelection from '~pages/Login/ProfileSelection'
import RegisterNumber from '~pages/Register'
import RegisterPersonal from '~pages/Register/Personal'
import RememberPass from '~pages/Register/RememberPass'
import RememberPassCpf from '~pages/Register/RememberPass/CPF'

export const routes = ['/login', '/recuperar-senha', '/cadastro', '/register']

const ModalsRoutes = () => (
  <Switch>
    <Route exact path="/login" component={Login} />

    <Route exact path="/login/perfil" component={ProfileSelection} />

    <Route exact path="/cadastro" component={RegisterNumber} />

    <Route path="/recuperar-senha/:cpf" component={RememberPassCpf} />

    <Route path="/recuperar-senha" component={RememberPass} />

    <Route
      path={['/cadastro/nova-senha/:token', '/register/change-password/:token']}
      component={Password}
    />

    <Route path="/cadastro/usuario/:token" component={Password} />

    <Route path="/cadastro/:cpf" component={RegisterPersonal} />
  </Switch>
)

export default ModalsRoutes
