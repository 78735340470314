//@flow
export default function Dialog() {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 999999999,
        },
      },
    },
  }
}
