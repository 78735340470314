//@flow
import React, { createContext, type Node } from 'react'
import useLocalStorage from '~hooks/useLocalStorage'

const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
}
const initialState = {
  ...defaultSettings,
  onChangeMode: () => {},
  onToggleMode: () => {},
  onChangeDirection: () => {},
}

type Props = {
  children: Node,
}

const SettingsContext = createContext<any>(initialState)

function SettingsProvider({ children }: Props) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeDirection: initialState.themeDirection,
  })

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    })
  }

  const onChangeMode = (data) => {
    setSettings({
      ...settings,
      themeMode: data,
    })
  }

  const onChangeDirection = (event) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    })
  }

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        onToggleMode,
        onChangeMode,
        onChangeDirection,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export { SettingsProvider, SettingsContext }
