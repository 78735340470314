// @flow
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'

type Params = {
  id: number,
  entityType: string,
  entityId: number,
  classroomId: number,
}

const useExam = (params: Params) => {
  const { loading, error, data } = useResource({
    key: 'useTeacherFocoExam',
    resource: getExam,
    params,
  })

  return { loading, error, data }
}

export const endpoint = (id: number) => `/api/v3/foco_exams/${id}`

export type FocoExam = {
  amount_of_created: number,
  amount_of_displayed: number,
  amount_of_answered: number,
  completion_rate: number,
  time_average: number,
}

const getExam = ({
  id,
  entityType,
  entityId,
  classroomId,
}: Params): APIResponse<FocoExam> =>
  fetchWithRoot('get')(endpoint(id), {
    params: {
      entity_type: classroomId ? 'classroom' : entityType,
      entity_id: classroomId || entityId,
      fields: [
        'amount_of_created',
        'amount_of_displayed',
        'amount_of_answered',
        'completion_rate',
        'time_average',
      ],
      q: {
        student_profile_classroom_id_eq: classroomId,
      },
    },
    bypassLoader: true,
  })

export default useExam
