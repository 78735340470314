//@flow
import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import Modal from '~primitives/Modal'
import SuccessCheck from '~assets/svg/success_check.svg'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'

type Props = {
  onClose: () => void,
  academicYear: number,
}

export const ModalSuccess = ({ onClose, academicYear }: Props) => {
  return (
    <Modal
      width={'500px'}
      height="98vh"
      confirmButtonText="Salvar"
      overflowContent={true}
      contentPadding="5px 30px 20px"
      showFooter={false}
      onClose={onClose}
    >
      <Typography variant="h4" margin="15px 0 5px">
        <UnderlinedHeading as="span">
          <strong>Ano letivo</strong>
        </UnderlinedHeading>
      </Typography>
      <Stack variant="modalBox">
        <Stack direction="column" alignItems="center" gap={1}>
          <img src={SuccessCheck} alt="" width="90px" />
          <Typography variant="h4">
            <strong>Ano letivo criado</strong>
          </Typography>
          <Typography variant="body2">
            O ano letivo <strong>{academicYear}</strong> foi cadastrado com
            sucesso
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" mt={2}>
        <Button variant="primary" size="small" onClick={onClose}>
          Voltar
        </Button>
      </Stack>
    </Modal>
  )
}
