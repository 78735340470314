// @flow
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Typography, Stack } from '@mui/material'
import { Scribble } from '~primitives/Typography/Underline'
import Modal from '~primitives/Modal'
import SupportSuccessCheck from '~assets/svg/support-form-success.svg'

type Props = {
  examName: string,
  isOpen: boolean,
  redirect: boolean,
  setIsOpen: (boolean) => void,
  onConfirm: () => void,
}

export const SuccessModal = ({
  examName,
  isOpen,
  redirect,
  setIsOpen,
  onConfirm,
}: Props) => {
  const history = useHistory()
  const { entityId } = useParams()

  const handleClose = () => {
    history.push({
      pathname: `/gerenciar-avaliacoes/school/${entityId || ''}/`,
    })
  }

  const handleConfirm = () => {
    setIsOpen(false)
    onConfirm()
    window.scrollTo(0, 0)
    if (redirect) {
      history.push({
        pathname: `/gerenciar-avaliacoes/school/${entityId || ''}/criar`,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      width="500px"
      height="98vh"
      confirmButtonText="Criar nova"
      closeButtonText="Voltar para lista"
      overflowContent={true}
      onClose={handleClose}
      onConfirm={handleConfirm}
    >
      <Typography fontSize={24} fontWeight={700} p={2}>
        <Scribble>Avaliação salva com sucesso!</Scribble>
      </Typography>

      <Stack variant="modalBox">
        <img src={SupportSuccessCheck} alt="" width="160px" />
        <Typography variant="h5">Parabéns!</Typography>
        <Typography variant="body2">
          Sua avaliação <strong>{examName}</strong> foi salva com sucesso!
        </Typography>
      </Stack>
    </Modal>
  )
}
