import { styled } from '@mui/material/styles'

export const HomeContainer = styled('section')`
  margin-top: -16px;
`

export const Thumbs = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  justify-items: center;
  max-width: 960px;
  margin: 0 auto;
  padding: 30px 0;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};

  @media screen and (min-width: 1024px) {
    max-width: 960px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: stretch;
  }
`
