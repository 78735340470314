// @flow
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useTheme, Box, Stack, Typography, Link, Button } from '@mui/material'
import { getStyles } from './styles'
import { ProgressBar } from './ProgressBar'
import { AbortButton } from './AbortButton'
import { pxToRem } from '~helpers/getFontValue'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { InputFile } from '~primitives/Input/File'
import SuccessCheck from '~assets/svg/success_check.svg'
import { studentsListImport } from '~data/manageStudents/studentsListImport'

type Props = {
  isOpened: boolean,
  setIsOpened: (boolean) => void,
}

export const ImportModal = ({ isOpened, setIsOpened }: Props) => {
  const controller = new AbortController()

  const { entityId } = useParams()
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [status, setStatus] = useState('idle')
  const [uploadProgress, setUploadProgress] = useState(0)

  const theme = useTheme()
  const styles = getStyles(theme)

  const handleLoading = (event) => {
    setStatus('loading')
    const { loaded, total } = event
    setUploadProgress(Math.round((loaded * 100) / total))
  }
  const queryClient = useQueryClient()
  const invalidateQuery = () => queryClient.invalidateQueries('StudentList')

  const handleSuccess = () => {
    setSuccessMessage(
      `${selectedFile?.name ?? ''} (${formatFileSize(selectedFile?.size)})`
    )
    setStatus('success')
    setSelectedFile(null)
    invalidateQuery()
  }

  const handleAbort = () => {
    controller.abort()
    setSelectedFile(null)
    setStatus('idle')
  }

  const handleError = (error) => {
    setStatus('error')
    setErrorMessage(getErrorMessage(error))
    invalidateQuery()
  }

  const handleClick = () => {
    setStatus('idle')
  }

  const handleSubmit = () => {
    studentsListImport({
      file: selectedFile,
      entityId: entityId ?? '',
      entityType: 'school',
      controller,
      onLoading: handleLoading,
      onSuccess: handleSuccess,
      onError: handleError,
    })
  }

  const handleClose = () => {
    setIsOpened(false)
    setSelectedFile(null)
    setStatus('idle')
  }

  return (
    <Modal
      isOpen={isOpened}
      onClose={handleClose}
      width={pxToRem(520)}
      showFooter={false}
      contentPadding="25px 0"
    >
      <Box sx={{ px: 2.5 }}>
        <Typography variant="h4" margin="15px 0">
          <UnderlinedHeading as="span">
            <strong>Importar alunos</strong>
          </UnderlinedHeading>
        </Typography>

        <Box
          sx={{
            ...styles.container,
          }}
        >
          <Typography variant="body2" sx={{ ...styles.label }}>
            Selecionar arquivo
          </Typography>
          <InputFile
            disabled={status === 'success'}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            onClick={handleClick}
            acceptedFormats={['.xlsx', '.xls', '.csv']}
            sx={{ ...styles.input }}
          />
          <Link
            sx={{ ...styles.link }}
            download
            href="https://foco-assets.s3.amazonaws.com/common/Modelo+de+importa%C3%A7%C3%A3o+de+alunos.xlsx"
          >
            Baixar modelo de arquivo pra importação
          </Link>
          <Box sx={{ ...styles.fileContainer }}>
            {status === 'success' ? (
              <Stack gap={1.25} direction="row" alignItems="center">
                <img src={SuccessCheck} alt="Success check" width={24} />
                <Typography variant="body2">
                  <strong>{successMessage}</strong> importado com sucesso!
                </Typography>
              </Stack>
            ) : status === 'loading' ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" color="grey.500">
                  {selectedFile?.name} ({formatFileSize(selectedFile?.size)})
                </Typography>
                <Stack direction="row">
                  <ProgressBar percent={uploadProgress} />
                  <AbortButton onClick={handleAbort} />
                </Stack>
              </Stack>
            ) : status === 'error' ? (
              <Stack gap={0.5} sx={{ fontSize: 12 }} color="error.main">
                {errorMessage}
              </Stack>
            ) : (
              ''
            )}
          </Box>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mt={2.5}
        >
          {status === 'success' ? (
            <Button variant="primary" onClick={handleClose} sx={{ width: 170 }}>
              Voltar para a lista
            </Button>
          ) : (
            <>
              <Button variant="text" onClick={handleClose} size="small">
                Cancelar
              </Button>
              <Button
                variant="primary"
                sx={{ width: 170 }}
                disabled={!selectedFile || status === 'loading'}
                onClick={handleSubmit}
                size="small"
              >
                {status === 'loading' ? 'Importando' : 'Importar'}
              </Button>
            </>
          )}
        </Stack>
      </Box>
    </Modal>
  )
}

const formatFileSize = (bytes) => {
  bytes = Number(bytes)
  if (bytes === 0) {
    return '0 Bytes'
  }
  const k = 1000
  const sizes = ['Bytes', 'kb', 'mb', 'gb']
  const size = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, size)).toFixed(1))}${sizes[size]}`
}

const getErrorMessage = ({
  status,
  errors,
}: {
  status: string,
  errors: Array<Object>,
}) => {
  const statusMessage = {
    partial_error: 'Erro ao importar alguns dados',
    error: 'Erro ao importar dados',
  }

  return (
    <>
      <span>
        <strong>{statusMessage[status]}</strong>
      </span>

      {errors.slice(0, 9).map(({ row_data, error_message }, index) => (
        <span key={index}>
          {row_data
            ? `Nome: ${row_data['Nome']} - Matrícula: 
              ${row_data['Matrícula']} | ${error_message}`
            : `${error_message}`}
        </span>
      ))}

      {errors.length > 10 && (
        <span>
          <strong>
            {statusMessage.partial_error} - Outros {errors.length - 10} alunos
            com erros.
          </strong>
        </span>
      )}
    </>
  )
}
