// @flow
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { InsightBox } from '~components/Common/Insight/Box'
import useExam from '~data/focoExams/teacher/exam'
import { type IconName } from '~primitives/Utilities/SVGIcon'
import { formatDecimalPercent } from '~helpers/formatting/numbers'
import useTheme from '~hooks/useTheme'
import useCustomization from '~hooks/useCustomization'
import useFilters from '~hooks/useFilters'

type Props = {
  focoExamId: number,
  entityType: string,
  entityId: number,
  classroomId?: number,
}

type Icon = {
  name: IconName,
  color?: string,
}

type BoxProps = {
  icon: Icon,
  label: string,
  value: string,
  suffix?: string,
  sx?: any,
}

const timeConvert = (time) => {
  const minutes = time % 60
  const hours = Math.floor(time / 60)

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export const Insights = ({
  focoExamId,
  entityType,
  entityId,
  classroomId,
}: Props) => {
  const { loading, error, data } = useExam({
    id: Number(focoExamId),
    entityType,
    entityId,
    classroomId: Number(classroomId),
  })
  const theme = useTheme()
  const customization = useCustomization()
  const { filters } = useFilters()

  if (error) return `Erro! ${error.message || ''}`

  if (loading || !data) return 'Carregando...'

  const boxes: Array<BoxProps> = [
    {
      icon: { name: 'chartSimple', color: '#ffffff' },
      label: 'Taxa de conclusão',
      value: formatDecimalPercent(0)(data.completion_rate).replace('%', ''),
      suffix: '%',
      sx: { bgcolor: theme.resultables[entityType], color: 'grey.0' },
    },
    {
      icon: { name: 'paperPlane' },
      label: 'Enviadas',
      value: data.amount_of_created,
    },
    {
      icon: { name: 'eye' },
      label: 'Visualizadas',
      value: data.amount_of_displayed,
    },
    {
      icon: { name: 'checkBage' },
      label: 'Concluídas',
      value: data.amount_of_answered,
    },
    {
      icon: { name: 'clock' },
      label: 'Média de tempo',
      value: timeConvert(data.time_average),
    },
  ]

  return (
    <Stack
      sx={{
        bgcolor: 'grey.200',
        mt: 2,
        py: 2,
        px: 2,
        textAlign: 'center',
        borderRadius: '4px',
      }}
    >
      <Typography component="h3" variant="subtitle1">
        Resultado geral
      </Typography>
      <Typography typography="body2" sx={{ pb: 2.5 }}>
        Veja os principais resultados da {customization[entityType]} em{' '}
        {filters?.subject_id?.selected?.name}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          borderRadius: '4px',
          gap: '13px',
        }}
      >
        {boxes.map(({ icon, label, value, suffix, sx }: BoxProps, index) => {
          return (
            <InsightBox
              icon={icon}
              label={label}
              value={value}
              suffix={suffix && suffix}
              key={index}
              sx={sx}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
