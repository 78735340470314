// @flow
import { Container } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { sortBy, prop } from 'ramda'
import Header from '~components/Common/Header'
import { ExamForm } from '~components/ManageExams/Forms/Exam'
import {
  patchBooklet,
  getEndpoint,
} from '~data/manageBooklets/form/patchBooklet'
import { useBookletFormDetails } from '~data/manageBooklets/form/bookletFormDetails'

export const ExamEditForm = () => {
  const { focoExamId, focoExamEditionId, entityId, entityType } = useParams()

  const { data, loading, error } = useBookletFormDetails({
    id: Number(focoExamId),
    entityType: entityType ?? '',
    entityId: Number(entityId),
  })

  if (!data || loading || !focoExamId || !focoExamEditionId) return 'Loading'

  if (error) return 'Error'

  const initialValues = getInitialValues(data)
  const endpoint = getEndpoint(focoExamId)

  return (
    <Container disableGutters maxWidth={false} sx={{ px: { sm: 8, xs: 2 } }}>
      {entityId && entityType && (
        <>
          <Header
            title="Editar caderno"
            breadcrumbs={[
              {
                text: 'Avaliações',
                pathname: `/gerenciar-avaliacoes/${entityType}/${entityId}`,
              },
              {
                text: 'Gerenciar avaliações ',
                pathname: `/gerenciar-avaliacoes/${entityType}/${entityId}`,
              },
              {
                text: 'Editar caderno',
                pathname: `/gerenciar-avaliacoes/${entityType}/${entityId}/avaliacao/${
                  focoExamEditionId ?? ''
                }/caderno/${focoExamId}/editar`,
              },
            ]}
          />

          <ExamForm
            entityId={entityId}
            entityType={entityType}
            focoExamEditionId={focoExamEditionId}
            requestOnSubmit={patchBooklet}
            requestEndpoint={endpoint}
            resetInitialValuesOnSubmit={true}
            {...initialValues}
          />
        </>
      )}
    </Container>
  )
}

const getInitialValues = (data) => {
  return {
    name: data?.name,
    examId: data?.foco_exam_edition.id,
    subjectId: data?.subject.id,
    gradeId: data?.grade.id,
    studentsByClassroom: normalizeStudentsByClassroom(
      data?.classrooms,
      data?.foco_exam_student_profiles
    ),
    initialSelectedQuestions:
      data?.foco_exam_foco_questions &&
      sortQuestionByPosition(data?.foco_exam_foco_questions),
  }
}

const sortQuestionByPosition = sortBy(prop('position'))

const normalizeStudentsByClassroom = (classrooms, studentProfiles) =>
  classrooms.reduce((acc, classroom, index) => {
    const studentId = studentProfiles[index]?.student_profile?.id
    const classroomIds = acc.map(({ id }) => id)

    return classroomIds.includes(classroom.id)
      ? acc.map((classroom) => ({
          ...classroom,
          studentIds: [...classroom.studentIds, studentId],
        }))
      : [
          ...acc,
          {
            ...classroom,
            studentIds: [studentId],
          },
        ]
  }, [])

export default ExamEditForm
