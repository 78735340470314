// @flow
import axios from 'axios'
import { fetchWithRoot, fetchWithoutRoot } from '../helpers/fetch'
import type { Menu, Profile, ProfileClassroom, User } from '~types/Profile'
import type { NetworkError } from '~types/Network'

type ProfilesResponse = {
  profiles: Array<Profile>,
  user: User,
}

type MenusResponse = {
  menu: Menu,
}

export const endpoints = {
  createUserWithProfiles: 'api/v2/users',
  getProfiles: '/api/v2/users/profiles',
  getFeatures: '/api/v2/menus',
  getStudentFeatures: '/api/v3/foco_exam_editions',
  getUser: '/api/v2/user',
  profileData: '/api/v2/users/profile',
  userData: '/api/v2/users/user',
}

const getProfiles = (): Promise<[?NetworkError, ?ProfilesResponse]> =>
  axios
    .get(endpoints.getProfiles)
    .then((response) => [null, response.data])
    .catch((error) => {
      if (error.response?.status === 401) {
        return [{ status: 401, logout: true }, null]
      } else {
        return [{ status: error.response?.status, logout: false }, null]
      }
    })

const getFeatures = (): Promise<[?NetworkError, ?MenusResponse]> =>
  axios
    .get(endpoints.getFeatures)
    .then((response) => [null, response.data])
    .catch((error) => {
      if (error.response?.status === 401) {
        return [{ status: 401, logout: true }, null]
      } else {
        return [{ status: error.response?.status, logout: false }, null]
      }
    })

const getStudentFeatures = () =>
  fetchWithRoot('get')(endpoints.getStudentFeatures)

export type ProfileParams = {|
  function?: string,
  expectations?: string,
  profile_classrooms_attributes?: {
    ...ProfileClassroom,
    _destroy?: boolean,
    ...
  }[],
|}

export type UserParams = {|
  news_email?: string,
  cellphone?: string,
  receive_news?: boolean,
  gender?: string,
  accept_terms_and_conditions?: boolean,
|}

const patchProfile = (
  data: ProfileParams
): Promise<[?NetworkError, ?Profile]> =>
  fetchWithoutRoot('patch')(endpoints.profileData, { data })

const patchUser = (data: UserParams): Promise<[?NetworkError, ?User]> =>
  fetchWithoutRoot('patch')(endpoints.userData, { data })

const getUser = () => fetchWithRoot('get')(endpoints.getUser)

const createUserWithProfiles = (user: Object) =>
  fetchWithRoot('post')(endpoints.createUserWithProfiles, { data: user })

export default {
  createUserWithProfiles,
  getProfiles,
  getFeatures,
  getStudentFeatures,
  getUser,
  endpoints,
  patchProfile,
  patchUser,
}
