//@flow

import { Stack, Typography } from '@mui/material'
import React from 'react'
import Modal from '~primitives/Modal'
import { Scribble } from '~primitives/Typography/Underline'
import SendCheck from '~assets/svg/icon-paper-check.svg'
import SuccessCheck from '~assets/svg/success_check.svg'

type Props = {
  handleConfirm: () => void,
  handleClose: () => void,
  allQuestionsAnswered: () => boolean,
  isOpen: boolean,
  isSuccess: boolean,
}

const ModalSubmit = ({
  handleConfirm,
  handleClose,
  allQuestionsAnswered,
  isOpen,
  isSuccess,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleConfirm}
      closeButton={!isSuccess}
      confirmButtonText={isSuccess ? 'Voltar' : 'Confirmar'}
      closeButtonText="Cancelar"
      minHeight="350px"
      width="550px"
    >
      <Typography variant="h4" component="header">
        <Scribble>Finalizar avaliação</Scribble>
      </Typography>

      <Stack variant="modalBox" sx={{ textAlign: 'center', mt: 2 }}>
        {isSuccess && (
          <>
            <img src={SuccessCheck} alt="imgSend" height={65} />
            <Typography component="h2" sx={{ pt: 1.5 }}>
              <strong>Avaliação enviada</strong>
            </Typography>

            <Typography component="span">
              A avaliação foi enviada com sucesso.
            </Typography>
          </>
        )}

        {!isSuccess && (
          <>
            <img src={SendCheck} alt="imgSend" height={65} />
            <Typography component="span" sx={{ pt: 1.5 }}>
              <strong>Atenção</strong>
            </Typography>

            {!allQuestionsAnswered() && (
              <Typography component="span">
                Não foram respondidas todas as questões.
              </Typography>
            )}

            <Typography component="span">
              Após a finalização não será possível retornar ao caderno.
            </Typography>
            <Typography component="span">
              Deseja finalizar a avaliação?
            </Typography>
          </>
        )}
      </Stack>
    </Modal>
  )
}

export default ModalSubmit
