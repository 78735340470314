// @flow
import React from 'react'
import { Box, Divider } from '@mui/material'
import { QuestionListWrapper } from './List'
import { Header } from './Header'

type Props = {
  selectedQuestionIds: Array<number>,
  setSelectedQuestionIds: (Array<number>) => void,
  handleOpenQuestionDetailsModal: (number) => void,
}

export const QuestionList = ({
  selectedQuestionIds,
  setSelectedQuestionIds,
  handleOpenQuestionDetailsModal,
}: Props) => (
  <Box sx={{ mb: 2, mt: 4 }}>
    <Header amountOfQuestions={selectedQuestionIds?.length} />
    <Divider sx={{ my: 2 }} />
    <QuestionListWrapper
      selectedQuestionIds={selectedQuestionIds}
      setSelectedQuestionIds={setSelectedQuestionIds}
      handleOpenQuestionDetailsModal={handleOpenQuestionDetailsModal}
    />
  </Box>
)
