import { styled } from '@mui/material/styles'

export const TooltipBody = styled('div')`
  background: #fff;
  padding: 0.5em 1.5em;
  position: relative;
  width: 395px;
  font-size: 13px;
  line-height: 25px;
`

export const CloseButton = styled('button')`
  background: transparent;
  color: ${({ theme }) => theme.palette.foco.linkColor};
  border: 0;
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
`

export const TooltipTitle = styled('h3')`
  font-size: 14px;
  font-weight: 700;
  width: 350px;
  padding: 0.8em;
  border-bottom: 1px solid ${({ theme }) => theme.palette.foco.border};
`

export const TooltipContent = styled('div')`
  font-size: 14px;
  padding: 0.8em;
  border-bottom: 1px solid ${({ theme }) => theme.palette.foco.border};
  width: 350px;
`

export const TooltipFooter = styled('div')`
  padding: 0.8em 0;
  display: flex;
  justify-content: space-between;
  width: 350px;
`

export const Navigation = styled('ul')`
  display: flex;
  align-items: center;
  margin-left: 5px;
`

export const NavigationItem = styled('li')`
  margin-right: 8px;
  display: flex;
  align-items: center;
`
export const NavigationButton = styled('button')`
  height: 6px;
  width: 6px;
  padding: 0;
  background: ${({ active, theme }) =>
    active ? theme.palette.foco.linkColor : '#E1E1E1'};
  border-radius: 50%;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer ')};
`
