// @flow
import { createTypes } from 'reduxsauce'

import type { NetworkError } from '~types/Network'

export const types = createTypes(`
  API_GENERIC_ERROR
  API_INVALID_SESSION
`)

export const handleApiError = (error: NetworkError) => ({
  type: types.API_GENERIC_ERROR,
  payload: error,
})
