import Highcharts from 'highcharts'

Highcharts.setOptions({
  lang: {
    decimalPoint: ',',
  },
  plotOptions: {
    line: {
      animation: false,
    },
    series: {
      animation: false,
    },
  },
})
