// @flow
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// This component watches the current pathname and calls Google Analytics
// tracking functions whenever the user navigates to a new path.
// This is necessary because react-router does not implement this by default

type Props = { children: React$Node }

const TrackRouteChange = (props: Props) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.gtag?.('event', 'page_view', {
      page_location: window.location.href,
    })
  }, [pathname])

  return props.children
}

export default TrackRouteChange
