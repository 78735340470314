// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpoint = '/api/v3/profiles/classrooms'

type Params = {
  schoolId?: string,
  gradeId?: string,
}

type Student = {
  name: string,
  id: string,
}

type Classroom = {
  id: string,
  name: string,
  students: Array<Student>,
}

type Classrooms = {
  classrooms: Array<Classroom>,
}

export const useManageBookletFilterClassrooms = (params: Params) => {
  const { data, error, loading } = useResource({
    key: 'useManageBookletFilterClassrooms',
    resource: getManageBookletFilterClassrooms,
    params,
    enabled: !!params?.schoolId && !!params?.gradeId,
  })

  return { loading, error, data }
}

const getManageBookletFilterClassrooms = (
  params: Params
): APIResponse<Classrooms> =>
  fetchWithRoot('get')(endpoint, {
    params: {
      school_id: params?.schoolId,
      grade_id: params?.gradeId,
      include: ['classrooms'],
      fields: [{ classrooms: ['id', 'name'] }],
      q: { year_eq: new Date().getFullYear() },
    },
  })
