// @flow
import React, { useState } from 'react'
import SelectionForm from './SelectionForm'
import { makeFinalPath } from './helpers'
import useResource from '~hooks/useResource'
import { track } from '~resources/ahoy'
import Filters from '~resources/filters'
import type { PersonableKind, FeatureName } from '~types/Profile'

type Props = {|
  feature: FeatureName,
  resource: string,
  examId?: number,
|}

export type Selected = {|
  kind?: PersonableKind,
  groupType?: number,
  districtId?: number,
  sectorId?: number,
  schoolId?: number,
|}

const Selection = ({ examId, feature, resource }: Props) => {
  const [selected, setSelected] = useState<Selected>({
    kind: undefined,
    groupType: undefined,
    districtId: undefined,
    sectorId: undefined,
    schoolId: undefined,
  })

  const { kind, groupType, districtId, sectorId, schoolId } = selected

  const {
    error,
    loading,
    data: filters,
  } = useResource({
    resource: Filters.getSelectionFilters,
    params: { ...selected, feature, resource },
  })

  const handleChange = <T>({ name, value }: { name: string, value: T }) => {
    track(`Selection / Selected ${name}`, {
      feature,
      resource,
      examId,
      value,
      current_selection: selected,
    })

    setSelected((currentSelected) => ({ ...currentSelected, [name]: value }))
  }

  const selectedWithDefaults = {
    ...selected,
    kind: kind || filters?.kinds?.[0]?.id,
    groupType: groupType || filters?.group_types?.[0]?.id,
    districtId: districtId || filters?.district.id,
    sectorId: sectorId || filters?.sectors?.[0]?.id,
    schoolId: schoolId || filters?.schools?.[0]?.id,
  }

  return (
    <SelectionForm
      selected={selectedWithDefaults}
      onChange={handleChange}
      pathname={makeFinalPath(selectedWithDefaults, {
        feature,
        resource,
        examId,
      })}
      error={error}
      filters={filters}
      examId={examId}
      feature={feature}
      resource={resource}
      loading={loading}
    />
  )
}

export default Selection
