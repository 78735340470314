// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpointProfileClassrom = '/api/v3/profiles/grades'

const endpoints = {
  put: (id) => `/api/v3/foco_exam_editions/${id || ''}`,
  post: '/api/v3/foco_exam_editions',
}

type Data = {
  name?: string,
  exam_start?: string,
  exam_end?: string,
  instructions?: string,
  description?: string,
  time_limit?: string,
  active?: boolean,
  publish_date?: string,
  foco_exam_edition_grade_subjects_attributes?: [
    { grade_id: number, subject_id: number },
  ],
  id?: number,
}
type ExamEditionsProps = {
  entity_id: number,
  data: Data,
}

type GradesProfileResponse = {
  grades: {
    id: number,
    name: string,
    subjects: [{ id: number, name: string }],
  }[],
}

export const useProfileClassrooms = () => {
  const { data, loading, error } = useResource({
    key: 'useProfileClassrooms',
    resource: getProfileClassrooms,
  })
  return { data, loading, error }
}

const getProfileClassrooms = (): APIResponse<GradesProfileResponse> =>
  fetchWithRoot('get')(endpointProfileClassrom, {
    params: {
      fields: [
        {
          grades: ['id', 'name', { subjects: ['id', 'name'] }],
        },
      ],
      include: [{ grades: ['subjects'] }],
    },
  })

export const postManageExam = async ({
  data,
  entity_id,
}: ExamEditionsProps) => {
  const [error] = await fetchWithRoot('post')(endpoints.post, {
    data: {
      entity_type: 'school',
      entity_id: entity_id,
      foco_exam_edition: data,
    },
  })

  if (error) {
    throw new Error(error?.message ?? error?.status)
  }
}

export const putManageExam = async ({ data, entity_id }: ExamEditionsProps) => {
  const [error] = await fetchWithRoot('put')(endpoints.put(data?.id), {
    data: {
      entity_type: 'school',
      entity_id: entity_id,
      foco_exam_edition: data,
    },
  })

  if (error) {
    throw new Error(error?.message ?? error?.status)
  }
}
