// @flow
import { createReducer } from 'reduxsauce'

export type AhoyVisit = {
  visit: ?string,
  visitor: ?string,
  visitExpires: ?string,
  visitorExpires: ?string,
}

export const initialState: AhoyVisit = {
  visit: null,
  visitor: null,
  visitExpires: null,
  visitorExpires: null,
}

type UpdateAhoyAction = {
  type: 'UPDATE_AHOY_VISIT',
  payload: AhoyVisit,
}

const updateVisit = (state: AhoyVisit, { payload }: UpdateAhoyAction) => ({
  ...state,
  ...payload,
})

const handlers = { UPDATE_AHOY_VISIT: updateVisit }

export default createReducer(initialState, handlers)
