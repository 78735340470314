export const options = {
  expiresSoon: {
    title: 'Vence em breve',
    color: '#e0400d',
  },
  available: {
    title: 'Disponível',
    color: '#3ca9ab',
  },
  started: {
    title: 'Em andamento',
    color: '#fda534',
  },
  answered: {
    title: 'Realizada',
    color: '#979797',
  },
  unperformed: {
    title: 'Não realizada',
    color: '#078db5',
  },
}

export const convertTimeToHour = (time = '0') => {
  const hour = parseInt(time / 60)
  const minutes = time % 60

  return `${hour > 9 ? hour : `0${hour}`}h${
    minutes > 9 ? minutes : `0${minutes}`
  }`
}
