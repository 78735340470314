// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export type Skill = {
  id: number,
  name: string,
}

export type SkillsResponse = { curriculum_skills: Skill[] }

export const endpoint = '/api/v3/curriculum_skills'

type Params = {
  subjectId?: number,
  gradeId?: number,
}

const useSkills = (params: Params) => {
  const { loading, error, data } = useResource({
    resource: getSkills,
    key: 'useQuestionSkills',
    params,
    enabled: !!params?.subjectId,
  })

  return { loading, error, data }
}

const getSkills = (params: Params): APIResponse<SkillsResponse> =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      include: ['curriculum_skills'],
      fields: [{ curriculum_skills: ['id', 'name', 'description'] }],
      q: {
        foco_questions_id_not_null: true,
        subject_id_eq: params?.subjectId,
        foco_questions_grade_id_eq: params?.gradeId,
      },
    },
  })

export default useSkills
