const SET_HEADER = 'SET_HEADER'

const setHeader = (response) => ({
  type: SET_HEADER,
  payload: response,
})

export const fetchHeader = (params) => (dispatch) => {
  dispatch(setHeader(params))
}
