// @flow
import axios from 'axios'

import { fetchWithRoot } from '~helpers/fetch'
import type { NetworkError } from '~types/Network'

export type LoginParams = {
  email?: string,
  password?: string,
  googleidtoken?: string,
}
export type LoginResponse = {
  'access-token': string,
  'token-type': string,
  client: string,
  expiry: string,
  uid: string,
}
export type LogoutResponse = {}

export const endpoints = {
  login: '/api/v2/login',
  logout: '/api/v2/logout',
  requestPasswordReset: '/api/v2/users/reset_password_token',
  resetPassword: (token: string) => `/api/v2/users/change_password/${token}`,
}

const processResponse = (response) => [null, response.data]

const login = (params: LoginParams): Promise<[?NetworkError, ?LoginResponse]> =>
  axios
    .post(endpoints.login, {}, { headers: params })
    .then(processResponse)
    .catch((error) => {
      const message = error.response?.data
        ? error.response.data.errors[0]?.details
        : 'Erro no sistema, tente novamente.'

      return [{ status: error.response?.status, logout: false, message }, null]
    })

const logout = (): Promise<[?NetworkError, ?LogoutResponse]> =>
  axios
    .delete(endpoints.logout, {})
    .then(processResponse)
    .catch((error) => {
      const message = error.response?.data
        ? error.response?.data.errors[0]
        : 'Erro no sistema, tente novamente.'

      return [{ status: error.response?.status, logout: false, message }, null]
    })

type TokenParams = {
  email?: string,
  identifier?: string,
}

export type ResetResponse = {
  user: { id: number, email: string, name: string },
}

const requestPasswordReset = (
  params: TokenParams
): Promise<[?NetworkError, ?ResetResponse]> =>
  fetchWithRoot('post')(endpoints.requestPasswordReset, {
    headers: { Email: params.email },
    data: { identifier: params.identifier },
  })

type ResetParams = { password: string, confirm: string, token: string }

const resetPassword = (
  params: ResetParams
): Promise<[?NetworkError, ?ResetResponse]> =>
  fetchWithRoot('post')(endpoints.resetPassword(params.token), {
    headers: {
      Password: params.password,
      'Password-confirmation': params.confirm,
    },
  })

export default {
  endpoints,
  login,
  logout,
  requestPasswordReset,
  resetPassword,
}
