//@flow
import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import ClockCircle from '~assets/svg/clock_circle.svg'

type Props = {
  isOpen: boolean,
}

function TimeExpiredModal({ isOpen }: Props) {
  const history = useHistory()

  const handleCloseModal = () => {
    history.push({
      pathname: '/',
    })
  }

  return (
    <Modal
      width="500px"
      height="98vh"
      overflowContent={true}
      contentPadding="25px"
      showFooter={false}
      footerPadding="20px 35px 30px"
      onClose={handleCloseModal}
      isOpen={isOpen}
    >
      <>
        <header>
          <UnderlinedHeading as="h2" size={7}>
            <Typography component="strong" variant="h4">
              Tempo esgotado
            </Typography>
          </UnderlinedHeading>
        </header>

        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{
            bgcolor: 'grey.200',
            px: 2,
            pt: 5,
            pb: 8,
            height: 185,
            mt: 2,
            mb: 3,
            borderRadius: 10,
          }}
        >
          <img src={ClockCircle} alt="" width="90px" />
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            Atenção!
          </Typography>
          <Typography variant="body2">
            O prazo estabelecido para conclusão da avaliação esgotou.
          </Typography>
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-end">
          <Button to="/" variant="primary" component={RouterLink}>
            Minhas provas
          </Button>
        </Stack>
      </>
    </Modal>
  )
}

export default TimeExpiredModal
