//@flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpoint = (id: number) =>
  `/api/v3/foco_exam_student_profiles/${id}`

type Params = {
  id: number,
}

type ExamsStudentsProfiles = {
  foco_answers: {
    correct: boolean | null,
    id: number,
    position: number,
  }[],
}

export const useManageExamsStudentProfiles = (params: Params) => {
  const { data, error, loading } = useResource({
    key: 'useManageExamsStudentProfiles',
    resource: getManageExamsStudentProfiles,
    params,
    enabled: true,
  })

  return { loading, error, data }
}

const getManageExamsStudentProfiles = ({
  id,
}: Params): APIResponse<ExamsStudentsProfiles> =>
  fetchWithRoot('get')(endpoint(id), {
    params: {
      include: ['foco_answers'],
      fields: [
        {
          foco_answers: ['id', 'position', 'correct'],
        },
      ],
    },
  })
