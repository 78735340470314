// @flow
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  Box,
  Button,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
} from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { Field, Formik } from 'formik'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { useTheme } from '@mui/material/styles'
import { GroupGradeSubjectInput } from './GroupGradeSubjectInput'
import { SuccessModal } from './SuccessModal'
import { Form } from '~primitives/Input/Form'
import { keyboardClick } from '~helpers/events'
import QuillInput from '~primitives/Input/Quill'
import { putManageExam, postManageExam } from '~data/manageExams/examCreate'
import { useFeedback } from '~contexts/Feedback'
import { TimePickerField } from '~primitives/Input/TimerPickerField'
import { DesktopDatePickerField } from '~primitives/Input/DesktopDatePickerField'

type Props = {
  grades?: {
    id: number,
    name: string,
    subjects: [{ id: number, name: string }],
  }[],
  dataEdit?: any,
  method: 'post' | 'put',
}

export const ExamEditionForm = ({ grades, dataEdit, method }: Props) => {
  const theme = useTheme()
  const history = useHistory()
  const { entityId } = useParams()
  const requestOnSubmit = {
    put: putManageExam,
    post: postManageExam,
  }

  const { showMessage } = useFeedback()

  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const [createdExamName, setCreatedExamName] = useState('')

  const [gradeSubjectPairs, setGradeSubjectPairs] = useState(
    dataEdit?.grade_subjects
      ? dataEdit?.grade_subjects.map((item) => {
          return {
            id: item.id,
            grade_id: item.grade.id,
            subject_id: item.subject.id,
          }
        })
      : [{ id: '', grade_id: '', subject_id: '' }]
  )

  const initialSelectedGrades = dataEdit?.grade_subjects
    ? dataEdit?.grade_subjects.map((item) => {
        return {
          id: item.id,
          grade_id: item.grade.id,
          subject_id: item.subject.id,
        }
      })
    : []

  const valueDateStart = dataEdit?.exam_start
    ? dayjs(new Date(dataEdit.exam_start))
    : null

  const valueDateEnd = dataEdit?.exam_end
    ? dayjs(new Date(dataEdit.exam_end))
    : null

  const valueDateStartAt = dataEdit?.publish_date
    ? dayjs(new Date(dataEdit.publish_date))
    : null

  const initialValues = {
    id: dataEdit?.id || 0,
    name: dataEdit?.name || '',
    description: dataEdit?.description || '',
    time_limit: dataEdit?.time_limit || '',
    exam_start: valueDateStart,
    exam_end: valueDateEnd,
    publish_date: valueDateStartAt,
    radio_active: dataEdit?.publish_date ? 1 : 0,
    instructions: dataEdit?.instructions || '',
  }

  const getRequestGradeSubjects = (initialSelected, selected) => {
    const updatedValues = initialSelected
      .map(({ id, subject_id, grade_id }) => {
        if (
          selected.find(
            (selected) =>
              selected.grade_id === grade_id &&
              selected.subject_id === subject_id
          )
        ) {
          return null
        }

        return { id, _destroy: true }
      })
      .filter((value) => !!value)

    const newValues = selected.filter(({ grade_id, subject_id }) => {
      if (
        initialSelected.find(
          (initialSelected) =>
            initialSelected.grade_id === grade_id &&
            initialSelected.subject_id === subject_id
        )
      ) {
        return false
      }

      return true
    })

    return [...updatedValues, ...newValues]
  }

  const queryClient = useQueryClient()

  const { mutate } = useMutation(
    ({ entity_id, data }) => requestOnSubmit[method]({ entity_id, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('useManageExamsList')
        queryClient.invalidateQueries('useFocoExamEdition')

        setIsModalSuccessOpen(true)
      },
      onError: (error) => {
        showMessage({ type: 'error', text: error.message })
      },
    }
  )

  const handleFormSubmit = (values, { setSubmitting }) => {
    values['foco_exam_edition_grade_subjects_attributes'] =
      getRequestGradeSubjects(initialSelectedGrades, gradeSubjectPairs)

    if (values.radio_active == 0) {
      values['publish_date'] = null
    }

    mutate({ entity_id: entityId, data: values })
    setCreatedExamName(values.name)
    setSubmitting(false)
  }

  const handleClose = () => {
    history.push({
      pathname: `/gerenciar-avaliacoes/school/${entityId || ''}/`,
    })
  }

  const handleFormValidate = (values) => {
    const errors = {}
    const fields = ['name']

    fields.forEach((field) => {
      if (values[field] === '' || values[field] === false) {
        errors[field] = 'Obrigatório'
      }
    })

    const examStart = values.exam_start?.isValid()
    const examEnd = values.exam_end?.isValid()

    if (!examStart && examEnd) errors.exam_start = 'Informe uma data de início'
    if (examStart && !examEnd) errors.exam_end = 'Informe uma data de fim'

    return errors
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validate={handleFormValidate}
          validateOnMount={true}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            isSubmitting,
            setFieldValue,
            resetForm,
          }) => (
            <Form
              onSubmit={handleSubmit}
              onKeyPress={keyboardClick}
              headingSize="24px"
            >
              <Box
                sx={{
                  padding: theme.spacing(2.2, 6, 14, 6),
                  backgroundColor: theme.palette.grey[230],
                  marginTop: theme.spacing(2.5),
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <Typography variant="h5" mb={2}>
                      Dados da avaliação
                    </Typography>

                    <Divider sx={{ my: 2 }} />

                    <Grid container spacing={0.5}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          id="name"
                          name="name"
                          onChange={handleChange}
                          label="Nome da avaliação"
                          value={values.name}
                          variant="standard"
                          fullWidth
                          required
                          error={Boolean(errors.name)}
                          helperText={errors.name}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          id="description"
                          name="description"
                          onChange={handleChange}
                          label="Descrição"
                          value={values.description}
                          multiline
                          rows={6}
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            marginBottom: '10px',
                            fontSize: '14px',
                          }}
                        >
                          Disciplinas *
                        </Typography>

                        <GroupGradeSubjectInput
                          gradeSubjectPairs={gradeSubjectPairs}
                          grades={grades || []}
                          setGradeSubjectPairs={setGradeSubjectPairs}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <QuillInput
                          id="instructions"
                          label="Instruções"
                          placeholder="Instruções da avaliação"
                          value={values.instructions}
                          onChange={(e) => {
                            setFieldValue('instructions', e)
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      paddingTop: {
                        sm: '100px !important',
                        lg: '24px !important',
                      },
                    }}
                  >
                    <Typography variant="h5" mb={2}>
                      Dados da publicação
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Field
                          component={DesktopDatePickerField}
                          variant="standard"
                          id="start-date"
                          name="exam_start"
                          label="Data de início"
                          minDate={dayjs(new Date())}
                          required
                          fullWidth
                          placeholder="Selecione"
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Field
                          component={TimePickerField}
                          id="start-time"
                          name="exam_start"
                          label="Hora de início"
                          required
                          fullWidth
                          placeholder="Selecione uma hora"
                          ampm={false}
                        />
                      </Grid>
                      <Divider sx={{ mt: 0, mb: 2 }} />
                      <Grid item xs={12} lg={6}>
                        <Field
                          component={DesktopDatePickerField}
                          variant="standard"
                          id="end-date"
                          name="exam_end"
                          label="Data de fim"
                          minDate={dayjs(values.exam_start)}
                          required
                          fullWidth
                          placeholder="Selecione"
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Field
                          component={TimePickerField}
                          id="end-time"
                          name="exam_end"
                          label="Hora de fim"
                          required
                          fullWidth
                          placeholder="Selecione uma hora"
                          ampm={false}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                          marginTop: {
                            lg: '3px',
                          },
                        }}
                      >
                        <Field
                          component={TextField}
                          id="time_limit"
                          name="time_limit"
                          type="number"
                          onChange={handleChange}
                          placeholder="em minutos"
                          label="Duração da avaliação"
                          variant="standard"
                          value={values.time_limit}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 0, mb: 2 }} />

                    <Typography sx={{ fontSize: '14px', fontWeight: '700' }}>
                      Disponibilizar avaliação finalizada para consulta
                    </Typography>

                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <RadioGroup
                          row
                          aria-labelledby="radio_active"
                          name="radio_active"
                          sx={{ paddingTop: '25px;' }}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                value={1}
                                checked={values.radio_active == 1}
                                onChange={handleChange}
                                name="radio_active"
                                color="secondary"
                              />
                            }
                            label="Sim"
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                value={0}
                                checked={values.radio_active == 0}
                                onChange={handleChange}
                                name="radio_active"
                                color="secondary"
                              />
                            }
                            label="Não"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    {values.radio_active == 1 && (
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                          <Field
                            component={DesktopDatePickerField}
                            variant="standard"
                            name="publish_date"
                            label="A partir de"
                            minDate={dayjs(new Date())}
                            required
                            fullWidth
                            placeholder="Selecione"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Field
                            component={TimePickerField}
                            name="publish_date"
                            label="Hora de início"
                            required
                            fullWidth
                            placeholder="Selecione uma hora"
                            ampm={false}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Stack flexDirection="row" justifyContent="flex-end" my={2.5}>
                <Button variant="text" onClick={handleClose} size="small">
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  sx={{ maxWidth: 160, width: 1 }}
                  size="small"
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    gradeSubjectPairs.length === 0
                  }
                >
                  Salvar
                </Button>
              </Stack>

              {isModalSuccessOpen && (
                <SuccessModal
                  examName={createdExamName}
                  isOpen={isModalSuccessOpen}
                  redirect={method === 'put'}
                  setIsOpen={setIsModalSuccessOpen}
                  onConfirm={() => {
                    resetForm()
                    setGradeSubjectPairs([])
                  }}
                />
              )}
            </Form>
          )}
        </Formik>
      </LocalizationProvider>
    </>
  )
}
