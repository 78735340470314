//@flow
export default function FormControlLabel(theme: Object) {
  return {
    MuiFormControlLabel: {
      variants: [
        {
          props: { variant: 'pill' },
          style: {
            marginLeft: 0,
            marginRight: 0,
            padding: 0,

            justifyContent: 'center',
            '& .MuiCheckbox-root': {
              display: 'none',
              '&.Mui-checked + .MuiFormControlLabel-label': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
              },
            },
            '& .MuiFormControlLabel-label': {
              borderRadius: 30,
              backgroundColor: theme.palette.grey[300],
              padding: theme.spacing(0.5),
              textAlign: 'center',
              flex: '1',
            },
          },
        },
      ],
      styleOverrides: {
        root: {},
      },
    },
  }
}
