//@flow

export const getMaterialsData = (
  initialMaterials: Array<{
    id: number,
    curriculum_material_id: number,
  }>,
  selectedMaterialsId: Array<number>
) => {
  const initialMaterialsId = initialMaterials.map(
    ({ curriculum_material_id }) => curriculum_material_id
  )

  const materialsToAdd = selectedMaterialsId
    .filter(
      (selectedMaterialId) => !initialMaterialsId.includes(selectedMaterialId)
    )
    .map((materialId) => ({
      curriculum_material_id: materialId,
    }))

  const materialsToDelete = initialMaterials
    .filter(
      (initialMaterial) =>
        !selectedMaterialsId.includes(initialMaterial.curriculum_material_id)
    )
    .map((material) => ({
      curriculum_material_id: material.curriculum_material_id,
      id: material.id,
      _destroy: true,
    }))

  return [...materialsToAdd, ...materialsToDelete]
}
