// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useFilters from '~hooks/useFilters'
import useResource from '~hooks/useResource'

type Classroom = { id: number, name: string }
type Grade = { id: number, name: string }

export type EventItem = {
  id: number,
  date: string,
  start_time: string,
  end_time: string,
  performed: boolean,
  classroom: Classroom,
  grade: Grade,
  performed_to_plan: boolean,
}

export type EventsResponse = {
  curriculum_events: EventItem[],
}

export const endpoint = '/api/v3/curriculum_events'

type Params = {
  starts: string,
  ends: string,
  grades: Array<number>,
}

const useWeekCurriculumEvents = ({ starts, ends, grades = [] }: Params) => {
  const { params }: any = useFilters()

  const { loading, error, data } = useResource({
    resource: getEvents,
    params: { ...params, starts, ends, grades },
    enabled: !!params,
    key: 'useWeekCurriculumEvents',
  })

  return {
    loading,
    error,
    data,
  }
}

const getEvents = ({ entityId, ...params }): APIResponse<EventsResponse> =>
  fetchWithRoot('get')(endpoint, {
    params: {
      entity_id: entityId,
      entity_type: 'school',
      classroom_id: params.classroom_id,
      grade_id: params.grades,
      q: { date_gteq: params.starts, date_lteq: params.ends },
      fields: [
        {
          curriculum_events: [
            'id',
            'date',
            'start_time',
            'end_time',
            'notes',
            'status',
            'performed',
            'performed_to_plan',
            { classroom: ['id', 'name'] },
            { grade: ['id', 'name', 'short_name'] },
            { subject: ['id', 'name'] },
          ],
        },
      ],
      include: [{ curriculum_events: ['grade', 'subject', 'classroom'] }],
    },
    bypassLoader: true,
  })

export default useWeekCurriculumEvents
