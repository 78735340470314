//@flow
export default function Chip(theme: Object) {
  return {
    MuiChip: {
      variants: [
        {
          props: { variant: 'circle' },
          style: {
            borderRadius: '100%',
            '& span': { padding: 0 },
          },
        },
        {
          props: { variant: 'padded' },
          style: { padding: '0 1em' },
        },
        {
          props: { variant: 'labelInfo' },
          style: {
            borderRadius: theme.shape.borderRadius * 2,
            height: 30,
            backgroundColor: '#29ACAE',
            color: theme.palette.common.white,
          },
        },
        {
          props: { size: 'medium' },
          style: { borderRadius: theme.shape.borderRadius * 2, height: 30 },
        },
        {
          props: { size: 'small' },
          style: { borderRadius: theme.shape.borderRadius * 2, height: 20 },
        },
        {
          props: { color: 'info' },
          style: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.common.white,
          },
        },
        {
          props: { variant: 'labelInfoLight' },
          style: {
            borderRadius: theme.shape.borderRadius * 2,
            height: 20,
            backgroundColor: theme.palette.info.light,
            color: theme.palette.common.white,
          },
        },
        {
          props: { variant: 'labelWarning' },
          style: {
            borderRadius: theme.shape.borderRadius * 2,
            height: 20,
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.common.white,
          },
        },
        {
          props: { variant: 'labelSuccess' },
          style: {
            borderRadius: theme.shape.borderRadius * 2,
            height: 20,
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
          },
        },
        {
          props: { variant: 'labelDefault' },
          style: {
            borderRadius: theme.shape.borderRadius * 2,
            height: 20,
          },
        },
        {
          props: { variant: 'labelInactive' },
          style: {
            borderRadius: theme.shape.borderRadius * 2,
            height: 20,
            backgroundColor: theme.palette.grey[550],
            color: theme.palette.grey[0],
          },
        },
      ],
      styleOverrides: {
        sizeLarge: {
          width: 50,
          height: 50,
          fontSize: 26,
        },
      },
    },
  }
}
