/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow
import { type Location, withRouter } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import React, { useEffect, useState, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import {
  Drawer,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
  Stack,
  Link,
} from '@mui/material'
import { getStyles } from './styles'
import TeacherFeaturesGroup from './FeaturesGroup/Teacher'
import StudentFeaturesGroup from './FeaturesGroup/Student'
import { FeatureLink } from './FeatureLink'
import { track } from '~resources/ahoy'
import usePrevious from '~hooks/usePrevious'
import type { Menu } from '~types/Profile'
import LogoSVG from '~assets/svg/logo.svg'
import LogoIU from '~assets/logo_iu.jpg'

const mapStateToProps = (state) => ({
  features: state.user.features,
})

type Props = {
  features: Menu,
  location: Location,
}

const AsideMenu = ({ features, location }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeCollapse, setActiveCollapse] = useState('')
  const [activeMenu, setActiveMenu] = useState('')
  const isShowingNewHome = useSelector((state) => state.user.showNewHome)
  const isStudent = useSelector((state) => state.user.student)
  const featuresDefaultProps = {
    activeCollapse,
    setActiveCollapse,
    activeMenu,
    setActiveMenu,
  }

  const featureList = useMemo(
    () =>
      isStudent ? (
        <StudentFeaturesGroup {...featuresDefaultProps} />
      ) : (
        <TeacherFeaturesGroup
          features={features}
          isShowingNewHome={isShowingNewHome}
          {...featuresDefaultProps}
        />
      ),
    [featuresDefaultProps, isShowingNewHome, isStudent, features]
  )

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const styles = getStyles(theme)

  const handleOpenMenu = () => {
    setIsOpen((isOpen) => !isOpen)
    track(`Menu / Mobile / click to ${isOpen ? 'open' : 'close'}`)
  }

  const handleCloseMenu = () => {
    setIsOpen(false)
  }

  const handleMouseLeave = () => {
    setActiveMenu('')
    track('Menu / Desktop / mouse leave')
  }

  const prevLocation = usePrevious(location)

  useEffect(() => {
    if (!isEqual(location, prevLocation)) {
      setIsOpen(false)
      document.querySelector('#main-content')?.focus()
    }
  }, [location, prevLocation])

  const drawer = (
    <nav role="navigation" id="main-nav">
      <Stack alignItems="center" sx={{ ...styles.logo }}>
        <Link data-testid="asidemenu-link-home" to="/pagina-inicial">
          <img src={LogoSVG} alt="Logotipo" height="64px" />
        </Link>
      </Stack>

      <Box component="ul" sx={{ mt: 2 }}>
        {!isStudent && (
          <FeatureLink
            iconName="iconHome"
            to="/pagina-inicial"
            text="Página inicial"
            isActive={
              isStudent
                ? location.search === '' &&
                  location.pathname === '/pagina-inicial'
                : location.pathname === '/pagina-inicial'
            }
          />
        )}

        <Box onMouseLeave={handleMouseLeave}>{featureList}</Box>

        <Divider sx={{ mt: 1 }} />

        {features?.foco_bi?.resources.length > 0 && (
          <FeatureLink
            iconName="bi"
            to="/bi"
            text="BI"
            isActive={location.pathname === '/bi'}
          />
        )}
      </Box>
    </nav>
  )

  return (
    <>
      <Box>
        {isMobile && (
          <img
            src={LogoIU}
            onClick={handleOpenMenu}
            alt="logo-iu-mobile"
            style={{ height: '80px', width: 'auto' }}
          />
        )}

        {isMobile ? (
          <Drawer
            data-testid="aside-menu"
            variant="temporary"
            open={isOpen}
            ModalProps={{
              keepMounted: true,
              onBackdropClick: handleCloseMenu,
            }}
            sx={{
              ...styles.menu.container,
            }}
          >
            {drawer}
          </Drawer>
        ) : (
          <Drawer
            data-testid="aside-menu"
            variant="permanent"
            sx={{
              ...styles.menu.container,
            }}
            open
          >
            {drawer}
          </Drawer>
        )}
      </Box>
    </>
  )
}

export default withRouter(connect(mapStateToProps)(AsideMenu))
