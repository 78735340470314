// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

type Params = { gradeId: ?number, skillId: ?number }

export type Question = {
  id: number,
  code: string,
  text: string,
  skill: { id: number, name: string, description: string },
}

export type QuestionsResponse = { questions: Question[] }

export const endpoint = '/api/v3/foco_questions'

const useQuestions = (params: Params) => {
  const { loading, error, data } = useResource({
    resource: getQuestions,
    params,
    key: 'useFocoQuestions',
    enabled: !!params.skillId,
  })

  return { loading, error, data }
}

const getQuestions = ({
  skillId,
  gradeId,
}: Params): APIResponse<QuestionsResponse> =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      include: [{ foco_questions: ['skill'] }],
      fields: [
        {
          foco_questions: [
            'id',
            'code',
            'text',
            { curriculum_skill: ['id', 'name', 'description'] },
          ],
        },
      ],
      q: { curriculum_skill_id_eq: skillId, grade_id_eq: gradeId },
    },
  })

export default useQuestions
