// @flow strict
import React from 'react'

type Props = {
  src: string,
  alt: string,
  groupName?: string,
  height?: string,
  width?: string,
  className?: string,
}

export const SVGImage = ({
  src,
  className,
  groupName = '',
  height = 'auto',
  width = 'auto',
  alt,
}: Props) => {
  return (
    <img
      alt={alt}
      className={className}
      src={`${src}${groupName !== '' ? '#' + groupName : ''}`}
      height={height}
      width={width}
    />
  )
}
