// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { Stack, useTheme } from '@mui/material'
import { getStyles } from './styles'
import { SVGIcon, type IconName } from '~primitives/Utilities/SVGIcon'

type Props = {
  to: string,
  iconName: IconName,
  text: string,
  isActive: boolean,
  onMouseOver?: (SyntheticMouseEvent<HTMLElement>) => void,
}

export const FeatureLink = ({
  to,
  iconName,
  text,
  isActive,
  onMouseOver,
}: Props) => {
  const theme = useTheme()
  const styles = getStyles(theme, isActive)

  return (
    <Stack component="li" sx={{ ...styles.general }} onMouseOver={onMouseOver}>
      <Link to={to}>
        <SVGIcon name={iconName} color={theme.palette.grey[200]} size="20px" />
        {text}
      </Link>
    </Stack>
  )
}
