// @flow
import React, { useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { Typography, Stack, Grid, IconButton, Button, Box } from '@mui/material'
import { useLocation, Link, useParams } from 'react-router-dom'
import qs from 'qs'
import Zoom from '@mui/material/Zoom'
import { SuggestedMaterialsModal } from '../Modals/Materials/SuggestedMaterials'
import { type MaterialData } from '../validate'
import { AddMaterialsModal } from '../Modals/Materials/AddMaterials'
import { getWeeks, sortWeeks } from './helpers'
import { TableLoadingPlaceholder } from './Loading'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import SortTable from '~primitives/molecules/Table/SortTable'
import useCurriculumEvents from '~data/curriculum/events'
import { Placeholder } from '~components/FocoExams/Student/MyExam/Placeholder'
import { PlanEventModal } from '~components/Curriculum/Modals/PlanEvent'
import { ElevationTooltip } from '~components/Common/Tooltips'

type LessonPlanData = {
  week: string,
  weekDays: string,
  curriculumEvents: Array<{
    id: number,
    status: 'unplanned' | 'planned' | 'unregistered' | 'registered',
    endTime: string,
  }>,
}

const cellsHeader = [
  {
    label: 'Semana',
  },
  {
    label: 'Habilidade do currículo',
  },
  {
    label: 'Unidade temática',
  },
  {
    label: 'Aulas realizadas/previstas',
    align: 'center',
  },
  {
    label: '',
    align: 'center',
  },
]

const Events = () => {
  const [isLessonPlanModalOpen, setIsLessonPlanModalOpen] = useState(false)
  const [isSuggestedMaterialsModalOpen, setIsSuggestedMaterialsModalOpen] =
    useState(false)
  const [isAddMaterialsModalOpen, setIsAddMaterialsModalOpen] = useState(false)

  const [selectedLessonPlan, setSelectedLessonPlan] =
    useState<LessonPlanData | null>(null)
  const [materialsData, setMaterialsData] = useState<MaterialData | null>(null)

  const { loading, error, data } = useCurriculumEvents()

  const { entityId, entityType } = useParams()
  const { search } = useLocation()

  const { order: direction, orderBy: property } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })

  const handleOpenLessonPlanModal = (data: LessonPlanData) => {
    setSelectedLessonPlan(data)
    setIsLessonPlanModalOpen(true)
  }

  const handleOpenSuggestedMaterialsModal = ({
    eventIndex,
    skillCode,
    skillId,
    date,
    initialValues,
    setValuesOnConfirm,
  }: MaterialData) => {
    setMaterialsData({
      eventIndex,
      skillCode,
      skillId,
      date,
      initialValues,
      setValuesOnConfirm,
    })
    setIsSuggestedMaterialsModalOpen(true)
  }

  const handleOpenAddMaterialsModal = ({
    eventIndex,
    skillCode,
    date,
  }: MaterialData) => {
    setMaterialsData({
      eventIndex,
      skillCode,
      date,
    })
    setIsAddMaterialsModalOpen(true)
  }

  if (error) return `Erro! ${error.message || ''}`

  const weeks = getWeeks(data?.curriculum_events)

  const placeholder = TableLoadingPlaceholder()
  const rows = weeks
    ? normalizeTableRow(
        sortWeeks({ weeks, order: { direction, property: [property] } }),
        entityType,
        entityId,
        search,
        handleOpenLessonPlanModal
      )
    : null

  if (rows?.length === 0 && !loading) {
    return (
      <Placeholder
        text=" Nenhum planejamento associado a esta turma. Para adicionar clique no
    botão Adicionar plano de aula"
      />
    )
  }

  return (
    <>
      <SortTable
        variant="simple"
        rows={rows ?? placeholder}
        rowsAlign={['left', 'left', 'left', 'center', 'center']}
        cellsHeader={cellsHeader}
        lengthText="Total de semanas"
      />

      {isLessonPlanModalOpen && selectedLessonPlan && entityId && (
        <PlanEventModal
          isOpen={
            isLessonPlanModalOpen &&
            !isSuggestedMaterialsModalOpen &&
            !isAddMaterialsModalOpen
          }
          setIsOpen={setIsLessonPlanModalOpen}
          week={selectedLessonPlan?.week}
          weekDays={selectedLessonPlan?.weekDays}
          entityId={entityId}
          curriculumEvents={selectedLessonPlan?.curriculumEvents}
          handleOpenSuggestedMaterialsModal={handleOpenSuggestedMaterialsModal}
          handleOpenAddMaterialsModal={handleOpenAddMaterialsModal}
        />
      )}

      {isSuggestedMaterialsModalOpen &&
        materialsData &&
        materialsData?.initialValues &&
        materialsData?.setValuesOnConfirm && (
          <SuggestedMaterialsModal
            isOpen={isSuggestedMaterialsModalOpen}
            setIsOpen={setIsSuggestedMaterialsModalOpen}
            closeButtonText="Volta para o Plano de Aulas"
            {...materialsData}
          />
        )}

      {isAddMaterialsModalOpen &&
        materialsData &&
        selectedLessonPlan &&
        materialsData?.eventIndex &&
        selectedLessonPlan?.curriculumEvents?.[materialsData?.eventIndex]
          ?.id && (
          <AddMaterialsModal
            isOpen={isAddMaterialsModalOpen}
            setIsOpen={setIsAddMaterialsModalOpen}
            eventIndex={materialsData.eventIndex}
            curriculumEventId={
              selectedLessonPlan?.curriculumEvents[materialsData.eventIndex]?.id
            }
            skillCode={materialsData.skillCode}
            date={materialsData.date}
          />
        )}
    </>
  )
}

const normalizeTableRow = (
  data,
  entityType,
  entityId,
  search,
  handleOpenLessonPlanModal
) =>
  data
    ? data.map(
        ({
          week,
          weekDays,
          curriculum_skill,
          curriculum_competencies,
          classes,
          performed_classes,
          curriculumEvents,
          day,
          month,
          year,
        }) => ({
          fields: [
            <React.Fragment key="week">
              <Typography component="span" variant="body2">
                <strong>Semana {week}</strong>
                <Typography
                  variant="caption"
                  component="span"
                  sx={{
                    color: 'text.secondary',
                    display: 'block',
                    minWidth: 100,
                  }}
                >
                  ({weekDays})
                </Typography>
              </Typography>
            </React.Fragment>,
            <React.Fragment key="skill">
              <Grid
                container
                direction="row"
                spacing={1}
                sx={{ pr: 12, minWidth: 340 }}
              >
                {curriculum_skill.map(({ name, description }, index) => (
                  <Grid item xs={6} lg={4} key={index}>
                    <ElevationTooltip
                      TransitionComponent={Zoom}
                      title={description}
                      placement="bottom-start"
                    >
                      <Button variant="skill">{name}</Button>
                    </ElevationTooltip>
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>,
            <React.Fragment key="minimum_curriculum_unit">
              <Typography component="span" variant="body2">
                {curriculum_competencies.map(({ name }, index) => {
                  return curriculum_competencies.length - 1 > index ? (
                    <>
                      {name.charAt(0).toUpperCase() +
                        name.slice(1).toLowerCase()}
                      {', '}
                    </>
                  ) : (
                    <>
                      {name.charAt(0).toUpperCase() +
                        name.slice(1).toLowerCase()}
                      {'.'}
                    </>
                  )
                })}
              </Typography>
            </React.Fragment>,
            <React.Fragment key="performed_classes">
              <Box minWidth={140}>
                <Typography component="strong" variant="h4">
                  {performed_classes}
                </Typography>
                <Typography component="span" variant="body2">
                  /{classes}
                </Typography>
              </Box>
            </React.Fragment>,
            <React.Fragment key="actions">
              <Stack direction="row" gap={1}>
                <Tooltip title="Plano de aula" placement="bottom" arrow>
                  <IconButton
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      handleOpenLessonPlanModal({
                        week,
                        weekDays,
                        curriculumEvents,
                      })
                    }
                  >
                    <SVGIcon name="eye" color="linkColor" size={3} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Ver calendário" placement="bottom" arrow>
                  <IconButton
                    to={`/curriculo/${entityType || ''}/${
                      entityId || ''
                    }/calendario/${day}/${month}/${year}/${search}`}
                    component={Link}
                    variant="outlined"
                    color="secondary"
                  >
                    <SVGIcon name="calendarCheck" color="linkColor" size={3} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </React.Fragment>,
          ],
          align: 'justify',
          key: week,
        })
      )
    : null

export default Events
