// @flow
import React from 'react'
import { FormControl, InputLabel, Switch, Stack } from '@mui/material'
import type { FormikField, FormikForm } from '~types/formik'

type Props = {
  field: FormikField,
  form: FormikForm,
  name: string,
  label: string,
  disabled?: boolean,
  required?: boolean,
}

export const SwitchField = ({
  name,
  label,
  disabled,
  required,
  field,
  form,
  ...props
}: Props) => {
  const error = form.errors[field.name]

  return (
    <FormControl fullWidth error={error}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Switch
          id={field.name}
          name={field.name}
          labelId={`${field.name}-label`}
          required={required}
          onChange={field.onChange}
          variant="default"
          checked={field.value}
          color="secondary"
          disabled={disabled}
          {...props}
        />

        <InputLabel
          id={`${field.name}-label`}
          required={required}
          htmlFor={field.name}
        >
          {label}
        </InputLabel>
      </Stack>
    </FormControl>
  )
}
