import './config/initializers'
import './config/interceptors'
import './styles'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { trackVisit } from './resources/ahoy'
import { handleSamlLogin } from './config/initializers/samlLogin'
import Routes from './react/routes'
import store, { persistor } from './redux/store'
import FocoThemeProvider from '~styles/themes/MUI'
import HtmlHead from '~components/Common/HtmlHead'
import Analytics from '~components/Common/Analytics'
import { ApplicationInsightsTracking } from '~contexts/ApplicationInsightsTracking'
import { CustomizationProvider } from '~contexts/Customization'
import { SettingsProvider } from '~contexts/SettingsContext'
import { FeedbackProvider } from '~contexts/Feedback'

const container = document.getElementById('app')
const root = createRoot(container)

const onBeforeLift = () => {
  handleSamlLogin()
  trackVisit()
}

const queryClient = new QueryClient()

root.render(
  <ApplicationInsightsTracking>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
        onBeforeLift={onBeforeLift}
      >
        <SettingsProvider>
          <FocoThemeProvider>
            <QueryClientProvider client={queryClient}>
              <CustomizationProvider>
                <HtmlHead />
                <BrowserRouter>
                  <FeedbackProvider>
                    <Analytics />
                    <Routes />
                  </FeedbackProvider>
                </BrowserRouter>
              </CustomizationProvider>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </FocoThemeProvider>
        </SettingsProvider>
      </PersistGate>
    </Provider>
  </ApplicationInsightsTracking>
)
