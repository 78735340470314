//@flow
import React, { useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { deleteCurriculum } from './fetch'
import Modal from '~primitives/Modal'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import SuccessCheck from '~assets/svg/success_check.svg'
import DeleteCheck from '~assets/svg/delete_check.svg'
import { useFeedback } from '~contexts/Feedback'

type Props = {
  handleCloseModal: () => void,
  baseCurriculumId: string,
  planId: number,
}

const ModalDeleteLessonPlan = ({
  handleCloseModal,
  baseCurriculumId,
  planId,
}: Props) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const queryClient = useQueryClient()
  const { showMessage } = useFeedback()

  const { mutate } = useMutation(
    () => deleteCurriculum({ baseCurriculumId, planId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('useBaseCurriculum')
        setIsSuccess(true)
      },
      onError: (error) => {
        setIsSuccess(false)
        showMessage({ type: 'error', text: error.message })
      },
    }
  )

  return (
    <Modal
      width="600px"
      height="98vh"
      overflowContent={true}
      contentPadding="25px"
      showFooter={false}
      footerPadding="20px 35px 30px"
      onClose={handleCloseModal}
    >
      <Typography variant="h4" margin="15px 0">
        <UnderlinedHeading as="span">
          <strong>Excluir Aula</strong>
        </UnderlinedHeading>
      </Typography>

      <Box>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{ bgcolor: 'grey.200', px: 4, height: 290 }}
        >
          {isSuccess ? (
            <>
              <img src={SuccessCheck} alt="" width="90px" />
              <Typography variant="h5">Aula excluida com sucesso!</Typography>
            </>
          ) : (
            <>
              <img src={DeleteCheck} alt="" width="120px" />
              <Typography variant="h5">
                Tem certeza que deseja excluir esta aula ?
              </Typography>
            </>
          )}
        </Stack>
      </Box>
      <Stack flexDirection="row" justifyContent="flex-end" mt={2.5}>
        {isSuccess ? (
          <Button
            variant="primary"
            onClick={handleCloseModal}
            sx={{ minWidth: 160 }}
          >
            Voltar para plano de aulas
          </Button>
        ) : (
          <>
            <Button variant="neutral" onClick={handleCloseModal}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={mutate} sx={{ minWidth: 160 }}>
              Deletar aula
            </Button>
          </>
        )}
      </Stack>
    </Modal>
  )
}

export default ModalDeleteLessonPlan
