// @flow
import React, { useState } from 'react'
import {
  Stack,
  InputLabel,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material'
import { useCurriculumActivities } from '~data/api/v3/curriculumActivities/query/findMany'

type Props = {
  disabled?: boolean,
  activityIds: number[],
  onChange: (number[]) => void,
  activityDescription: string,
  onChangeDescription: (string) => void,
}

export const Activities = ({
  disabled = false,
  activityIds,
  onChange,
  activityDescription,
  onChangeDescription,
}: Props) => {
  const [otherChecked, setOtherChecked] =
    useState<boolean>(!!activityDescription)

  const { data } = useCurriculumActivities()

  const options = data?.curriculum_activities ?? []

  const handleChange = (target, id) => {
    if (target.checked) {
      onChange([...activityIds, id])
    } else {
      onChange(activityIds.filter((activityId) => activityId !== id))
    }
  }

  const handleChangeOther = ({ target }) => {
    setOtherChecked(target.checked)
    if (!target.checked) onChangeDescription('')
  }

  return (
    <Stack component="ul" spacing={0}>
      <li>
        <InputLabel id="activities-select-label">
          Práticas pedagógicas
        </InputLabel>
      </li>

      {options.map(({ id, name }) => (
        <Stack key={id} component="li" direction="row" verticalAlign="center">
          <FormControlLabel
            control={
              <Checkbox
                name={`activity-${id}`}
                disabled={disabled}
                checked={activityIds.includes(id)}
                onChange={({ target }) => handleChange(target, id)}
                sx={{ padding: '5px 9px' }}
              />
            }
            label={name}
          />
        </Stack>
      ))}

      <Stack component="li">
        <FormControlLabel
          control={
            <Checkbox
              name="activity-other"
              disabled={disabled}
              checked={otherChecked}
              onChange={handleChangeOther}
            />
          }
          label="Outras"
        />

        {otherChecked && (
          <TextField
            disabled={disabled}
            variant="standard"
            type="text"
            placeholder="Digite"
            value={activityDescription}
            onChange={({ target }) => onChangeDescription(target.value)}
            size="small"
          />
        )}
      </Stack>
    </Stack>
  )
}
