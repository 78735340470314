// @flow
import React from 'react'
import { Stack, Typography, Button } from '@mui/material'
import _ from 'lodash'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

type TableHeaderProps = {
  label: string,
  property: string,
  reorder: (string, string) => void,
  sx?: any,
}

const TableHeader = ({ label, property, reorder, sx }: TableHeaderProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: 14,
          pl: 2,
        }}
      >
        {label}
      </Typography>
      <Stack justifyContent="space-between">
        <Button
          data-testid={_.kebabCase(`button-ascend-${property}`)}
          disableRipple
          variant="transparent"
          sx={{
            minWidth: 24,
          }}
          onClick={() => {
            reorder(property, 'asc')
          }}
        >
          <SVGIcon name="closeDropdownBold" color="linkColor" size={'8px'} />
        </Button>
        <Button
          data-testid={_.kebabCase(`button-descend-${property}`)}
          disableRipple
          variant="transparent"
          sx={{
            minWidth: 24,
          }}
          onClick={() => {
            reorder(property, 'desc')
          }}
        >
          <SVGIcon name="openDropdownBold" color="linkColor" size={'8px'} />
        </Button>
      </Stack>
    </Stack>
  )
}

export default TableHeader
