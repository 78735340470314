//@flow

import {
  Badge,
  Box,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import React, { useState } from 'react'
import ModalDelete from '../../../Modals/ExamDelete'
import { ActionButtons } from './ActionButtons'
import { formatDecimalPercent } from '~helpers/formatting/numbers'

type Classroom = {
  id: number,
  name: string,
}

type ExamList = {
  id: number,
  name: string,
  status: string,
  questions_count: number,
  amount_of_student: number,
  participation_rate: number,
  classrooms?: Classroom[],
}

type Props = {
  data: ExamList,
  focoExamEditionId: number,
}

export const CardExam = ({ data, focoExamEditionId }: Props) => {
  const { palette } = useTheme()
  const format = formatDecimalPercent(0)
  const { entityType, entityId } = useParams()
  const [deleteExamId, setDeleteExamId] = useState(undefined)

  const closeModalDelete = () => {
    setDeleteExamId(undefined)
  }

  const basePath = `/gerenciar-avaliacoes/${entityType ?? ''}/${entityId ?? ''}`

  return (
    <>
      <Paper component="article" elevation={0} sx={{ padding: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Stack gap={1} direction="row" alignItems="center">
            <Badge
              variant="index"
              badgeContent={data.name[0]}
              color="secondary"
            />
            <Typography>{data.name}</Typography>
          </Stack>
          <ActionButtons
            status={data.status}
            basePath={basePath}
            focoExamId={data.id}
            focoExamEditionId={Number(focoExamEditionId)}
            onClickDelete={setDeleteExamId}
          />
        </Stack>
        <Divider />
        <Stack direction="column" my={2}>
          <Box>
            Turma:{' '}
            {data.classrooms?.map((item, index) => (
              <React.Fragment key={`classroom-${index}`}>
                {item.name}{' '}
              </React.Fragment>
            ))}
          </Box>
          <Stack direction="row" justifyContent="space-between" gap={1} my={1}>
            <Stack
              bgcolor={palette.grey[230]}
              p={2}
              width="100%"
              alignItems="center"
              justifyContent="center"
              direction="row"
            >
              <Typography variant="body2">
                <strong>Questões:</strong> {data.questions_count}
              </Typography>
            </Stack>
            <Stack
              bgcolor={palette.grey[230]}
              p={2}
              width="100%"
              alignItems="center"
              justifyContent="center"
              direction="row"
            >
              <Typography variant="body2">
                <strong>Alunos:</strong> {data.amount_of_student ?? 0}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" gap={2}>
            <Typography variant="body2">
              <strong>Participação</strong>
            </Typography>
            <LinearProgress
              variant="determinate"
              sx={{
                width: '100%',
                '.MuiLinearProgress-barColorPrimary': {
                  backgroundColor: getColorByPercents(
                    data.participation_rate * 100 || 0
                  ),
                },
              }}
              value={data.participation_rate * 100 || 0}
            />
            <Typography variant="body2">
              {format(data.participation_rate ?? 0)}
            </Typography>
          </Stack>
        </Stack>
      </Paper>

      {deleteExamId && (
        <ModalDelete
          examId={Number(deleteExamId)}
          handleCloseModal={closeModalDelete}
        />
      )}
    </>
  )
}

const getColorByPercents = (value) => (value >= 50 ? '#54BCC0' : '#F30037')
