// @flow
import axios from 'axios'
import { addAuthHeaders, logoutOnError } from '~config/interceptors/auth'

const endpoint = '/api/v3/student_profiles/import'

const loaderlessAxios = axios.create()
loaderlessAxios.interceptors.request.use(addAuthHeaders, (error) =>
  Promise.reject(error)
)
loaderlessAxios.interceptors.response.use((response) => response, logoutOnError)

type Props = {
  file: any,
  entityType: 'school' | 'district' | 'sector',
  entityId: string,
  controller: any,
  onLoading: any,
  onSuccess: any,
  onError: any,
}

export const studentsListImport = ({
  file,
  entityType,
  entityId,
  controller,
  onLoading,
  onSuccess,
  onError,
}: Props) => {
  if (!file) return
  const data = new FormData()
  data.append('file', file)

  axios
    .post(endpoint, data, {
      params: { entity_id: entityId, entity_type: entityType },
      onUploadProgress: (event) => {
        onLoading(event)
      },
      signal: controller.signal,
    })
    .then((response) => {
      if (response?.data?.status === 'success') {
        onSuccess(response)
      } else {
        onError(response?.data)
      }
    })
}
