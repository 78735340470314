//@flow
import React from 'react'
import dayjs from 'dayjs'
import { Grid } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { FormikText } from '~primitives/Input/Text'
import { SelectField } from '~primitives/Input/SelectField'
import { DesktopDatePickerField } from '~primitives/Input/DesktopDatePickerField'

export const Fields = () => {
  const { values, setFieldValue } = useFormikContext()

  const periodSelect = [
    { value: 'bimester', label: 'Bimestre' },
    { value: 'semester', label: 'Semestre' },
  ]

  const handleChangeYear = ({ target }) => {
    setFieldValue('year', target.value)

    const year = Number(target.value)
    setFieldValue('initialDate', values.initialDate.year(year))
    setFieldValue('endDate', values.endDate.year(year))
  }

  const minDate = dayjs().year(values.year).startOf('year')

  const maxDate = dayjs().year(values.year).endOf('year')

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <Grid item xs={12} md={6}>
            <Field
              component={FormikText}
              name="year"
              label="Ano letivo"
              variant="standard"
              size="small"
              type="number"
              required
              fullWidth
              placeholder="Ano letivo"
              onChange={handleChangeYear}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Field
                component={DesktopDatePickerField}
                variant="standard"
                name="initialDate"
                label="Data de início"
                maxDate={values.endDate}
                minDate={minDate}
                required
                fullWidth
                placeholder="Selecione"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                component={DesktopDatePickerField}
                variant="standard"
                name="endDate"
                label="Data fim"
                minDate={values.initialDate}
                maxDate={maxDate}
                required
                fullWidth
                placeholder="Selecione"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Field
                component={FormikText}
                name="expectedTotalDays"
                label="Total de dias letivos"
                variant="standard"
                size="small"
                type="number"
                required
                fullWidth
                placeholder="Selecione"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                component={SelectField}
                variant="standard"
                name="periodType"
                label="Período"
                required
                options={periodSelect}
                placeholder="Selecione"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
