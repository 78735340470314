// @flow
import { Stack, useTheme } from '@mui/material'
import React from 'react'
import { Option } from './Option'
import { getStyles } from './styles'

type Props = {
  selected: string,
  setSelected: (string) => void,
  expiresSoon?: number,
  available?: number,
  started?: number,
  answered?: number,
  unperformed?: number,
}

export const FilterExam = (props: Props) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const optionKeys = [
    'expiresSoon',
    'available',
    'started',
    'answered',
    'unperformed',
  ]

  return (
    <Stack sx={{ ...styles.container }}>
      {optionKeys.map((option, index) => (
        <Option
          key={index}
          selected={props.selected}
          setSelected={props.setSelected}
          name={option}
          value={props[option]}
        />
      ))}
    </Stack>
  )
}
