// @flow
import React from 'react'
import { Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { ActionButton } from '~primitives/molecules/ActionButton'

const STATUS_TO_DISABLE_DELETE = ['in_progress', 'finished', 'inactive']
const STATUS_TO_DISABLE_EDIT = ['finished', 'in_progress']

type Props = {
  id: number,
  name: string,
  basePath: string,
  examsCount: number,
  status: 'created' | 'published' | 'in_progress' | 'finished' | 'inactive',
  name: string,
  handleOpenDeleteModal: ({ name: string, id: number }) => void,
  handleOpenStatusModal: ({ name: string, id: number }) => void,
}

export const ActionButtons = ({
  id,
  name,
  basePath,
  examsCount,
  status,
  handleOpenDeleteModal,
  handleOpenStatusModal,
}: Props) => {
  return (
    <Stack flexWrap="nowrap" direction="row" gap={1}>
      {status === 'in_progress' ? (
        <ActionButton
          tooltipTitle="Acompanhar avaliação"
          iconName="history"
          role="button"
          onClick={() => handleOpenStatusModal({ name, id: Number(id) })}
        />
      ) : (
        <ActionButton
          tooltipTitle="Visualizar"
          iconName="eye"
          component={Link}
          role="button"
          to={`${basePath}/avaliacao/${id}/visualizar`}
        />
      )}
      <ActionButton
        tooltipTitle="Editar"
        iconName="edit"
        component={Link}
        role="button"
        to={`${basePath}/avaliacao/${id}/editar`}
        disabled={STATUS_TO_DISABLE_EDIT.includes(status)}
      />

      <ActionButton
        tooltipTitle="Deletar"
        iconName="delete"
        disabled={
          STATUS_TO_DISABLE_DELETE.includes(status) ||
          (examsCount > 0 && status === 'published')
        }
        onClick={() => {
          handleOpenDeleteModal({ name, id: Number(id) })
        }}
      />
    </Stack>
  )
}
