// @flow
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

export const PrintView = styled('main')`
  @media screen {
    position: relative;
    height: 100vh;
    background: ${({ theme }) => theme.palette.foco.primary};
    padding: 20px;
  }
`

export const PrintPageContainer = styled('section')`
  @media screen {
    height: 100%;
    max-width: 900px;
    padding: 20px;
    background: #fff;
    border: 2px solid ${({ theme }) => theme.palette.foco.border};
    overflow-y: scroll;
  }

  @media print {
    padding: 0;

    * {
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }
  }
`

export const PrintButton = styled(Button)`
  position: fixed;
  right: 80px;
  top: 50px;
  width: 200px;

  @media print {
    display: none;
  }
`

export const BackButton = styled(Button)`
  position: fixed;
  right: 80px;
  top: 120px;
  width: 200px;

  @media print {
    display: none;
  }
`
