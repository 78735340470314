// @flow
export type FormValues = { password: string, confirm: string }

const validate = ({ password, confirm }: FormValues) => {
  if (!password) return { password: 'Digite uma senha' }

  if (password !== confirm)
    return { confirm: 'As senhas informadas não coincidem.' }
  return {}
}
export default validate
