// @flow
import React from 'react'
import { Alert, Box, Container, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import QuestionDetails from '~components/ManageExams/ViewDetails/Exam/QuestionDetails'
import { DownloadPDF } from '~components/ManageExams/ViewDetails/Exam/DownloadPDF'
import Header from '~components/Common/Header'
import { useFocoExamEdition } from '~data/api/focoExamEdition/findOne'
import { Content } from '~components/ManageExams/ViewDetails/ExamEdition'
import { useBookletDetails } from '~data/manageBooklets/bookletDetails'

export const ManagementBookletsViewDetails = () => {
  const { focoExamEditionId, entityType, entityId } = useParams()

  const focoExamId = Number(useParams().focoExamId)

  const {
    data: examData,
    loading: examLoading,
    error: examError,
  } = useBookletDetails({
    id: focoExamId,
    entityType: entityType ?? '',
    entityId: Number(entityId),
  })

  const {
    data: examEditionData,
    loading: examEditionLoading,
    error: examEditionError,
  } = useFocoExamEdition({
    id: String(focoExamEditionId),
    entityId: entityId ?? '',
    entityType: entityType ?? '',
    requestTemplate: 'exam-details',
  })

  return (
    <Container disableGutters maxWidth={false} sx={{ px: { sm: 8, xs: 2 } }}>
      <Header
        title={examData?.name || ''}
        breadcrumbs={[
          {
            text: 'Cadernos',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }`,
          },
          {
            text: 'Gerenciar cadernos',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }`,
          },
          {
            text: 'Visualizar caderno',
            pathname: `/gerenciar-avaliacoes/${entityType ?? ''}/${
              entityId ?? ''
            }/avaliacao/${focoExamEditionId ?? ''}/caderno/${
              focoExamId ?? ''
            }/visualizar`,
          },
        ]}
      />

      {(examEditionLoading || examLoading) && <span>Carregando...</span>}

      {(examEditionError || examError) && (
        <Box mt={5}>
          <Alert variant="filled" severity="error">
            Ocorreu um erro ao carregar os dados da avaliação.
          </Alert>
        </Box>
      )}

      {examEditionData && examData && (
        <>
          <Stack sx={{ maxWidth: 720, width: 1, margin: '0 auto' }} gap={4}>
            <DownloadPDF
              focoExamId={focoExamId}
              examName={examData.name}
              editionName={examEditionData.name}
              gradeName={examData.grade.name}
            />

            <Content
              examStart={examEditionData.exam_start}
              examEnd={examEditionData.exam_end}
              timeLimit={examEditionData.time_limit}
              name="Caderno"
              subjects={[examData.subject]}
              grades={[examData.grade]}
              instructions={examEditionData.instructions}
            />

            {examData.foco_exam_foco_questions.map(
              ({ foco_question, position, id }) => (
                <QuestionDetails
                  position={position}
                  options={foco_question.foco_question_options}
                  curriculumSkill={foco_question.curriculum_skill}
                  command={foco_question.command}
                  reference={foco_question.reference}
                  statement={foco_question.statement}
                  imagePath={foco_question.support_image_path}
                  key={id}
                />
              )
            )}
          </Stack>
        </>
      )}
    </Container>
  )
}

export default ManagementBookletsViewDetails
