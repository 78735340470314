// @flow
import axios from 'axios'
import dayjs from 'dayjs'
import { v1 as generateUUID } from 'uuid'
import { addAuthHeaders } from '~config/interceptors/auth'
import store from '~store'

// eslint-disable-next-line no-console
const captureUnexpectedErrors = console.error

export const ahoyAxios = axios.create()

ahoyAxios.interceptors.request.use(addAuthHeaders, (error) =>
  Promise.reject(error)
)

const validToken = (now, expires, token) => token && now < dayjs(expires)

const getTokens = () => {
  const { visit, visitor, visitExpires, visitorExpires } =
    store.getState().ahoy || {}
  const now = dayjs()

  const visit_token = validToken(now, visitExpires, visit)
    ? visit
    : generateUUID()

  const visitor_token = validToken(now, visitorExpires, visitor)
    ? visitor
    : generateUUID()

  store.dispatch({
    type: 'UPDATE_AHOY_VISIT',
    payload: {
      visit: visit_token,
      visitor: visitor_token,
      visitExpires: dayjs().add(4, 'hours'),
      visitorExpires: dayjs().add(2, 'years'),
    },
  })

  return { visit_token, visitor_token }
}

export const track = (name: string, properties: ?Object = {}) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', name, {
      ...properties,
    })
  }

  return ahoyAxios
    .post('/ahoy/events', {
      ...getTokens(),
      events: [{ name, properties, time: dayjs().toISOString() }],
    })
    .catch(captureUnexpectedErrors)
}

export const trackView = (name: string, properties: ?Object = {}) => {
  const eventName = `View ${name}`

  if (typeof window.gtag === 'function') {
    window.gtag('event', eventName, { ...properties })
  }

  return ahoyAxios
    .post('/ahoy/events', {
      ...getTokens(),
      events: [{ name: eventName, properties, time: dayjs().toISOString() }],
    })
    .catch(captureUnexpectedErrors)
}

export const trackVisit = () =>
  ahoyAxios
    .post('/ahoy/visits', {
      ...getTokens(),
      platform: 'Web',
      landing_page: window.location.href,
      screen_width: window.screen.width,
      screen_height: window.screen.height,
      referrer: document.referrer,
    })
    .catch(captureUnexpectedErrors)
