import { hideVisually } from 'polished'
import { styled } from '@mui/material/styles'

const Label = styled('label')`
  font-size: 14px;
  font-weight: bold;
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  ${({ inline }) => inline && 'margin-right: 0.8em'};
  color: #334449;
  ${({ hide }) => hide && hideVisually()};
  ${({ required, theme }) =>
    required &&
    `:after {
    color: ${theme.danger};
    content: ' *';
  }`}
  margin-bottom: 2px;
  line-height: 20px;
`

export default Label
