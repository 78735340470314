// @flow
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import dayjs from 'dayjs'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Formik } from 'formik'
import {
  useCurriculumCompetencies,
  useCurriculumUnits,
  updateEvent,
} from '../fetch'
import { type MaterialData, type RegisterFormBuffer } from '../../../validate'
import { getMaterialsData } from '../helper'
import { Activities } from '../../PlanEvent/Content/Form/Activities'
import { Header } from './Header'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import type { CurriculumEvent } from '~data/curriculum/event'
import useResponsive from '~hooks/useResponsive'

export type Props = {
  ...CurriculumEvent,
  entityId: number,
  formBuffer: RegisterFormBuffer | null,
  setFormBuffer: (RegisterFormBuffer) => void,
  handleClose: () => void,
  onOpenAddMaterials: (MaterialData) => void,
  onOpenSuggestedMaterials: (MaterialData) => void,
  onOpenPresences: () => void,
}

export const Form = ({
  formBuffer,
  setFormBuffer,
  handleClose,
  onOpenPresences,
  onOpenSuggestedMaterials,
  onOpenAddMaterials,
  ...props
}: Props) => {
  const queryClient = useQueryClient()

  const isDesktop = useResponsive({ query: 'up', key: 'lg' })

  const plannedCurriculumSkill = props.curriculum_events_curriculum_skills.find(
    ({ status }) => status === 'planned'
  )

  const performedCurriculumSkill =
    props.curriculum_events_curriculum_skills.find(
      ({ status }) => status === 'performed'
    )

  const performedCompetency =
    formBuffer?.competencies ??
    performedCurriculumSkill?.curriculum_skill.curriculum_competency.id

  const [currentCompetency, setCurrentCompetency] =
    useState(performedCompetency)

  const { error: errorCompetencies, data: competencies } =
    useCurriculumCompetencies(props?.subject.id)

  const { error: errorCurriculumUnits, data: curriculumUnits } =
    useCurriculumUnits(currentCompetency, props.grade.id)

  const [submitError, setSubmitError] = useState(false)

  const { mutate } = useMutation(
    ({ data, entityId, eventId }) =>
      updateEvent({
        data,
        entityId,
        eventId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('useWeekCurriculumEvents')
        queryClient.invalidateQueries('useCurriculumEvents')
        queryClient.invalidateQueries('useCurriculumEvent')
        handleClose()
      },
      onError: () => {
        setSubmitError('Erro ao enviar ao salvar informações')
      },
    }
  )

  if (errorCompetencies || errorCurriculumUnits)
    return 'Ops! Tivemos um problema com a requisição.'

  const materialsId = props.curriculum_events_curriculum_materials?.map(
    ({ curriculum_material_id }) => curriculum_material_id
  )

  const initialValues = formBuffer ?? {
    performed_curriculum_skill: performedCurriculumSkill?.curriculum_skill.id,
    activity_description: props.activity_description,
    objective: props.objective,
    notes: props.notes,
    performed: props.performed ? 1 : 0,
    performed_to_plan: props.performed_to_plan ? 1 : 0,
    unperformed_justification: props.unperformed_justification,
    unplanned_justification: props.unplanned_justification,
    content_description: props.content_description,
    competencies: currentCompetency,
    materials_id: materialsId ?? [],
    initial_materials: props.curriculum_events_curriculum_materials,
    curriculum_activity_ids: props.curriculum_activities.map(({ id }) => id),
  }

  const formattedDate = dayjs(props.date).format('DD/MM/YYYY')

  const handleFormSubmit = (values, { setSubmitting }) => {
    const skillsAttributes = values.performed_curriculum_skill
      ? [
          {
            id: performedCurriculumSkill?.id,
            curriculum_skill_id: values.performed_curriculum_skill,
            status: 'performed',
          },
        ]
      : []

    setSubmitting(true)

    mutate({
      data: {
        ...values,
        curriculum_events_curriculum_skills_attributes: skillsAttributes,
        curriculum_events_curriculum_materials: getMaterialsData(
          values.initial_materials,
          values.materials_id
        ),
      },
      entityId: props.entityId,
      eventId: props.id,
    })
  }

  const handleChangeMaterialIds = (values) => (materialsId) => {
    setFormBuffer({
      ...values,
      materials_id: materialsId,
    })
  }

  const handleFormValidate = (values) => {
    const errors = {}

    if (values.performed && values.performed_to_plan) return errors

    const requiredFields = {
      perfomed: [],
      unplanned: ['objective', 'activity_description'],
      unperformed: ['unperformed_justification'],
    }

    const validateRequired = (status) =>
      requiredFields[status].forEach((field) => {
        if (!values[field]) {
          errors[field] = 'Obrigatório'
        }
      })

    if (Number(values.performed) === 0) validateRequired('unperformed')

    if (
      Number(values.performed) === 1 &&
      Number(values.performed_to_plan) === 1
    )
      validateRequired('perfomed')

    if (
      Number(values.performed) === 1 &&
      Number(values.performed_to_plan) === 0
    )
      validateRequired('unplanned')

    return errors
  }

  return (
    <React.Fragment>
      <Typography variant="h4" margin="15px 0">
        <UnderlinedHeading as="span">
          <strong>Registro da aula</strong>
        </UnderlinedHeading>
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validate={handleFormValidate}
        validateOnMount={true}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          values,
          errors,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack variant="modalBox" style={{ display: 'block' }}>
              <Header
                classroom={props?.classroom?.name}
                subject={props?.subject?.name}
                date={props.date}
                startTime={props.start_time}
                endTime={props.end_time}
                status={props?.status}
                performed={values?.performed}
                id={props?.id}
                onClickPresences={onOpenPresences}
                values={values}
                setFormBuffer={setFormBuffer}
              />

              <Divider sx={{ my: 1 }} />

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                my={2}
              >
                <Typography variant="body2" fontWeight={700}>
                  Aula realizada *
                </Typography>
                <FormControl sx={{ p: 0 }}>
                  <RadioGroup
                    row
                    aria-labelledby="performed"
                    name="performed"
                    onChange={handleChange}
                    sx={{ pb: 0 }}
                  >
                    <FormControlLabel
                      componentsProps={{ typography: { variant: 'body2' } }}
                      value={1}
                      checked={values.performed == 1}
                      control={<Radio color="secondary" />}
                      label="Sim"
                    />
                    <FormControlLabel
                      componentsProps={{ typography: { variant: 'body2' } }}
                      value={0}
                      checked={values.performed == 0}
                      control={<Radio color="secondary" />}
                      label="Não"
                      sx={{ mr: 0 }}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>

              <Divider sx={{ my: 1 }} />
              {values.performed == 0 && (
                <>
                  <TextField
                    type="text"
                    name="unperformed_justification"
                    defaultValue={values.unperformed_justification}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Justifique a razão pela qual a aula não foi realizada *"
                    variant="standard"
                    fullWidth
                    helperText={errors.unperformed_justification}
                    error={errors.unperformed_justification}
                    multiline
                    rows={4}
                    size="small"
                  />
                  <Divider sx={{ my: 2 }} />
                </>
              )}

              {values.performed == 1 && (
                <React.Fragment>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    my={2}
                  >
                    <Typography variant="body2" fontWeight={700}>
                      O plano de aula proposto foi aplicado? *
                    </Typography>
                    <FormControl sx={{ p: 0 }}>
                      <RadioGroup
                        row
                        aria-labelledby="performed_to_plan"
                        name="performed_to_plan"
                        onChange={handleChange}
                        sx={{ pb: 0 }}
                      >
                        <FormControlLabel
                          componentsProps={{ typography: { variant: 'body2' } }}
                          value={1}
                          checked={values.performed_to_plan == 1}
                          control={<Radio color="secondary" />}
                          label="Sim"
                        />
                        <FormControlLabel
                          componentsProps={{ typography: { variant: 'body2' } }}
                          value={0}
                          checked={values.performed_to_plan == 0}
                          control={<Radio color="secondary" />}
                          label="Não"
                          sx={{ mr: 0 }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>

                  <Divider sx={{ my: 1.5 }} />

                  <Box>
                    <Typography variant="body2" fontWeight={700}>
                      Unidade temática
                    </Typography>
                    <Typography variant="body2">
                      {
                        plannedCurriculumSkill?.curriculum_skill
                          .curriculum_competency.name
                      }
                    </Typography>
                  </Box>

                  <Box sx={{ my: 2 }}>
                    <Typography variant="body2" fontWeight={700}>
                      Habilidade
                    </Typography>
                    <Typography variant="body2">
                      <strong>
                        ({plannedCurriculumSkill?.curriculum_skill.name})
                      </strong>{' '}
                      {plannedCurriculumSkill?.curriculum_skill.description}
                    </Typography>
                  </Box>

                  {values.performed_to_plan == 0 && (
                    <React.Fragment>
                      <Stack justifyContent="space-between" gap={1}>
                        <FormControl fullWidth>
                          <InputLabel>Unidade temática</InputLabel>
                          <Select
                            name="competencies"
                            value={values.competencies}
                            defaultValue={
                              values?.competencies ?? currentCompetency
                            }
                            disabled={!competencies}
                            onChange={(event) => {
                              setCurrentCompetency(event.target.value)
                              handleChange(event)
                            }}
                            onBlur={handleBlur}
                            variant="standard"
                            MenuProps={{
                              style: { zIndex: 9999999 },
                            }}
                            native={!isDesktop}
                            size="small"
                          >
                            {competencies?.curriculum_competencies.map(
                              (competency) =>
                                isDesktop ? (
                                  <MenuItem
                                    value={competency.id}
                                    key={competency.id}
                                  >
                                    {competency.description}
                                  </MenuItem>
                                ) : (
                                  <option
                                    value={competency.id}
                                    key={competency.id}
                                  >
                                    {competency.description}
                                  </option>
                                )
                            )}
                          </Select>
                        </FormControl>

                        <FormControl fullWidth>
                          <InputLabel>Habilidade</InputLabel>
                          <Select
                            name="performed_curriculum_skill"
                            value={values.performed_curriculum_skill}
                            defaultValue={values.performed_curriculum_skill}
                            disabled={
                              !curriculumUnits?.minimum_curriculum_units
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="standard"
                            MenuProps={{
                              style: { zIndex: 9999999 },
                            }}
                            native={!isDesktop}
                            size="small"
                          >
                            {curriculumUnits?.minimum_curriculum_units.map(
                              (unity) =>
                                isDesktop ? (
                                  <MenuItem value={unity.id} key={unity.id}>
                                    <Typography
                                      variant="body2"
                                      my={1}
                                      overflow="hidden"
                                    >
                                      <strong>
                                        ({unity.curriculum_skill.name}){' '}
                                      </strong>
                                      {unity.curriculum_skill.description}
                                    </Typography>
                                  </MenuItem>
                                ) : (
                                  <option value={unity.id} key={unity.id}>
                                    ({unity.curriculum_skill.name}){' '}
                                    {unity.curriculum_skill.description}
                                  </option>
                                )
                            )}
                          </Select>
                        </FormControl>
                      </Stack>
                    </React.Fragment>
                  )}

                  <Divider sx={{ my: 1.5 }} />

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body2" fontWeight={700}>
                      Conteúdo ({materialsId?.length ?? 0})
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      gap={2.5}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        disabled={status === 'registered'}
                        onClick={() => {
                          onOpenAddMaterials({
                            eventIndex: 1,
                            skillCode:
                              plannedCurriculumSkill?.curriculum_skill.name,
                            date: props.date,
                          })
                        }}
                      >
                        Adicionar materiais
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        disabled={status === 'registered'}
                        onClick={() => {
                          setFormBuffer({ ...values })
                          onOpenSuggestedMaterials({
                            skillId:
                              plannedCurriculumSkill?.curriculum_skill.id,
                            skillCode:
                              plannedCurriculumSkill?.curriculum_skill.name,
                            date: formattedDate,
                            initialValues: values.materials_id,
                            setValuesOnConfirm: handleChangeMaterialIds(values),
                          })
                        }}
                      >
                        Materiais sugeridos
                      </Button>
                    </Stack>
                  </Stack>

                  <Divider sx={{ my: 1.5 }} />

                  {values.performed_to_plan == 0 ? (
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        name="objective"
                        defaultValue={values.objective}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Objetivos"
                        variant="standard"
                        fullWidth
                        multiline
                        rows={4}
                        size="small"
                      />
                    </FormControl>
                  ) : (
                    <React.Fragment>
                      <Box>
                        <Typography variant="body2" fontWeight={700}>
                          Objetivos
                        </Typography>
                        <Typography variant="body2">
                          {props?.objective ?? '--'}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  )}

                  <Activities
                    activityIds={values.curriculum_activity_ids}
                    onChange={(value) =>
                      setFieldValue('curriculum_activity_ids', value)
                    }
                    activityDescription={values.activity_description}
                    onChangeDescription={(value) =>
                      setFieldValue('activity_description', value)
                    }
                  />

                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      name="notes"
                      defaultValue={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Comentários/Ocorrências"
                      variant="standard"
                      fullWidth
                      size="small"
                    />
                  </FormControl>
                </React.Fragment>
              )}

              {submitError && (
                <Typography variant="body2" sx={{ color: 'error.main' }}>
                  {submitError}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" justifyContent="flex-end" my={2}>
              <Button variant="neutral" size="small" onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="small"
                disabled={isSubmitting || Object.keys(errors).length > 0}
              >
                Enviar
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </React.Fragment>
  )
}
