//@flow
import React, { type Node, createContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Notifications from '~components/Notifications'
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { PaginatedAPIResponse } from '~types/Network'
import type { FeatureName, ExamType } from '~types/Profile'

type Props = {
  children?: Node,
}
type NotificationsData = {
  notifications: ?Array<Object>,
  updateNotificationStatus: (notification: number) => void,
  updateAllNotificationsStatus: () => void,
  popoverIsOpen: any,
  setPopoverIsOpen: (event: null | HTMLButtonElement) => void,
}
type NotificationsResponse = {
  data: { notifications: Array<Notification> },
}
export type Notification = {
  id: number,
  message: string,
  url: string,
  district_id: number,
  created_at: string,
  updated_at: string,
  new_tab: boolean,
  exam_info: ?{
    feature: FeatureName,
    resource: string,
    exam_result_id: number,
    exam_type: ExamType,
  },
}

export const endpoint = '/api/v3/notifications'

const getNotifications = (
  params: any
): PaginatedAPIResponse<NotificationsResponse> =>
  fetchWithRoot('get')(endpoint, {
    params: {
      ...params,
    },
    bypassLoader: true,
  })

export const NotificationsContext = createContext<NotificationsData>({
  notifications: null,
  updateNotificationStatus: () => {},
  updateAllNotificationsStatus: () => {},
  popoverIsOpen: null,
  setPopoverIsOpen: () => {},
})

export const NotificationsProvider = ({ children }: Props) => {
  const user = useSelector((state) => state.user)
  const profile = user.profiles.find(({ id }) => id === user.selectedProfile)
  const isStudent = user.student
  const [notifications, setNotifications] = useState([])
  const [popoverIsOpen, setPopoverIsOpen] = useState(null)

  const { loading, data } = useResource({
    key: 'getNotifications',
    resource: getNotifications,
    enabled: !isStudent,
    params: {
      include: ['notifications'],
      q: {
        notifications_districts_district_id_eq: profile.personable?.district_id,
        s: 'created_at desc',
      },
    },
  })

  useEffect(() => {
    if (!loading && data) {
      setNotifications(data.notifications)
    }
  }, [loading, data])

  const updateAllNotificationsStatus = () => {
    notifications
      .filter((notification) => !notification.read)
      .map(({ id }) => {
        fetchWithRoot('put')(`${endpoint}/${id}`, {
          data: { user_ids: user.id },
          bypassLoader: true,
        })
      })

    const mapped = notifications.map((notification) => {
      return !notification.read
        ? { ...notification, read: true }
        : { ...notification }
    })
    setNotifications(mapped)
  }

  const updateNotificationStatus = (id) => {
    fetchWithRoot('put')(`${endpoint}/${id}`, {
      data: { user_ids: user.id },
      bypassLoader: true,
    })

    const mapped = notifications.map((notification) => {
      return notification.id === id
        ? { ...notification, read: true }
        : { ...notification }
    })
    setNotifications(mapped)
  }

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        updateNotificationStatus,
        updateAllNotificationsStatus,
        popoverIsOpen,
        setPopoverIsOpen,
      }}
    >
      <Notifications />
      {children}
    </NotificationsContext.Provider>
  )
}

export const NotificationsConsumer = NotificationsContext.Consumer

export default NotificationsContext
