// @flow
import React from 'react'
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import ExamIcon from '~assets/svg/icon_exam_name.svg'

type QuestionOption = {
  alternative: string,
  correct: boolean,
  id: number,
  text: string,
}
type Props = {
  position: number,
  options: QuestionOption[],
  curriculumSkill: {
    code: string,
    description: string,
    id: number,
    name: 'string',
  },
  command: null | string,
  reference: null | string,
  statement: null | string,
  imagePath: null | string,
}
const IMG_STYLES = {
  width: '100%',
  height: 'auto',
}

const AVATAR_STYLES = {
  bgcolor: 'primary.main',
  width: 28,
  height: 28,
  fontSize: 14,
  fontWeight: 700,
}

export default function QuestionDetails({
  position,
  options,
  curriculumSkill,
  command,
  reference,
  statement,
  imagePath,
}: Props) {
  const correctOption = options.find(({ correct }) => correct)

  return (
    <Stack variant="modalBox" component="article">
      <Box sx={{ width: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <img src={ExamIcon} alt="Ícone de exames" />
            <Typography variant="h4">Questão {position}</Typography>
          </Stack>
          <Typography variant="body2">
            Habilidade: <strong>{curriculumSkill.name}</strong>
          </Typography>
        </Stack>

        <Divider sx={{ my: 2 }} />

        <Stack spacing={2}>
          {statement && <Typography variant="body2">{statement}</Typography>}
          {imagePath && (
            <img
              src={`${ENV_VARS.FOCO_API_HOST}/${imagePath}`}
              alt="Imagem representativa da questão"
              style={IMG_STYLES}
            />
          )}
          {reference && <Typography variant="body2">{reference}</Typography>}
          {command && <Typography variant="body2">{command}</Typography>}

          {options.map(({ id, alternative, text }) => {
            return (
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                key={id}
              >
                <Avatar
                  sx={{
                    ...AVATAR_STYLES,
                  }}
                >
                  {alternative}
                </Avatar>
                <Typography variant="body2">{text}</Typography>
              </Stack>
            )
          })}
        </Stack>

        <Divider sx={{ my: 2 }} />

        {correctOption && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              Resposta correta
            </Typography>
            <Avatar
              sx={{
                ...AVATAR_STYLES,
              }}
            >
              {correctOption.alternative}
            </Avatar>
          </Stack>
        )}
      </Box>
    </Stack>
  )
}
