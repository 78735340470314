// @flow
import React from 'react'
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { times } from 'ramda'
import useQuestions from '~data/questions/questions'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const LIST_STYLES = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  height: 44,
  px: 2,
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
}

type Props = {
  gradeId: ?number,
  skillId: ?number,
  selectedQuestionIds: Array<number>,
  setSelectedQuestionIds: (Array<number>) => void,
  handleOpenQuestionDetailsModal: (number) => void,
}

const Questions = ({
  gradeId,
  skillId,
  selectedQuestionIds,
  setSelectedQuestionIds,
  handleOpenQuestionDetailsModal,
}: Props) => {
  const { error, data } = useQuestions({
    gradeId: gradeId ? gradeId : null,
    skillId,
  })

  const { palette } = useTheme()

  const handleToggleQuestion = (id) => {
    const isActive = selectedQuestionIds.includes(id)
    if (isActive) {
      setSelectedQuestionIds(
        selectedQuestionIds.filter((question) => question !== id)
      )
    } else {
      setSelectedQuestionIds([...selectedQuestionIds, id])
    }
  }

  if (!data && !error) return <Placeholder amount={6} />

  if (error) return 'Erro'

  if (!data) return null

  return (
    <section>
      <List>
        {data?.foco_questions?.length === 0 && (
          <Typography>
            Nenhuma questão disponível para essa série e habilidade
          </Typography>
        )}
        {data?.foco_questions?.length > 0 &&
          data?.foco_questions.map(({ id, code, text }) => (
            <React.Fragment key={id}>
              <ListItem data-testid={`question-item-${id}`} sx={{ px: 0 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Stack width="10%">
                    <Checkbox
                      edge="end"
                      onChange={() => handleToggleQuestion(id)}
                      checked={selectedQuestionIds.includes(id)}
                      color="secondary"
                      inputProps={{
                        'aria-labelledby': `label-${id}`,
                        'data-testid': `question-check-${id}`,
                      }}
                    />
                  </Stack>
                  <ListItemText align="center" sx={{ width: '10%' }}>
                    <Typography variant="body2" component="span">
                      {code}
                    </Typography>
                  </ListItemText>
                  <ListItemText sx={{ width: '70%' }}>
                    <Stack sx={{ ...LIST_STYLES }}>
                      <Typography variant="body2">{text}</Typography>
                    </Stack>
                  </ListItemText>
                  <IconButton
                    variant="outlinedSecondary"
                    data-testid="button-action-edit"
                    key={id}
                    onClick={() => handleOpenQuestionDetailsModal(id)}
                  >
                    <SVGIcon
                      name="eye"
                      color={palette.secondary.main}
                      size={3}
                    />
                  </IconButton>
                </Stack>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
      </List>
    </section>
  )
}

const Placeholder = ({ amount }: { amount: number }) => (
  <List>
    {times(
      () => (
        <React.Fragment>
          <ListItem>
            <ListItemText>
              <Stack direction="row" gap={2} alignItems="center" height={44}>
                <Box width="80px">
                  <Skeleton variant="text" height={16} />
                </Box>
                <Box width={1}>
                  <Skeleton variant="text" height={16} />
                  <Skeleton variant="text" height={16} width="70%" />
                </Box>
                <Box>
                  <Skeleton variant="circular" width={34} height={34} />
                </Box>
              </Stack>
            </ListItemText>
          </ListItem>
          <Divider />
        </React.Fragment>
      ),
      amount
    )}
  </List>
)

export default Questions
