//@flow

import React from 'react'
import { Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { ActionButton } from '~primitives/molecules/ActionButton'

type Props = {
  focoExamEditionId: number,
  focoExamId: number,
  status: string,
  basePath: string,
  onClickDelete: (number) => void,
}

export const ActionButtons = ({
  focoExamEditionId,
  focoExamId,
  status,
  basePath,
  onClickDelete,
}: Props) => {
  const STATUS_TO_DISABLE_DELETE = ['in_progress', 'finished', 'inactive']
  const STATUS_TO_DISABLE_EDIT = ['finished', 'in_progress']

  return (
    <Stack flexWrap="nowrap" direction="row" gap={1}>
      <ActionButton
        tooltipTitle="Visualizar"
        iconName="eye"
        component={Link}
        role="button"
        to={`${basePath}/avaliacao/${focoExamEditionId}/caderno/${focoExamId}/visualizar`}
      />

      <ActionButton
        tooltipTitle="Editar"
        iconName="edit"
        component={Link}
        role="button"
        to={`${basePath}/avaliacao/${focoExamEditionId}/caderno/${focoExamId}/editar`}
        disabled={STATUS_TO_DISABLE_EDIT.includes(status)}
      />

      <ActionButton
        tooltipTitle="Deletar"
        iconName="delete"
        disabled={STATUS_TO_DISABLE_DELETE.includes(status)}
        onClick={() => onClickDelete(focoExamId)}
      />
    </Stack>
  )
}
