// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

const endpoint = 'api/v3/profiles/grades'

type Grade = {
  id: number,
  name: string,
}

type GradesResponse = {
  academic_years: Array<Grade>,
}

export const useGradesByProfile = () => {
  const { error, data, loading } = useResource({
    resource: getGradesByProfile,
    params: {},
    key: 'useGradesByProfile',
  })

  return { error, data, loading }
}

const getGradesByProfile = (): APIResponse<GradesResponse> =>
  fetchWithRoot('get')(endpoint, {
    params: {
      include: ['grades'],
      fields: ['id', 'name'],
    },
  })
