// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

const getEndpoint = (schoolId: string) =>
  `api/v3/schools/${schoolId}/base_curriculums/district_curriculums`

type Params = {
  schoolId: number,
  gradeId: number | null,
  curriculumPeriodId: number | null,
}

type Subject = {
  id: number,
  name: string,
}

type BaseCurriculum = {
  id: number,
  subject: Subject,
}

type BaseCurriculumsResponse = {
  base_curriculums: Array<BaseCurriculum>,
}

export const useBaseCurriculumsByDistrict = ({
  schoolId,
  gradeId,
  curriculumPeriodId,
}: Params) => {
  const { data, error, loading } = useResource({
    key: 'useBaseCurriculumsByDistrict',
    params: { schoolId, gradeId, curriculumPeriodId },
    enabled: !!gradeId && !!curriculumPeriodId,
    resource: getBaseCurriculumsByDistrict,
  })

  return { data, error, loading }
}

const getBaseCurriculumsByDistrict = ({
  schoolId,
  gradeId,
  curriculumPeriodId,
}: Params): APIResponse<BaseCurriculumsResponse> =>
  fetchWithRoot('get')(getEndpoint(String(schoolId)), {
    params: {
      curriculum_period_id: curriculumPeriodId,
      grade_id: gradeId,
      include: [{ base_curriculums: ['subject'] }],
      fields: ['id', { subject: ['id', 'name'] }],
    },
  })
