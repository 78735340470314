import { styled } from '@mui/material/styles'

const getBorderColor = ({ theme, error, hasIcon, bgColor, borderColor }) => {
  if (error && !hasIcon) return theme.palette.foco.danger
  if (borderColor) return theme.palette.foco[borderColor]
  return theme.palette.foco[bgColor]
}

export const Wrapper = styled('span')`
  display: flex;
  flex-direction: row;
  border: 1px solid ${getBorderColor};
  border-radius: 8px;

  figure {
    flex: 0 0 50px;
    height: 50px;
    width: 50px;
    border-radius: 8px 0 0 8px;
    transition: all 200ms;
    background-color: ${({ theme, error }) =>
      error ? theme.palette.foco.danger : theme.palette.foco.primaryMedium};
    float: left;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    flex: 1;
    height: 50px;
    display: block;
    justify-content: center;
    border: 0;
    background-color: ${({ theme, bgColor }) => theme.palette.foco[bgColor]};
    outline: none;
    border-radius: ${({ hasIcon }) => (hasIcon ? '0 8px 8px 0' : '8px')};
    padding: 0 0 0 15px;
    appearance: none;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
  }
`
