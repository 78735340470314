// @flow
import React from 'react'
import { Alert } from '@mui/material'
import { type MaterialData, type RegisterFormBuffer } from '../../validate'
import { Form } from './Form'
import { Placeholder } from './Placeholder'
import { useCurriculumEvent } from '~data/curriculum/event'
import Modal from '~primitives/Modal'

export type Props = {
  eventId: number,
  isOpen: boolean,
  entityId: string,
  formBuffer: RegisterFormBuffer | null,
  setFormBuffer: (RegisterFormBuffer) => void,
  onClose: () => void,
  onOpenPresences: () => void,
  onOpenSuggestedMaterials: (MaterialData) => void,
  onOpenAddMaterials: (MaterialData) => void,
}

export const RegisterEvent = ({
  eventId,
  isOpen,
  entityId,
  formBuffer,
  setFormBuffer,
  onClose,
  onOpenSuggestedMaterials,
  onOpenPresences,
  onOpenAddMaterials,
}: Props) => {
  const { loading, error, data } = useCurriculumEvent(eventId)

  return (
    <Modal
      width="700px"
      height="98vh"
      closeOnDismiss={false}
      isOpen={isOpen}
      confirmButtonText="Salvar"
      contentPadding="0 35px"
      showFooter={false}
      footerPadding="0 0 20px"
      onClose={onClose}
    >
      {loading || !data ? (
        <Placeholder />
      ) : error ? (
        <Alert variant="outlined">{error}</Alert>
      ) : (
        <Form
          {...data}
          entityId={entityId}
          formBuffer={formBuffer}
          setFormBuffer={setFormBuffer}
          handleClose={onClose}
          onOpenSuggestedMaterials={onOpenSuggestedMaterials}
          onOpenPresences={onOpenPresences}
          onOpenAddMaterials={onOpenAddMaterials}
        />
      )}
    </Modal>
  )
}
