// @flow
import { type Location, type RouterHistory, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import {
  PrintView,
  PrintPageContainer,
  PrintButton,
  BackButton,
} from './styles'
import { fetchFeatures, fetchProfiles } from '~actions/User'
import Loader from '~components/Common/Loader'
import PrintRoutes from '~routes/Print'
import usePrevious from '~hooks/usePrevious'

type Props = { history: RouterHistory, location: Location }

const PrintTemplate = ({ history, location }: Props) => {
  const user = useSelector((state) => state.user)
  const loader = useSelector((state) => state.loader)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user.profiles.length) {
      dispatch(fetchProfiles())
    }
  }, [dispatch, user.profiles.length])

  const prevSelectedProfile = usePrevious(user.selectedProfile)
  useEffect(() => {
    if (!user.selectedProfile) {
      dispatch({
        type: 'SET_LOGIN_REDIRECT',
        payload: { pathname: location.pathname, search: location.search },
      })

      history.push('/login')
    } else if (user.selectedProfile !== prevSelectedProfile) {
      dispatch(fetchFeatures())
    }
  }, [
    dispatch,
    history,
    location.pathname,
    location.search,
    prevSelectedProfile,
    user.selectedProfile,
  ])

  if (!user.profiles.length || !user.selectedProfile) {
    return (
      <section>
        <Loader />
      </section>
    )
  }

  return (
    <PrintView>
      {loader.fetching && <Loader />}

      <PrintButton
        variant="primary"
        data-testid="start-print"
        onClick={window.print}
      >
        Imprimir Página
      </PrintButton>

      <BackButton
        variant="primary"
        size="small"
        data-testid="back"
        onClick={() => history.goBack()}
      >
        Voltar
      </BackButton>

      <PrintPageContainer>
        <PrintRoutes />
      </PrintPageContainer>
    </PrintView>
  )
}

export default withRouter(PrintTemplate)
