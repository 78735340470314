// @flow
import React from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material'
import type { FormikField, FormikForm } from '~types/formik'

type Props = {
  field: FormikField,
  form: FormikForm,
  name: string,
  label: string,
  disabled?: boolean,
  required?: boolean,
  placeholder?: string,
  variant?: string,
  options?: Array<{ value: number | string, label: string }>,
}

export const SelectField = ({
  name,
  label,
  disabled,
  required,
  options,
  field,
  form,
  placeholder,
  variant = 'standard',
  ...props
}: Props) => {
  const error = form.touched[field.name] && form.errors[field.name]

  return (
    <FormControl fullWidth error={error} variant={variant}>
      <InputLabel
        id={`${field.name}-label`}
        required={required}
        htmlFor={`select-${field.name}`}
        variant={variant}
      >
        {label}
      </InputLabel>

      <Select
        labelId={`${field.name}-label`}
        required={required}
        id={`select-${field.name}`}
        data-testid={`select-${field.name}`}
        name={field.name}
        label={`${name}-label`}
        value={field.value ?? 'mui-placeholder-value'}
        variant={variant}
        size="small"
        disabled={disabled}
        onChange={field.onChange}
        MenuProps={{ style: { zIndex: 9999999 } }}
        renderValue={() => {
          if (!field.value) {
            return (
              <Typography color="text.disabled" variant="body2">
                {placeholder}
              </Typography>
            )
          }

          return options?.find(({ value }) => value === field.value)?.label
        }}
        {...props}
      >
        {options?.map(({ value, label }) => (
          <MenuItem key={String(value)} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>

      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </FormControl>
  )
}
