//@flow
import { type State, type Action, type CurriculumEvent } from '../../validate'

export const getTabs = (state: State): CurriculumEvent[] =>
  state.map(({ id, status, endTime }) => ({
    id,
    status,
    endTime,
  }))

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_VALUES':
      return state.map((curriculumEvent) =>
        curriculumEvent.id === action.id
          ? { ...curriculumEvent, ...action.values }
          : curriculumEvent
      )
    default:
      return state
  }
}

export const getInitialValues = (curriculumEvents: Array<Object>): State =>
  curriculumEvents.map((event) => ({
    ...event,
    activityDescription: undefined,
    isFormBuffed: false,
  }))

export const getConfirmButtonText = (
  isSuccess: boolean,
  isEventSuccess: boolean,
  isSubmitting: boolean
) => {
  if (isSubmitting) return 'Salvando'

  if (isSuccess) return 'Continuar editando'

  if (isEventSuccess) return 'Voltar para o currículo'

  return 'Salvar'
}

export const getMaterialsData = (
  initialMaterials: Array<{
    id: number,
    curriculum_material_id: number,
  }>,
  selectedMaterialsId: Array<number>
) => {
  const initialMaterialsId = initialMaterials.map(
    ({ curriculum_material_id }) => curriculum_material_id
  )

  const materialsToAdd = selectedMaterialsId
    .filter(
      (selectedMaterialId) => !initialMaterialsId.includes(selectedMaterialId)
    )
    .map((materialId) => ({
      curriculum_material_id: materialId,
    }))

  const materialsToDelete = initialMaterials
    .filter(
      (initialMaterial) =>
        !selectedMaterialsId.includes(initialMaterial.curriculum_material_id)
    )
    .map((material) => ({
      curriculum_material_id: material.curriculum_material_id,
      id: material.id,
      _destroy: true,
    }))

  return [...materialsToAdd, ...materialsToDelete]
}
