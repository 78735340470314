// @flow
import dayjs from 'dayjs'
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { getStyles } from './styles'
import ExamIcon from '~assets/svg/icon_exam_name.svg'

type Subject = {
  name: string,
  id: number,
}

type Grade = {
  name: string,
  id: number,
}

type Props = {
  examStart: string,
  examEnd: string,
  timeLimit: string,
  name: string,
  subjects: Array<Subject>,
  grades: Array<Grade>,
  instructions: string,
}

export const Content = ({
  examStart,
  examEnd,
  timeLimit,
  name,
  subjects = [],
  grades = [],
  instructions,
}: Props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  return (
    <Stack alignItems="center">
      <Box sx={{ ...styles.container }}>
        <Stack
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Typography fontWeight={700} variant="h5">
            {name}
          </Typography>
          <Stack alignItems="flex-end">
            {subjects.map((subject, index) => (
              <Typography variant="h5" key={subject.id}>
                {subject?.name} - {grades[index]?.name}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Divider sx={{ mt: 2.5, mb: 4 }} />
        <Stack
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          gap={1}
        >
          <Stack gap={1}>
            <Typography variant="body2">
              <strong>Data de início:</strong>{' '}
              {examStart
                ? `${dayjs(examStart).format('DD/MM/YYYY')} às ${dayjs(
                    examStart
                  ).format('HH:mm')}`
                : '-'}
            </Typography>
            <Typography variant="body2">
              <strong>Data de fim:</strong>{' '}
              {examEnd
                ? `${dayjs(examEnd).format('DD/MM/YYYY')} às ${dayjs(
                    examEnd
                  ).format('HH:mm')}`
                : '-'}
            </Typography>
          </Stack>
          <Typography variant="body2">
            <strong>Duração: </strong> {timeLimit} min
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" mt={5}>
          <Box height={40} mr={1}>
            <img src={ExamIcon} alt="Ícone de exames" />
          </Box>
          <Typography component="h3" fontWeight={700} variant="h5">
            Regras e instruções para a prova:
          </Typography>
        </Stack>

        <Divider sx={{ mt: 2.5, mb: 4 }} />
        {instructions ? (
          <Box
            data-testid="exam-instructions"
            dangerouslySetInnerHTML={{ __html: instructions }}
            sx={{ ...styles.instructions }}
          />
        ) : (
          <Box sx={{ ...styles.instructions }}>
            Nenhuma regra ou instrução disponível
          </Box>
        )}
      </Box>
    </Stack>
  )
}
