// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import useFilters from '~hooks/useFilters'

type AcademicYear = {
  id: number,
  year: number,
}

type CurriculumPeriod = {
  id: number,
  name: string,
  academic_year: AcademicYear,
}

type Subject = {
  id: number,
  name: string,
}
type Grade = {
  id: number,
  name: string,
}

export type BaseCurriclum = {
  id: number,
  events_per_week: number,
  events_count: number,
  child_base_curriculums_count: number,
  curriculum_period: CurriculumPeriod,
  grade: Grade,
  subject: Subject,
}

type BaseCurriculums = {
  base_curriculums: Array<BaseCurriclum>,
}

type Params = {
  entityType: string,
  entityId: number,
  order?: 'asc' | 'desc',
  orderBy?: 'grade' | 'subject' | 'curriculum_period',
  page: number,
}

export const endpoint = (entityType: string, entityId: string) =>
  `api/v3/${entityType}s/${entityId}/base_curriculums`

export const useBaseCurriculums = ({ entityType, entityId }: Params) => {
  const { params }: any = useFilters()

  const { loading, error, data } = useResource({
    resource: getBaseCurriculums,
    params: { ...params, entityType, entityId },
    enabled: !!params,
    key: 'useManagementBaseCurriculums',
  })

  return {
    loading,
    error,
    data,
  }
}

const getBaseCurriculums = ({
  entityType,
  entityId,
  order = 'desc',
  orderBy = 'created_at',
  page,
  ...params
}): APIResponse<BaseCurriculums> => {
  const grade_id_eq =
    Number(params.grade_id) !== 0 ? params.grade_id : undefined
  const subject_id_eq =
    Number(params.subject_id) !== 0 ? params.subject_id : undefined
  const curriculum_period_id_eq =
    Number(params.curriculum_period_id) !== 0
      ? params.curriculum_period_id
      : undefined

  return fetchWithRoot('get')(endpoint(entityType, entityId), {
    params: {
      entity_type: entityType,
      per_page: 10,
      page: page || 1,
      fields: [
        {
          base_curriculums: [
            'id',
            'events_per_week',
            'events_count',
            'child_base_curriculums_count',
            {
              curriculum_period: [
                'id',
                'name',
                { academic_year: ['id', 'year'] },
              ],
              subject: ['id', 'name'],
              grade: ['id', 'name'],
            },
          ],
        },
        'meta',
      ],
      include: [
        {
          base_curriculums: [
            {
              curriculum_period: [
                'id',
                'name',
                { academic_year: ['id', 'year'] },
              ],
            },
            'subject',
            'grade',
          ],
        },
      ],
      q: {
        grade_id_eq,
        subject_id_eq,
        curriculum_period_id_eq,
        s: `${orderBy} ${order}`,
      },
    },
    bypassLoader: true,
  })
}
