// @flow
import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (id: number) =>
  `/api/v3/foco_exam_student_profiles/${id}/update_exam_status`

type UpdateParams = {
  id: number,
  data: {
    started: boolean,
    answered: boolean,
  },
}

export const updateFocoExamStart = async ({ id, data }: UpdateParams) =>
  fetchWithRoot('patch')(getEndpoint(id), {
    data,
    bypassLoader: true,
  }).then(([error]) => {
    if (error) {
      throw new Error(error?.message ?? `Erro: ${error?.status}`)
    }
  })
