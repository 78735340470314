import { last, pipe, toPairs } from 'ramda'
import axios from 'axios'
import { addAuthHeaders, logoutOnError } from '~config/interceptors/auth'

export const loaderlessAxios = axios.create()
loaderlessAxios.interceptors.request.use(addAuthHeaders, (error) =>
  Promise.reject(error)
)
loaderlessAxios.interceptors.response.use((response) => response, logoutOnError)

export const processError = ({ response }) => {
  if (!response) {
    return [{ status: 0, logout: false }, null]
  } else if (response.status === 401) {
    return [{ status: 401, logout: true }, null]
  } else {
    try {
      const error = Array.isArray(response.data.errors)
        ? response.data.errors[0].details
        : response.data.errors.details

      const errorMessage =
        typeof error === 'string' ? error : Object.values(error)[0]

      return [
        {
          status: response.status,
          logout: false,
          message: errorMessage,
        },
        null,
      ]
    } catch (e) {
      return [{ status: response.status, logout: false }, null]
    }
  }
}

export const fetchWithoutRoot =
  (method) =>
  (url, { bypassLoader, ...config } = {}) => {
    const request = bypassLoader ? loaderlessAxios : axios

    return request({ method, url, ...config })
      .then(({ data }) => [null, pipe(toPairs, last, last)(data)])
      .catch(processError)
  }

export const fetchWithRoot =
  (method) =>
  (url, { bypassLoader, ...config } = {}) => {
    const request = bypassLoader ? loaderlessAxios : axios

    return request({ method, url, ...config })
      .then(({ data }) => [null, data])
      .catch(processError)
  }
