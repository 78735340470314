// @flow
import { styled } from '@mui/material/styles'

type Props = { vertical?: boolean }

export const Divider = styled('hr')`
  height: ${({ vertical }: Props) => (vertical ? undefined : '1px')};
  width: ${({ vertical }: Props) => (vertical ? '2px' : undefined)};
  margin: ${({ vertical }: Props) => (vertical ? '0 15px' : '15px 0')};
  background-color: ${({ theme }) => theme.palette.foco.backgroundLight};
  border: none;
`
