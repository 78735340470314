// @flow
import { fetchWithRoot } from '~helpers/fetch'
import type {
  ClassGroupList,
  ExamCustomization,
  ExamName,
} from '~types/Customization'
import type { NetworkError } from '~types/Network'

export const endpoints = {
  home: '/api/v2/home',
  state: '/api/v2/state',
}

export type HomeCustomization = {
  applicationName: string,
  homeBanner: string,
  logoSymbol: string,
  logoText: string,
  fullLogo: string,
  inline_logo: string,
  faviconFolder: string,
  loginVideo: string,
  clientName: string,
  clientLogo: string,
  departmentName: string,
  smallText: string,
  supportUrl: string,
  supportEmail: string,
  custom_preregister_message: ?string,
}

export const getHome = (): Promise<[?NetworkError, ?HomeCustomization]> =>
  fetchWithRoot('get')(endpoints.home, { bypassLoader: true })

type ProfileCustomization = {
  classGroupNames: ClassGroupList,
  indicatorName: string,
  district: string,
  sector: string,
  school: string,
  stateLogo: string,
  map_style: string,
  map_zoom: [number, number],
  coordinates: { latitude: number, longitude: number },
  resources: Array<{ ...ExamCustomization, resource: ExamName }>,
}

export const getProfile = (): Promise<[?NetworkError, ?ProfileCustomization]> =>
  fetchWithRoot('get')(endpoints.state, { bypassLoader: true })
