// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useFilters from '~hooks/useFilters'
import useResource from '~hooks/useResource'

type CurriculumResponse = {
  ['schools' | 'sectors']: {
    id: number,
    name: string,
    curriculum_performed_rate: number,
  },
}

type CurriculumInsights = {
  curriculum_performed_rate: number,
  planned_curriculum_skills_count: number,
}

export const schools_endpoint = '/api/v3/schools'

export const sectors_endpoint = '/api/v3/sectors'

const getEndpoint = (entityType: string) => {
  return entityType === 'sector' ? schools_endpoint : sectors_endpoint
}

export const useManagementCurriculumData = () => {
  const { params }: any = useFilters()

  const { loading, error, data } = useResource({
    resource: getManagementCurriculumData,
    params: params || {},
    enabled: !!params,
    key: 'useManagementCurriculumData',
  })

  return {
    loading,
    error,
    data,
  }
}

const getManagementCurriculumData = ({
  entityId,
  entityType,
  ...params
}): APIResponse<CurriculumResponse> => {
  const endpoint = getEndpoint(entityType)
  const customParams =
    entityType === 'sector'
      ? {
          include: ['schools'],
          fields: [{ schools: ['id', 'name', 'curriculum_performed_rate'] }],
        }
      : {
          include: ['sectors'],
          fields: [{ sectors: ['id', 'name', 'curriculum_performed_rate'] }],
        }

  return fetchWithRoot('get')(endpoint, {
    params: {
      ...params,
      entity_id: entityId,
      entity_type: entityType,
      with_curriculum: true,
      ...customParams,
    },
    bypassLoader: true,
  })
}

export const useManagementCurriculumInsights = () => {
  const { params }: any = useFilters()

  const { loading, error, data } = useResource({
    resource: getManagementCurriculumInsights,
    params: params || {},
    enabled: !!params,
    key: 'useManagementCurriculumInsights',
  })

  return {
    loading,
    error,
    data,
  }
}

const getManagementCurriculumInsights = ({
  entityId,
  entityType,
  ...params
}): APIResponse<CurriculumInsights> => {
  const endpoint = getEndpoint(entityType)

  return fetchWithRoot('get')(endpoint, {
    params: {
      ...params,
      entity_id: entityId,
      entity_type: entityType,
      fields: ['curriculum_performed_rate', 'planned_curriculum_skills_count'],
    },
    bypassLoader: true,
  })
}
