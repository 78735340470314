//@flow

import React, { useState } from 'react'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import { Link, useHistory } from 'react-router-dom'
import { ModalSuccess } from '../ModalSuccess'
import Fields from './Fields'
import { useFeedback } from '~contexts/Feedback'
import { useJourney } from '~data/api/v3/journeys/query/findUnique'
import { useCreateJourneys } from '~data/api/v3/journeys/mutation/create'
import { useUpdateJourneys } from '~data/api/v3/journeys/mutation/update'

type Props = {
  entityId: number,
  journeyId?: number,
}

export const FormJourney = ({ entityId, journeyId }: Props) => {
  const history = useHistory()
  const [success, setSuccess] = useState(false)
  const { showMessage } = useFeedback()

  const { data } = useJourney({
    entityType: 'school',
    entityId,
    journeyId,
  })

  const valueHourStart = data?.hour_initial ? dayjs(data.hour_initial) : null

  const valueHourEnd = data?.hour_end ? dayjs(data.hour_end) : null

  const initialValues = {
    id: data?.id,
    name: data?.name,
    hour_initial: valueHourStart,
    hour_end: valueHourEnd,
    active: !!data?.active,
    entityType: 'school',
    entityId: entityId,
  }

  const { mutate: createJourney } = useCreateJourneys({
    onSuccess: () => setSuccess(true),
    onError: (error) => {
      showMessage({ type: 'error', text: `Erro: ${error.message}` })
    },
  })

  const { mutate: updateJourney } = useUpdateJourneys({
    onSuccess: () => setSuccess(true),
    onError: (error) => {
      showMessage({ type: 'error', text: `Erro: ${error.message}` })
    },
  })

  const handleSubmit = (values) => {
    data?.id ? updateJourney(values) : createJourney(values)
  }

  const onCloseModal = () => {
    setSuccess(false)
    history.push(`/gerenciar-calendario/school/${entityId}/turnos/`)
  }

  const validate = (values) =>
    Object.fromEntries(
      ['name', 'hour_initial', 'hour_end']
        .filter((field) => !values[field])
        .map((field) => [field, 'Obrigatório'])
    )

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        enableReinitialize
        validateOnMount={false}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <Stack
              variant="modalBox"
              p={4}
              direction="column"
              alignItems="flex-start"
            >
              <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                Dados gerais
              </Typography>

              <Divider sx={{ width: '100%', my: 2 }} />

              <Box width="100%">
                <Fields />
              </Box>
            </Stack>

            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
              <Button
                variant="neutral"
                size="small"
                sx={{ minWidth: 160 }}
                component={Link}
                to={`/gerenciar-calendario/school/${entityId}/turnos`}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="small"
                sx={{ minWidth: 160 }}
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            </Stack>
          </>
        )}
      </Formik>

      {success && <ModalSuccess onClose={onCloseModal} isEdit={!!journeyId} />}
    </>
  )
}
