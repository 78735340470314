//@flow

type Props = {
  hex: string,
  opacity: number,
}

export const convertHexToRGBA = ({ hex, opacity }: Props) => {
  hex = hex.replace(/^#/, '')

  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  const alpha = Math.min(1, Math.max(0, opacity))

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
