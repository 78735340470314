// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpoint = (id?: number) =>
  `/api/v3/foco_exams/${id || 0}/foco_exam_result`

type PrioritarySkill = { id: number, name: string, description: string }

export type PrioritarySkills = {
  highest_skill_priority: PrioritarySkill,
  lowest_skill_priority: PrioritarySkill,
}

type Params = {
  examId?: number,
  classroomId?: number,
  schoolId: number,
}

export const useFocoExamPrioritarySkills = (params: Params) => {
  const { data, loading, error } = useResource({
    key: 'useFocoExamsPrioritarySkills',
    resource: getFocoExamPrioritarySkills,
    params,
    enabled: Boolean(params.examId && params.classroomId),
  })

  return { data, loading, error }
}

const getFocoExamPrioritarySkills = (
  params: Params
): APIResponse<PrioritarySkills> =>
  fetchWithRoot('get')(endpoint(params.examId), {
    params: {
      entity_type: 'school',
      entity_id: params.schoolId,
      classroom_id: params.classroomId,
      include: ['highest_skill_priority', 'lowest_skill_priority'],
      fields: [
        { highest_skill_priority: ['id', 'name', 'description'] },
        { lowest_skill_priority: ['id', 'name', 'description'] },
      ],
    },
  })
