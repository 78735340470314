// @flow
import React from 'react'
import { styled } from '@mui/material/styles'
import useSupportWidget from '~hooks/useSupportWidget'

type Props = {|
  buttonText: string,
  type?: 'link' | 'button',
|}

const makeButtonStyles = ({ type, theme }) => {
  switch (type) {
    case 'link':
      return `
        display: inline;
        background: transparent;
        border: 0;
        color: ${theme.palette.foco.linkColor};
        font-size: 1em;
        cursor: pointer;
        text-decoration: underline;
        padding: 0;
        &:hover {
          color: ${theme.palette.foco.hoverColor};
        }
      `
    case 'button':
    default:
      return 'display: inline-block'
  }
}

const Button = styled('button')`
  ${makeButtonStyles};
`

const SupportButton = ({ buttonText, type }: Props) => {
  const support = useSupportWidget()

  return (
    <Button
      type={type}
      onClick={support.open}
      data-testid="open-support-button"
    >
      {buttonText}
    </Button>
  )
}

export default SupportButton
