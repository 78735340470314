// @flow
import { config } from '../config'
import { fetchWithoutRoot } from '~helpers/fetch'

const { endpoint } = config

type Props = {
  journeyId: number,
  params: {
    entityType: string,
    entityId: number,
  },
}

export const deleteJourneys = async ({ journeyId, params }: Props) => {
  const [response] = await fetchWithoutRoot('delete')(
    `${endpoint(params.entityType, params.entityId)}/${journeyId}`
  )

  if (response?.message) {
    throw new Error(response)
  }
}
