//@flow

import React from 'react'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import Header from '~components/Common/Header'
import { Content } from '~components/AcademicYear/Content'

const SchoolYear = () => {
  const { entityType, entityId } = useParams()

  return (
    <Container>
      <Header
        title="Cadastrar ano letivo"
        breadcrumbs={[
          {
            text: 'Cadastrar',
            pathname: `/gerenciar-calendario/${entityType ?? ''}/${
              entityId ?? ''
            }/anos-letivos`,
          },
          {
            text: 'Ano letivo',
            pathname: `/gerenciar-calendario/${entityType ?? ''}/${
              entityId ?? ''
            }/anos-letivos`,
          },
        ]}
      />

      {entityType && entityId && (
        <Content entityType={entityType} entityId={Number(entityId)} />
      )}
    </Container>
  )
}

export default SchoolYear
