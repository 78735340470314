// @flow
import React from 'react'
import { TableCell, TableHead, TableRow } from '@mui/material'
import TableHeader from '~components/FocoExams/Teacher/TableHeader'

type StudentHeaderParams = {
  setOrder: Function,
}

const StudentHeader = ({ setOrder }: StudentHeaderParams) => (
  <TableHead>
    <TableRow>
      <TableCell>
        <TableHeader label="Nome" property="user_name" reorder={setOrder} />
      </TableCell>
      <TableCell>
        <TableHeader
          label="Turma"
          property="classroom_name"
          reorder={setOrder}
          sx={{
            justifyContent: 'center',
          }}
        />
      </TableCell>
      <TableCell>
        <TableHeader
          label="Status"
          property="answered_at"
          reorder={setOrder}
          sx={{
            justifyContent: 'center',
          }}
        />
      </TableCell>
    </TableRow>
  </TableHead>
)

export default StudentHeader
