// @flow
import React, { useState } from 'react'
import { Stack, Typography, Divider } from '@mui/material'
import { DeleteMaterial } from '../DeleteMaterial'
import { useCurriculumMaterials } from '~data/api/v3/curriculumEvents/curriculumMaterials/query/findMany'
import { ActionButton } from '~primitives/molecules/ActionButton'

type Props = {
  curriculumEventId: number,
  entityType: string,
  entityId: number,
}

export const AddedMaterials = ({
  curriculumEventId,
  entityType,
  entityId,
}: Props) => {
  const { data, loading, error } = useCurriculumMaterials({
    curriculumEventId,
    entityType: String(entityType),
    entityId: Number(entityId),
  })

  const [deleteMaterial, setDeleteMaterial] = useState(null)

  const handleDelete = ({ id, name }) => {
    setDeleteMaterial({ id, name })
  }

  return (
    <>
      {!!data?.curriculum_materials.length && (
        <Divider orientation="horizontal" />
      )}

      {(!loading || !error) && (
        <Stack
          component="ul"
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
          mt={2}
        >
          {data?.curriculum_materials.map(
            ({ id, name, file_path, file_type }) => (
              <Stack
                key={id}
                component="li"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                px={1}
              >
                {file_type === 'file' ? (
                  <Typography>{name}</Typography>
                ) : (
                  <a href={getLinkPath(file_path)} target="__blank">
                    {name}
                  </a>
                )}

                <Stack direction="row" spacing={1} component="span">
                  {file_type === 'file' && (
                    <ActionButton
                      component="a"
                      href={`${ENV_VARS.FOCO_API_HOST}/${file_path}`}
                      target="__blank"
                      tooltipTitle="Baixar"
                      iconName="iconDownload"
                    />
                  )}

                  <ActionButton
                    tooltipTitle="Deletar"
                    iconName="delete"
                    onClick={() => handleDelete({ id, name })}
                  />
                </Stack>
              </Stack>
            )
          )}
        </Stack>
      )}

      {deleteMaterial && (
        <DeleteMaterial
          {...deleteMaterial}
          onClose={() => setDeleteMaterial(null)}
        />
      )}
    </>
  )
}

const getLinkPath = (path) => {
  if (path.match(/^http(s)?:\/\//)) return path

  return `http://${path}`
}
