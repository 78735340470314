// @flow
import { Container } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import Header from '~components/Common/Header'
import { ExamForm } from '~components/ManageExams/Forms/Exam'
import { postBooklet, endpoint } from '~data/manageBooklets/form/postBooklet'

export const ExamCreateForm = () => {
  const { entityId, entityType, focoExamEditionId } = useParams()

  return (
    <Container disableGutters maxWidth={false} sx={{ px: { sm: 8, xs: 2 } }}>
      {entityId && entityType && focoExamEditionId && (
        <>
          <Header
            title="Criar caderno"
            breadcrumbs={[
              {
                text: 'Avaliações',
                pathname: `/gerenciar-avaliacoes/${entityType}/${entityId}`,
              },
              {
                text: 'Gerenciar avaliações',
                pathname: `/gerenciar-avaliacoes/${entityType}/${entityId}`,
              },
              {
                text: 'Criar caderno',
                pathname: `/gerenciar-avaliacoes/${entityType}/${entityId}/avaliacao/${focoExamEditionId}/caderno/criar`,
              },
            ]}
          />

          <ExamForm
            entityId={entityId}
            entityType={entityType}
            focoExamEditionId={focoExamEditionId}
            requestOnSubmit={postBooklet}
            requestEndpoint={endpoint}
            resetOnSuccessSubmit={true}
          />
        </>
      )}
    </Container>
  )
}

export default ExamCreateForm
