//@flow
import React, { useState } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { getStatusIcons } from '../../../../helpers'
import { type Id, type Status, type MaterialData } from '../../../../validate'
import { Activities } from './Activities'
import { SkillToPlan } from './SkillToPlan'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'

const STATUS_LABEL = {
  unplanned: 'não planejada',
  planned: 'planejada',
  unregistered: 'não registrada',
  registered: 'registrada',
}

type Props = {
  id: Id,
  status: Status,
  date: string,
  formattedStartTime: string,
  formattedEndTime: string,
  endTime: string,
  baseCompetency: { id: number, name: string },
  baseSkill: { id: number, name: string, description: string },
  plannedCompetencyId?: number,
  plannedSkillId?: number,
  objective: string,
  activityDescription: string,
  materialsId?: Array<number> | null,
  eventIndex: number,
  handleSetValues: (id: Id, values: Object) => void,
  handleOpenSuggestedMaterialsModal: (MaterialData) => void,
  activityIds?: number[],
  handleOpenAddMaterialsModal: (MaterialData) => void,
}

export const Form = ({
  id,
  status,
  date,
  formattedStartTime,
  formattedEndTime,
  endTime,
  baseCompetency,
  baseSkill,
  plannedCompetencyId,
  plannedSkillId,
  objective,
  activityDescription,
  materialsId,
  eventIndex,
  handleSetValues,
  handleOpenSuggestedMaterialsModal,
  activityIds = [],
  handleOpenAddMaterialsModal,
}: Props) => {
  const [isChangePlannedCurriculum, setIsChangePlannedCurriculum] = useState(
    plannedSkillId !== baseSkill.id
  )

  const { palette } = useTheme()

  const icon = getStatusIcons(status, endTime)

  const handleChangePlannedCurriculum = (event) => {
    const value = JSON.parse(event.target.value)
    setIsChangePlannedCurriculum(value)
    handleSetValues(id, { performedToPlan: value })
  }

  const handleChangeFormInput = (event) => {
    const { value, name } = event.target
    handleSetValues(id, { [name]: value })
  }

  const handleSetMaterialIds = (materialsId: Array<number>) => {
    handleSetValues(id, { materialsId })
  }

  return (
    <React.Fragment>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2">
          <strong>Data</strong> {date}
        </Typography>
        <Typography variant="body2">
          <strong>Horário</strong> {formattedStartTime} - {formattedEndTime}
        </Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2">Aula {STATUS_LABEL[status]}</Typography>
          <SVGIcon
            name={icon?.name}
            color={palette?.[icon?.color]?.main}
            size={3}
          />
        </Stack>
      </Stack>

      <Divider sx={{ my: 1 }} />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Typography variant="body2" fontWeight={700}>
          Alterar currículo previsto?
        </Typography>
        <FormControl sx={{ p: 0 }} disabled={status === 'registered'}>
          <RadioGroup
            row
            name="radio-buttons-group"
            value={isChangePlannedCurriculum}
            onChange={handleChangePlannedCurriculum}
            sx={{ pb: 0 }}
          >
            <FormControlLabel
              componentsProps={{ typography: { variant: 'body2' } }}
              value={true}
              control={<Radio color="secondary" />}
              label="Sim"
            />
            <FormControlLabel
              componentsProps={{ typography: { variant: 'body2' } }}
              value={false}
              control={<Radio color="secondary" />}
              label="Não"
              sx={{ mr: 0 }}
            />
          </RadioGroup>
        </FormControl>
      </Stack>

      <Divider sx={{ my: 1.5 }} />

      <Box>
        <Typography variant="body2" fontWeight={700}>
          Unidade temática
        </Typography>
        <Typography variant="body2">{baseCompetency.name}</Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant="body2" fontWeight={700}>
          Habilidade
        </Typography>
        <Typography variant="body2">
          <strong>({baseSkill.name})</strong> {baseSkill.description}
        </Typography>
      </Box>

      {isChangePlannedCurriculum && (
        <React.Fragment>
          <Divider sx={{ my: 1.5 }} />
          <SkillToPlan
            id={id}
            status={status}
            handleSetValues={handleSetValues}
            plannedCompetencyId={plannedCompetencyId}
            plannedSkillId={plannedSkillId}
          />
        </React.Fragment>
      )}

      <Divider sx={{ my: 1.5 }} />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" fontWeight={700}>
          Conteúdo ({materialsId?.length ?? 0})
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={2.5}
        >
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            disabled={status === 'registered'}
            onClick={() => {
              handleOpenAddMaterialsModal({
                eventIndex,
                skillCode: baseSkill.name,
                date,
              })
            }}
          >
            Adicionar materiais
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            disabled={status === 'registered'}
            onClick={() => {
              handleOpenSuggestedMaterialsModal({
                eventIndex,
                skillId: baseSkill.id,
                skillCode: baseSkill.name,
                date,
                initialValues: materialsId ?? [],
                setValuesOnConfirm: handleSetMaterialIds,
              })
            }}
          >
            Materiais sugeridos
          </Button>
        </Stack>
      </Stack>

      <Divider sx={{ my: 1.5 }} />

      <Stack gap={1.5}>
        {status === 'registered' ? (
          <Box sx={{ my: 1 }}>
            <Typography variant="body2" fontWeight={700}>
              Objetivos
            </Typography>
            <Typography variant="body2">{objective ?? '--'}</Typography>
          </Box>
        ) : (
          <TextField
            id="objective"
            name="objective"
            variant="standard"
            label="Objetivos"
            multiline
            size="small"
            value={objective}
            onChange={handleChangeFormInput}
            minRows={4}
            maxRows={4}
          />
        )}

        <Activities
          disabled={status === 'registered'}
          activityIds={activityIds}
          onChange={(value) => handleSetValues(id, { activityIds: value })}
          activityDescription={activityDescription}
          onChangeDescription={(activityDescription) =>
            handleSetValues(id, { activityDescription })
          }
        />
      </Stack>
    </React.Fragment>
  )
}
