// @flow
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'

type Props = { id: number }

const useExamResult = ({ id }: Props) => {
  const { loading, error, data } = useResource({
    resource: getExamResult,
    params: { id },
    key: 'useExamResult',
  })

  return { loading, error, data }
}

export const endpoint = (id: number) => `/api/v3/exam_results/${id}`

const getExamResult = ({ id }: Props) =>
  fetchWithRoot('get')(endpoint(id), {
    params: { fields: ['label'] },
  })

export default useExamResult
