// @flow
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'

type Params = {
  id: number,
  entityId: number,
  classroomId: number,
  page: number,
  order?: string,
  orderBy?: string,
}

type ExamParams = {
  entityId: number,
  gradeId?: number,
  subjectId?: number,
  districtId?: number,
  classroomId?: number,
  examEditionId?: number,
  page: number,
  order?: string,
  orderBy?: string,
}

const useStudentList = (params: ExamParams) => {
  const { data: examData } = useResource({
    key: 'useFocoExamData',
    resource: getFocoExamData,
    params,
  })

  const selectedExamId = examData?.foco_exams[0]?.id

  const { loading, error, data } = useResource({
    key: 'useStudentList',
    resource: getStudentList,
    params: {
      id: Number(selectedExamId),
      classroomId: Number(params.classroomId),
      entityId: Number(params.entityId),
      page: Number(params.page),
      order: params?.order,
      orderBy: params?.orderBy,
    },
    enabled: !!selectedExamId,
  })

  return { loading, error, data, examData }
}

export const focoExamEndpoint = '/api/v3/foco_exams/'

export type Exam = {
  id: number,
  estimated_time: number,
  amount_of_displayed: number,
  active: boolean,
  amount_of_answered: number,
  amount_of_started: number,
}

const getFocoExamData = (params: ExamParams): APIResponse<Exam> =>
  fetchWithRoot('get')(focoExamEndpoint, {
    params: {
      entity_type: params?.classroomId ? 'classroom' : 'school',
      entity_id: params?.classroomId || params?.entityId,
      q: {
        district_id_eq: params?.districtId,
        grade_id_eq: params?.gradeId,
        subject_id_eq: params?.subjectId,
      },
      fields: [{ foco_exams: ['id'] }],
      include: ['foco_exams'],
    },
    bypassLoader: true,
  })

export type Student = {
  id: number,
  name: string,
  answered: boolean,
  registration_number: number,
}

export const endpoint = (id: number) =>
  `/api/v3/foco_exams/${id}/foco_exam_student_profiles`

const getStudentList = ({
  id,
  entityId,
  classroomId,
  page,
  order = 'asc',
  orderBy = 'user_name',
}: Params): APIResponse<Student> =>
  fetchWithRoot('get')(endpoint(id), {
    params: {
      entity_type: classroomId ? 'classroom' : 'school',
      entity_id: classroomId || entityId,
      page: page || 1,
      per_page: 10,
      q: { s: `${orderBy} ${order}` },
      include: [
        {
          foco_exam_student_profiles: [
            {
              student_profile: ['user', 'classroom'],
            },
          ],
        },
      ],
      fields: [
        'meta',
        {
          foco_exam_student_profiles: [
            'id',
            'answered',
            {
              student_profile: [
                'id',
                { classroom: ['id', 'name'] },
                { user: ['id', 'name'] },
              ],
            },
          ],
        },
      ],
    },
    bypassLoader: true,
  })

export default useStudentList
