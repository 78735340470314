// @flow
import { Route, Switch, Redirect } from 'react-router-dom'
import React from 'react'
import StudentHome from '~pages/Home/Student'
import ExamResults from '~pages/FocoExams/Student/Results'
import StudentExamView from '~pages/FocoExams/Student/Exam'

const StudentRoutes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/pagina-inicial" />
      </Route>
      <Route
        exact
        path={['/pagina-inicial', '/home']}
        component={StudentHome}
      />
      <Route
        exact
        path={['/responder-caderno/:id']}
        component={StudentExamView}
      />
      <Route exact path={['/meus-resultados']} component={ExamResults} />
      <Route exact path="*">
        <Redirect to="/pagina-inicial" />
      </Route>
    </Switch>
  )
}
export default StudentRoutes
