// @flow
import React from 'react'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material'
import TableHeader, { type CellHeader } from './Header'
import { Pagination } from '~primitives/molecules/Pagination'
import { Placeholder } from '~components/FocoExams/Student/MyExam/Placeholder'

export type Align = 'center' | 'inherit' | 'justify' | 'left' | 'right'

type Row = {
  fields: Array<string | React$Node>,
  disabled?: boolean,
  key: number,
}

type Props = {
  rows: Array<Row>,
  rowsAlign?: Array<Align>,
  cellsHeader: Array<CellHeader>,
  lengthText?: string,
  variant?: string,
  totalPages?: number,
  currentPage?: number,
  pagination?: boolean,
  firstCheckbox?: boolean,
  emptyContentText?: string,
  size?: string,
}

const SortTable = ({
  rows,
  rowsAlign,
  cellsHeader,
  lengthText,
  variant,
  totalPages = 1,
  currentPage = 1,
  pagination = true,
  firstCheckbox = false,
  size = '',
  emptyContentText = 'Nenhum resultado encontrado',
}: Props) => {
  const history = useHistory()
  const query = qs.parse(useLocation().search, { ignoreQueryPrefix: true })

  const setOrder = (orderBy, order) => {
    history.push({
      search: qs.stringify({ ...query, order, orderBy }),
    })
  }

  if (rows.length === 0) {
    return <Placeholder text={emptyContentText} />
  }

  return (
    <>
      <TableContainer>
        <Table
          variant={variant}
          size={size}
          sx={{
            color: 'grey.800',
            borderSpacing: '0 2px',
            borderCollapse: 'separate',
          }}
        >
          <TableHeader cells={cellsHeader} setOrder={setOrder} />
          <TableBody data-testid="students-table-body">
            {rows.map(({ fields, disabled, key }) => (
              <TableRow key={key} data-testid={`table-row-${key}`}>
                {fields.map((cell, index) => (
                  <TableCell
                    align={rowsAlign?.[index] || 'inherit'}
                    data-testid={`table-cell-${key}-${index}`}
                    key={index}
                    colSpan={cellsHeader.length / fields.length}
                    sx={{
                      color: disabled ? 'grey.500' : 'grey.800',
                      paddingLeft: firstCheckbox ? '0' : 'auto',
                    }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {lengthText && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap="8px"
          mt="14px"
        >
          <Typography fontSize="14px">{lengthText} </Typography>
          <Stack
            sx={{
              bgcolor: '#EDEDED',
              height: '26px',
              fontSize: '14px',
              px: 2,
              mr: 3,
              justifyContent: 'center',
            }}
          >
            {rows.length}
          </Stack>
        </Stack>
      )}
      {pagination && (
        <Pagination totalPages={totalPages} currentPage={currentPage} />
      )}
    </>
  )
}

export default SortTable
