//@flow
export default function Accordion(theme: Object) {
  return {
    MuiAccordion: {
      variants: [
        {
          props: { variant: 'simple' },
          style: {
            borderRadius: theme.shape.borderRadius * 2,
            backgroundColor: theme.palette.grey[200],
            marginBottom: 12,
            '&:before': {
              width: '0px',
            },
            '& .MuiAccordionDetails-root': {
              padding: '0px 30px 15px 30px',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
  }
}
