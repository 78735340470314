import React from 'react'
import { Avatar, Stack, Skeleton } from '@mui/material'

const AVATAR_STYLES = {
  bgcolor: 'primary.main',
  width: 28,
  height: 28,
  fontSize: 14,
  fontWeight: 700,
}

const OPTIONS = ['A', 'B', 'C', 'D']

export const OptionsPlaceholder = () =>
  OPTIONS.map((option, index) => (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      key={index}
    >
      <Avatar sx={{ ...AVATAR_STYLES }}>{option}</Avatar>
      <Skeleton variant="text" sx={{ width: '50%' }} />
    </Stack>
  ))
