// @flow
import React from 'react'
import { Stack, Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import { InsightBox } from './Box'
import { formatDecimalPercent } from '~helpers/formatting/numbers'
import { useManagementCurriculumInsights } from '~data/curriculum/management'

export const Insights = () => {
  const { data, error } = useManagementCurriculumInsights()
  const { entityType } = useParams()
  const performedRate = data?.curriculum_performed_rate

  if (error) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={2.5}
        bgcolor="grey.200"
        p={2.5}
        borderRadius={2}
        sx={{ height: 126 }}
      >
        Erro: {error.status} {error.message && `- ${error.message}`}
      </Stack>
    )
  }

  if (!data || !entityType)
    return (
      <Stack
        direction="row"
        alignItems="left"
        gap={2.5}
        bgcolor="grey.200"
        p={2.5}
        borderRadius={2}
      >
        <Skeleton variant="rectangular" width={244} height={86} />
        <Skeleton variant="rectangular" width={244} height={86} />
      </Stack>
    )

  return (
    <Stack
      direction="row"
      alignItems="left"
      gap={2.5}
      bgcolor="grey.200"
      p={2.5}
      borderRadius={2}
    >
      <InsightBox
        label="Aplicação do currículo"
        value={formatDecimalPercent(0)(data?.curriculum_performed_rate).replace(
          '%',
          ''
        )}
        suffix={
          performedRate === null || performedRate === undefined ? '' : '%'
        }
        name="perfomed-rate"
        entity={entityType}
      />
      <InsightBox
        label="Habilidades previstas"
        value={data?.planned_curriculum_skills_count}
        name="planned-curriculum-skill-count"
        entity={entityType}
      />
    </Stack>
  )
}
