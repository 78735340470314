//@flow
import React from 'react'
import { Alert, Box, Button, Skeleton, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { TableJourneys } from '../TableJourneys'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { useJourneys } from '~data/api/v3/journeys/query/findMany'

type Props = {
  entityId: number,
}

export const Content = ({ entityId }: Props) => {
  const { data, error, loading } = useJourneys({
    entityId: entityId,
    entityType: 'school',
    extensions: ['in_use'],
  })

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
      >
        <Typography variant="h4">Visão geral</Typography>
        <Button
          variant="primary"
          component={Link}
          to={`/gerenciar-calendario/school/${entityId}/turnos/novo`}
          startIcon={<SVGIcon name="addCircle" color="white" />}
          sx={{ display: 'flex', pr: 5, gap: 3 }}
          size="small"
        >
          Adicionar turno
        </Button>
      </Stack>

      {error && (
        <Alert variant="filled" severity="error">
          Ocorreu um erro ao carregar os dados dos turnos
        </Alert>
      )}

      {loading && (
        <Skeleton
          sx={{ mt: 3 }}
          variant="rounded"
          minWidth="100%"
          height={200}
        />
      )}

      {!error && data && (
        <Stack variant="modalBox" mt={3}>
          {data && <TableJourneys data={data} entityId={entityId} />}
        </Stack>
      )}
    </Box>
  )
}
