// @flow strict
import type { Profile } from '~types/Profile'

type Feature = 'retrospective' | 'prospective'
type Resource = string

export const getSkillMapLink = (
  feature: Feature,
  resource: Resource,
  exam_id: number,
  profile: ?Profile
) =>
  !!profile && profile.kind === 'school'
    ? `/resultados/${feature}/${resource}?exam_id=${exam_id}&resultable_id=${
        profile.personable.id
      }&resultable_type=school`
    : `/selecao/${feature}/${resource}/${exam_id}`

export const getEnemMapLink = (profile: ?Profile) =>
  !!profile && profile.kind === 'school'
    ? `/enem/mapa?resultable_type=school&resultable_id=${profile.personable.id}`
    : '/selecao/enem/map'
