import { isNil, merge, pickBy } from 'ramda'
import axios from 'axios'

import store from '~store/index.js'

// Adds authentication headers from redux store data
export const addAuthHeaders = (config) => {
  const { session, selectedProfile } = store.getState().user

  const authHeaders = {
    'Access-Token': session.token,
    'Token-Type': session.type,
    Client: session.client,
    Expiry: session.expiry,
    UID: session.uid,
    'Profile-ID': selectedProfile,
  }

  return merge(config, {
    headers: merge(
      config.headers,
      pickBy((val) => !isNil(val), authHeaders)
    ),
  })
}

axios.interceptors.request.use(addAuthHeaders, (error) => Promise.reject(error))

export const logoutOnError = (error) => {
  if (error.response?.status == 401) {
    store.dispatch({ type: 'LOGOUT' })
  }

  return Promise.reject(error)
}

axios.interceptors.response.use((response) => response, logoutOnError)
