//@flow
import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { Typography, useTheme } from '@mui/material'
import type { FormikField, FormikForm } from '~types/formik'

type Props = {
  id?: string,
  field: FormikField,
  form: FormikForm,
  name: string,
  label: string,
  disabled?: boolean,
  required?: boolean,
  fullWidth?: boolean,
  placeholder?: string,
  variant?: string,
}

export const DesktopDatePickerField = ({
  id,
  name,
  label,
  disabled,
  required,
  field,
  form,
  placeholder,
  fullWidth = true,
  variant = 'standard',
  ...props
}: Props) => {
  const theme = useTheme()

  const error = form.touched[field.name] && form.errors[field.name]

  return (
    <>
      <DesktopDatePicker
        variant="inline"
        id={`date-picker-${field.name}`}
        name={field.name}
        defaultValue={field.value}
        value={field.value}
        label={label}
        format="DD/MM/YYYY"
        onChange={(value) => form.setFieldValue(field.name, value)}
        disabled={disabled}
        slotProps={{
          label: { for: id ?? field.name },
          textField: {
            id: id ?? field.name,
            variant: 'standard',
            size: 'small',
            fullWidth,
            required,
          },
        }}
        sx={
          variant === 'inline' && {
            '&,.MuiFormControl-root': {
              flexDirection: 'row',
              alignItems: 'baseline',
              gap: theme.spacing(1),
              paddingBottom: 0,
              '& .MuiFormLabel-root': {
                flexGrow: 1,
                flexShrink: 0,
              },
              '& .MuiInput-root': {
                flexGrow: 1,
              },
            },
          }
        }
        {...props}
      />

      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </>
  )
}
