import { createReducer } from 'reduxsauce'

const simpleMerge = (state, { payload }) => ({ ...state, ...payload })

const initialState = { subjects: [{ id: 1, name: '' }] }

const handlers = {
  GET_SUBJECTS: simpleMerge,
}

export default createReducer(initialState, handlers)
