// @flow
import { IMaskInput } from 'react-imask'
import React, { type ElementRef } from 'react'
import { TextField } from '@mui/material'
import { Wrapper } from './styles'
import { type IconName } from '~primitives/Utilities/SVGIcon'
import type { FormikField, FormikForm } from '~types/formik'
import type { ThemeColor } from '~styles/themes/focoEscola'

export const InputWrapper = Wrapper
export type Props = {|
  error?: boolean,
  helperText?: string,
  icon?: IconName,
  onChange?: (SyntheticInputEvent<HTMLInputElement>) => void,
  onBlur?: (SyntheticFocusEvent<HTMLInputElement>) => void,
  value: string,
  type?: string,
  id?: string,
  name: string,
  placeholder?: string,
  mask?: string,
  definitions?: Object,
  InputProps?: Object,
  label?: string,
  required?: boolean,
  bgColor?: ThemeColor,
  borderColor?: ThemeColor,
  hideWarning?: boolean,
  inputRef?: ElementRef<any>,
  disabled?: boolean,
  multiline?: boolean,
  maxRows?: number,
  variant?: string,
  size?: string,
  sx?: Object,
|}

type CustomProps = {
  onChange: (event: { target: { name: string, value: string } }) => void,
  name: string,
  mask: string,
  definitions: Object,
}

const InputMask = React.forwardRef<CustomProps, HTMLElement>(function InputMask(
  { name, mask, definitions, onChange, ...inputProps }: CustomProps,
  ref
) {
  return (
    <IMaskInput
      {...inputProps}
      name={name}
      mask={mask}
      definitions={definitions}
      inputRef={ref}
      onChange={onChange}
      onAccept={(value: any) => onChange({ target: { name: name, value } })}
      overwrite
    />
  )
})

export const TextInput = ({
  error,
  icon,
  type = 'text',
  name,
  id,
  placeholder,
  mask,
  definitions,
  label,
  required,
  value,
  onChange,
  onBlur,
  InputProps,
  inputRef,
  variant = 'standard',
  size = 'medium',
  ...props
}: Props) => {
  const componentProps = {
    type,
    id: id ?? name,
    name,
    label,
    placeholder,
    value,
    error,
    variant,
    size,
    onChange,
    onBlur,
    inputRef,
    required,
    fullWidth: true,
    InputLabelProps: { shrink: true },
    ...props,
  }

  if (mask) {
    return (
      <TextField
        InputProps={{
          ...InputProps,
          inputComponent: InputMask,
          inputProps: { mask, definitions, 'data-testid': id || name },
        }}
        {...componentProps}
      />
    )
  }

  return (
    <TextField
      InputProps={{ ...InputProps, inputProps: { 'data-testid': id || name } }}
      {...componentProps}
    />
  )
}

export type FormikProps = {|
  icon?: IconName,
  type?: string,
  placeholder?: string,
  mask?: string,
  field: FormikField,
  form: FormikForm,
  label?: string,
  required?: boolean,
  bgColor?: ThemeColor,
  borderColor?: ThemeColor,
  hideWarning?: boolean,
|}

export const FormikText = ({ field, form, ...props }: FormikProps) => {
  const { name, value = '' } = field
  const inputError = form.touched[name] && form.errors[name]

  return (
    <>
      <TextInput
        onChange={field.onChange}
        onBlur={field.onBlur}
        name={name}
        value={value}
        error={!!inputError}
        helperText={inputError}
        {...props}
      />
    </>
  )
}
