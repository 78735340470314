//@flow
import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (eventID: number) =>
  `api/v3/curriculum_events/${eventID}`

type CurriculumMaterial =
  | { curriculum_material_id: number }
  | {
      id: number,
      curriculum_material_id: number,
    }
  | {
      id: number,
      curriculum_material_id: number,
      _destroy: true,
    }

type Data = {
  objective?: string,
  activityDescription?: string,
  curriculumMaterials: CurriculumMaterial[],
}

type PatchCurriculumEventParams = {
  data: Data,
  eventId: number,
  entityId: string,
  attributesId?: number,
  skillId?: number,
  activityIds?: number[],
}

export const registerCurriculumEvent = ({
  data,
  eventId,
  entityId,
  attributesId,
  skillId,
  activityIds,
}: PatchCurriculumEventParams) =>
  fetchWithRoot('put')(getEndpoint(eventId), {
    data: {
      entity_id: entityId,
      entity_type: 'school',
      curriculum_event: {
        objective: data?.objective,
        activity_description: data?.activityDescription,
        curriculum_events_curriculum_materials_attributes:
          data?.curriculumMaterials,
        curriculum_events_curriculum_skills_attributes: [
          { id: attributesId, curriculum_skill_id: skillId, status: 'planned' },
        ],
        curriculum_activity_ids: activityIds,
      },
    },
  }).then(([error]) => {
    if (error) {
      throw new Error(error?.message ?? `Erro: ${error?.status}`)
    }
  })
