//@flow
export default function Table(theme: Object) {
  return {
    MuiTable: {
      variants: [
        {
          props: { variant: 'borderless' },
          style: {
            '.MuiTableCell-root': {
              border: 'none',
            },
            '.MuiTableCell-root:first-of-type': {
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
            },
            '.MuiTableCell-root:last-of-type': {
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
            },
          },
        },
        {
          props: { variant: 'whiteRow' },
          style: {
            '.MuiTableHead-root .MuiTableRow-head': {
              backgroundColor: theme.palette.grey[200],
            },
            '.MuiTableCell-body': {
              padding: theme.spacing(3),
            },
            '.MuiTableRow-root': {
              backgroundColor: theme.palette.grey[0],
            },
          },
        },
        {
          props: { variant: 'simple' },
          style: {
            '.MuiTableHead-root': {
              backgroundColor: theme.palette.grey[200],
            },
            '.MuiTableCell-root:first-of-type': {
              borderTopLeftRadius: '8px',
            },
            '.MuiTableCell-root:last-of-type': {
              borderTopRightRadius: '8px',
            },
            '.MuiTableCell-head': {
              height: '40px',
              padding: '0 16px',
            },
            '.MuiTableCell-body': {
              borderBottom: `solid 1px ${theme.palette.grey[200]}`,
            },
          },
        },
        {
          props: { variant: 'striped' },
          style: {
            '.MuiTableCell-root': {
              padding: '5px 8px',
              border: 'none',
            },
            '& tbody .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: theme.palette.grey[200],
            },
            '& thead .MuiTableCell-root': {
              backgroundColor: 'none',
              padding: '10px 8px',
            },
            '& tbody .MuiTableRow-root:nth-of-type(even)': {
              backgroundColor: theme.palette.grey[0],
            },
            '&.MuiTableCell-body:last-child td, &:last-child th': {
              border: 0,
            },
            '.MuiTableCell-root:first-of-type': {
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
            },
            '.MuiTableCell-root:last-of-type': {
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
            },
          },
        },
      ],
    },
  }
}
