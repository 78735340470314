export const now = () => new Date().toString()

export const addSeconds = (seconds) => (dateString) => {
  const date = new Date(dateString)
  date.setSeconds(date.getSeconds() + parseInt(seconds, 10))
  return date.toString()
}

export const getMilisecondsUntil = (stringUntil) => (stringFrom) => {
  const dateUntil = new Date(stringUntil)
  const dateFrom = new Date(stringFrom)

  return dateUntil.getTime() - dateFrom.getTime()
}

export const convertTimeToHour = (time = '0') => {
  const hour = parseInt(time / 60)
  const minutes = time % 60

  return `${hour > 9 ? hour : `0${hour}`} hs ${
    minutes > 9 ? minutes : `0${minutes} min`
  }`
}
