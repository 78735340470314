// @flow
import { fetchWithRoot } from '~helpers/fetch'

export const endpoint = '/api/v3/foco_exams'

type DataProps = {
  endpoint: string,
  name: string,
  examId: string,
  subjectId: string,
  gradeId: string,
  studentProfileIds: Array<number>,
  questionIds: Array<{ foco_question_id: number, position: number }>,
}

export const postBooklet = async (data: DataProps) =>
  fetchWithRoot('post')(data.endpoint, {
    data: {
      foco_exam: {
        name: data?.name,
        foco_exam_edition_id: data?.examId,
        subject_id: data?.subjectId,
        grade_id: data?.gradeId,
        student_profile_ids: data?.studentProfileIds,
        foco_exam_foco_questions_attributes: data?.questionIds,
      },
    },
  })
