// @flow
import React from 'react'
import { Chip } from '@mui/material'

type Props = { status: $Keys<typeof labels> }

export const ExamStatus = ({ status }: Props) => (
  <Chip label={labels[status].label ?? ''} variant={labels[status].variant} />
)

const labels = {
  created: { variant: 'labelInfoLight', label: 'Criado' },
  published: { variant: 'labelWarning', label: 'Publicada' },
  in_progress: { variant: 'labelSuccess', label: 'Em curso' },
  finished: { variant: 'labelDefault', label: 'Finalizada' },
  inactive: { variant: 'labelInactive', label: 'Inativa' },
}
