// @flow
import { config } from '../../config'
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'
import { getFieldsAndIncludes } from '~data/helpers'

const { endpoint, key } = config

type Extension = $Keys<typeof extensionsConfig>

type Params = {
  entityType: string,
  entityId: number,
  extensions?: Extension[],
  ...
}

export type AcademicYear = {
  id: number,
  year: string,
  end_date: string,
  start_date: string,
  active: boolean,
}

type AcademicYearsResponse = { academic_years: AcademicYear[] }

export const usePendingAcademicYears = (params: Params = {}) => {
  const { loading, error, data } = useResource({
    key,
    resource: getPendingAcademicYears,
    params,
  })

  return { loading, error, data }
}

const extensionsConfig = {
  default: {
    include: [{ academic_years: ['curriculum_periods'] }],
    fields: [
      {
        academic_years: [
          'id',
          'year',
          'initial_date',
          'end_date',
          'expected_total_days',
          'active',
          { curriculum_periods: ['id', 'name', 'start_date', 'end_date'] },
        ],
      },
    ],
    q: { s: 'year desc' },
  },
}

const getPendingAcademicYears = ({
  entityType,
  entityId,
  extensions = [],
}: Params): APIResponse<AcademicYearsResponse> => {
  return fetchWithRoot('get')(`${endpoint(entityType, entityId)}/pending`, {
    params: { ...getFieldsAndIncludes(extensionsConfig, extensions) },
    bypassLoader: true,
  })
}
