//@flow
import { alpha } from '@mui/material/styles'

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`
}

const PRIMARY = {
  lighter: '#BFC0C5',
  light: '#6A6D78',
  main: '#2A2E3E',
  dark: '#1F2330',
  darker: '#0F121B',
}
const SECONDARY = {
  lighter: '#C6D92D',
  light: '#4DDDF7',
  main: '#00CEF4',
  dark: '#00C2F1',
  darker: '#059bce',
}
const INFO = {
  lighter: '#0AFFF4',
  light: '#09B7E8',
  main: '#1890FF',
  dark: '#0942E8',
  darker: '#0D0AFF',
}
const SUCCESS = {
  lighter: '#62D3D9',
  light: '#66E3C6',
  main: '#66CB97',
  dark: '#66E37F',
  darker: '#6ED962',
}
const WARNING = {
  lighter: '#FFFB05',
  light: '#E8CE05',
  main: '#FFCD11',
  dark: '#E8A005',
  darker: '#FF9605',
}

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#F30037',
  dark: '#B72136',
  darker: '#7A0C2E',
}

const NEUTRAL = {
  lighter: '#68CCCE',
  light: '#4EC3C6',
  main: '#3BB4B7',
  dark: '#339B9D',
  darker: '#2A8184',
}

const RESULTABLES = {
  classroom: '#1C4F8D',
  school: '#7D69CC',
  sector: '#54BCC0',
  district: '#EE7786',
  result: '#66CB97',
  target: '#B5C1D0',
  group: '#5E98EF',
}

const GREY = {
  '0': '#FFFFFF',
  '100': '#F9FAFB',
  '200': '#F3F3F3',
  '230': '#F4F4F4',
  '250': '#E9E9E9',
  '300': '#DFE3E8',
  '400': '#C4CDD5',
  '500': '#96A6A9',
  '550': '#8C8C8C',
  '600': '#597983',
  '650': '#535353',
  '700': '#454F5B',
  '800': '#2A2E3E',
  '850': '#334449',
  '900': '#161C24',
  '500_8': alpha('#96A6A9', 0.08),
  '500_12': alpha('#96A6A9', 0.12),
  '500_16': alpha('#96A6A9', 0.16),
  '500_24': alpha('#96A6A9', 0.24),
  '500_32': alpha('#96A6A9', 0.32),
  '500_48': alpha('#96A6A9', 0.48),
  '500_56': alpha('#96A6A9', 0.56),
  '500_80': alpha('#96A6A9', 0.8),
}

const INACTIVE = {
  lighter: GREY[200],
  light: GREY[300],
  main: GREY[400],
  dark: GREY[500],
  darker: GREY[550],
}

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  neutral: createGradient(NEUTRAL.light, NEUTRAL.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
}

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
  heat: ['#D62A2A', '#FF8A00', '#FFCD11', '#66CB97'],
}

const OLD_FOCO_THEME = {
  borderRadius: '2px',

  text: '#334449',
  textPlaceholder: '#859092',
  textMedium: '#717171',
  textLight: PRIMARY.main,
  textDark: '#1d3a3b',
  textNegative: '#FFF',

  linkColor: '#059bce',
  buttonLinkColor: '#00cef4',
  hoverColor: '#059bce',

  primary: PRIMARY.main,
  primaryLight: '#618586',
  primaryMedium: '#2E6162',
  primaryDark: '#1D3A3B',
  primaryBlack: '#2E6162',
  secondary: '#C6D92D',

  backgroundPrimary: PRIMARY.main,
  backgroundPrimaryDarker: '#283A3E',
  backgroundSecondary: '#C6D92D',
  backgroundTertiary: '#00CEF4',
  backgroundLight: GREY[200],
  backgroundMedium: '#EBEFF4',
  backgroundDark: '#D8DFE7',
  backgroundNeutral: '#979797',
  backgroundLightWhite: '#F1F1F1',
  backgroundGradient:
    'linear-gradient(335.75deg, #7AA4E2 0%, #547BC5 40%, #4268B7 100%)',

  backgroundMenuOpen: '#F4F4F4',
  backgroundMenu: '#F4F4F4',
  backgroundMenuActive: '#DDDDDD',
  backgroundMenuDark: '#0E2946',
  backgroundDanger: '#FEEDEF',
  menuHover: '#47596E',

  border: '#D8DFE7',
  borderLight: '#E5E5E5',
  borderDark: '#979797',

  warning: '#FFCD11',
  success: '#66CB97',
  danger: '#D62A2A',
  info: '#0B486B',
  neutral: '#C2C2C2',
  neutralLight: '#ABB7CA',
  disabled: '#D8DFE7',
  question: '#e8e39a',

  white: '#ffffff',
  black: '#000000',
  blue: '#2FA3DA',
  darkBlue: '#0B486B',
  green: '#C1C722',
  purple: '#9B4AD0',
  transparent: 'transparent',

  chartColors: {
    mixed: ['#709CBE', '#2E6162', '#61387A'],
    heat: ['#D62A2A', '#FF8A00', '#FFCD11', '#66CB97'],
    grading: ['#1E479C', '#4268B7', '#7EA9E0', '#B4D1F7'],
  },

  resultables: {
    classroom: '#1C4F8D',
    school: '#7D69CC',
    sector: '#54BCC0',
    district: '#EE7786',
    result: '#66CB97',
    target: '#B5C1D0',
    group: '#5E98EF',
  },

  breakpoints: {
    small: '320px',
    medium: '960px',
    mediumLarge: '980px',
    large: '1200px',
    wide: '1600px',

    number: {
      small: 320,
      medium: 800,
      mediumLarge: 1000,
      large: 1200,
      wide: 1600,
    },
  },
}

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  neutral: { ...NEUTRAL, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY['800'] },
  warning: { ...WARNING, contrastText: GREY['800'] },
  inactive: { ...INACTIVE, contrastText: GREY['800'] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  resultables: RESULTABLES,
  divider: GREY['500_24'],
  foco: OLD_FOCO_THEME,
  action: {
    hover: GREY['500_8'],
    selected: GREY['500_16'],
    disabled: GREY['500_80'],
    disabledBackground: GREY['500_24'],
    focus: GREY['500_24'],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: {
      white: GREY['0'],
      primary: GREY['800'],
      secondary: GREY['600'],
      disabled: GREY['500'],
    },
    background: { paper: '#fff', default: '#fff', neutral: GREY['200'] },
    action: { active: GREY['600'], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY['500'], disabled: GREY['600'] },
    background: {
      paper: GREY['800'],
      default: GREY['900'],
      neutral: GREY['500_16'],
    },
    action: { active: GREY['500'], ...COMMON.action },
  },
}

export default palette
