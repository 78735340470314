import React from 'react'
import { Box, Skeleton } from '@mui/material'

export const TextPlaceholder = () => (
  <Box sx={{ width: 1 }}>
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
  </Box>
)
