import { styled } from '@mui/material/styles'

export const Container = styled('div')`
  border-radius: ${({ contained }) => (contained ? '4px' : '0')};
  margin: ${({ margin }) => margin};
  background: ${({ theme }) => theme.palette.foco.backgroundLight};
  clear: both;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 20px 30px 0;
  }
`

export const Grid = styled('div')`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 20px;

  @media print {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: ${({ theme }) => theme.palette.foco.backgroundLight};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 1rem;
    padding: 0;
    background-color: transparent;

    button {
      flex: 0 0 100px;
      padding: 0;
      margin: 0.4em 0 0;
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: flex;
    flex-direction: row;
  }
`

export const FilterWrapper = styled('div')`
  flex: 1;
  width: 100%;

  .select__label {
    font-size: 12px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.mediumLarge}) {
    flex: ${({ filtersLength }) => (filtersLength > 2 ? '1' : '0 2 180px')};
    width: auto;
    margin-right: 10px;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-right: ${({ filtersLength }) =>
      filtersLength > 3 ? '10px' : '30px'};
    max-width: ${({ maxWidth }) => maxWidth ?? 'none'};
  }
`

export const ToggleFilter = styled('div')`
  height: 44px;
  padding: 0 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  > h1 {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  > button {
    background: none;
    border: none;

    > i {
      width: 22px;
      height: 22px;
    }
  }

  > button.active {
    margin: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`
