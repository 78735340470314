// @flow
import React from 'react'
import { Route } from 'react-router-dom'
import ManageExams from '~pages/ManageExams'
import ManageExamsViewDetails from '~pages/ManageExams/ViewDetails'
import ManageExamsResult from '~pages/ManageExams/Results'
import ExamEdititonEditForm from '~pages/ManageExams/Forms/ExamEdition/Edit'
import ExamEditionCreateForm from '~pages/ManageExams/Forms/ExamEdition/Create'
import ExamCreateForm from '~pages/ManageExams/Forms/Exam/Create'
import ExamEditForm from '~pages/ManageExams/Forms/Exam/Edit'
import ExamDetails from '~pages/ManageExams/ViewDetails/Exam'

const ManageExamsRoutes = () => (
  <>
    <Route
      exact
      path="/gerenciar-avaliacoes/:entityType/:entityId"
      component={ManageExams}
    />

    <Route
      exact
      path="/gerenciar-avaliacoes/:entityType/:entityId/avaliacao/criar"
      component={ExamEditionCreateForm}
    />

    <Route
      exact
      path="/gerenciar-avaliacoes/:entityType/:entityId/avaliacao/:focoExamEditionId/editar"
      component={ExamEdititonEditForm}
    />

    <Route
      exact
      path="/gerenciar-avaliacoes/:entityType/:entityId/avaliacao/:focoExamEditionId/visualizar"
      component={ManageExamsViewDetails}
    />

    <Route
      exact
      path="/gerenciar-avaliacoes/:entityType/:entityId/avaliacao/:focoExamEditionId/resultados"
      component={ManageExamsResult}
    />

    <Route
      exact
      path="/gerenciar-avaliacoes/:entityType/:entityId/avaliacao/:focoExamEditionId/caderno/criar"
      component={ExamCreateForm}
    />

    <Route
      exact
      path="/gerenciar-avaliacoes/:entityType/:entityId/avaliacao/:focoExamEditionId/caderno/:focoExamId/editar"
      component={ExamEditForm}
    />
    <Route
      exact
      path="/gerenciar-avaliacoes/:entityType/:entityId/avaliacao/:focoExamEditionId/caderno/:focoExamId/visualizar"
      component={ExamDetails}
    />
  </>
)

export default ManageExamsRoutes
