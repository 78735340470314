// @flow
import React, { type Node } from 'react'
import { styled } from '@mui/material/styles'
import { triangle } from 'polished'

type Props = {
  className?: string,
  children?: Node,
  arrow?: 'top' | 'bottom' | 'left' | 'right',
  style?: Object,
  padding?: string,
  width?: string,
  index?: string,
}

const getPosition = ({ arrow }) => {
  if (arrow === 'right') return 'right: 40px; top: -10px;'
  if (arrow === 'top') return 'top: 30px; right: -8px;'
  if (arrow === 'bottom') return 'bottom: 25px; right: 0px;'
  return 'left: 40px; top: -10px;'
}

const getArrowPosition = ({ arrow }) => {
  if (arrow === 'right') return 'right: -8px; top: 12px;'
  if (arrow === 'top') return 'top: -8px; right: 10px;'
  if (arrow === 'bottom') return 'bottom: -8px; right: 12px;'
  return 'left: -8px; top: 12px;'
}

const Container = styled('span')`
  font-size: 14px;
  border-radius: 2px;
  box-shadow:
    0px 5px 5px -3px rgb(150 166 169 / 20%),
    0px 8px 10px 1px rgb(150 166 169 / 14%),
    0px 3px 14px 2px rgb(150 166 169 / 12%);
  background: #fff;
  position: relative;
  position: absolute;
  text-transform: none;
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  ${getPosition}
  text-align: left;
  line-height: 20px;
  z-index: 10;

  p {
    margin: 0;
  }

  &:before {
    display: block;
    ${({ theme, arrow }) =>
      triangle({
        pointingDirection: arrow,
        width: ['left', 'right'].includes(arrow) ? '8px' : '12px',
        height: ['left', 'right'].includes(arrow) ? '12px' : '8px',
        foregroundColor: theme.palette.foco.border,
      })}
    content: '';
    position: absolute;
    ${getArrowPosition}
  }

  &:after {
    display: block;
    ${({ arrow }) =>
      triangle({
        pointingDirection: arrow,
        width: ['left', 'right'].includes(arrow) ? '10px' : '12px',
        height: ['left', 'right'].includes(arrow) ? '12px' : '10px',
        foregroundColor: '#FFF',
      })}
    content: '';
    position: absolute;
    ${getArrowPosition}
  }
`

const Tooltip = ({
  className = '',
  children,
  arrow = 'bottom',
  style,
  padding = '25px',
  width = '280px',
}: Props) => {
  return (
    <Container
      arrow={arrow}
      className={`${className}`}
      style={style}
      padding={padding}
      width={width}
    >
      {children}
    </Container>
  )
}

export default Tooltip
