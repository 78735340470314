// @flow
import React, { type Node } from 'react'
import { styled } from '@mui/material/styles'

export const fontSizes = [
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '30px',
  '42px',
]

const Typography = styled('span')`
  width: ${({ width }) => width ?? '100%'};
  font-size: ${({ size }) => fontSizes[size]};
  line-height: ${({ lineHeight = 1.5 }) => `${lineHeight}em`};
  color: ${({ theme, color }) => theme.palette.foco[color]};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  font-weight: 400;
  max-width: ${({ maxWidth }) => maxWidth};
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
  ${({ borderBottom }) => borderBottom && 'border-bottom: 1px solid #F4F4F4;'}
  ${({ invertedBorderBottom }) =>
    invertedBorderBottom && 'border-bottom: 1px solid #E7E7E7;'}
  ${({ underline }) => underline && 'text-decoration: underline;'}
  ${({ semibold }) => semibold && 'font-weight: 600;'}
  ${({ bold }) => bold && 'font-weight: 700;'}
  ${({ italic }) => italic && 'font-style: italic;'};
  ${({ center }) => center && 'text-align: center;'};
  ${({ right }) => right && 'text-align: right;'};
  ${({ inline }) => inline && 'display: inline;'};
  ${({ nowrap }) => nowrap && 'white-space: nowrap;'};
  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ hideMobile }) => hideMobile && 'display: none;'}
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ hideDesktop }) => hideDesktop && 'display: none;'}
  }
`

type Props = {|
  className?: string,
  children?: Node,
  size?: number,
  lineHeight?: number,
  margin?: string,
  padding?: string,
  maxWidth?: string,
  width?: string,
  color?: string,
  uppercase?: boolean,
  underline?: boolean,
  italic?: boolean,
  semibold?: boolean,
  bold?: boolean,
  center?: boolean,
  right?: boolean,
  inline?: boolean,
  nowrap?: boolean,
  hideMobile?: boolean,
  hideDesktop?: boolean,
  borderBottom?: boolean,
  invertedBorderBottom?: boolean,
  'data-testid'?: string,
|}

type TextProps = {|
  ...Props,
  as?: 'p' | 'span' | 'strong' | 'label' | 'legend' | 'li',
|}

export const Text = ({
  children,
  as = 'p',
  size = 2,
  color = 'text',
  margin,
  maxWidth = 'none',
  ...props
}: TextProps) => {
  return (
    <Typography
      as={as}
      size={size}
      margin={margin || (as === 'p' ? '1em 0' : '0')}
      maxWidth={maxWidth}
      {...props}
      color={color}
    >
      {children}
    </Typography>
  )
}

type HeadingProps = {|
  ...Props,
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
|}

export const Heading = ({
  children,
  as = 'h2',
  size = 4,
  color = 'text',
  margin = '1.2em 0',
  maxWidth = 'none',
  ...props
}: HeadingProps) => {
  return (
    <Typography
      as={as}
      size={size}
      color={color}
      margin={margin}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </Typography>
  )
}
