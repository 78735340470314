//@flow

import React from 'react'
import qs from 'qs'

import { useLocation } from 'react-router-dom'
import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material'
import CardGeneralView from '../CardGeneralView'
import FilterBar from '~components/Common/FilterBar'
import SortTable from '~primitives/molecules/Table/SortTable'
import Header from '~components/Common/Header'
import useFilters from '~hooks/useFilters'
import useStudentResultList from '~data/focoExams/student/results'

const Content = () => {
  const { filters } = useFilters()
  const search = qs.parse(useLocation().search)
  const order = search.order || search['?order']
  const orderBy = search.orderBy || search['?orderBy']

  const { palette } = useTheme()
  const { data, error, loading } = useStudentResultList({
    focoExamEditionId: filters?.foco_exam_edition_id.selected.id,
    subjectId: filters?.subject_id.selected.id,
    order,
    orderBy,
  })

  const cellsHeader = [
    { label: 'Tipo de avaliação', property: 'foco_exam_edition' },
    {
      label: 'Disciplina',
      property: 'subject',
      align: 'center',
    },
    {
      label: 'Taxa de acerto',
      property: 'hit_rate',
      align: 'center',
    },
    { label: '', align: 'center' },
  ]

  if (error || !data) return null

  const rows = normalizeTableRow(data?.foco_exam_student_profiles)

  return (
    <React.Fragment>
      <Header
        title="Meus resultados"
        breadcrumbs={[
          {
            id: '',
            text: 'Avaliações',
            pathname: '/',
          },
          {
            id: '',
            text: 'Meus resultados',
            pathname: '/avaliacoes-foco/meus-resultados',
          },
        ]}
      />

      <FilterBar contained margin="20px 0" />

      <Typography variant="h5">Visão geral</Typography>

      <Box my={2} p={2.5} bgcolor="grey.200">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} xl={2}>
            <CardGeneralView
              heading={'Participação nas provas'}
              content={
                data?.participation_rate ? data?.participation_rate * 100 : 0
              }
              suffix="%"
              backgroundColor={
                data?.participation_rate > 50
                  ? palette.success.main
                  : palette.error.main
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={3} xl={2}>
            <CardGeneralView
              heading={'Média da taxa de acerto'}
              content={
                data?.average_hit_rate ? data?.average_hit_rate * 100 : 0
              }
              suffix="%"
              backgroundColor={
                data?.average_hit_rate > 50
                  ? palette.success.main
                  : palette.error.main
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={3} xl={2}>
            <CardGeneralView
              heading={'Ranking da turma'}
              icon={data?.classroom_ranking && data?.classroom_ranking <= 5}
              suffix={data?.classroom_ranking && 'º'}
              loading={loading}
              content={data?.classroom_ranking || ' - '}
              backgroundColor={palette.secondary.main}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb={3}>
        <SortTable
          variant="simple"
          rows={loading || !data ? getPlaceholder() : rows}
          rowsAlign={['left', 'center', 'center', 'center']}
          cellsHeader={cellsHeader}
        />
      </Box>
    </React.Fragment>
  )
}

export default Content

const normalizeTableRow = (data) =>
  data
    ? data?.map(({ foco_exam_edition, subject, hit_rate }, index) => ({
        fields: [foco_exam_edition.name, subject.name, hit_rate * 100 + '%'],
        key: index,
      }))
    : []

const getPlaceholder = () =>
  [...Array(5).keys()].map((_, index) => ({
    fields: [
      <Skeleton key={`name-${index}`} variant="text" />,
      <Skeleton key={`subject-${index}`} variant="text" />,
      <Skeleton key={`hit-rate-${index}`} variant="text" />,
    ],
    key: index,
  }))
