// @flow
export const getStyles = (theme: Object) => ({
  label: {
    height: 24,
    borderRadius: theme.shape.borderRadius / 2,
    color: theme.palette.grey[0],
    p: 0.5,
    fontSize: 12,
    lineHeight: '16px',
  },
  link: {
    height: 36,
    width: 1,
    mr: 1,
    maxWidth: 126,
    color: theme.palette.secondary.main,
  },
})
