// @flow
import React, { useState } from 'react'
import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material'
import { useParams, useLocation } from 'react-router-dom'
import qs from 'qs'
import { CreateExamButton } from './CreateExamButton'
import { DeleteModal } from './Modal/Delete'
import { StatusModal } from './Modal/Status'
import ExamsList from './ExamsList'
import FilterBar from '~components/Common/FilterBar'
import { useManageExamsList } from '~data/manageExams/examList'
import useFilters from '~hooks/useFilters'

export const Content = () => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false)
  const [selectedExam, setSelectedExam] = useState({ id: 0, name: '' })
  const { entityType, entityId } = useParams()
  const { filters } = useFilters()
  const { search } = useLocation()

  const handleOpenDeleteModal = ({ name, id }) => {
    setSelectedExam({ name, id })
    setDeleteModalIsOpen(true)
  }

  const handleOpenStatusModal = ({ name, id }) => {
    setSelectedExam({ name, id })
    setStatusModalIsOpen(true)
  }

  const { order, orderBy, page } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })

  const { data, error } = useManageExamsList({
    entityId: entityId ?? '',
    entityType: entityType ?? '',
    id: filters?.foco_exam_edition_id.selected.id,
    gradeId: filters?.grade_id.selected.id,
    subjectId: filters?.subject_id.selected.id,
    status: filters?.status_id.selected.id,
    order,
    orderBy,
    page: page ? Number(page) : 1,
  })

  return (
    <>
      <Box>
        <FilterBar margin="0" contained wrapperProps={{ maxWidth: '160px' }} />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={5}
        >
          <Typography variant="h4">Lista de avaliações</Typography>
          <CreateExamButton entityId={Number(entityId)} />
        </Stack>

        {error && (
          <Box mt={3}>
            <Alert variant="filled" severity="error">
              <AlertTitle>Erro</AlertTitle>
              Ocorreu um erro ao tentar carregar as avaliações - {error.status}
            </Alert>
          </Box>
        )}

        <Box pt={3}>
          {data ? (
            <ExamsList
              data={data?.foco_exam_editions}
              meta={data?.meta}
              page={page ? Number(page) : 1}
              handleOpenDeleteModal={handleOpenDeleteModal}
              handleOpenStatusModal={handleOpenStatusModal}
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>

      <DeleteModal
        isOpen={deleteModalIsOpen}
        setIsOpen={setDeleteModalIsOpen}
        selectedExam={selectedExam}
      />

      <StatusModal
        isOpen={statusModalIsOpen}
        setIsOpen={setStatusModalIsOpen}
        selectedExam={selectedExam}
      />
    </>
  )
}
