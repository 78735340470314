// @flow
import React from 'react'
import { Typography } from '@mui/material'
import type { PerformanceProficiency } from '~data/manageExams/results/performanceProficiency'
import { formatDecimalPercent } from '~helpers/formatting/numbers'

type Props = {
  proficiency: PerformanceProficiency,
}

const formatRate = formatDecimalPercent(0)

export const BelowBasicText = ({ proficiency }: Props) => {
  const { insufficient, below_basic, basic, proficient, advanced } = proficiency

  const totalStudents =
    insufficient + below_basic + basic + proficient + advanced

  const lowLevelStudents = insufficient + below_basic

  const lowLevelRate =
    totalStudents === 0 ? 1 : lowLevelStudents / totalStudents

  return (
    <Typography variant="body2">
      <strong>
        {lowLevelStudents} alunos ({formatRate(lowLevelRate)})
      </strong>{' '}
      estão com taxa de acerto insuficiente ou abaixo do básico
    </Typography>
  )
}
