import React from 'react'
import { useParams } from 'react-router-dom'
import { Info, ErrorMessage } from './styles'

import useResource from '~hooks/useResource'
import Auth from '~resources/auth'

const CPF = () => {
  const { cpf } = useParams()

  const { error, loading, data } = useResource({
    resource: Auth.requestPasswordReset,
    params: { identifier: cpf },
  })

  if (loading) {
    return 'Carregando...'
  }

  if (error) {
    return (
      <ErrorMessage>
        <h2>Houve um erro ao processar o pedido:</h2>
        <p>{error.message}</p>
      </ErrorMessage>
    )
  }

  return (
    <div>
      <Info>
        <h3>
          Você recebeu uma mensagem no e-mail{' '}
          <strong>{data?.user.email}</strong>
        </h3>
        <div>
          <p>
            Para finalizar seu cadastro, acesse a{' '}
            <strong>caixa de entrada de seu e-mail</strong> e siga as instruções
            da mensagem que enviamos.
          </p>
        </div>
        <p>
          Aguarde 2 minutos, por favor. Caso não receba a mensagem, verifique
          sua <strong>caixa de spam</strong> ou{' '}
          <strong>lixo eletrônico.</strong>
        </p>
      </Info>
    </div>
  )
}

export default CPF
