// @flow
export default function Radio(theme: any) {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 1, 0, 1),
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  }
}
