// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export type Skill = {
  id: number,
  name: string,
}

export type SkillsResponse = { curriculum_skills: Skill[] }

export const endpoint = '/api/v3/curriculum_skills'

type Params = {
  subjectId?: number,
  gradeId?: number,
  competencyId?: number | null,
}

export const useCurriculumSkills = (params: Params) => {
  const { loading, error, data } = useResource({
    resource: getCurriculumSkills,
    key: 'useCurriculumSkills',
    params,
    enabled: !!params?.subjectId || !!params?.gradeId || !!params?.competencyId,
  })

  return { loading, error, data }
}

const getCurriculumSkills = (params: Params): APIResponse<SkillsResponse> =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      include: ['curriculum_skills'],
      fields: [{ curriculum_skills: ['id', 'name', 'description'] }],
      q: {
        subject_id_eq: params?.subjectId,
        grades_id_eq: params?.gradeId,
        curriculum_competency_id_eq: params?.competencyId,
      },
    },
  })

export default useCurriculumSkills
