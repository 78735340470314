// @flow
import { requestTemplate, type RequestTemplate } from './params'
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export type SkillsResponse = { curriculum_skills: any }

export const endpoint = '/api/v3/curriculum_skills'

type Params = {
  requestTemplate: RequestTemplate,
  curriculumSkillId?: string | number,
}

export const useCurriculumSkills = (params: Params) => {
  const { loading, error, data } = useResource({
    resource: getCurriculumSkills,
    key: 'useCurriculumSkills',
    params,
    enabled: !!params.curriculumSkillId,
  })

  return { loading, error, data }
}

const getCurriculumSkills = (params: Params): APIResponse<SkillsResponse> =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      ...requestTemplate['modal-suggested-material'](params),
    },
  })

export default useCurriculumSkills
