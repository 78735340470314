//@flow
import type { Profile } from '~types/Profile'

export const getCurriculumLink = (profile: Profile, resource: string) => {
  const basePath = `/curriculo/${profile.kind}/${profile.personable.id}`

  if (resource === 'curriculums_management') return basePath + '/listar'

  if (profile.kind === 'school') {
    if (resource === 'calendar') return basePath + '/calendario'
    if (resource === 'events_management') return basePath
  } else {
    if (resource === 'events_management') return basePath + '/gestao'
  }

  return ''
}
