//@flow
import { fetchWithRoot } from '~helpers/fetch'

const endpoint_event = (eventId: number) =>
  `api/v3/curriculum_events/${eventId}`

type Params = {
  data: any,
  eventId: number,
  entityId: number,
}

export const updateEvent = ({ data, eventId, entityId }: Params) => {
  return fetchWithRoot('patch')(endpoint_event(eventId), {
    data: {
      entity_id: entityId,
      entity_type: 'school',
      curriculum_event: {
        ...data,
      },
    },
  })
}
