export const getStyles = (theme) => ({
  container: {
    mb: 2.5,
    py: 1.5,
    px: 4,
    gap: 3,
    borderRadius: theme.shape.borderRadius * 2,
    bgcolor: 'grey.200',
  },
})
