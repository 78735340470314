// @flow
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'

type Params = {
  id: number,
  entityType: string,
  entityId: number,
}

export const getEndpoint = (id: number) => `/api/v3/foco_exams/${id}`

export const useBookletFormDetails = (params: Params) => {
  const { data, loading, error } = useResource({
    key: 'useBookletFormDetails',
    resource: getBookletFormDetails,
    params,
    enabled: !!params.id || !!params.entityId || !!params.entityType,
  })

  return { data, loading, error }
}

type BookletResponse = {
  name: string,
  foco_exam_edition: { id: number, name: string },
  subject: { id: number, name: string },
  grade: { id: number, name: string },
  foco_exam_foco_questions: Array<{ foco_question_id: number }>,
  foco_exam_student_profiles: Array<{
    student_profile: { id: number, name: string },
  }>,
}

const getBookletFormDetails = ({
  id,
  entityId,
  entityType,
}: Params): APIResponse<BookletResponse> =>
  fetchWithRoot('get')(getEndpoint(id), {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      include: [
        'foco_exam',
        'foco_exam_edition',
        'subject',
        'grade',
        'classrooms',
        'foco_exam_foco_questions',
        { foco_exam_student_profiles: ['student_profile'] },
      ],
      fields: [
        ['name'],
        {
          foco_exam_edition: ['id', 'name'],
          subject: ['id', 'name'],
          grade: ['id', 'name'],
          classrooms: ['id', 'name'],
          foco_exam_foco_questions: ['foco_question_id', 'id', 'position'],
          foco_exam_student_profiles: [{ student_profile: ['id'] }],
        },
      ],
    },
  })
