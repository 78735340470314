// @flow
import { useSelector } from 'react-redux'
import type { FeatureName } from '~types/Profile'
import type { UserState } from '~reducers/user'

const useProfileData = () => {
  const { features }: UserState = useSelector((state) => state.user)

  const getFeature = (featureName: FeatureName) => {
    return features[featureName]
  }

  const getResource = (
    featureName: FeatureName,
    resourceName: string,
    exam_id?: number
  ) => {
    if (!features[featureName]) return

    const resource = features[featureName].resources.find(({ resource, id }) =>
      exam_id
        ? id === Number(exam_id) && resource === resourceName
        : resource === resourceName
    )

    return resource
  }

  const profileHasFeature = (featureName: FeatureName, resourceName: string) =>
    !!getResource(featureName, resourceName)

  return { getFeature, getResource, profileHasFeature }
}

export default useProfileData
