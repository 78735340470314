//@flow
import React from 'react'
import { Tooltip, IconButton } from '@mui/material'
import { SVGIcon, type IconName } from '~primitives/Utilities/SVGIcon'

type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'

type Props = {
  tooltipTitle: string,
  tooltipPlacement?: Placement,
  iconName: IconName,
  ...
}

export const ActionButton = (props: Props) => {
  const { tooltipTitle, tooltipPlacement, iconName, ...rest } = props

  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement} arrow>
      <IconButton {...rest} variant="outlined" color="secondary">
        <SVGIcon name={iconName} size={3} />
      </IconButton>
    </Tooltip>
  )
}
