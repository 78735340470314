// @flow
import { prop, isNil } from 'ramda'
import { useSelector } from 'react-redux'
import type { UserState } from '~reducers/user'

export default function useIsLoggedin() {
  const user: UserState = useSelector(prop('user'))
  const isLoggedin = !isNil(user.session?.token) && !isNil(user.selectedProfile)

  return isLoggedin
}
