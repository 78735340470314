// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export const activities_endpoint = '/api/v3/curriculum_activities'
export const competencies_endpoint = '/api/v3/curriculum_competencies'
export const curriculum_units_endpoint = '/api/v3/minimum_curriculum_units'
export const update_event_endpoint = (eventID: number) =>
  `/api/v3/curriculum_events/${eventID}`

export const useCurriculumActivities = () => {
  const { error, loading, data } = useResource({
    key: 'getCurriculumActivities',
    resource: getCurriculumActivities,
  })

  return { error, loading, data }
}

export const getCurriculumActivities = () =>
  fetchWithRoot('get')(activities_endpoint, {
    bypassLoader: true,
    params: {
      page: 1,
      per_page: 10,
      include: ['curriculum_activities'],
      fields: [{ curriculum_activities: ['id', 'name', 'active'] }],
      q: { active_eq: true },
    },
  })

export const useCurriculumCompetencies = (subject_id: number) => {
  const { error, loading, data } = useResource({
    key: 'getCurriculumCompetencies',
    resource: getCurriculumCompetencies,
    params: { subject_id },
  })

  return { error, loading, data }
}

export const getCurriculumCompetencies = (params: { subject_id: number }) =>
  fetchWithRoot('get')(competencies_endpoint, {
    bypassLoader: true,
    params: {
      include: ['curriculum_competencies'],
      fields: [{ curriculum_competencies: ['id', 'name', 'description'] }],
      q: {
        active_eq: true,
        subject_id_eq: params.subject_id,
        curriculum_skills_minimum_curriculum_units_id_not_null: true,
      },
    },
  })

export const useCurriculumUnits = (
  competency_id: ?number,
  grade_id: number
) => {
  const { error, loading, data } = useResource({
    key: 'getCurriculumUnits',
    resource: getCurriculumUnits,
    params: { competency_id, grade_id },
    enabled: !!competency_id,
  })

  return { error, loading, data }
}

export const getCurriculumUnits = (params: {
  competency_id: ?number,
  grade_id: number,
}) => {
  return fetchWithRoot('get')(curriculum_units_endpoint, {
    bypassLoader: true,
    params: {
      include: [{ minimum_curriculum_units: ['curriculum_skill'] }],
      fields: [
        {
          minimum_curriculum_units: [
            'id',
            'name',
            { curriculum_skills: ['description'] },
          ],
        },
      ],
      q: {
        active_eq: true,
        curriculum_skill_curriculum_competency_id_eq: params.competency_id,
        curriculum_skill_grades_id_eq: params.grade_id,
      },
    },
  })
}

type Params = {
  data: any,
  eventId: number,
  entityId: number,
}

export const updateEvent = ({ data, eventId, entityId }: Params) => {
  return fetchWithRoot('patch')(update_event_endpoint(eventId), {
    data: {
      entity_id: entityId,
      entity_type: 'school',
      curriculum_event: {
        ...data,
        performed: Number(data.performed),
        performed_to_plan: Number(data.performed_to_plan),
        curriculum_events_curriculum_materials_attributes:
          data?.curriculumMaterials,
        minimum_curriculum_unit_id:
          Number(data.performed_to_plan) || !Number(data.performed)
            ? undefined
            : data.minimum_curriculum_unit_id,
      },
      fields: ['id'],
    },
  })
}
