// @flow
import React, { useState } from 'react'
import qs from 'qs'
import FormBirthdate from '~components/Register/Personal/FormBirthdate'
import FormEmail from '~components/Register/Personal/FormEmail'

type Props = {
  match: { params: { cpf: string } },
  location: { search: string },
}
type State = {
  step: 'birthdate' | 'terms-and-conditions' | 'email',
  birthYear: string,
}

const Personal = (props: Props) => {
  const [state, setState] = useState<State>({
    step: 'birthdate',
    birthYear: '',
  })

  const { step, birthYear } = state
  const { cpf } = props.match.params
  const { name } = qs.parse(props.location.search, { ignoreQueryPrefix: true })

  const handleClickReset = (step) => {
    setState({
      step: step,
      birthYear: birthYear,
    })
  }

  const handleBirthDateValidationSuccess = (birthYear: string) => {
    setState({
      step: 'email',
      birthYear,
    })
  }

  return (
    <>
      {step === 'birthdate' && (
        <FormBirthdate
          cpf={cpf}
          name={name}
          onSuccess={handleBirthDateValidationSuccess}
          returnPath="/cadastro"
        />
      )}

      {step === 'email' && (
        <FormEmail
          cpf={cpf}
          birthYear={birthYear}
          onClickReset={handleClickReset}
        />
      )}
    </>
  )
}

export default Personal
