import { styled } from '@mui/material/styles'

export const LoaderWrapper = styled('div')`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  z-index: 10000;
  display: flex;
  justify-content: center;
`
