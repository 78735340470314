// @flow
import React from 'react'
import { FormControl, Select, InputLabel, MenuItem } from '@mui/material'

type Props = {
  classroomOptions: Array<{ id: number, name: string, enabled: boolean }>,
  selectedClassroom: number,
  handleChangeClassroom: (SyntheticInputEvent<HTMLInputElement>) => void,
}

export const SelectClassroom = ({
  classroomOptions,
  selectedClassroom,
  handleChangeClassroom,
}: Props) => {
  const styles = getStyles()

  return (
    <FormControl
      sx={{
        ...styles.formControl,
      }}
    >
      <InputLabel id="classroom-label" required>
        Turma
      </InputLabel>
      <Select
        id="select-classroom"
        name="classroom"
        label="classroom-label"
        value={selectedClassroom}
        variant="standard"
        size="small"
        onChange={handleChangeClassroom}
      >
        {classroomOptions.length === 0 && <MenuItem value={0}>-</MenuItem>}
        {classroomOptions.length > 0 &&
          classroomOptions.map(({ id, name, enabled }) => (
            <MenuItem key={id} value={id} disabled={!enabled}>
              {name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

const getStyles = () => ({
  formControl: {
    minWidth: 110,
  },
})
