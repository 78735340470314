// @flow
import { alpha } from '@mui/material/styles'

export default function Select(theme: Object) {
  return {
    MuiSelect: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            '&.MuiOutlinedInput-root': {
              '& .MuiSelect-outlined': {
                ...theme.typography.body2,
                lineHeight: 'inherit',
                borderRadius: theme.shape.borderRadius * 2,
                border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                padding: '0 20px',
                height: '100%',
                boxSizing: 'border-box',
                '&::placeholder': {
                  color: theme.palette.grey[600],
                },
              },
              '&.Mui-error': {
                color: theme.palette.grey[800],
                '& .MuiSelect-outlined': {
                  border: `1px solid ${theme.palette.error.main}`,
                },
              },
            },
            fieldset: {
              display: 'none',
            },
          },
        },
        {
          props: { variant: 'standard' },
          style: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius * 2,
            padding: 0,
            marginTop: '4px !important',

            '&.Mui-error': {
              border: `1px solid ${theme.palette.error.main}`,
            },

            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
            '& .MuiSelect-select': {
              padding: '0 20px',
              ...theme.typography.body2,
              '&:focus': {
                background: 'transparent',
              },
            },
            '.MuiSvgIcon-root': {
              color: '#00CEF4',
            },
            '.MuiInputBase-input': {
              em: {
                opacity: 0.5,
                color: theme.palette.grey[600],
                fontStyle: 'initial',
              },
            },
            '.MuiInputBase.MuiSelect-select': {
              ...theme.typography.body2,
              paddingTop: 0,
              paddingBottom: 0,
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[200],
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[200],
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[200],
            },
          },
        },
      ],
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              boxShadow: 'none',
              padding: 0,
              width: 'auto',
              maxHeight: 200,
            },
          },
          MenuListProps: {
            style: { padding: 0 },
          },
        },
        inputProps: {
          style: {
            padding: 0,
          },
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius * 2,
          padding: 0,
          '.MuiSvgIcon-root': {
            color: theme.palette.secondary.main,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[200],
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[200],
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[200],
          },
          '& .MuiNativeSelect-select': {
            lineHeight: 'initial',
          },
        },
      },
    },
  }
}
