// @flow
import { styled } from '@mui/material/styles'

export const Container = styled('section')`
  display: flex;
  flex-direction: column;
  margin: 20px;
  max-width: 700px;
  background: #fff;
  border-radius: 3px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 50px auto 20px;
  }
`

export const FeatureIcon = styled('i')`
  flex: 0 0 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-right: 10px;
  background: ${({ theme }) => theme.palette.foco.backgroundLight};

  > i {
    background-color: ${({ theme }) => theme.palette.foco.primary};
  }
`

export const ContainerHeader = styled('header')`
  display: flex;
  justify-content: center;
  background: #fff;
  padding: 25px 20px;

  h2 {
    font-size: 18px;
    line-height: 1.3em;
    display: inline;
    width: auto;
  }
`

export const FormHeader = styled('header')`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.foco.backgroundLight};

  h3 {
    border-bottom: 1px solid ${({ theme }) => theme.palette.foco.border};
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 40px;
    margin: 0 70px;
  }
`

export const Form = styled('form')`
  padding: 20px;
  background: ${({ theme }) => theme.palette.foco.backgroundLight};

  div {
    margin-bottom: 30px;

    label {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 40px;
    margin: 0 70px;
    padding-bottom: 0;
    margin-bottom: 40px;
  }
`
