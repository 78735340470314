// @flow
import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (
  entityType: string,
  entityId: number,
  curriculumId: number
) => `/api/v3/${entityType}s/${entityId}/base_curriculums/${curriculumId}`

type DeleteParams = {
  entityType: string,
  entityId: number,
  curriculumId: number,
}

export const deleteCurriculum = async ({
  entityType,
  entityId,
  curriculumId,
}: DeleteParams) => {
  if (!curriculumId) return false

  return fetchWithRoot('delete')(
    getEndpoint(entityType, entityId, curriculumId)
  ).then(([error]) => {
    if (error) throw new Error(error?.message ?? error?.status)
  })
}
