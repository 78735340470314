// @flow
import React from 'react'
import { Stack, Typography } from '@mui/material'
import SuccessCheck from '~assets/svg/success_check.svg'
import WarningForm from '~assets/svg/warning_form.svg'

type Props = {
  data: Array<{
    classroom: { id: number, name: string },
    subject: { id: number, name: string },
    success: boolean,
    error_message: string,
  }>,
}

export default function Feedback({ data }: Props) {
  if (!data) return null

  return (
    <Stack alignItems="flex-start" p={2} gap={2}>
      {data.map(({ classroom, subject, success, error_message }, index) => {
        return (
          <Stack direction="row" alignItems="flex-start" gap={1} key={index}>
            <img
              src={success ? SuccessCheck : WarningForm}
              alt={`Icone de ${success ? 'sucesso' : 'alerta'}`}
              width="32px"
            />
            <Typography variant="body1">
              <strong>{classroom.name}</strong> -{' '}
              <strong>{subject.name}</strong>:{' '}
              {success ? 'Plano de aula criado com sucesso' : error_message}.
            </Typography>
          </Stack>
        )
      })}
    </Stack>
  )
}
