// @flow
import React from 'react'
import { InsightBox as Box } from '~components/Common/Insight/Box'

type Props = {
  label?: string,
  value?: string,
  suffix?: string,
  name: string,
  entity: string,
}

export const InsightBox = ({ label, value, suffix, name, entity }: Props) => {
  return (
    <Box
      name={name}
      label={label ?? '-'}
      labelSize={14}
      value={value ?? '-'}
      valueSize={26}
      suffix={suffix}
      suffixSize={26}
      sx={{
        bgcolor: `resultables.${entity}`,
        color: 'grey.0',
        maxWidth: 224,
        height: 86,
      }}
    />
  )
}
