// @flow strict
import type { Profile } from '~types/Profile'

type Feature = 'retrospective' | 'prospective'

export const getAAPLink = (feature: Feature, id: number, profile: ?Profile) =>
  !!profile && profile.kind === 'school'
    ? `/diagnostica/${id}/${feature}/school/${profile.personable.id}`
    : `/selecao/${feature}/aap/${id}`

export const getEnemQuestionsLink = (profile: ?Profile) =>
  !!profile && profile.kind === 'school'
    ? `/enem/analise-questoes?school_id=${profile.personable.id}`
    : '/selecao/enem/question_analysis'
