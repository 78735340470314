// @flow
import { fetchWithRoot } from '~helpers/fetch'

export const getEndpoint = (examId: string) => `/api/v3/foco_exams/${examId}`

type DeleteParams = {
  examId: number,
}

export const deleteExam = async ({ examId }: DeleteParams) =>
  fetchWithRoot('delete')(getEndpoint(String(examId))).then(([error]) => {
    if (error) {
      throw new Error(error?.message ?? `Erro: ${error?.status}`)
    }
  })
