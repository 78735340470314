// @flow
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'

const endpoint = '/api/v3/foco_exam_student_profiles'

type Params = {
  focoExamEditionId?: string,
  subjectId?: String,
  order?: string,
  orderBy?: string,
}

type StudentList = {
  participation_rate: number,
  average_hit_rate: number,
  classroom_ranking: number,
  foco_exam_student_profiles: [
    {
      id: number,
      displayed?: boolean,
      started_at?: string,
      answered_at?: string,
      answered?: boolean,
      elapsed_time?: number,
      hit_rate?: number,
      foco_exam_edition: {
        id: number,
        name: string,
      },
      subject: {
        id: number,
        name: string,
      },
    },
  ],
}

const useStudentResultList = (params: Params) => {
  const { data, loading, error } = useResource({
    key: 'useStudentsResultList',
    resource: getStudentResultList,
    params,
    enabled: !!params,
  })

  return { loading, error, data }
}

const getStudentResultList = ({
  focoExamEditionId,
  subjectId,
  order = 'asc',
  orderBy = 'foco_exam_student_profiles',
}: Params): APIResponse<StudentList> =>
  fetchWithRoot('get')(endpoint, {
    params: {
      q: {
        s: `${orderBy} ${order}`,
        foco_exam_edition_id_eq: focoExamEditionId || undefined,
        subject_id_eq: subjectId || undefined,
      },
      fields: [
        'participation_rate',
        'average_hit_rate',
        'classroom_ranking',
        {
          foco_exam_student_profiles: [
            'id',
            'hit_rate',
            {
              foco_exam_edition: ['id', 'name'],
              subject: ['id', 'name'],
            },
          ],
        },
      ],
      include: [
        {
          foco_exam_student_profiles: ['foco_exam_edition', 'subject'],
        },
      ],
    },
    bypassLoader: true,
  })

export default useStudentResultList
