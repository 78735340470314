import { createGlobalStyle } from 'styled-components'
import { focoEscolaTheme } from './themes/focoEscola'
import { fontFace } from './typography'

const theme = focoEscolaTheme()

export default createGlobalStyle`
  ${fontFace};

  html{ 
    scroll-behavior: smooth;
    height: auto;
  }

  /* http://meyerweb.com/eric/tools/css/reset/
   * v2.0 | 20110126
   * License: none (public domain)
   */

  * {
    box-sizing: border-box;
  }
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-weight: 400;
  }

  
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  strong {
    font-weight: 700;
  }
  button, input, textarea {
    font-family: 'Aribau Grotesk', sans-serif;
  }

  /* Reset end */

  body {
    font-family: 'Aribau Grotesk', sans-serif;
    line-height: 1;
    color: ${theme.text};
    background-color: #fff;
    overflow-y: scroll;
    height: 100%;
  }

  @media print  {
      * {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-print-color-adjust: exact;
        color-adjust: exact;

        @page {
          margin: 5mm;
          size: A4;
        }
      }

      .hideOnPrint {
        display: none !important;
      }
  }


  #app {
    background-color: #fff;

  }

  a {
    color: ${theme.linkColor};
    text-decoration: none;

    &:hover, &:focus {
      color: ${theme.hoverColor};
    }
  }

  *:hover, *:focus {
    outline: none;
  }

  /* Dialog global styles */
  :root {
    --reach-dialog: 1;
  }

  [data-reach-dialog-overlay] {
    background: hsla(0, 0%, 0%, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    /* Customization */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
  }

  [data-reach-dialog-content] {
    max-width: 80vw;
    margin: 10vh auto;
    background: white;
    padding: 2rem;
    outline: none;
  }
`
