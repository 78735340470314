// @flow
import type { APIResponse } from '~types/Network'
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'

export type Competency = {
  id: number,
  name: string,
  description: string,
}

export type CompetenciesResponse = { curriculum_competencies: Competency[] }

export const endpoint = '/api/v3/curriculum_competencies'

type Params = {
  subjectId?: number,
  gradeId?: number,
}

export const useCurriculumCompetencies = (params: Params) => {
  const { loading, error, data } = useResource({
    resource: getCurriculumCompetencies,
    key: 'useCurriculumCompetencies',
    params,
    enabled: !!params?.subjectId || !!params?.gradeId,
  })

  return { loading, error, data }
}

const getCurriculumCompetencies = (
  params: Params
): APIResponse<CompetenciesResponse> =>
  fetchWithRoot('get')(endpoint, {
    bypassLoader: true,
    params: {
      include: ['curriculum_competencies'],
      fields: [{ curriculum_competencies: ['id', 'name', 'description'] }],
      q: {
        subject_id_eq: params?.subjectId,
        grade_id_eq: params?.gradeId,
      },
    },
  })

export default useCurriculumCompetencies
