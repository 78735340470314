//@flow
import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import Modal from '~primitives/Modal'
import SuccessCheck from '~assets/svg/success_check.svg'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'

type Props = {
  onClose: () => void,
  isEdit: boolean,
}

export const ModalSuccess = ({ onClose, isEdit }: Props) => {
  return (
    <Modal
      width={'500px'}
      height="98vh"
      confirmButtonText="Salvar"
      overflowContent={true}
      contentPadding="5px 30px 20px"
      showFooter={false}
      onClose={onClose}
    >
      <Typography variant="h4" margin="15px 0 5px">
        <UnderlinedHeading as="span">
          <strong>Turno</strong>
        </UnderlinedHeading>
      </Typography>
      <Stack variant="modalBox">
        <Stack direction="column" alignItems="center" gap={1}>
          <img src={SuccessCheck} alt="" width="90px" />
          <Typography variant="h4">
            <strong>{isEdit ? 'Tuno Editado' : 'Turno Criado'}</strong>
          </Typography>
          <Typography variant="body2">
            O turno foi {isEdit ? 'editado' : 'editado'} com sucesso
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" mt={2}>
        <Button variant="primary" size="small" onClick={onClose}>
          Voltar
        </Button>
      </Stack>
    </Modal>
  )
}
