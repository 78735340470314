import { styled } from '@mui/material/styles'
import { Clearfix } from '~primitives/Utilities/Clearfix'

export const LoggedInContainer = styled(Clearfix.withComponent('main'))`
  padding-left: 0px;
  transition: padding 0.2s ease-in-out;

  @media (min-width: 800px) {
    padding-left: 190px;
  }
  @media print {
    padding: 0;
  }
`

export const LoggedInInner = styled(Clearfix.withComponent('section'))`
  position: relative;
  min-height: 100vh;
  padding: 0;
  overflow-x: hidden;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-bottom: 132px;
  }
`
