//@flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpoint = (id: number) =>
  `/api/v3/foco_exam_editions/${id}/foco_exam_student_profiles`

type Params = {
  editionId: number,
  examId: ?number,
}

type StudentsProfile = {
  id: number,
  hit_rate: number,
  answered_at: string,
  student_profile: {
    user: {
      id: number,
      name: string | null,
    },
  },
}

export type StudentsList = {
  foco_exam_student_profiles: StudentsProfile[],
}

export const useManageExamsStudentList = (params: Params) => {
  const { data, error, loading } = useResource({
    key: 'useManageExamsStudentList',
    resource: getManageExamsStudentList,
    params,
    enabled: true,
  })

  return { loading, error, data }
}

const getManageExamsStudentList = ({
  editionId,
  examId,
}: Params): APIResponse<StudentsList> =>
  fetchWithRoot('get')(endpoint(editionId), {
    params: {
      include: [
        { foco_exam_student_profiles: [{ student_profile: ['user'] }] },
      ],
      fields: [
        {
          foco_exam_student_profiles: [
            'id',
            'hit_rate',
            'answered_at',
            'started_at',
            {
              student_profile: [{ user: ['id', 'name'] }],
            },
          ],
        },
      ],
      q: { foco_exam_id_eq: examId },
    },
  })
