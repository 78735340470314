// @flow
import React from 'react'
import { styled } from '@mui/material/styles'
import Icons from './data'
import { fontSizes } from '~primitives/Typography'
import type { ThemeColor } from '~styles/themes/focoEscola'

export type IconName = $Keys<typeof Icons>

type Props = {
  name: IconName,
  color?: ThemeColor,
  size?: number | string,
  ['data-testid']: string | void,
}

const StyledIcon = styled('i')`
  font-size: ${({ size }) =>
    size ? (fontSizes[size] ? fontSizes[size] : size) : '1em'};
  display: inline-block;
  background-color: ${({ theme, color }) => theme.palette.foco[color] || color};
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url('${({ url }) => url}');
  width: 1em;
  height: 1em;
  text-indent: -9999em;
  vertical-align: middle;
`

export const SVGIcon = ({
  name,
  color,
  size,
  ['data-testid']: testId,
}: Props) => (
  <StyledIcon
    className="svg-icon"
    url={Icons[name].src}
    color={color}
    size={size}
    aria-hidden
    data-testid={testId}
  >
    {Icons[name].alt}
  </StyledIcon>
)
