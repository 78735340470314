//@flow
import { config } from '../../config'
import useResource from '~hooks/useResource'
import { fetchWithRoot } from '~helpers/fetch'
import type { APIResponse } from '~types/Network'
import { getFieldsAndIncludes } from '~data/helpers'

const { endpoint, key } = config

type Extension = $Keys<typeof extensionsConfig>

type Params = {
  entityType: string,
  entityId: number,
  classroomId?: number,
  page?: number,
  perPage?: number,
  order?: string,
  orderBy?: string,
  searchText?: string,
  enabled?: boolean,
  extensions: Extension[],
  ...
}

export const useStudentProfiles = (params: Params = {}) => {
  const { data, loading, error } = useResource({
    key,
    resource: getStudentProfiles,
    params,
  })
  return { loading, error, data }
}

const getStudentProfiles = ({
  extensions,
  entityType,
  entityId,
  page,
  perPage,
  order,
  orderBy,
  classroomId,
  searchText,
}: Params): APIResponse<any> =>
  fetchWithRoot('get')(endpoint, {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      page: page || 1,
      per_page: perPage,
      q: {
        s: order && orderBy && `${orderBy} ${order}`,
        classroom_id_eq: classroomId,
        user_name_or_user_email_matches: searchText && `%${searchText}%`,
      },
      ...getFieldsAndIncludes(extensionsConfig, extensions),
    },
    bypassLoader: true,
  })

const extensionsConfig = {
  default: {
    fields: [{ student_profiles: ['id', 'registration_number', 'active'] }],
  },
  manageStudents: {
    include: [{ student_profiles: ['user', 'classroom'] }],
    fields: [
      'meta',
      {
        student_profiles: ['classroom', { user: ['id', 'name', 'email'] }],
      },
    ],
  },
  manageClassrooms: {
    include: [{ student_profiles: ['user'] }],
    fields: [{ student_profiles: [{ user: ['email', 'name'] }] }],
  },
}
