// @flow
import { useMutation, useQueryClient } from 'react-query'
import { config } from '../config'
import { fetchWithoutRoot } from '~helpers/fetch'

const { endpoint } = config

type Props = {
  onSuccess: (Object) => void,
  onError: (Object) => void,
}

type Params = {
  entityType: string,
  entityId: number,
  name: string,
  hour_initial: Object,
  hour_end: Object,
  active: boolean,
  id: number,
}

export const useUpdateJourneys = ({ onError, onSuccess }: Props) => {
  const queryClient = useQueryClient()

  return useMutation((params: Params) => updateJourneys(params), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(config.key)
      onSuccess(data)
    },
    onError,
  })
}

export const updateJourneys = async (params: Params) => {
  const response = await fetchWithoutRoot('put')(
    `${endpoint(params?.entityType, params?.entityId)}/${params?.id}`,
    {
      data: {
        name: params?.name,
        hour_initial: params?.hour_initial.format('HH:mm:ss'),
        hour_end: params?.hour_end.format('HH:mm:ss'),
        active: params?.active,
      },
    }
  )

  if (response[0]?.message) {
    throw new Error(response[0]?.message)
  }
}
