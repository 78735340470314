// @flow

const isServerSide = () => typeof window === 'undefined'

// Formats floating point numbers to use commas +
// selected number of decimal places
export const formatDecimalNumber =
  (digits: number = 2) =>
  (value?: number | string | null) => {
    if (value === null || value === undefined) return '-'

    if (isServerSide()) {
      return Number(value).toFixed(digits)
    }

    const formatter = new window.Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
    })

    return formatter.format(value)
  }

// Formats floating point numbers to use commas + percent +
// selected number of decimal places
export const formatDecimalPercent =
  (digits: number = 2) =>
  (value?: number | string | null) => {
    if (value === null || value === undefined) return '-'

    if (isServerSide()) {
      return Number(value).toFixed(digits)
    }

    const formatter = new window.Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
      style: 'percent',
    })

    return formatter.format(value)
  }

export const onlyNumbers = (value?: ?string) =>
  !value ? '' : value.replace(/[^\d]/g, '')
