//@flow
import React, { useEffect } from 'react'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import Tooltip from './Tooltip'
import { StartButtonContainer, StartButton } from './styles'
import { track } from '~resources/ahoy'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import usePrevious from '~hooks/usePrevious'
import useWalkthrough from '~hooks/useWalkthrough'

type Step = {
  title?: string,
  target: string,
  content: string,
  placement?: string,
}
type Props = {|
  steps: Array<Step>,
  name: string,
  autoplay?: boolean,
  showButton?: boolean,
|}
type CallbackData = {
  action: string,
  index: number,
  status: string,
  type: string,
  step: { target: string },
}

// We need a "fake" step added as the first item in the array because it is
// necessary to set stepIndex to 1 if we want the tour to autoplay
const emptyStep = { target: '#noId', content: '' }

export default function Walkthrough({
  steps,
  name,
  autoplay = true,
  showButton = true,
}: Props) {
  const { state, setState } = useWalkthrough()

  const prevWalkthroughAutoplay = usePrevious(autoplay)

  useEffect(() => {
    if (prevWalkthroughAutoplay !== autoplay) {
      if (
        autoplay &&
        localStorage.getItem(`_focobr_${name}_tour_played`) !== 'true'
      ) {
        track('Walkthrough / First view', { name: name })
        setState({ ...state, run: true })
      }
      localStorage.setItem(`_focobr_${name}_tour_played`, 'true')
    }
  }, [prevWalkthroughAutoplay, autoplay, state, setState, name])

  const handleClickStart = () => {
    track('Walkthrough / Click start', { name: name })
    setState({ ...state, run: true })
  }

  const handleCallback = ({ action, status, type, step }: CallbackData) => {
    const target = document.querySelector(step.target)

    if (target && type === EVENTS.STEP_BEFORE) {
      track('Walkthrough / View step', {
        name: name,
        step: state.step,
        target: step.target,
      })
      if (target?.scrollIntoView) {
        target.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }

    if (
      [STATUS.SKIPPED, STATUS.FINISHED].includes(status) ||
      action === ACTIONS.CLOSE
    ) {
      track('Walkthrough / Close', {
        name: name,
        step: state.step,
        target: step.target,
      })

      setState({ run: false, step: 1 })
    }
  }

  if (steps.length === 0) return null

  return (
    <>
      <Joyride
        run={state.run}
        steps={[emptyStep, ...steps]}
        stepIndex={state.step}
        callback={handleCallback}
        tooltipComponent={Tooltip}
        scrollOffset={80}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        continuous
        showProgress
      />

      <StartButtonContainer>
        {showButton && (
          <StartButton
            onClick={handleClickStart}
            id="start-walkthrough"
            data-testid="start-walkthrough"
          >
            <SVGIcon name="tour" color="linkColor" size={5} />
            Fazer tour pela página
          </StartButton>
        )}
      </StartButtonContainer>
    </>
  )
}
