// @flow strict
import AccountMember from '~assets/svg/icon_account_member.svg'
import Book from '~assets/svg/icon_book.svg'
import Book2 from '~assets/svg/icon_book_2.svg'
import Add from '~assets/svg/icon_add.svg'
import AddCircle from '~assets/svg/icon_add_circle.svg'
import Calendar from '~assets/svg/icon_calendar.svg'
import CalendarCheck from '~assets/svg/icon_calendar_check.svg'
import CaretDown from '~assets/svg/icon-caret-down.svg'
import Checkmark from '~assets/svg/icon_checkmark.svg'
import Check from '~assets/svg/check.svg'
import CheckBadge from '~assets/svg/icon_check_badge.svg'
import BackArrow from '~assets/svg/icon_back_arrow.svg'
import Clock from '~assets/svg/icon_clock.svg'
import ClockCircle from '~assets/svg/icon_clock_circle.svg'
import ChartSimple from '~assets/svg/icon_chart_simple.svg'
import ChevronUp from '~assets/svg/icon_chevron_up.svg'
import ChevronDown from '~assets/svg/icon_chevron_down.svg'
import ChevronUpBold from '~assets/svg/icon_chevron_up_bold.svg'
import ChevronDownBold from '~assets/svg/icon_chevron_down_bold.svg'
import ChevronLeft from '~assets/svg/icon_chevron_left.svg'
import ChevronRight from '~assets/svg/icon_chevron_right.svg'
import Close from '~assets/svg/icon_close.svg'
import DragDotHandle from '~assets/svg/icon_drag_dot_handle.svg'
import GridDots from '~assets/svg/icon-grid-dots.svg'
import EditIcon from '~assets/svg/icon_edit.svg'
import Eye from '~assets/svg/icon_eye.svg'
import Identifier from '~assets/svg/icon_identifier.svg'
import LogoutIcon from '~assets/svg/icon_logout.svg'
import Mail from '~assets/svg/icon_mail.svg'
import PaperPlane from '~assets/svg/icon_paper_plane.svg'
import Password from '~assets/svg/icon_password.svg'
import Photo from '~assets/svg/icon_photo.svg'
import ShowPassword from '~assets/svg/icon_show_password.svg'
import HidePassword from '~assets/svg/icon_hide_password.svg'
import Sort from '~assets/svg/icon_sort.svg'
import WhatsApp from '~assets/svg/icon_whatsapp.svg'
import ShareIcon from '~assets/svg/share_icon.svg'
import WhatsAppSolid from '~assets/svg/whatsapp_logo_solid.svg'
import List from '~assets/svg/icon_list.svg'
import Warning from '~assets/svg/icon_warning.svg'
import Options from '~assets/svg/icon_options.svg'
import History from '~assets/svg/history_switch.svg'
import Anchor from '~assets/svg/icon_anchor.svg'
import Search from '~assets/svg/icon_search.svg'
import EnemIcon from '~assets/svg/menu_enem.svg'
import FocoExamsEditions from '~assets/svg/foco-exams-editions.svg'
import IndicatorsIcon from '~assets/svg/menu_indicators.svg'
import SchoolIcon from '~assets/svg/menu_school.svg'
import StudentsIcon from '~assets/svg/menu_students.svg'
import Blog from '~assets/svg/blog.svg'
import Tour from '~assets/svg/tour_start.svg'
import Sirene from '~assets/svg/icon_sirene.svg'
import Medal from '~assets/svg/icon_medal.svg'
import Megaphone from '~assets/svg/icon_megaphone.svg'
import CheckCircle from '~assets/svg/icon_check_circle.svg'
import CloseCircle from '~assets/svg/icon_close_circle.svg'
import TripOrigin from '~assets/svg/trip_origin.svg'
import RadioButtonUnchecked from '~assets/svg/radio_button_unchecked.svg'
import LibraryBooks from '~assets/svg/library_books.svg'
import Assignment from '~assets/svg/icon_assignment.svg'
import AssignmentTurnedIn from '~assets/svg/icon_assignment_turned_in.svg'
import AssignmentInd from '~assets/svg/assignment_ind.svg'
import CollectionsBookmark from '~assets/svg/collections_bookmark.svg'
import GridOn from '~assets/svg/grid_on.svg'
import IconAlert from '~assets/svg/icon_negative.svg'
import DragHandle from '~assets/svg/drag_handle.svg'
import ExternalLink from '~assets/svg/aberto.svg'
import Expand from '~assets/svg/icon_expand.svg'
import People from '~assets/svg/icon_people.svg'
import Like from '~assets/svg/like_icon.svg'
import CloudUpload from '~assets/svg/cloud_upload_icon.svg'
import School from '~assets/svg/icon_school.svg'
import Student from '~assets/svg/coloricon-student.svg'
import ReferenceDocs from '~assets/svg/icon_reference_docs.svg'
import IconRounded from '~assets/svg/icon_rounded.svg'
import IconCircle from '~assets/svg/icon_circle.svg'
import DownloadIcon from '~assets/svg/icon_download_file.svg'
import Print from '~assets/svg/icon_print.svg'
import Increase from '~assets/svg/icon_increase.svg'
import Decrease from '~assets/svg/icon_decrease.svg'
import MobileMenu from '~assets/svg/icon_mobile_menu.svg'
import Notification from '~assets/svg/notification-icon.svg'
import NotificationOpen from '~assets/svg/notification-icon-active.svg'
import IconHome from '~assets/svg/icon-home.svg'
import FocoExams from '~assets/svg/foco_exams.svg'
import IcnInstagram from '~assets/svg/icn-instagram.svg'
import IcnFacebook from '~assets/svg/icn-facebook.svg'
import IcnYoutube from '~assets/svg/icn-youtube.svg'
import IcnWhatsappGroup from '~assets/svg/icn-whatsapp-group.svg'
import IcnWhatsapp from '~assets/svg/icn-whatsapp.svg'
import ExportButton from '~assets/svg/export_button.svg'
import Delete from '~assets/svg/delete.svg'
import EllipsisVertical from '~assets/svg/icon-ellipsis-vertical.svg'
import MenuHamburger from '~assets/svg/icon-menu-hamburguer.svg'
import MenuDashboard from '~assets/svg/icon_menu_dashboard.svg'
import MenuSchoolResult from '~assets/svg/icon_menu_school_result.svg'
import MenuFocoteca from '~assets/svg/icon_menu_focoteca.svg'
import MenuCurriculum from '~assets/svg/icon_menu_curriculum.svg'
import MenuExams from '~assets/svg/icon_menu_exams.svg'
import MenuManagement from '~assets/svg/icon_menu_management.svg'
import CommonArrow from '~assets/svg/common-arrow.svg'
import HandleFilterOpen from '~assets/svg/common-filter-icon.svg'
import DayFilter from '~assets/svg/day-filter-icon.svg'
import WeekFilter from '~assets/svg/week-filter-icon.svg'
import PaperPlaneLocked from '~assets/svg/icon-paper-plane-locked.svg'
import PaperPlaneFilled from '~assets/svg/icon_paper_plane_filled.svg'
import IconChevron from '~assets/svg/icon_chevron_down_1.svg'
import FocoBI from '~assets/svg/bi.svg'
import ProgressLineChart from '~assets/svg/icon_progress_line_chart.svg'
import Info from '~assets/svg/icon_info.svg'

export default {
  accountMember: { src: AccountMember, alt: 'Ícone de usuário' },
  edit: { src: EditIcon, alt: 'Editar' },
  eye: { src: Eye, alt: 'Visualização' },
  logout: { src: LogoutIcon, alt: 'Sair' },
  showPassword: { src: ShowPassword, alt: 'Mostrar senha' },
  hidePassword: { src: HidePassword, alt: 'Esconder senha' },
  backArrow: { src: BackArrow, alt: 'Voltar' },
  clockCircle: { src: ClockCircle, alt: 'Relógio' },
  clock: { src: Clock, alt: 'Relógio' },
  chartSimple: { src: ChartSimple, alt: 'Gráfico de Colunas' },
  closeDropdown: { src: ChevronUp, alt: 'Fechar' },
  closeDropdownBold: { src: ChevronUpBold, alt: 'Fechar' },
  openDropdown: { src: ChevronDown, alt: 'Abrir' },
  openDropdownBold: { src: ChevronDownBold, alt: 'Abrir' },
  next: { src: ChevronRight, alt: 'Próximo' },
  previous: { src: ChevronLeft, alt: 'Anterior' },
  photo: { src: Photo, alt: 'Fotografia' },
  add: { src: Add, alt: 'Adicionar' },
  addCircle: { src: AddCircle, alt: 'Adicionar' },
  close: { src: Close, alt: 'Fechar' },
  sort: { src: Sort, alt: 'Reordenar' },
  mail: { src: Mail, alt: 'Email' },
  password: { src: Password, alt: 'Senha' },
  paperPlane: { src: PaperPlane, alt: 'Avião de Papel / Enviar' },
  date: { src: Calendar, alt: 'Data' },
  calendarCheck: { src: CalendarCheck, alt: 'Calendário' },
  identifier: { src: Identifier, alt: 'CPF' },
  whatsapp: { src: WhatsApp, alt: 'WhatsApp' },
  shareIcon: { src: ShareIcon, alt: 'Share Icon' },
  whatsappSolid: { src: WhatsAppSolid, alt: 'WhatsApp' },
  checkmark: { src: Checkmark, alt: 'OK' },
  check: { src: Check, alt: 'OK' },
  checkBage: { src: CheckBadge, alt: 'OK' },
  list: { src: List, alt: 'Ver lista' },
  warning: { src: Warning, alt: 'Aviso' },
  options: { src: Options, alt: 'Opções' },
  history: { src: History, alt: 'Histórico' },
  anchor: { src: Anchor, alt: 'Acessar link' },
  search: { src: Search, alt: 'Pesquisar' },
  prospective: { src: StudentsIcon, alt: 'Resultados dos alunos' },
  retrospective: { src: SchoolIcon, alt: 'Resultados da escola' },
  indicators: { src: IndicatorsIcon, alt: 'Indicadores' },
  enem: { src: EnemIcon, alt: 'ENEM' },
  focoExamEditions: { src: FocoExamsEditions, alt: 'Avaliações' },
  blog: { src: Blog, alt: 'Blog' },
  tour: { src: Tour, alt: 'Tour' },
  negativeResult: { src: Sirene, alt: 'Crítico' },
  positiveResult: { src: Medal, alt: 'Destaque' },
  regularResult: { src: Megaphone, alt: 'Regular' },
  checkCircle: { src: CheckCircle, alt: 'Acerto' },
  closeCircle: { src: CloseCircle, alt: 'Erro' },
  tripOrigin: { src: TripOrigin, alt: 'Trip Origin' },
  radioButtonUnchecked: { src: RadioButtonUnchecked, alt: 'Radio Button' },
  libraryBooks: { src: LibraryBooks, alt: 'Biblioteca' },
  assigment: { src: Assignment, alt: 'Distribuição das respostas' },
  assigmentTurnedIn: {
    src: AssignmentTurnedIn,
    alt: 'Explicação dos distratores',
  },
  assigmentInd: { src: AssignmentInd, alt: 'Listagem de alunos' },
  collectionsBookmark: {
    src: CollectionsBookmark,
    alt: 'Referências pedagógicas',
  },
  gridOn: { src: GridOn, alt: 'Referência na matriz' },
  iconAlert: { src: IconAlert, alt: 'Alerta' },
  dragHandle: { src: DragHandle, alt: 'Arrastar' },
  dragDotHandle: { src: DragDotHandle, alt: 'Arrastar' },
  externalLink: { src: ExternalLink, alt: 'Link externo' },
  expand: { src: Expand, alt: 'Expandir' },
  people: { src: People, alt: 'Aluno' },
  like: { src: Like, alt: 'Recomendar' },
  cloudUpload: { src: CloudUpload, alt: 'Upload de arquivo' },
  school: { src: School, alt: 'Escola' },
  student: { src: Student, alt: 'Aluno' },
  referenceDocs: { src: ReferenceDocs, alt: 'Documentos de referência' },
  iconCircle: { src: IconCircle, alt: 'Nulo' },
  iconRounded: { src: IconRounded, alt: 'Não aplicável' },
  iconDownload: { src: DownloadIcon, alt: 'Download' },
  iconPrint: { src: Print, alt: 'Imprimir' },
  increase: { src: Increase, alt: 'Aumento' },
  decrease: { src: Decrease, alt: 'Queda' },
  menuMobile: { src: MobileMenu, alt: 'Menu mobile' },
  iconNotification: { src: Notification, alt: 'Notificações' },
  iconNotificationOpen: { src: NotificationOpen, alt: 'Notificações' },
  book: { src: Book, alt: 'Livro' },
  book2: { src: Book2, alt: 'Livro' },
  iconHome: { src: IconHome, alt: 'Página inicial' },
  icnInstagram: { src: IcnInstagram, alt: 'Ícone Instagram' },
  icnFacebook: { src: IcnFacebook, alt: 'Ícone Facebook' },
  icnYoutube: { src: IcnYoutube, alt: 'Ícone Youtube' },
  icnWhatsappGroup: { src: IcnWhatsappGroup, alt: 'Ícone Whatsapp' },
  icnWhatsapp: { src: IcnWhatsapp, alt: 'Ícone Whatsapp' },
  focoExam: { src: FocoExams, alt: 'Avaliação' },
  exportButton: { src: ExportButton, alt: 'Ícone de exportar' },
  delete: { src: Delete, alt: 'Ícone de deletar' },
  gridDots: { src: GridDots, alt: 'Ícone grade de pontos' },
  medal: { src: Medal, alt: 'Ícone de medalha' },
  caretDown: { src: CaretDown, alt: 'Ícone de dropdown' },
  ellipsisVertical: {
    src: EllipsisVertical,
    alt: 'Ícone de elipse na vertical',
  },
  menuHamburger: {
    src: MenuHamburger,
    alt: 'Ícone menu hamburguer',
  },
  menuDashboard: { src: MenuDashboard, alt: 'Icone do paínel' },
  menuSchoolResult: {
    src: MenuSchoolResult,
    alt: 'Icone do resultados da escola',
  },
  menuFocoteca: { src: MenuFocoteca, alt: 'Icone do Focoteca' },
  menuCurriculum: { src: MenuCurriculum, alt: 'Icone do Currículo' },
  menuExams: { src: MenuExams, alt: 'Icone de Exames' },
  menuManagement: { src: MenuManagement, alt: 'Icone de Cadastro' },
  commonArrow: { src: CommonArrow, alt: 'Seta' },
  handleFilterOpen: { src: HandleFilterOpen, alt: 'Abrir filtro' },
  dayFilter: { src: DayFilter, alt: 'Visualizar por dia' },
  weekFilter: { src: WeekFilter, alt: 'Visualizar por semana' },
  paperPlaneLocked: {
    src: PaperPlaneLocked,
    alt: 'Impossível enviar resposta',
  },
  paperPlaneFilled: {
    src: PaperPlaneFilled,
    alt: 'Enviar resposta',
  },
  bi: { src: FocoBI, alt: 'Foco BI' },
  arrowDown: { src: IconChevron, alt: 'Icone sinal para baixo' },
  progressLineChart: { src: ProgressLineChart, alt: 'Resultados' },
  info: { src: Info, alt: 'Informação' },
}
