// @flow
import React, { type Node, forwardRef, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { Content, Wrapper, Footer, Header, CloseButton } from './styles'
import { Divider } from '~primitives/atoms/Divider'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import { VisuallyHidden } from '~primitives/Utilities/VisuallyHidden'

export type ModalProps = {
  children?: Node,
  closeButton?: boolean,
  closeButtonText?: string,
  closeOnDismiss?: boolean,
  confirmButtonText?: string,
  confirmButtonType?: 'button' | 'reset' | 'submit',
  disableConfirm?: boolean,
  showFooter?: boolean,
  isOpen?: boolean,
  onClose?: () => void,
  onConfirm?: () => void,
  title?: string,
  width?: string,
  height?: string,
  minHeight?: string,
  contentPadding?: string,
  overflowContent?: boolean,
  showFooterDivider?: boolean,
  footerPadding?: string,
}

const defaultProps = {
  isOpen: true,
  closeButton: true,
  closeButtonText: 'Fechar',
  closeOnDismiss: true,
  confirmButtonText: 'Confirmar',
  confirmButtonType: 'button',
  disableConfirm: false,
  showFooter: true,
  showFooterDivider: true,
  onClose: () => {},
  onConfirm: () => {},
  width: '900px',
  height: '80vh',
  contentPadding: '25px 35px',
  footerPadding: '0 35px 25px',
}

const Modal = forwardRef<ModalProps, HTMLDivElement>(
  (props: ModalProps, ref) => {
    const {
      children,
      closeButton,
      closeButtonText,
      closeOnDismiss,
      confirmButtonText,
      confirmButtonType,
      disableConfirm,
      showFooter,
      showFooterDivider,
      isOpen,
      onClose,
      onConfirm,
      title,
      width,
      height,
      minHeight = 'auto',
      contentPadding,
      overflowContent,
      footerPadding,
    } = { ...defaultProps, ...props }

    const [showDialog, setShowDialog] = useState(isOpen)
    const handleClose = () => {
      onClose()
      setShowDialog(false)
    }

    useEffect(() => setShowDialog(isOpen), [isOpen])

    return (
      <Wrapper
        data-testid="modal-wrapper"
        isOpen={showDialog}
        onDismiss={closeOnDismiss ? handleClose : () => {}}
        width={width}
        height={height}
        minHeight={minHeight}
        withHeader={!!title}
      >
        {title && (
          <Header data-testid="modal-header">
            <h1>
              <strong>{title}</strong>
            </h1>
            {closeButton && (
              <Button
                variant="neutral"
                onClick={handleClose}
                data-testid="modal-header-close-btn"
              >
                <VisuallyHidden>Fechar</VisuallyHidden>
                <SVGIcon name="close" color="neutralLight" size={3} />
              </Button>
            )}
          </Header>
        )}

        {!title && closeButton && (
          <CloseButton
            onClick={handleClose}
            data-testid="modal-header-close-btn"
          >
            <SVGIcon name="close" color="textLight" size={5} />
          </CloseButton>
        )}

        <Content
          data-testid="modal-content"
          ref={ref}
          padding={contentPadding}
          overflow={overflowContent}
        >
          {children}
        </Content>

        {showFooter && (
          <Footer data-testid="modal-footer" footerPadding={footerPadding}>
            {showFooterDivider && <Divider />}
            <div>
              {closeButton && (
                <Button
                  data-testid="modal-close-btn"
                  onClick={handleClose}
                  variant="neutral"
                  sx={{ mr: 2, minWidth: 'auto' }}
                  size="small"
                >
                  {closeButtonText}
                </Button>
              )}

              <Button
                data-testid="modal-confirm-btn"
                onClick={onConfirm}
                type={confirmButtonType}
                disabled={disableConfirm}
                variant="primary"
                sx={{ minWidth: 160 }}
                size="small"
              >
                {confirmButtonText}
              </Button>
            </div>
          </Footer>
        )}
      </Wrapper>
    )
  }
)

export default Modal
