//flow

import { Box, Grid, Skeleton, Stack } from '@mui/material'
import React from 'react'

export const TableLoadingPlaceholder = () =>
  Array(5)
    .fill(null)
    .map((_, index) => ({
      fields: [
        <Stack key="week" minWidth={100}>
          <Skeleton variant="text" height={14} width={80} />
          <Skeleton variant="text" height={14} />
        </Stack>,
        <Box key="skill" minWidth={340}>
          <Grid
            container
            direction="row"
            spacing={1}
            sx={{ pr: 12, minWidth: 340 }}
          >
            <Grid item xs={6} lg={4} key={index}>
              <Skeleton variant="rounded" minWidth="100%" height={30} />
            </Grid>
            <Grid item xs={6} lg={4} key={index}>
              <Skeleton variant="rounded" minWidth="100%" height={30} />
            </Grid>
            <Grid item xs={6} lg={4} key={index}>
              <Skeleton variant="rounded" minWidth="100%" height={30} />
            </Grid>
            <Grid item xs={6} lg={4} key={index}>
              <Skeleton variant="rounded" minWidth="100%" height={30} />
            </Grid>
            <Grid item xs={6} lg={4} key={index}>
              <Skeleton variant="rounded" minWidth="100%" height={30} />
            </Grid>
            {index % 2 === 0 && (
              <Grid item xs={6} lg={4} key={index}>
                <Skeleton variant="rounded" minWidth="100" height={30} />
              </Grid>
            )}
          </Grid>
        </Box>,
        <Stack key="minimum-curriculum-unit">
          <Skeleton variant="text" height={14} />
          <Skeleton variant="text" height={14} width={50} />
        </Stack>,
        <Box key="performed-classes" minWidth={140}>
          <Skeleton variant="text" height={14} />
        </Box>,
        <Stack direction="row" gap={1} key="actions">
          <Skeleton variant="circular" height={34} width={34} />
          <Skeleton variant="circular" height={34} width={34} />
        </Stack>,
      ],
      align: 'justify',
      key: `loading-${index}`,
    }))
