// @flow
import { concat, mergeDeepWith } from 'ramda'

export const getFieldsAndIncludes = <T: Object>(
  extensionsConfig: T,
  extensions: $Keys<T>[]
) =>
  extensions.reduce(
    (fields, extensionName) =>
      mergeDeepWith(concat, fields, extensionsConfig[extensionName]),
    extensionsConfig.default
  )
