import { styled } from '@mui/material/styles'

export const Container = styled('article')`
  max-width: 420px;
  padding: 30px;
  text-align: center;
  background: ${({ theme }) => theme.palette.grey[200]};
  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 305px;
  }
`

export const IconWrapper = styled('div')`
  width: 190px;
  height: 160px;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled('h3')`
  font-size: 15px;
  line-height: 20px;
  font-weight: 800;
  margin-top: 20px;
  text-transform: uppercase;
  color: #334449;
`

export const Description = styled('p')`
  max-width: 360px;
  margin: 12px auto;
  font-size: 14px;
  line-height: 22px;
  color: #334449;
`

export const Buttons = styled('footer')`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  a {
    flex: 1 1 auto;
    max-width: 200px;
    width: 100%;
    height: 40px;
    font-weight: 400;
    padding-left: 0;
    padding-right: 0;
    margin: 0.3em 0;
  }
`
