import React from 'react'
import { useParams } from 'react-router-dom'
import FocoExamSchool from '~pages/FocoExams/Teacher/Exam/School'
import FocoExamSector from '~pages/FocoExams/Teacher/Exam/Sector'
import FocoExamDistrict from '~pages/FocoExams/Teacher/Exam/District'

const FocoExamTeacher = () => {
  const { entityType } = useParams()

  switch (entityType) {
    case 'school':
      return <FocoExamSchool />
    case 'sector':
      return <FocoExamSector />
    case 'district':
      return <FocoExamDistrict />
    default:
      return null
  }
}

export default FocoExamTeacher
