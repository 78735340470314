// @flow
import React, { useState } from 'react'
import { Typography, Stack, Button, Box } from '@mui/material'
import Modal from '~primitives/Modal'
import { Form } from '~primitives/Input/Form'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import { FormFeedback } from '~primitives/molecules/FormFeedback'
import { keyboardClick } from '~helpers/events'
import { useAcademicYear } from '~data/api/v3/academicYears/query/findUnique'
import { useDeleteAcademicYear } from '~data/api/v3/academicYears/mutation/delete'
import { useFeedback } from '~contexts/Feedback'

type Props = {
  id: number,
  entityType: string,
  entityId: number,
  onClose: () => void,
}

export const DeleteAcademicYear = ({
  id,
  entityType,
  entityId,
  onClose,
}: Props) => {
  const { showMessage } = useFeedback()

  const [success, setSuccess] = useState(false)

  const { loading, data: academicYear } = useAcademicYear({
    academicYearId: Number(id),
    entityType: entityType,
    entityId: entityId,
  })

  const { mutate: deleteAcademicYear, loading: deleting } =
    useDeleteAcademicYear({
      onError: (error) => {
        showMessage({ type: 'error', text: `Erro: ${error.message}` })
      },
      onSuccess: () => setSuccess(true),
    })

  const handleConfirm = () => {
    deleteAcademicYear({ id, entityType, entityId })
  }

  return (
    <Modal
      width="500px"
      height="98vh"
      overflowContent={true}
      contentPadding="5px 30px 20px"
      showFooter={false}
      onClose={onClose}
    >
      <Form
        onSubmit={(e) => e.preventDefault()}
        noValidate
        onKeyPress={keyboardClick}
        headingSize="24px"
      >
        <header>
          <UnderlinedHeading as="h2" size={7}>
            <Typography component="strong" variant="h4">
              Deletar ano letivo
            </Typography>
          </UnderlinedHeading>
        </header>

        <Box backgroundColor="grey.200" borderRadius={3} p={3}>
          {success ? (
            <FormFeedback
              title="Ano letivo deletado!"
              text="O ano letivo foi excluído com sucesso."
            />
          ) : (
            <FormFeedback
              type="warning"
              title="Atenção!"
              text={
                <>
                  Tem certeza que você deseja deletar{' '}
                  <strong>{academicYear?.year}</strong> da sua lista?
                </>
              }
            />
          )}
        </Box>

        <Stack direction="row-reverse" component="footer">
          {!success && (
            <Button
              variant="primary"
              type="submit"
              onClick={handleConfirm}
              disabled={loading || deleting}
            >
              Confirmar
            </Button>
          )}

          <Button
            variant={success ? 'primary' : 'transparent'}
            onClick={onClose}
          >
            Voltar
          </Button>
        </Stack>
      </Form>
    </Modal>
  )
}
