// @flow
import { useState } from 'react'
import { useQuery } from 'react-query'
import useUser from '~data/user'
import type {
  APIResponse,
  PaginatedAPIResponse,
  NetworkError,
} from '~types/Network'

type AnyAPIResponse<T> = APIResponse<T> | PaginatedAPIResponse<T>
type ResourceFn<P, R> = (P) => AnyAPIResponse<R>

type Options<P, R> = {|
  resource: ResourceFn<P, R>,
  params?: P,
  key?: string,
  enabled?: boolean,
  staleTime?: number,
  keepPreviousData?: boolean,
|}

const useResource = <P, R>({
  resource,
  params = {},
  key = resource.name,
  enabled = true,
  staleTime = Infinity,
  keepPreviousData = true,
}: Options<P, R>): { error: ?NetworkError, data: ?R, loading: boolean } => {
  const { selectedProfile } = useUser()
  const [loading, setLoading] = useState(false)

  const queryKey = [key, selectedProfile, params]

  const fetchData = () =>
    new Promise((resolve) => {
      setLoading(true)
      resource(params).then((data) => {
        setLoading(false)
        resolve(data)
      })
    })

  const queryOptions = { enabled, staleTime, keepPreviousData }

  const query = useQuery(queryKey, fetchData, queryOptions)

  const [error, data] = query.data ?? []

  return { loading, error, data }
}

export default useResource
