import axios from 'axios'

import store from '~store'

let countRequests = 0

axios.interceptors.request.use(
  (config) => {
    countRequests += 1
    store.dispatch({ type: 'BEGIN_REQUEST' })

    return config
  },
  (error) => Promise.reject(error)
)

axios.interceptors.response.use(
  (response) => {
    countRequests -= 1
    if (countRequests == 0) store.dispatch({ type: 'END_REQUEST' })

    return response
  },
  (error) => {
    countRequests -= 1
    store.dispatch({ type: 'END_REQUEST' })

    return Promise.reject(error)
  }
)
