// @flow
import { styled } from '@mui/material/styles'
import ShareIconHover from '~assets/svg/share-icon-hover.svg'

export const Container = styled('div')`
  flex: ${({ customization }) =>
    customization == 'notification' ? 'auto' : '1 0 100%'};
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: flex-end;
  border-top: ${({ customization }) =>
    customization == 'notification' ? 'none' : '1px solid #e1e1e1'};
  margin-top: ${({ customization }) =>
    customization == 'notification' ? '0' : '8px'};
  padding-top: ${({ customization }) =>
    customization == 'notification' ? '0' : '8px'};

  a {
    display: flex;
    align-items: center;
  }

  p {
    font-size: 14px;
    margin: 0 6px 0 0;
  }

  &:hover {
    .share-icon {
      background-color: ${({ theme }) => theme.palette.grey[200]};
      .svg-icon {
        mask-image: url(${ShareIconHover});
      }
    }

    p {
      color: ${({ theme }) => theme.palette.secondary.darker};
    }
  }
`

export const ShareIcon = styled('i')`
  height: 23px;
  width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  > i {
    font-size: 14px;
  }
`
