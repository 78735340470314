//@flow
export default function HelperText() {
  return {
    MuiFormHelperText: {
      variants: [],
      styleOverrides: {
        root: {
          position: 'absolute',
          bottom: '2px',
          margin: 0,
        },
      },
    },
  }
}
