// @flow
import { useMutation, useQueryClient } from 'react-query'
import { config } from '../../config'
import { fetchWithRoot } from '~helpers/fetch'

const { endpoint } = config

type Props = {
  onSuccess: (Object) => void,
  onError: (Object) => void,
}

type Params =
  | {
      entityType: 'district',
      entityId: number,
      year: number,
      initialDate: Object,
      endDate: Object,
      periodType: string,
      expectedTotalDays: number,
    }
  | {
      entityType: 'school',
      entityId: number,
      year: number,
      parentAcademicYear: number,
      initialDate: Object,
      endDate: Object,
      periodType: string,
      expectedTotalDays: number,
      curriculumPeriods: Array<{
        parentCurriculumPeriod: number,
        name: string,
        startDate: Object,
        endDate: Object,
      }>,
    }

export const useCreateAcademicYear = ({ onError, onSuccess }: Props) => {
  const queryClient = useQueryClient()

  return useMutation((params: Params) => createAcademicYear(params), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(config.key)
      onSuccess(data)
    },
    onError,
  })
}

export const createAcademicYear = async (params: Params) => {
  const createParams =
    params.entityType === 'school'
      ? {
          year: params.year,
          parent_academic_year_id: params.parentAcademicYear,
          initial_date: params.initialDate.format('YYYY-MM-DD'),
          end_date: params.endDate.format('YYYY-MM-DD'),
          period_type: params.periodType,
          expected_total_days: params.expectedTotalDays,
          curriculum_periods_attributes: params.curriculumPeriods.map(
            ({ parentCurriculumPeriod, name, startDate, endDate }) => ({
              entity_type: 'school',
              entity_id: params.entityId,
              name,
              parent_curriculum_period_id: parentCurriculumPeriod,
              start_date: startDate.format('YYYY-MM-DD'),
              end_date: endDate.format('YYYY-MM-DD'),
            })
          ),
        }
      : {
          year: params.year,
          initial_date: params.initialDate.format('YYYY-MM-DD'),
          end_date: params.endDate.format('YYYY-MM-DD'),
          period_type: params.periodType,
          expected_total_days: params.expectedTotalDays,
        }

  const [error, data] = await fetchWithRoot('post')(
    endpoint(params.entityType, params.entityId),
    {
      data: createParams,
      bypassLoader: true,
    }
  )

  if (error) throw new Error(error.message)

  return data
}
