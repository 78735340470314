// @flow
import { fetchWithRoot } from '~helpers/fetch'
import useResource from '~hooks/useResource'
import type { APIResponse } from '~types/Network'

export const endpoint = '/api/v3/foco_exam_editions'

type Params = {
  entityType?: string,
  entityId?: string,
  focoExamEditionId?: string,
}

type Grade = {
  id: string,
  name: string,
}

type Subject = {
  id: string,
  name: string,
}

type FocoExam = {
  id: string,
  name: string,
  grade_subjects: { grade: Grade, subject: Subject }[],
}

type FocoExams = {
  foco_exam_editions: Array<FocoExam>,
}

export const useManageBookletFilterExam = (params: Params) => {
  const { data, error, loading } = useResource({
    key: 'useManageBookletFilterExam',
    resource: getManageBookletFilterExam,
    params,
    enabled:
      !!params?.entityType && !!params?.entityId && !!params?.focoExamEditionId,
  })

  return { loading, error, data }
}

const getManageBookletFilterExam = ({
  entityType,
  entityId,
  focoExamEditionId,
}: Params): APIResponse<FocoExams> =>
  fetchWithRoot('get')(endpoint, {
    params: {
      entity_type: entityType,
      entity_id: entityId,
      include: [
        {
          foco_exam_editions: [{ grade_subjects: ['grade', 'subject'] }],
        },
      ],
      fields: [
        {
          foco_exam_editions: [
            'id',
            'name',
            {
              grade_subjects: [
                { grade: ['id', 'name'] },
                { subject: ['id', 'name'] },
              ],
            },
          ],
        },
      ],
      q: {
        id_eq: focoExamEditionId,
      },
    },
  })
