import { onlyNumbers } from './formatting/numbers'

export const validateEmail = (value) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)

export const validateDate = (date = '') => {
  const rgx =
    /^(?:(?:(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(\/|-|\.|\x20)(?:0?2\1(?:29)))|(?:(?:(?:1[6-9]|[2-9]\d)?\d{2})(\/|-|\.|\x20)(?:(?:(?:0?[13578]|1[02])\2(?:31))|(?:(?:0?[1,3-9]|1[0-2])\2(29|30))|(?:(?:0?[1-9])|(?:1[0-2]))\2(?:0?[1-9]|1\d|2[0-8]))))$/
  const dateToTest = date.replace(/^(\d+)\/(\d+)\/(\d+)$/, '$3-$2-$1')

  return rgx.test(dateToTest)
}

export const validateCPF = (cpf) => {
  const string = onlyNumbers(cpf)
  let sum = 0
  let rest

  if (!string) {
    return false
  }

  if (
    string.length !== 11 ||
    string === '00000000000' ||
    string === '11111111111' ||
    string === '22222222222' ||
    string === '33333333333' ||
    string === '44444444444' ||
    string === '55555555555' ||
    string === '66666666666' ||
    string === '77777777777' ||
    string === '88888888888' ||
    string === '99999999999'
  ) {
    return false
  }

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(string.substring(i - 1, i), 10) * (11 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(string.substring(9, 10), 10)) {
    return false
  }

  sum = 0

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(string.substring(i - 1, i), 10) * (12 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(string.substring(10, 11), 10)) {
    return false
  }

  return true
}

export const validateCellphone = (input) =>
  onlyNumbers(input).length === 10 || onlyNumbers(input).length === 11
