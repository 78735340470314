// @flow

type BlobConfig = {| data: string, fileName: string |}

export const saveBlob = ({ data, fileName }: BlobConfig) => {
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', fileName)
  document.body?.appendChild(link)
  link.click()
  document.body?.removeChild(link)
}
