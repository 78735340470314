// @flow
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'
import { useSelector } from 'react-redux'
import Events from '~components/Curriculum/Events'
import { Content } from '~components/Curriculum'
import CreateCurriculum from '~components/Curriculum/Modals/CreateCurriculum'
import { FiltersProvider } from '~contexts/Filters'
import useEntity from '~data/entity'
import filters from '~resources/filters'
import { pxToRem } from '~helpers/getFontValue'
import { Breadcrumbs } from '~primitives/Breadcrumbs'
import { Scribble } from '~primitives/Typography/Underline'
import { getSelectedProfile } from '~selectors/profile'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import FilterBar from '~components/Common/FilterBar'

export const Curriculum = () => {
  const profile = useSelector(getSelectedProfile)
  const { entityType, entityId } = useParams()
  const { data } = useEntity('school', String(entityId))

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const breadcrumbList = {
    district: [
      { id: 'curriculum', text: 'Currículo', pathname: '#' },
      {
        id: 'curriculum-district',
        text: 'Rede',
        pathname: `/curriculo/district/${data?.district?.id ?? ''}/gestao`,
      },
      {
        id: 'curriculum-sector',
        text: 'Regional',
        pathname: `/curriculo/sector/${data?.sector?.id ?? ''}/gestao`,
      },
      {
        id: 'curriculum-aplication',
        text: 'Aplicação',
        pathname: '#',
      },
    ],
    sector: [
      { id: 'curriculum', text: 'Currículo', pathname: '#' },
      {
        id: 'curriculum-sector',
        text: 'Regional',
        pathname: `/curriculo/sector/${data?.districtt?.id ?? ''}/gestao`,
      },
      {
        id: 'curriculum-aplication',
        text: 'Aplicação',
        pathname: '#',
      },
    ],
    school: [
      { id: 'curriculum', text: 'Currículo', pathname: '#' },
      {
        id: 'curriculum-aplication',
        text: 'Aplicação',
        pathname: '#',
      },
    ],
  }

  return (
    <FiltersProvider
      config={{
        curriculum_period_id: {
          filter: 'curriculum_periods',
          label: 'Período',
        },
        subject_id: { filter: 'subjects', label: 'Disciplina' },
        grade_id: { filter: 'grades', label: 'Série' },
        classroom_id: { filter: 'classrooms', label: 'Turma' },
      }}
      resource={filters.getCurriculumFilters}
      additionalParams={{
        entityType,
        entityId,
      }}
    >
      <Box sx={{ px: 8 }}>
        <Breadcrumbs margin="0" items={breadcrumbList[profile.kind]} />
        <Typography
          sx={{ fontSize: pxToRem(24), fontWeight: 700, my: pxToRem(6) }}
        >
          <Scribble>Aplicação do currículo</Scribble>
          <Button
            onClick={() => {
              setModalIsOpen(true)
            }}
            variant="primary"
            size="small"
            startIcon={<SVGIcon name="addCircle" color="white" />}
            sx={{
              minWidth: '200px',
              display: 'flex',
              float: { xs: 'none', sm: 'right' },
            }}
          >
            Adicionar plano de aula
          </Button>
        </Typography>

        <FilterBar contained margin="20px 0" reloadOnChange={false} />

        <Content />

        <Events />

        {entityId && modalIsOpen && (
          <CreateCurriculum
            entityId={entityId}
            setModalIsOpen={setModalIsOpen}
          />
        )}
      </Box>
    </FiltersProvider>
  )
}

export default Curriculum
