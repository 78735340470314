// @flow
import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { FiltersProvider } from '~contexts/Filters'
import filters from '~resources/filters'
import { Breadcrumbs } from '~primitives/Breadcrumbs'
import Calendar from '~components/Curriculum/Calendar'

export const CurriculumCalendar = () => {
  const { entityType, entityId } = useParams()
  return (
    <FiltersProvider
      config={{
        grade_id: { filter: 'grades', label: 'Série' },
        curriculum_competencie_id: {
          filter: 'curriculum_competencies',
          label: 'Competencias',
        },
      }}
      resource={filters.getCurriculumFiltersCalendar}
      additionalParams={{ entityType, entityId }}
    >
      <Box
        sx={{
          px: {
            xs: 2,
            sm: 2,
            md: 2,
            lg: 8,
          },
        }}
      >
        <Breadcrumbs
          margin="0"
          items={[
            { id: 'curriculum', text: 'Currículo', pathname: '#' },
            {
              id: 'curriculum-school',
              text: 'Aplicação',
              pathname: `/curriculo/school/${entityId || 0}`,
            },
            {
              id: 'curriculum-school-calendar',
              text: 'Calendário',
              pathname: `/curriculo/school/${entityId || 0}/calendario`,
            },
          ]}
        />
        <Calendar />
      </Box>
    </FiltersProvider>
  )
}

export default CurriculumCalendar
