//@flow
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import {
  Badge,
  Button,
  Checkbox,
  Chip,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { updateEvent } from './fetch'
import { UnderlinedHeading } from '~primitives/Typography/UnderlinedHeading'
import Modal from '~primitives/Modal'
import { useCurriculumEvent } from '~data/curriculum/event'
import SuccessCheck from '~assets/svg/success_check.svg'

type Props = {
  eventId: number,
  onClose: () => void,
  entityId: number,
  isOpen: boolean,
}

export const Presences = ({ isOpen, onClose, eventId, entityId }: Props) => {
  const { data } = useCurriculumEvent(eventId)

  const [submitError, setSubmitError] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const queryClient = useQueryClient()
  const [selectedRows, setSelectedRows] = useState(
    data?.curriculum_events_student_profiles
      ?.filter((x) => x.presence)
      ?.map((item) => item.student_profile.id) || []
  )

  const handleSelectRow = (studentId) => {
    if (selectedRows.includes(studentId)) {
      setSelectedRows(selectedRows.filter((id) => id !== studentId))
    } else {
      setSelectedRows([...selectedRows, studentId])
    }
  }

  const { mutate } = useMutation(
    ({ data, entityId, eventId }) =>
      updateEvent({
        data,
        entityId,
        eventId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('useCurriculumEvent')
        queryClient.invalidateQueries('useWeekCurriculumEvents')
        queryClient.invalidateQueries('useCurriculumEvents')
        setSubmitSuccess(true)
      },
      onError: () => {
        setSubmitError('Erro ao salvar as alterações')
      },
    }
  )

  const submitFrequency = () => {
    const students = data?.curriculum_events_student_profiles?.map(
      ({ id, student_profile }) => {
        if (!selectedRows?.includes(student_profile.id)) {
          return {
            id,
            presence: false,
            student_profile_id: student_profile.id,
          }
        } else {
          return {
            id,
            presence: true,
            student_profile_id: student_profile.id,
          }
        }
      }
    )

    mutate({
      data: {
        performed: true,
        curriculum_events_student_profiles_attributes: students,
      },
      entityId: entityId,
      eventId: eventId,
    })
  }

  return (
    <Modal
      width="700px"
      height="98vh"
      closeOnDismiss={false}
      isOpen={isOpen}
      confirmButtonText="Salvar"
      contentPadding="0 35px"
      showFooter={false}
      footerPadding="0 0 20px"
      onClose={onClose}
    >
      <Typography variant="h4" margin="15px 0">
        <UnderlinedHeading as="span">
          <strong>Registrar frequência</strong>
        </UnderlinedHeading>
      </Typography>
      <Stack variant="modalBox" style={{ display: 'block' }}>
        {!submitSuccess && (
          <>
            <Stack
              justifyContent="space-between"
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              direction={{ xs: 'column', sm: 'row' }}
              pb={2.5}
            >
              <Chip
                size="medium"
                color="info"
                label={`${data?.classroom.name || ''} - ${
                  data?.subject.name || ''
                }`}
              />

              <Stack
                alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
                mt={{ xs: 1, sm: 0 }}
              >
                <Stack direction="column" gap={1}>
                  <Typography variant="body2">
                    <strong>Data</strong> {getFormattedDate(data?.date)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Horário</strong>{' '}
                    {`${getFormattedHour(
                      data?.start_time
                    )} - ${getFormattedHour(data?.end_time)}`}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Divider />
            {submitError && (
              <Typography variant="body2" sx={{ color: 'error.main' }}>
                {submitError}
              </Typography>
            )}
            <Table variant="striped" stickyHeader>
              <TableBody>
                {data?.curriculum_events_student_profiles?.map(
                  (item, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={{ maxWidth: 20 }}>
                        <Stack direction="row" alignItems="center" gap={2}>
                          <Checkbox
                            checked={selectedRows?.includes(
                              item.student_profile.id
                            )}
                            onChange={() =>
                              handleSelectRow(item.student_profile.id)
                            }
                          />
                          <Badge
                            variant="index"
                            badgeContent={index + 1}
                            color="secondary"
                          />
                          <Typography variant="body2" fontWeight={700}>
                            {item.student_profile.user.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </>
        )}

        {submitSuccess && (
          <Stack
            direction="column"
            alignItems="center"
            gap={1}
            padding="60px 0px"
          >
            <img src={SuccessCheck} alt="" width="90px" />
            <Typography variant="body2">
              <strong>Frequência registrada com sucesso!</strong>
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack direction="row" justifyContent="flex-end" my={2}>
        {!submitSuccess && (
          <>
            <Button variant="neutral" size="small" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="primary"
              size="small"
              onClick={submitFrequency}
            >
              Salvar
            </Button>
          </>
        )}

        {submitSuccess && (
          <Button variant="primary" size="small" onClick={onClose}>
            Voltar
          </Button>
        )}
      </Stack>
    </Modal>
  )
}

const getFormattedHour = (time?: string) => {
  const date = dayjs(time)
  if (date.minute() === 0) {
    return date.format('H[h]')
  }
  return date.format('H:mm[h]')
}

const getFormattedDate = (date?: string) => dayjs(date).format('DD/MM/YYYY')
